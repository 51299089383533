import React, {useContext,useState, useEffect} from 'react';
import { DataGrid } from '@material-ui/data-grid';
import {UserContext} from '../../contexts/UserContext'
import Grid from '@material-ui/core/Grid';
import { makeStyles,withStyles, } from '@material-ui/core/styles';
import customConfig from '../../customConfig.json'
import Checkbox from '@material-ui/core/Checkbox';
import * as moment from 'moment'
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Stavy from '../Zasilky/Stavy/Stavy'
import Polozky from '../Zasilky/Polozky/Polozky'
import Adresy from '../Zasilky/Adresy/Adresy'
import Sluzby from '../Zasilky/Sluzby/Sluzby'
import ZakladniInformace from '../Zasilky/ZakladniInformace/ZakladniInformace'





function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{padding:'0px'}} p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
} 

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    flexDirection:"row",
    justifyContent: 'center',
    backgroundColor: customConfig.Colors.PrimaryColor,
    '& > span': {
      width: '100%',
      backgroundColor: customConfig.Colors.PrimaryColor,
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function MojeZasilky(){

    const initialZasilky = {
        ZasilkyInfo:{
            ZasilkaID:'',
        },
    };    
    const useStyles = makeStyles((theme) => ({
        modal: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        paper: {
          backgroundColor: theme.palette.background.paper,
          border: customConfig.Colors.BorderColor,          
          boxShadow: theme.shadows[5],
          padding: theme.spacing(2, 4, 3),
        },
        margin: {
          margin: theme.spacing(0),
        },
        hide: {
          display: 'none',
        },      
        callContainer: {
          backgroundColor: 'none',
        },
        form: {
          width: '100%', // Fix IE 11 issue.
          marginTop: theme.spacing(1),
          backgroundColor: 'none',
        },
        withoutLabel: {
          marginTop: theme.spacing(1),
        },
        boxStyle:{
          marginTop:'15px'

        },
        nadpis:{
          color: customConfig.Colors.PrimaryTextColor
        },
        left:{
          paddingLeft: '10px',
        },        
        textf: {
          padding: '0px 8px 0px 0px',
        },
        bgGrid: {
          backgroundColor: customConfig.Colors.GridBackgroundColor,
          padding:'0px !important',
          width:'100% !important',
          margin:'0px !important'
        },
        containerX: {
          padding:'0px !important',
          width:'100% !important',
          margin:'0px !important'
        },
        datag: {
          '& .super-app-theme--header': {
            backgroundColor: customConfig.Colors.PrimaryColor,
            color:customConfig.Colors.ButtonTextColor,
          },
          '& .MuiDataGrid-columnsContainer': {
            backgroundColor: customConfig.Colors.PrimaryColor,
            color:customConfig.Colors.ButtonTextColor
          },
          '& .MuiDataGrid-row': {
            cursor:'pointer'
          },
          borderBlockColor:customConfig.Colors.PrimaryColor
        },
        appBar:{
          padding:'0px',
          color:customConfig.Colors.PrimaryColor,
          backgroundColor:customConfig.Colors.PrimaryColor,
          
          
          
      },
      tab:{
          
          color: customConfig.Colors.TextColor,
          backgroundColor:customConfig.Colors.PrimaryColor,
          textTransform:'none',
          '&.Mui-selected': {
              backgroundColor: customConfig.Colors.SecColor,
              color: customConfig.Colors.TextColor,
              
          },
          '&:hover': {
             backgroundColor: customConfig.Colors.SecColor,
             color: customConfig.Colors.TextColor,
             opacity: 1,
          },       
      },
      root: {
        flexGrow: 1,
      },  

        
      }));

 

    const {getZasilky,getStavy,getDruhy} = useContext(UserContext);
    const [zasilky,setState] = useState(initialZasilky);  
    const [Zasilky, setZasilky] = useState([])    
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [StavyZasilek, setStavyZasilek] = useState([]);
    const [DruhyZasilek, setDruhyZasilek] = useState([]);
    const [disable] = React.useState(true);

    useEffect(() => {

      document.title = "SEBAplus - Moje zásilky";
      // getZasilky()
      // .then(data =>
      //     setZasilky(
      //         data)                
      // ); 
      
      // getStavy()
      // .then(datax =>
      //   setStavyZasilek(
      //         datax)                
      // ); 

      // getDruhy()
      // .then(datax =>
      //   setDruhyZasilek(
      //         datax)                
      // ); 


      async function fetchData() {
        const dataOUD = await getZasilky(); 
        const dataOUD2 = await getStavy(); 
        const dataOUD3 = await getDruhy(); 
        setZasilky(dataOUD) 
        setStavyZasilek(dataOUD2) 
        setDruhyZasilek(dataOUD3) 
      }

      fetchData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])   


    const columns = [
        
    
        { field: 'ZasilkaID', type: 'string', hide: true },
        { field: 'StavID', type: 'string', hide: true },
        { field: 'CisloZasilky', type: 'string', headerClassName: 'super-app-theme--header', headerName: 'Číslo SEBAplus', width: 160,}, 
        { field: 'NazavStavu', type: 'string', headerClassName: 'super-app-theme--header', headerName: 'Stav', width: 120}, 
        { field: 'NazevDruhu', type: 'string', headerClassName: 'super-app-theme--header', headerName: 'Druh', width: 120}, 
        { field: 'SluzbaNazev', type: 'string', headerClassName: 'super-app-theme--header', headerName: 'Název služby', width: 200},
        { field: 'Track', type: 'string', headerClassName: 'super-app-theme--header', headerName: 'Track', width: 150,  disableColumnMenu:true},
        {
            field: "DatumZalozeni",
            headerName: "Objednáno",
            headerClassName: 'super-app-theme--header',
            disableColumnMenu:true, 
            sortable: true,
            width: 150,
            renderCell: (params) => {
                
                const api = params.api;
                const fields = api
                  .getAllColumns()
                  .map((c) => c.field)
                  .filter((c) => c !== "__check__" && !!c);
    
                const thisRow = {};
                fields.forEach((f) => {
                  thisRow[f] = params.getValue(f);
                });
                if(thisRow.DatumZalozeni)
                {
                    return (moment(thisRow.DatumZalozeni).format("DD.MM.YYYY"));

                }
                else
                {
                    return("");
                }
            }
        },
        { field: 'Firma', type: 'string', headerClassName: 'super-app-theme--header', headerName: 'Objednavatel', width: 200,},         
        { field: 'Odesilatel', type: 'string', headerClassName: 'super-app-theme--header', headerName: 'Odesílatel', width: 200,}, 
        { field: 'Prijemce', type: 'string', headerClassName: 'super-app-theme--header', headerName: 'Příjemce', width: 200,},
        {
            field: "Vyzvednuto",
            headerName: "Vyzvednuto",
            headerClassName: 'super-app-theme--header',
            disableColumnMenu:true, 
            sortable: true,
            width: 150,
            renderCell: (params) => {
                
                const api = params.api;
                const fields = api
                  .getAllColumns()
                  .map((c) => c.field)
                  .filter((c) => c !== "__check__" && !!c);
    
                const thisRow = {};
                fields.forEach((f) => {
                  thisRow[f] = params.getValue(f);
                });
                
                if(thisRow.Vyzvednuto)
                {
                    return (moment(thisRow.Vyzvednuto).format("DD.MM.YYYY"));

                }
                else
                {
                    return("");
                }
            }
        }, 
        {
            field: "Doruceno",
            headerName: "Doručeno",
            headerClassName: 'super-app-theme--header',
            disableColumnMenu:true, 
            sortable: true,
            width: 150,
            renderCell: (params) => {
                
                const api = params.api;
                const fields = api
                  .getAllColumns()
                  .map((c) => c.field)
                  .filter((c) => c !== "__check__" && !!c);
    
                const thisRow = {};
                fields.forEach((f) => {
                  thisRow[f] = params.getValue(f);
                });
                
                if(thisRow.Doruceno)
                {
                    return (moment(thisRow.Doruceno).format("DD.MM.YYYY"));

                }
                else
                {
                    return("");
                }
            }
        },
        { field: 'Popis', type: 'string', headerClassName: 'super-app-theme--header', headerName: 'Popis', width: 200,disableColumnMenu:true},
      ];



    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    function currentlySelected(v) {
      
        setState({
          ...zasilky,
          ZasilkyInfo:{
              ...zasilky.ZasilkyInfo,
              ZasilkaID:v.ZasilkaID
          },
      });        
    }


  return (


<div className={classes.bgGrid,classes.containerX}>
  <Grid container spacing={2} className={classes.bgGrid}> 
    <Grid item xs={12} sm={12} spacing={2} container className={classes.bgGrid}>     
      <div style={{ minHeight:500, height: '100%', width: '100%' }}>
          <DataGrid className={classes.datag} 
          getRowId={(row) => row.ZasilkaID} 
          AdresypageSize={100} 
          rowHeight={30} 
          rows={Zasilky?Zasilky:[]} 
          pageSize={25} 
          columns={columns}
          
          hideFooterSelectedRowCount="true" 
          pagination
          onRowSelected={(e) => currentlySelected(e.data)}                  
          />
      </div>
      </Grid>

      <Grid item xs={12} sm={12} spacing={2} container className={classes.bgGrid}>     
      <AppBar className={classes.appBar} position="relative" style={{flexDirection: 'row', alignItems:'center'}}>        
          <StyledTabs
              value={value} 
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              centered
              className={classes.tabs}
              aria-label="scrollable force tabs example"
          >
            <Tab className={classes.tab} label="Základní informace" {...a11yProps(0)} />
            <Tab className={classes.tab} label="Obsah zásilky" {...a11yProps(1)} />
            <Tab className={classes.tab} label="Adresy" {...a11yProps(2)} />
            <Tab className={classes.tab} label="Stavy" {...a11yProps(3)} />
            <Tab className={classes.tab} label="Doplňkové služby" {...a11yProps(4)} />
          </StyledTabs>

        </AppBar>      
        <div style={{width: '100%',paddingRight:'0px',marginRight:'0px'}}>
          <TabPanel value={value} index={0}>
            <ZakladniInformace data={[zasilky.ZasilkyInfo.ZasilkaID,StavyZasilek,disable,DruhyZasilek]}/>            
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Polozky data={zasilky.ZasilkyInfo.ZasilkaID}/>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Adresy data={zasilky.ZasilkyInfo.ZasilkaID}/>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Stavy data={zasilky.ZasilkyInfo.ZasilkaID}/>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Sluzby data={zasilky.ZasilkyInfo.ZasilkaID}/>
          </TabPanel>
        </div>
      </Grid>

    </Grid>
</div>
  );
}


export default MojeZasilky