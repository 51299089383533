import React, {useContext,useState, useEffect} from 'react';
import { DataGrid } from '@material-ui/data-grid';
import {UserContext} from '../../contexts/UserContext'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import { makeStyles,withStyles, } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Autocomplete from '@material-ui/lab/Autocomplete';
import customConfig from '../../customConfig.json'
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SaveIcon from '@material-ui/icons/Save';
import Paper from '@material-ui/core/Paper';

function Adresy(){



    const initialAdresy = {
        AdresyInfo:{
            AdresaID:'',
            Nazev:'',
            Oznaceni:'',
            Adresa:'',
            Adresa2:'',
            Adresa3:'',
            Mesto:'',
            psc:'',
            stat:'',
            Email:'',
            Telefon:'',
            KonkaktniOsoba:''
        },
        defaultStat:[],
    };    
    const useStyles = makeStyles((theme) => ({
        modal: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        paper: {
          backgroundColor: theme.palette.background.paper,
          border: customConfig.Colors.BorderColor,          
          boxShadow: theme.shadows[5],
          padding: '0px',
          borderRadius:'10px 10px 8px 8px',
          border:'none'
        },
        paperH: {
            textAlign: 'left',
            paddingLeft:'20px',
            backgroundColor: customConfig.Colors.PrimaryColor,
            boxShadow: 'none',
            border:'1px',
            borderRadius: '8px 8px 0px 0px'
        },
        nadpisH:{
            color: customConfig.Colors.ButtonTextColor,
            fontSize:'20px'
        },
            
        margin: {
          margin: theme.spacing(0),
        },
        hide: {
          display: 'none',
        },      
        callContainer: {
          backgroundColor: 'none',
        },
        form: {
          width: '100%', // Fix IE 11 issue.
          marginTop: theme.spacing(1),
          backgroundColor: 'none',
        },
        nadpis:{
          color: customConfig.Colors.PrimaryTextColor
        },
        textf: {
          padding: '0px 8px 0px 0px',
        },
        datag: {
          '& .super-app-theme--header': {
            backgroundColor: customConfig.Colors.PrimaryColor,
            color:customConfig.Colors.ButtonTextColor,
          },
          '& .MuiDataGrid-columnsContainer': {
            backgroundColor: customConfig.Colors.PrimaryColor,
            color:customConfig.Colors.ButtonTextColor,
          },
          '& .MuiDataGrid-row': {
            cursor:'pointer'
          },
          borderBlockColor:customConfig.Colors.PrimaryColor
        },
        bgGrid: {
          backgroundColor: customConfig.Colors.GridBackgroundColor,
          padding:'0px !important',
          width:'100% !important',
          margin:'0px !important'
        },
        containerX: {
            padding:'0px !important',
            width:'100% !important',
            margin:'0px !important'
        },
        innerPadding:{
            padding: '20px',
          },          
      }));



    const {getAdresy,getStaty,postAdresy,delAdresy} = useContext(UserContext);
    const [Adresy, setAdresy] = useState([])    
    const [Staty, setStaty] = useState([])  
    const [adresy,setState] = useState(initialAdresy);  
    const [open, setOpen] = React.useState(false);
    const [openDel, setOpenDel] = React.useState(false);
    const classes = useStyles();

    useEffect(() => {
        
        document.title = "SEBAplus - Můj adresář";

        async function fetchData() {

            const dataOUD = await getAdresy();    
            const dataOUD2 = await getStaty();    
        
            setAdresy(dataOUD) 
            setStaty(dataOUD2.theStaty.stat) 
        }
        
        fetchData()
            

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])   


    const GreenButton = withStyles((theme) => ({
        root: {
          color: customConfig.Colors.ButtonTextColor, 
          backgroundColor: customConfig.Colors.PrimaryColor,
          fontWeight:'520',
          '&:hover': {
            backgroundColor: customConfig.Colors.SecColor,
          },
        },
      }))(Button);

    const columns = [
        
        { field: 'AdresaID', hide: true },
        { field: 'Nazev', headerName: 'Název kontaktu', width: 250, headerClassName: 'super-app-theme--header', },
        { field: 'Oznaceni', headerName: 'Společnost', width: 250, headerClassName: 'super-app-theme--header', },
        { field: 'KonkaktniOsoba', headerName: 'Kontaktní osoba', width: 200, headerClassName: 'super-app-theme--header', },
        { field: 'Email', headerName: 'Email', width: 180, headerClassName: 'super-app-theme--header', },
        { field: 'Telefon', headerName: 'Telefon', width: 150, headerClassName: 'super-app-theme--header', },
        { field: 'Adresa', headerName: 'Adresa', width: 300, headerClassName: 'super-app-theme--header', },
        { field: 'Adresa2', headerName: 'Adresa2', hide: true },
        { field: 'Adresa3', headerName: 'Adresa3', hide: true },
        { field: 'Mesto', headerName: 'Město', width: 120, headerClassName: 'super-app-theme--header', },
        { field: 'PSC', headerName: 'PSč', width: 100, headerClassName: 'super-app-theme--header', },
        { field: 'StatNazev', headerName: 'Stát', width: 200, headerClassName: 'super-app-theme--header', },
        { field: 'StatKod', hide: true, headerClassName: 'super-app-theme--header', disableColumnMenu:true, },
        {
            field: "del",
            headerName: "Smazání",
            sortable: false,
            headerClassName: 'super-app-theme--header',
            disableColumnMenu:true, 
            width: 90,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                const onClick = () => {
                const api = params.api;
                const fields = api
                  .getAllColumns()
                  .map((c) => c.field)
                  .filter((c) => c !== "__check__" && !!c);
    
                const thisRow = {};
                fields.forEach((f) => {
                  thisRow[f] = params.getValue(f);
                });

                setState({
                    ...adresy,
                    AdresyInfo:{
                        ...adresy.AdresyInfo,
                        AdresaID:thisRow.AdresaID,
                        Nazev:thisRow.Nazev
                        
                    },
                })
                setOpenDel(true);
            }
            
                return (<IconButton onClick={onClick}><DeleteIcon className="fa fa-plus-circle" style={{ color: customConfig.Colors.PrimaryColor }}  /></IconButton>);
            }
        },
      ];

    
      const handleOpenNew = () => {
        setOpen(true);
        setState({
        ...adresy,
            AdresyInfo:initialAdresy.AdresyInfo
          },
        )
      };
    
      const handleCloseNew = () => {
        setOpen(false);
        setState({
        ...adresy,
            AdresyInfo:initialAdresy.AdresyInfo
          },
        )
      };


      const onChangeValue = (e) => {
        setState({
            ...adresy,
            AdresyInfo:{
                ...adresy.AdresyInfo,
                [e.target.name]:e.target.value
            },
        });        
    }

      
    const SubmitPostAdresy = async (event) => {
        
        event.preventDefault();

        await postAdresy(adresy.AdresyInfo);        
        setOpen(false);



        const dataOUD = await getAdresy();    
        setAdresy(dataOUD);

        // getAdresy()
        // .then(data =>
        //     setAdresy(
        //         data)
        // ); 

        setState({
            ...adresy,
                AdresyInfo:initialAdresy.AdresyInfo
            },
        )
    }

    const SubmitDelAdresy = async (event) => {
        
        
        event.preventDefault();

        await delAdresy(adresy.AdresyInfo);

        const dataOUD = await getAdresy();    
        setAdresy(dataOUD);


        setOpenDel(false);
        setState({
            ...adresy,
                AdresyInfo:initialAdresy.AdresyInfo
            },
        )
    }

    const handleDelClose = () => {
        setOpenDel(false);
        setState({
        ...adresy,
            AdresyInfo:initialAdresy.AdresyInfo
          },
        )
    };


    function currentlySelected(v) {
      
        setState({
            ...adresy,
            AdresyInfo:{
                ...adresy.AdresyInfo,
                Nazev:v.Nazev,
                Oznaceni:v.Oznaceni,
                Adresa:v.Adresa,
                Adresa2:v.Adresa2,
                Adresa3:v.Adresa3,
                Mesto:v.Mesto,
                psc:v.PSC,
                AdresaID:v.AdresaID,
                stat:v.StatKod,
                Email:v.Email,
                Telefon:v.Telefon,
                KonkaktniOsoba:v.KonkaktniOsoba
                
            },
            defaultStat: Staty.find(x => x.Kod === v.StatKod) !== undefined ? Staty.find(x => x.Kod === v.StatKod) : null,     
        })

      }


  return (


<div className={classes.bgGrid,classes.containerX}>
    <Grid container spacing={2} className={classes.bgGrid} > 
        <Grid item xs={12} container spacing={2} sm={12} className={classes.bgGrid} > 
            <div style={{ minHeight:500, height: '100%', width: '100%' }}>
                <DataGrid className={classes.datag} getRowId={(row) => row.AdresaID} pageSize={25} onRowSelected={(e) => currentlySelected(e.data)} rowHeight={30} rows={ Adresy?Adresy:[] } hideFooterSelectedRowCount="true" columns={columns} rowsPerPageOptions={[25, 50, 100]} pagination />
            </div>
        </Grid>    
        <Grid item xs={12} spacing={2} container sm={12} > 
            <Grid item xs={12} sm={4}> 
                <TextField name="Nazev" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Nazev" label="Název adresy" required placeholder="Název adresy" value={adresy.AdresyInfo.Nazev} onChange={onChangeValue} />
            </Grid>
            <Grid item xs={12} sm={8}> 
                <TextField name="Oznaceni" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Oznaceni" label="Společnost" required placeholder="Společnost" value={adresy.AdresyInfo.Oznaceni} onChange={onChangeValue} />
            </Grid>
            <Grid item xs={12} sm={4}> 
                <TextField name="KonkaktniOsoba" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="KonkaktniOsoba" label="Kontaktní osoba" required placeholder="Kontaktní osoba" value={adresy.AdresyInfo.KonkaktniOsoba} onChange={onChangeValue} />
            </Grid>
            <Grid item xs={12} sm={4}> 
                <TextField name="Email" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Email" label="Email" required placeholder="Email" value={adresy.AdresyInfo.Email} onChange={onChangeValue} />
            </Grid>
            <Grid item xs={12} sm={4}> 
                <TextField name="Telefon" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Telefon" label="Telefon" required placeholder="Telefon" value={adresy.AdresyInfo.Telefon} onChange={onChangeValue} />
            </Grid>
            <Grid item xs={12} sm={4}> 
                <TextField name="Adresa" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Adresa" label="Adresa" required placeholder="Adresa" value={adresy.AdresyInfo.Adresa} onChange={onChangeValue} />
            </Grid>
            <Grid item xs={12} sm={4}> 
                <TextField name="Adresa2" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Adresa2" label="Adresa 2" placeholder="Adresa 2" value={adresy.AdresyInfo.Adresa2} onChange={onChangeValue} />
            </Grid>
            <Grid item xs={12} sm={4}> 
                <TextField name="Adresa3" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Adresa3" label="Adresa 3" placeholder="Adresa 3" value={adresy.AdresyInfo.Adresa3} onChange={onChangeValue} />
            </Grid>
            <Grid item xs={12} sm={4}> 
                <TextField name="Mesto" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Mesto" label="Město" required placeholder="Město" value={adresy.AdresyInfo.Mesto} onChange={onChangeValue} />
            </Grid>
            <Grid item xs={12} sm={4}> 
                <TextField name="psc" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="psc" label="PSČ" required placeholder="PSČ" value={adresy.AdresyInfo.psc} onChange={onChangeValue} />
            </Grid>   
            <Grid item xs={12} sm={4} container justify="center"> 
                <Autocomplete
                    id="stat"
                    options={Staty}
                    getOptionLabel={(option) => option.Nazev ? option.Nazev : ''}
                    fullWidth
                    value={adresy.defaultStat}                       
                    onChange={(event, newValue) => {
                    if (newValue) {                                  
                        setState({
                            ...adresy,
                            AdresyInfo:{
                                ...adresy.AdresyInfo,
                                stat:newValue.Kod
                            },
                            defaultStat:newValue,
                        });
                    }
                    }}       
                    renderOption={(option) => (
                    <React.Fragment>
                        <span><img src={"../img/flags/24/"+option.Kod+".png"} alt={option.Kod}/></span>
                        {option.Nazev}
                    </React.Fragment>
                    )}
                    renderInput={(params) => <TextField {...params} label="Stát" size="small" variant="outlined" />}
                />
            </Grid>
        </Grid>   

        <Grid item xs={12} spacing={2} container sm={12}> 
            <Grid item xs={12} sm={2}> 
                <GreenButton onClick={handleOpenNew} fullWidth startIcon={<AddIcon />}>Přidat kontakt</GreenButton>
            </Grid>
            <Grid item xs={12} sm={8}/> 
            <Grid item xs={12} sm={2}> 
                <GreenButton fullWidth disabled={adresy.AdresyInfo.AdresaID.length>0?false:true} onClick={SubmitPostAdresy} startIcon={<SaveIcon />} >Uložit</GreenButton>
            </Grid>
        </Grid>
    </Grid>

    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        key="add"
        onClose={handleCloseNew}
        closeAfterTransition
        BackdropComponent={Backdrop}
    >
        <Fade in={open}>
            <div className={classes.paper}>
              <Paper className={classes.paperH}>
                <Typography className={classes.nadpisH}>Přidání nového kontaktu</Typography>
              </Paper>
              <Container className={classes.innerPadding} maxWidth="sm">
                    <Grid container spacing={2}>      
                        <Grid item xs={12} sm={12}> 
                            <TextField name="Nazev" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Nazev" label="Název adresy" required placeholder="Název adresy" value={adresy.AdresyInfo.Nazev} onChange={onChangeValue} />
                        </Grid>
                        <Grid item xs={12} sm={12}> 
                            <TextField name="Oznaceni" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Oznaceni" label="Společnost" required placeholder="Společnost" value={adresy.AdresyInfo.Oznaceni} onChange={onChangeValue} />
                        </Grid>
                        <Grid item xs={12} sm={12}> 
                            <TextField name="KonkaktniOsoba" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="KonkaktniOsoba" label="Kontaktní osoba" required placeholder="Kontaktní osoba" value={adresy.AdresyInfo.KonkaktniOsoba} onChange={onChangeValue} />
                        </Grid>
                        <Grid item xs={12} sm={12}> 
                            <TextField name="Email" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Email" label="Email" required placeholder="Email" value={adresy.AdresyInfo.Email} onChange={onChangeValue} />
                        </Grid>
                        <Grid item xs={12} sm={12}> 
                            <TextField name="Telefon" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Telefon" label="Telefon" required placeholder="Telefon" value={adresy.AdresyInfo.Telefon} onChange={onChangeValue} />
                        </Grid>
                        <Grid item xs={12} sm={12}> 
                            <TextField name="Adresa" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Adresa" label="Adresa" required placeholder="Adresa" value={adresy.AdresyInfo.Adresa} onChange={onChangeValue} />
                        </Grid>
                        <Grid item xs={12} sm={12}> 
                            <TextField name="Adresa2" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Adresa2" label="Adresa 2" placeholder="Adresa 2" value={adresy.AdresyInfo.Adresa2} onChange={onChangeValue} />
                        </Grid>
                        <Grid item xs={12} sm={12}> 
                            <TextField name="Adresa3" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Adresa3" label="Adresa 3" placeholder="Adresa 3" value={adresy.AdresyInfo.Adresa3} onChange={onChangeValue} />
                        </Grid>
                        <Grid item xs={12} sm={12}> 
                            <TextField name="Mesto" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Mesto" label="Město" required placeholder="Město" value={adresy.AdresyInfo.Mesto} onChange={onChangeValue} />
                        </Grid>
                        <Grid item xs={12} sm={12}> 
                            <TextField name="psc" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="psc" label="PSČ" required placeholder="PSČ" value={adresy.AdresyInfo.psc} onChange={onChangeValue} />
                        </Grid>   
                        <Grid item xs={12} sm={12} container justify="center"> 
                            <Autocomplete
                                id="stat"
                                options={Staty}
                                getOptionLabel={(option) => option.Nazev ? option.Nazev : ''}
                                fullWidth                      
                                onChange={(event, newValue) => {
                                if (newValue) {                                  
                                    setState({
                                        ...adresy,
                                        AdresyInfo:{
                                            ...adresy.AdresyInfo,
                                            stat:newValue.Kod
                                        },
                                    });
                                }
                                }}       
                                renderOption={(option) => (
                                <React.Fragment>
                                    <span><img src={"../img/flags/24/"+option.Kod+".png"} alt={option.Kod}/></span>
                                    {option.Nazev}
                                </React.Fragment>
                                )}
                                renderInput={(params) => <TextField {...params} label="Stát" size="small" variant="outlined" />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <GreenButton fullWidth onClick={handleCloseNew} >Zrušit</GreenButton> 
                        </Grid>
                        <Grid item xs={12} sm={4}/> 
                        <Grid item xs={12} sm={4}> 
                            <GreenButton fullWidth onClick={SubmitPostAdresy} startIcon={<SaveIcon />}>Uložit</GreenButton>
                        </Grid>
                    </Grid>

                </Container>
            </div>
        </Fade>
    </Modal>            

    <Dialog
        open={openDel}
        onClose={handleDelClose}
        fullWidth
        maxWidth='xs'
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Opravdu si přejete smazat kontakt: {adresy.AdresyInfo.Nazev} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Grid container spacing={2}>      
                <Grid item xs={12} sm={4}> 
                    <GreenButton fullWidth onClick={handleDelClose}>Zrušit</GreenButton>
                </Grid>
                <Grid item xs={12} sm={4}/> 
                <Grid item xs={12} sm={4}> 
                    <GreenButton fullWidth onClick={SubmitDelAdresy} startIcon={<DeleteIcon />}>Smazat</GreenButton>
                </Grid>
            </Grid>
          
        </DialogActions>
      </Dialog>




</div>
  );
}


export default Adresy