import React, {useContext,useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import customConfig from '../../customConfig.json'
import clsx from 'clsx';
import { makeStyles,withStyles, } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {UserContext} from '../../contexts/UserContext'
import Link from '@material-ui/core/Link';
import SearchIcon from '@material-ui/icons/Search';



const TextFields = withStyles({
  root: {
    '& label.Mui-focused': {
      color: customConfig.Colors.PrimaryColor,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: customConfig.Colors.PrimaryColor,
    },
    '& .MuiOutlinedInput-root': {

      '&:hover fieldset': {
        borderColor: customConfig.Colors.SecColor,
      },
      '&.Mui-focused fieldset': {
        borderColor: customConfig.Colors.PrimaryColor,
      },
    },
  },
})(TextField);


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  shad:{
    boxShadow:'0px 1px 3px #777777c4',
    border:'1px solid'+customConfig.Colors.PrimaryColor,
  },
  table:{
    border:'1px solid'+customConfig.Colors.PrimaryColor,
  },
  tableRows:{
    borderBottom:'1px solid'+customConfig.Colors.PrimaryColor,
  },
  tableRow:{
    border: "none",
    padding:"5px"
  },
  paperH: {
    textAlign: 'center',
    backgroundColor: customConfig.Colors.PrimaryColor,
    boxShadow: 'none',
    border:'1px solid'+customConfig.Colors.PrimaryColor,
    borderRadius: '0px'
  },
  nadpisH:{
    color: customConfig.Colors.ButtonTextColor,
    fontSize:'20px'
  },
  Link:{
    color: customConfig.Colors.PrimaryColor,
    fontSize:'20px',
    textAlign:'center'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: customConfig.Colors.BorderColor,       
    borderRadius:"10px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));


const GreenButton = withStyles((theme) => ({
    root: {
      color: customConfig.Colors.ButtonTextColor, 
      backgroundColor: customConfig.Colors.PrimaryColor,
      fontWeight:'520',
      '&:hover': {
        backgroundColor: customConfig.Colors.SecColor,
      },
    },
  }))(Button);
  
function VyhledatZasilku(){

    
  const classes = useStyles();
  const {vyhledatZasilku} = useContext(UserContext);
  const [cisloZasilky, setcisloZasilky] = useState({
    CisloZasilky:"",
    VyhledavanaZasilka:"",
    TackLink:"",
    Vyhledano:false,
  })  


  const submitForm = async (event) => {
        
    if(cisloZasilky.VyhledavanaZasilka.length>5)
    {
      event.preventDefault();
      
      const data = await vyhledatZasilku(cisloZasilky);
      

      if(data.length>0)
      {
  
        setcisloZasilky({
          ...cisloZasilky,
          CisloZasilky:data[0].CisloZasilky,
          TackLink:data[0].TackLink,
          Vyhledano:true,
      });
      }
      else
      {
        setcisloZasilky({
          ...cisloZasilky,
          CisloZasilky:"Nepodařilo se dohledat zásilku.",
          TackLink:"",
          Vyhledano:true,
      });
  
      }
    }    
  }

const onChangeValue = (e) => {

  setcisloZasilky({
      ...cisloZasilky,
      VyhledavanaZasilka:e.target.value,
      Vyhledano:false,
    });
}

return (
    <div>


      <Paper className={classes.shad}>
        <Grid spacing={2}  direction="row"  justify="center"  alignItems="center" container>
            <Grid item xs={12} sm={12}> 
                <Paper className={classes.paperH}>
                    <Typography className={classes.nadpisH}>Vyhledat zásilku</Typography>
                </Paper>
            </Grid>

            <Grid item xs={12} sm={8}> 
                <TextFields name="VyhledavanaZasilka" className={clsx(classes.textf,classes.margin)} type="email" variant="outlined" size="small" fullWidth id="VyhledavanaZasilka" label="Číslo zásilky" required placeholder="Číslo zásilky" value={cisloZasilky.VyhledavanaZasilka} onChange={(e) => {onChangeValue(e)}} />
            </Grid>
            <Grid item xs={12} sm={3}> 
                <GreenButton onClick={(e) => {submitForm(e)}} fullWidth ><SearchIcon/> Vyhledat</GreenButton>
            </Grid>

            <Grid item xs={12} sm={12}> 
                
              {(cisloZasilky.TackLink !== "") && ( 
                <Typography className={classes.Link}><Link href={cisloZasilky.TackLink} target="_blank" rel="noopener"> {cisloZasilky.CisloZasilky} </Link></Typography>
              )}

              {(cisloZasilky.TackLink === '' &&  cisloZasilky.Vyhledano === true ) && ( 
                <Typography className={classes.Link}> {cisloZasilky.CisloZasilky} </Typography>             
              )}

            </Grid>


        </Grid>
      </Paper>
    </div>
  );

}

export default VyhledatZasilku
