import React, {useEffect} from 'react';

import './NotFound.css';

function NotFound(){
    
  useEffect(() => {
    document.title = "SEBAplus - 404 Page not fund";  

      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])     

    return (
      <div className="row small-up-2 medium-up-3 large-up-4" id="Body">
      <div className="medium-12 columns">
        <h2>404 Page</h2>
      </div>
    </div>
    );
}

export default NotFound;