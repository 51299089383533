import React, {useContext, useEffect} from 'react'
import {UserContext} from '../../contexts/UserContext'
import { useHistory } from "react-router-dom";

function Logout(){

    const {logoutUser} = useContext(UserContext);
    let history = useHistory();
    

    
    
    useEffect(() => {
        logoutUser();
        history.push("/Login");
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []) 

    return(
            <div></div>          
    );
}

export default Logout;