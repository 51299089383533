import React, {useContext,useState, useEffect} from 'react';
import { DataGrid } from '@material-ui/data-grid';
import {UserContext} from '../../../contexts/UserContext'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles,withStyles, } from '@material-ui/core/styles';
import customConfig from '../../../customConfig.json'
import * as moment from 'moment'
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import Checkbox from '@material-ui/core/Checkbox';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from '@material-ui/core/TableHead';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Nahled from '../Nahled/Nahled';
import Container from '@material-ui/core/Container';
import Backdrop from '@material-ui/core/Backdrop';
import SaveIcon from '@material-ui/icons/Save';




function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        style={{width: "auto"}}
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
        <Box style={{padding:'0px'}} p={3}>
            {children}
        </Box>
        )}
      </div>
    );
  }

const GreenCheckbox = withStyles({
  root: {
    color: customConfig.Colors.PrimaryColor,
    '&$checked': {
      color: customConfig.Colors.PrimaryColor,
    },
    padding:'0px'
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    flexDirection:"row",
    justifyContent: 'center',
    backgroundColor: customConfig.Colors.PrimaryColor,
    '& > span': {
      width: '100%',
      backgroundColor: customConfig.Colors.PrimaryColor,
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const GreenButton = withStyles((theme) => ({
  root: {
    color: customConfig.Colors.ButtonTextColor, 
    backgroundColor: customConfig.Colors.PrimaryColor,
    fontWeight:'520',
    '&:hover': {
      backgroundColor: customConfig.Colors.SecColor,
    },
  },
}))(Button);



function FakturyPrehled(){

    const useStyles = makeStyles((theme) => ({
        modal: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        paper: {
          backgroundColor: theme.palette.background.paper,
          border: customConfig.Colors.BorderColor,          
          boxShadow: theme.shadows[5],
          padding: theme.spacing(2, 4, 3),
        },
        margin: {
          margin: theme.spacing(0),
        },
        table: {
          minWidth: 200
        },
        tableRows: {
          padding: '25px'
        },
        tableRow: {
          padding:"5px 15px 5px 25px",
          backgroundColor: customConfig.Colors.GridBackgroundColor,
      
        },
        bgGrid: {
          backgroundColor: customConfig.Colors.GridBackgroundColor,
          width:'100%',
          padding:'0px !important',
          margin:'0px !important'
        },
        tableHeadRow: {
          padding:"5px 15px 5px 25px",
          backgroundColor: customConfig.Colors.PrimaryColor,
          color: customConfig.Colors.ButtonTextColor
        },
        hide: {
          display: 'none',
        },      
        callContainer: {
          backgroundColor: 'none',
        },
        form: {
          width: '100%', // Fix IE 11 issue.
          marginTop: theme.spacing(1),
          backgroundColor: 'none',
        },
        withoutLabel: {
          marginTop: theme.spacing(1),
        },
        boxStyle:{
          margin:'15px'
        },
        nadpis:{
          color: customConfig.Colors.PrimaryTextColor
        },
        left:{
          paddingLeft: '10px',
        },        
        textf: {
          padding: '0px 8px 0px 0px',
        },
        pad: {
          padding:'0px'
        },
        containerX: {
          backgroundColor: customConfig.Colors.GridBackgroundColor,
          width:'100%',
          padding:'0px !important',
          margin:'0px !important'
        },
        datag: {
          '& .super-app-theme--header': {
            backgroundColor: customConfig.Colors.PrimaryColor,
            color:customConfig.Colors.ButtonTextColor,
          },
          '& .MuiDataGrid-columnsContainer': {
            backgroundColor: customConfig.Colors.PrimaryColor,
            color:customConfig.Colors.ButtonTextColor
          },
          '& .MuiDataGrid-row': {
            cursor:'pointer'
          },
          borderBlockColor:customConfig.Colors.PrimaryColor,
          padding:'0px',
        },
        appBar:{
          padding:'0px',
          color:customConfig.Colors.PrimaryColor,
          backgroundColor:customConfig.Colors.PrimaryColor,   
      },
      tab:{
          
          color: customConfig.Colors.TextColor,
          backgroundColor:customConfig.Colors.PrimaryColor,
          textTransform:'none',
          '&.Mui-selected': {
              backgroundColor: customConfig.Colors.SecColor,
              color: customConfig.Colors.TextColor,
              
          },
          '&:hover': {
             backgroundColor: customConfig.Colors.SecColor,
             color: customConfig.Colors.TextColor,
             opacity: 1,
          },       
      },

        
      }));

  const initialFaktury = {
    FakturyInfo:{
        
        FakturaID:'',
        KlientID:'',
        KlientNazev:'',
        KlientAdresa:'',
        KlientMesto:'',
        KlientIC:'',
        KlientDIC:'',
        ZpusobPlabtbyID:'1',
        ZpusobPlabtbyNazev:'Převodem',
        CenaCelkem:'',
        CisloFakturyNadpis:moment().format("YYYY")+'-XXXXXX',
        KodBanky:'',
        VS:moment().format("YYYY"),
        CisloUctu:'',
        PredcisliUctu:'',
        StavID:'1',
        DatumVystaveni:moment().format("YYYY-MM-DD"),
        DatumSplatnosti:moment().add(1, 'month').format("YYYY-MM-DD"),
        DatumZdanObdobi:'',
        DodavatelNazev:'',
        DodavatelAdresa:'',
        DodavatelMesto:'',
        DodavatelIC:'',
        DodavatelDIC:'',

    },    
    polozky:[],
    zasilky:[],
    souhrnCena:[],
    tisk:false
  }; 

    const {getFaktury,getFakturaPolozky,getFakturaByID,postFakturaZaplaceno,postFakturaStorno,postFaktura} = useContext(UserContext);
    const [faktury,setState] = useState(initialFaktury);  
    const [Faktury, setFaktury] = useState([])    
    const [Polozky, setPolozky] = useState([])    
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [openNahled, setopenNahled] = React.useState(false);

    useEffect(() => {
      document.title = "SEBAplus - Faktury";

      async function fetchData() {

        const dataOUD = await getFaktury(faktury);    
    
        setFaktury(dataOUD) 
    }
    
    fetchData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])   

  const handleCloseNahled = (e) => {
    setopenNahled(!openNahled);
  };
  
  const handleOpenNahled = async (FakturaID) => {  
    
    const fakturaData = await getFakturaByID(FakturaID)



    setState({
      ...faktury,
      FakturyInfo:fakturaData.FakturyInfo[0],
      souhrnCena:fakturaData.souhrnCena,
      zasilky:fakturaData.zasilky,
      polozky:fakturaData.polozky,
      tisk:false

    });

    setopenNahled(!openNahled);    
  };

  const handleOpenTisk = async (FakturaID) => {  
    
    const fakturaData = await getFakturaByID(FakturaID)


    setState({
      ...faktury,
      FakturyInfo:fakturaData.FakturyInfo[0],
      souhrnCena:fakturaData.souhrnCena,
      zasilky:fakturaData.zasilky,
      polozky:fakturaData.polozky,
      tisk:true

    });

    setopenNahled(!openNahled);
  };

  const fakturaStorno = async (FakturaID) => {  
    
    const fakturaData = await postFakturaStorno(FakturaID);

    if(fakturaData)
    {      
       const oudData = await getFaktury( faktury)
       setFaktury(oudData);

    }

  };
  const fakturaEditace = async (e) => {  
    
    const fakturaData = await postFaktura(faktury.FakturyInfo);

    if(fakturaData)
    {      
       const oudData = await getFaktury(faktury)
       setFaktury(oudData);
    }

  };

  const fakturaZaplaceno = async (FakturaID) => {  
    
    const fakturaData = await postFakturaZaplaceno(FakturaID);

    if(fakturaData)
    {      
      const oudData = await getFaktury(faktury)
      setFaktury(oudData);
    }
    
  };



    const columns = [
        
    
        { field: 'FakturaID', type: 'string', hide: true },
        { field: 'StavID', type: 'string', hide: true },
        { field: 'CisloFaktury', type: 'string', headerClassName: 'super-app-theme--header', headerName: 'Číslo faktury', width: 160,}, 
        { field: 'StavNazev', type: 'string', headerClassName: 'super-app-theme--header', headerName: 'Stav', width: 120}, 
        { field: 'Firma', type: 'string', headerClassName: 'super-app-theme--header', headerName: 'Firma', width: 250}, 
        { field: 'IC', type: 'string', headerClassName: 'super-app-theme--header', headerName: 'IČ', width: 120}, 
        {
            field: "DatumSplatnosti",
            headerName: "Datum splatnosti",
            headerClassName: 'super-app-theme--header',
            disableColumnMenu:true, 
            sortable: true,
            width: 150,
            renderCell: (params) => {
                
                const api = params.api;
                const fields = api
                  .getAllColumns()
                  .map((c) => c.field)
                  .filter((c) => c !== "__check__" && !!c);
    
                const thisRow = {};
                fields.forEach((f) => {
                  thisRow[f] = params.getValue(f);
                });
                if(thisRow.DatumSplatnosti)
                {
                    return (moment(thisRow.DatumSplatnosti).format("DD.MM.YYYY"));

                }
                else
                {
                    return("");
                }
            }
        },
        {
            field: "DatumVystaveni",
            headerName: "Datum vystavení",
            headerClassName: 'super-app-theme--header',
            disableColumnMenu:true, 
            sortable: true,
            width: 150,
            renderCell: (params) => {
                
                const api = params.api;
                const fields = api
                  .getAllColumns()
                  .map((c) => c.field)
                  .filter((c) => c !== "__check__" && !!c);
    
                const thisRow = {};
                fields.forEach((f) => {
                  thisRow[f] = params.getValue(f);
                });
                if(thisRow.DatumVystaveni)
                {
                    return (moment(thisRow.DatumVystaveni).format("DD.MM.YYYY"));

                }
                else
                {
                    return("");
                }
            }
        },
        {
            field: "DatumZdanObdobi",
            headerName: "Zadnitelne plněni",
            headerClassName: 'super-app-theme--header',
            disableColumnMenu:true, 
            sortable: true,
            width: 150,
            renderCell: (params) => {
                
                const api = params.api;
                const fields = api
                  .getAllColumns()
                  .map((c) => c.field)
                  .filter((c) => c !== "__check__" && !!c);
    
                const thisRow = {};
                fields.forEach((f) => {
                  thisRow[f] = params.getValue(f);
                });
                if(thisRow.DatumZdanObdobi)
                {
                    return (moment(thisRow.DatumZdanObdobi).format("DD.MM.YYYY"));

                }
                else
                {
                    return("");
                }
            }
        },
        { field: 'ZpusobPlatby', type: 'string', headerClassName: 'super-app-theme--header', headerName: 'Zpusob platby', width: 150},
        {
            field: "nahled",
            headerName: "Náhled",
            sortable: false,
            headerClassName: 'super-app-theme--header',
            disableColumnMenu:true, 
            width: 120,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                const onClick = (e) => {
                const api = params.api;
                const fields = api
                  .getAllColumns()
                  .map((c) => c.field)
                  .filter((c) => c !== "__check__" && !!c);
    
                const thisRow = {};
                fields.forEach((f) => {
                  thisRow[f] = params.getValue(f);
                });

                handleOpenNahled(thisRow.FakturaID);
              }

                return (<GreenButton fullWidth onClick={(e) => onClick(e)}>Náhled</GreenButton>);

            }
        },  
        {
            field: "tisk",
            headerName: "Tisk",
            sortable: false,
            headerClassName: 'super-app-theme--header',
            disableColumnMenu:true, 
            width: 120,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                const onClickx = (e) => {
                const api = params.api;
                const fields = api
                  .getAllColumns()
                  .map((c) => c.field)
                  .filter((c) => c !== "__check__" && !!c);
    
                const thisRow = {};
                fields.forEach((f) => {
                  thisRow[f] = params.getValue(f);
                });

                handleOpenTisk(thisRow.FakturaID);
              }
            
                return (<GreenButton fullWidth onClick={(e) => onClickx(e)}>Tisk</GreenButton>);
            }
        },        
        {
            field: "Zaplaceno",
            headerName: "Zaplaceno",
            sortable: false,
            headerClassName: 'super-app-theme--header',
            disableColumnMenu:true, 
            width: 150,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                const onClickx = (e) => {
                const api = params.api;
                const fields = api
                  .getAllColumns()
                  .map((c) => c.field)
                  .filter((c) => c !== "__check__" && !!c);
    
                const thisRow = {};
                fields.forEach((f) => {
                  thisRow[f] = params.getValue(f);
                });

                fakturaZaplaceno(thisRow.FakturaID);
              }
            
              if(params.getValue("StavNazev")==='Nová')
              {
                return (<GreenButton fullWidth onClick={(e) => onClickx(e)}>Zaplaceno</GreenButton>);
              }
              else
              {
                return('');
              }
            }
        },      
        {
            field: "Storno",
            headerName: "Storno",
            sortable: false,
            headerClassName: 'super-app-theme--header',
            disableColumnMenu:true, 
            width: 150,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                const onClickx = (e) => {
                const api = params.api;
                const fields = api
                  .getAllColumns()
                  .map((c) => c.field)
                  .filter((c) => c !== "__check__" && !!c);
    
                const thisRow = {};
                fields.forEach((f) => {
                  thisRow[f] = params.getValue(f);
                });

                fakturaStorno(thisRow.FakturaID);
              }
              if(params.getValue("StavNazev")==='Nová')
              {
                return (<GreenButton fullWidth onClick={(e) => onClickx(e)}>Storno</GreenButton>);
              }
              else
              {
                return('');
              }
            }
        }, 
      ];

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };


    const onChangeValue = (e) => {
      setState({
          ...faktury,
          FakturyInfo:{
              ...faktury.FakturyInfo,
              [e.target.name]:e.target.value
          },
      });        
    }

    const currentlySelected = async (v) => {
      
        setState({
          ...faktury,
          FakturyInfo:{
              ...faktury.FakturyInfo,
              FakturaID:v.FakturaID,
              DatumVystaveni:v.DatumVystaveni,
              DatumZdanObdobi:v.DatumZdanObdobi,
              DatumSplatnosti:v.DatumSplatnosti,
              CenaCelkem:v.CenaCelkem,
              CisloFaktury:v.CisloFaktury,
              KlientID:v.KlientID,
              Firma:v.Firma,
              IC:v.IC,
              ZpusobPlatby:v.ZpusobPlatby,
              StavID:v.StavID,
              StavNazev:v.StavNazev

          },
      }); 
      const data = await getFakturaPolozky(v.FakturaID);
      setPolozky(data);
      
    }

  return (


<div className={classes.containerX}>
  
    <Grid container spacing={3} className={classes.bgGrid}> 
        <Grid item xs={12} sm={12} spacing={2} name='testx' container className={classes.bgGrid}>  

            <div style={{ height: 600, width: '100%' }}>
                <DataGrid className={classes.datag} getRowId={(row) => row.FakturaID} 
                pageSize={50} 
                rowHeight={30} 
                rows={Faktury?Faktury:[]} 
                rowsPerPageOptions={[2000]}
                pagination
                hideFooterSelectedRowCount="true" 
                columns={columns}
                onRowSelected={(e) => currentlySelected(e.data)}                  
                />
            </div>        
        </Grid>

        <Grid item xs={12} sm={12} spacing={2} container className={classes.bgGrid} direction="row" justify="space-between" alignItems="flex-start">     
            <AppBar className={classes.appBar} position="relative" style={{flexDirection: 'row', width:'100%', alignItems:'center'}}>        
                <StyledTabs
                    value={value} 
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                    aria-label="scrollable force tabs example"
                >
                    <Tab className={classes.tab} label="Základní informace" {...a11yProps(0)} />
                    <Tab className={classes.tab} label="Položky" {...a11yProps(1)} />
                </StyledTabs>
            </AppBar>          
              <div style={{width: '100%',paddingRight:'0px',marginRight:'0px'}}>
                <TabPanel value={value} index={0}>    
                
                  <Grid container>   
                    <Grid container item spacing={2} className={classes.bgGrid}>            
                        <Grid item xs={12} sm={6}>                                              
                            <TextField name="Firma" disabled className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Firma" label="Firma" required placeholder="Firma" value={faktury.FakturyInfo.Firma || ''} />
                        </Grid>                   
                        <Grid item xs={12} sm={3}>                                              
                            <TextField name="CisloFaktury" disabled className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="CisloFaktury" label="Číslo faktury" required placeholder="Číslo faktury" value={faktury.FakturyInfo.CisloFaktury || ''} />
                        </Grid>         
   
                        <Grid item xs={12} sm={2}>  
                            <TextField
                            required
                            id="DatumSplatnosti"
                            key="DatumSplatnosti"
                            name="DatumSplatnosti"
                            label="Datum splatnosti"
                            type="date"
                            size="small"
                            onChange={onChangeValue}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            value={faktury.FakturyInfo.DatumSplatnosti}
                            />   
                        </Grid>   

            
                        <Grid item xs={12} sm={3}>                                              
                            <TextField name="StavNazev" disabled className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="StavNazev" label="Stav" required placeholder="Stav" value={faktury.FakturyInfo.StavNazev || ''} />
                        </Grid>              
                        <Grid item xs={12} sm={3}>                                              
                            <TextField name="ZpusobPlatby" disabled className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="ZpusobPlatby" label="Zpusob platby" required placeholder="Zpusob platby" value={faktury.FakturyInfo.ZpusobPlatby || ''} />
                        </Grid>  
                        <Grid item xs={12} sm={3}>                                              
                            <TextField name="CenaCelkem" className={clsx(classes.textf,classes.margin)} type="number" onChange={onChangeValue} variant="outlined" size="small" fullWidth id="CenaCelkem" label="Cena celkem" required placeholder="Cena celkem" value={faktury.FakturyInfo.CenaCelkem || ''} />
                        </Grid>
                        <Grid item xs={12} sm={2}>  
                            <TextField
                            required
                            id="DatumVystaveni"
                            key="DatumVystaveni"
                            name="DatumVystaveni"
                            label="Datum vystavení"
                            type="date"
                            size="small"
                            fullWidth
                            variant="outlined"
                            onChange={onChangeValue}
                            InputLabelProps={{ shrink: true }}
                            value={faktury.FakturyInfo.DatumVystaveni}
                            />   
                        </Grid>    
                        <Grid item xs={12} sm={2}>  
                            <TextField
                            required
                            id="DatumZdanObdobi"
                            key="DatumZdanObdobi"
                            name="DatumZdanObdobi"
                            label="Zdanitelné plnění"
                            type="date"
                            size="small"
                            fullWidth
                            variant="outlined"
                            onChange={onChangeValue}
                            InputLabelProps={{ shrink: true }}
                            value={faktury.FakturyInfo.DatumZdanObdobi}
                            />   
                        </Grid>   
                        <Grid item xs={12} sm={8}/>   
                        <Grid item xs={12} sm={10}/>  
                        <Grid item xs={12} sm={2}>  
                          <GreenButton fullWidth startIcon={<SaveIcon />} onClick={(e) => fakturaEditace(e)}>Uložit</GreenButton>
                        </Grid>    

                    </Grid>
                  </Grid>

                </TabPanel>
                <TabPanel value={value} className={classes.pad} index={1}>                   
                  {(Polozky!==[] && Polozky!== undefined) && ( 
                      <Table className={classes.table}>
                          <TableHead>
                              <TableRow>
                                  <TableCell className={classes.tableHeadRow} width="150px" align="left">Druh</TableCell>
                                  <TableCell className={classes.tableHeadRow} width="250px" align="left">Název</TableCell>
                                  <TableCell className={classes.tableHeadRow} width="80px" align="left">DPH</TableCell>
                                  <TableCell className={classes.tableHeadRow} width="200px" align="left">Sazba DPH</TableCell>
                                  <TableCell className={classes.tableHeadRow} width="150px" align="left">Cena bez DPH</TableCell>
                              </TableRow>
                          </TableHead>
                          <TableBody>
                              {Polozky.map(row => {
                              return (
                                  <TableRow key={row.Nazev}  className={classes.tableRows}>
                                      <TableCell className={classes.tableRow} width="150px">{row.EntityType}</TableCell>        
                                      <TableCell className={classes.tableRow} width="150px">{row.Nazev}</TableCell>     
                                      <TableCell className={classes.tableRow} width="80px" style={{textAlign:"center"}}><GreenCheckbox checked={row.DPH==="1" ? true : false} /></TableCell>   
                                      <TableCell className={classes.tableRow} width="100px">{row.DPH==="1" ? row.SazbaDPH+' %' : '' }</TableCell>
                                      <TableCell className={classes.tableRow} width="150px">{row.Cena}</TableCell>                        
                                  </TableRow>
                              );
                              })}
                          </TableBody>
                      </Table>  
                  )}                
                </TabPanel>
              </div>
        </Grid>
    </Grid>




    <Dialog
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            className={classes.modal}
            open={openNahled}
            key="Nahled"
            fullWidth={true}
            maxWidth={'xl'}
            onClose={(e) => handleCloseNahled(e)}
            closeAfterTransition
            BackdropComponent={Backdrop}
        >
        <DialogContent>
            <Container maxWidth="lg">      
                <Nahled data={faktury} />    
            </Container>
        </DialogContent>
        </Dialog> 


</div>
  );
}


export default FakturyPrehled