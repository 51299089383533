import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import customConfig from '../../customConfig.json'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paperin: {
    height: 70,
    textAlign: 'center',
    backgroundColor: customConfig.Colors.textfieldsbackground,
    boxShadow: 'none',    
    border:'1px solid'+customConfig.Colors.PrimaryColor,
    borderRadius: '0px'
  },
  paperL: {
    height: 70,
    textAlign: 'center',
    backgroundColor: customConfig.Colors.textfieldsbackground,
    boxShadow: 'none',
    border:'1px solid'+customConfig.Colors.PrimaryColor,
    borderRadius: '0px'
  },
  paperR: {
    height: 70,
    textAlign: 'center',
    backgroundColor: customConfig.Colors.textfieldsbackground,
    boxShadow: 'none',
    border:'1px solid'+customConfig.Colors.PrimaryColor,
    borderRadius: '0px'
  },
  paperH: {
    textAlign: 'center',
    backgroundColor: customConfig.Colors.PrimaryColor,
    boxShadow: 'none',
    border:'1px solid'+customConfig.Colors.PrimaryColor,
    borderRadius: '0px'
  },
  nadpisH:{
    color: customConfig.Colors.ButtonTextColor,
    fontSize:'20px'
  },
  nadpis:{
    color: customConfig.Colors.PrimaryColor,
    fontSize:'20px'
  },
  hodnota:{
    color: customConfig.Colors.PrimaryTextColor,
    fontSize:'28px'
  },
  table:{
    border: "none"
  },
  tableCell:{
    border: "none",
    padding:"5px"
  },
  shad:{

    boxShadow:'0px 1px 3px #777777c4',
    border:'1px solid'+customConfig.Colors.PrimaryColor,
  },
  valueHeader:{
    fontWeight: 700,
    color: customConfig.Colors.RecesrTextColor,
    display:'flex',
    marginLeft:'10px'
  
  },
  valueText:{
    fontWeight: 500,
    paddingLeft:'10px',
    color: customConfig.Colors.RecesrTextColor
  },
}));

function PrehledFinance(data){

    
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [souhrn, setSouhrn] = useState([{
    MinulyRok:'-',
    AktualniRok:'-',
    MinulyMesic:'-',
    AktualniMesic:'-',
  }]) 
  const [souhrnZisky, setSouhrnZisky] = useState([{
    MinulyRok:'-',
    AktualniRok:'-',
    MinulyMesic:'-',
    AktualniMesic:'-',
  }]) 


  useEffect(() => {
      
    if(data.data)
    {
      if(data.data.success===1){
        if(data.data.Finance!==undefined) 
        {
          setSouhrn(data.data.Finance);
        }
        if(data.data.FinanceZisky) 
        {
          setSouhrnZisky(data.data.FinanceZisky);
          setOpen(true)
        }
        else
        {          
          setSouhrnZisky([{MinulyRok:'-',AktualniRok:'-',MinulyMesic:'-',AktualniMesic:'-',}]);
          setOpen(false)
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]) 

if (souhrn){

  return (
    <Paper className={classes.shad}>
      <div>
        {(souhrn!==[] && souhrn!== undefined) && (           
          <Grid>         
            <Grid key={1} item item xs={12} sm={12}> 
              <Paper className={classes.paperH}>
                <Typography className={classes.nadpisH}>Celkové náklady</Typography>
              </Paper>
            </Grid>                
            <Grid container justify="center" item xs={12}>
              <TableContainer>
                <Table className={classes.table} aria-label="caption table">
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.tableCell} width="150px" align="left"><div className={classes.valueText}>{"Minulý rok:"}</div></TableCell>
                      <TableCell className={classes.tableCell} width="150px" align="left"><div className={classes.valueHeader}>{(souhrn[0].MinulyRok?souhrn[0].MinulyRok:'-')+" Kč"}</div></TableCell>
                      {(open===true) && (   
                        <>
                          <TableCell className={classes.tableCell} width="150px" align="left"><div className={classes.valueText}>{"Zisk minulý rok:"}</div></TableCell>
                          <TableCell className={classes.tableCell} width="150px" align="left"><div className={classes.valueHeader}>{(souhrnZisky[0].MinulyRok?souhrnZisky[0].MinulyRok:'-')+" Kč"}</div></TableCell>
                        </>                  
                      )}                          
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{"Aktuální rok:"}</div></TableCell>
                      <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{(souhrn[0].AktualniRok?souhrn[0].AktualniRok:'-')+" Kč"}</div></TableCell>
                      {(open===true) && (   
                        <>
                          <TableCell className={classes.tableCell} width="150px" align="left"><div className={classes.valueText}>{"Zisk aktuální rok:"}</div></TableCell>
                          <TableCell className={classes.tableCell} width="150px" align="left"><div className={classes.valueHeader}>{(souhrnZisky[0].AktualniRok?souhrnZisky[0].AktualniRok:'-')+" Kč"}</div></TableCell>
                        </>                  
                      )}                          
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{"Minulý měsíc:"}</div></TableCell>
                      <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{(souhrn[0].MinulyMesic?souhrn[0].MinulyMesic:'-')+" Kč"}</div></TableCell>
                      {(open===true) && (   
                        <>
                          <TableCell className={classes.tableCell} width="150px" align="left"><div className={classes.valueText}>{"Zisk minulý měsíc:"}</div></TableCell>
                          <TableCell className={classes.tableCell} width="150px" align="left"><div className={classes.valueHeader}>{(souhrnZisky[0].MinulyMesic?souhrnZisky[0].MinulyMesic:'-')+" Kč"}</div></TableCell>
                        </>                  
                      )}                       
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{"Aktuální měsíc:"}</div></TableCell>
                      <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{(souhrn[0].AktualniMesic?souhrn[0].AktualniMesic:'-')+" Kč"}</div></TableCell>
                      {(open===true) && (   
                        <>
                          <TableCell className={classes.tableCell} width="150px" align="left"><div className={classes.valueText}>{"Zisk aktuální měsíc:"}</div></TableCell>
                          <TableCell className={classes.tableCell} width="150px" align="left"><div className={classes.valueHeader}>{(souhrnZisky[0].AktualniMesic?souhrnZisky[0].AktualniMesic:'-')+" Kč"}</div></TableCell>
                        </>                  
                      )}                            
                    </TableRow>
                  </TableBody>     
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        )}   
   </div>
  </Paper>
  );
}  
else
{
  return (
    <Paper className={classes.shad}>
      <div>
        {(souhrn!==[] && souhrn!== undefined) && (           
          <Grid>         
            <Grid key={1} item item xs={12} sm={12}> 
              <Paper className={classes.paperH}>
                <Typography className={classes.nadpisH}>Celkové náklady</Typography>
              </Paper>
            </Grid>                
            <Grid container justify="center" item xs={12}>
              <TableContainer>
                <Table className={classes.table} aria-label="caption table">
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.tableCell} width="150px" align="left"><div className={classes.valueText}>{"Minulý rok:"}</div></TableCell>
                      <TableCell className={classes.tableCell} width="150px" align="left"><div className={classes.valueHeader}>{"- Kč"}</div></TableCell>                  
                      {(open===true) && (   
                        <>
                          <TableCell className={classes.tableCell} width="150px" align="left"><div className={classes.valueText}>{"Zisk minulý rok:"}</div></TableCell>
                          <TableCell className={classes.tableCell} width="150px" align="left"><div className={classes.valueHeader}>{"- Kč"}</div></TableCell>
                        </>                  
                      )}   
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{"Aktuální rok:"}</div></TableCell>
                      <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{"- Kč"}</div></TableCell>
                      {(open===true) && (   
                        <>
                          <TableCell className={classes.tableCell} width="150px" align="left"><div className={classes.valueText}>{"Zisk aktuální rok:"}</div></TableCell>
                          <TableCell className={classes.tableCell} width="150px" align="left"><div className={classes.valueHeader}>{"- Kč"}</div></TableCell>
                        </>                  
                      )}   
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{"Minulý měsíc:"}</div></TableCell>
                      <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{"- Kč"}</div></TableCell>
                      {(open===true) && (   
                        <>
                          <TableCell className={classes.tableCell} width="150px" align="left"><div className={classes.valueText}>{"Zisk minulý měsíc:"}</div></TableCell>
                          <TableCell className={classes.tableCell} width="150px" align="left"><div className={classes.valueHeader}>{"- Kč"}</div></TableCell>
                        </>                  
                      )}                         
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{"Aktuální měsíc:"}</div></TableCell>
                      <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{"- Kč"}</div></TableCell>
                      {(open===true) && (   
                        <>
                          <TableCell className={classes.tableCell} width="150px" align="left"><div className={classes.valueText}>{"Zisk aktuální měsíc:"}</div></TableCell>
                          <TableCell className={classes.tableCell} width="150px" align="left"><div className={classes.valueHeader}>{"- Kč"}</div></TableCell>
                        </>                  
                      )}                         
                    </TableRow>
                  </TableBody>     
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        )}   
   </div>
  </Paper>
  );
}


}

export default PrehledFinance
