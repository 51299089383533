import React, { useContext,useEffect, useState } from 'react';
import {UserContext} from '../../../contexts/UserContext'
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import customConfig from '../../../customConfig.json'
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import InputAdornment from "@material-ui/core/InputAdornment";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import LaunchIcon from '@material-ui/icons/Launch';
import Link from '@material-ui/core/Link';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Container from '@material-ui/core/Container';
import { useHistory } from "react-router-dom";
import Checkbox from '@material-ui/core/Checkbox';


const TextFields = withStyles({
  root: {
    '& label.Mui-focused': {
      color: customConfig.Colors.PrimaryColor,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: customConfig.Colors.PrimaryColor,
    },
    '& .MuiOutlinedInput-root': {

      '&:hover fieldset': {
        borderColor: customConfig.Colors.SecColor,
      },
      '&.Mui-focused fieldset': {
        borderColor: customConfig.Colors.PrimaryColor,
      },
    },
  },
})(TextField);


  const GreenButton = withStyles((theme) => ({
    root: {
      color: customConfig.Colors.ButtonTextColor, 
      backgroundColor: customConfig.Colors.PrimaryColor,
      fontWeight:'520',
      '&:hover': {
        backgroundColor: customConfig.Colors.SecColor,
        
      },
    },
  }))(Button);

  const GreenCheckbox = withStyles({
    root: {
      color: customConfig.Colors.PrimaryColor,
      '&$checked': {
        color: customConfig.Colors.PrimaryColor,
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);
  

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 200
  },
  tableRows: {
    height: '25px'
  },
  tableRow: {
    padding:"5px 15px 5px 25px",
    backgroundColor: customConfig.Colors.GridBackgroundColor,

  },
  bgGrid: {
    backgroundColor: customConfig.Colors.GridBackgroundColor,
  },
  tableHeadRow: {
    padding:"5px 15px 5px 25px",
    backgroundColor: customConfig.Colors.PrimaryColor,
    color: customConfig.Colors.ButtonTextColor
  },
  nadpis:{
    color: customConfig.Colors.PrimaryTextColor
  },
  left:{
    paddingLeft: '10px',
  },  
  input: {
    display: 'none',
  },
  typo: {
    color: 'Black',
  },   
  gridM: {
    margin: '10px',
  },   
  trckUrl: {
    display: 'flex',
    alignItems:'center'
  },   
  btnm: {
    margin:'0px 0px 0px 25px'
  },   
  trckUrlic: {
    color: customConfig.Colors.PrimaryColor,
    margin:'0px',
    padding:'0px',
    fontSize:30

    
  },  


  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: customConfig.Colors.BorderColor,          
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  
  }));


function ZakladniInformace(data){

    const initialZasilky = {
        ZasilkyInfo:{
    
            ZasilkaID:'',
            SluzbaID:'',
            SluzbaNazev:'',    
            PrepravceID:'',
            PrepravceNazev:'',            
            DruhID:null, 
            NazevDruhu:'',            
            StatVyzvednutiKod:'',
            StatVyzvednuti:'',
            StatDoruceni:'',
            StatDoruceniKod:'',
            DatumVyzvednuti:'',
            DatumZalozeni:'',
            StavID:null,
            NazavStavu:'',
            Hodnota:'',
            ReferencniCislo:'',
            CisloZasilky:'',
            Track:'',
            CenaZasilky:'',
            CenaZaklad:'',
            PoznamkaDopravce:'',
            Popis:'',
            tackCisloURL:'',
            CasVyzvednutiDo:'00:00',
            CasVyzvednutiOd:'00:00',
            Prijemce:'',
            Odesilatel:'',
            Firma:'',
            Vyzvednuto:'',
            Doruceno:'',
            CenaCelkem:'',
            DPH:false,
            SazbaDPH:0,

        },
        defaultStav:[],
        defaultDruh:[]
    };    

  const classes = useStyles();
  
  const {getZasilkaZakladniInformace,postEditZasilky,postAkceptace} = useContext(UserContext);
  const [ZakladniInformace, setZakladniInformace] = useState([]);
  const [Prilohy, setPrilohy] = useState([]);
  const [zakladniInformace, setState] = useState(initialZasilky);
  const [disable,setenabledEdit] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  let history = useHistory();

  const handleOpenAccept = (e) => {
    setOpen(true);    
  };

  const handleCloseAccept = () => {
    setOpen(false);
  }

  const onChangeFile = (event) => {
  
    for (let i = 0; i < Prilohy.length; i++) {
      Prilohy[i] = undefined;
    }

    setPrilohy([]);

    let files = event.target.files;    
    let fil = [];

    
    for (let i = 0; i < files.length; i++) {
      
      (function(file) {
        let reader = new FileReader();
        reader.fileName = file.name;
        reader.fileType = file.type;
        reader.readAsDataURL(file); 

        reader.onload = function(eventX) {  
          fil[i] = {name:eventX.target.fileName,type:eventX.target.fileType,file:eventX.target.result};
          setPrilohy([fil]);
        }
      })(files[i]);
    }

  };

  const onChangeValue = (e) => {
    setState({
        ...zakladniInformace,
        ZasilkyInfo:{
            ...zakladniInformace.ZasilkyInfo,
            [e.target.name]:e.target.value
        },
    });        
  }

  const updZasilky = (e) => {

    postEditZasilky(zakladniInformace.ZasilkyInfo);  

    getZasilkaZakladniInformace(data.data[0])
    .then(oudData => setZakladniInformace(oudData)
    );    
    history.push("/Objednavky");
  }

  const akceptovatZasilku = (e) => {
    let prloha = []
    if(Prilohy.length>0){
      prloha = Prilohy[0];
    }
    postAkceptace(zakladniInformace.ZasilkyInfo,prloha);  

    getZasilkaZakladniInformace(data.data[0])
    .then(oudData => setZakladniInformace(oudData)
    );  

    handleCloseAccept();
    history.push("/Objednavky");

  }


  useEffect(() => {

    if(data.data[0] !=='' && data.data[0]!==undefined)
    {
        getZasilkaZakladniInformace(data.data[0])
        .then(oudData => setZakladniInformace(oudData)
        );   
        setenabledEdit(data.data[2])
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]) 

  useEffect(() => {
        setPrilohy([]);
        if(ZakladniInformace.ZakladniInformace!==[] && ZakladniInformace.ZakladniInformace!== undefined)
        {
            setState({
                ...zakladniInformace,
                ZasilkyInfo:{
                    ...zakladniInformace.ZasilkyInfo,
                    ZasilkaID:ZakladniInformace.ZakladniInformace[0].ZasilkaID,
                    SluzbaID:ZakladniInformace.ZakladniInformace[0].SluzbaID,
                    SluzbaNazev:ZakladniInformace.ZakladniInformace[0].SluzbaNazev,    
                    PrepravceID:ZakladniInformace.ZakladniInformace[0].PrepravceID,
                    PrepravceNazev:ZakladniInformace.ZakladniInformace[0].PrepravceNazev,            
                    DruhID:ZakladniInformace.ZakladniInformace[0].DruhID, 
                    NazevDruhu:ZakladniInformace.ZakladniInformace[0].NazevDruhu,            
                    StatVyzvednutiKod:ZakladniInformace.ZakladniInformace[0].StatVyzvednutiKod,
                    StatVyzvednuti:ZakladniInformace.ZakladniInformace[0].StatVyzvednuti,
                    StatDoruceni:ZakladniInformace.ZakladniInformace[0].StatDoruceni,
                    StatDoruceniKod:ZakladniInformace.ZakladniInformace[0].StatDoruceniKod,
                    DatumVyzvednuti:ZakladniInformace.ZakladniInformace[0].DatumVyzvednuti,
                    DatumZalozeni:ZakladniInformace.ZakladniInformace[0].DatumZalozeni,
                    StavID:ZakladniInformace.ZakladniInformace[0].StavID,
                    NazavStavu:ZakladniInformace.ZakladniInformace[0].NazavStavu,
                    Hodnota:ZakladniInformace.ZakladniInformace[0].Hodnota,
                    ReferencniCislo:ZakladniInformace.ZakladniInformace[0].ReferencniCislo,
                    CisloZasilky:ZakladniInformace.ZakladniInformace[0].CisloZasilky,
                    Track:ZakladniInformace.ZakladniInformace[0].Track,
                    CenaZasilky:ZakladniInformace.ZakladniInformace[0].CenaZasilky,
                    CenaZaklad:ZakladniInformace.ZakladniInformace[0].CenaZaklad,
                    PoznamkaDopravce:ZakladniInformace.ZakladniInformace[0].PoznamkaDopravce,
                    Popis:ZakladniInformace.ZakladniInformace[0].Popis,
                    tackCisloURL:ZakladniInformace.ZakladniInformace[0].tackCisloURL,
                    CasVyzvednutiDo:ZakladniInformace.ZakladniInformace[0].CasVyzvednutiDo,
                    CasVyzvednutiOd:ZakladniInformace.ZakladniInformace[0].CasVyzvednutiOd,
                    Prijemce:ZakladniInformace.ZakladniInformace[0].Prijemce,
                    Odesilatel:ZakladniInformace.ZakladniInformace[0].Odesilatel,
                    Firma:ZakladniInformace.ZakladniInformace[0].Firma,
                    Doruceno:ZakladniInformace.ZakladniInformace[0].Doruceno,
                    Vyzvednuto:ZakladniInformace.ZakladniInformace[0].Vyzvednuto,
                    SazbaDPH:ZakladniInformace.ZakladniInformace[0].SazbaDPH,
                    DPH:ZakladniInformace.ZakladniInformace[0].DPH == "1" ? true : false,
                    CenaCelkem:ZakladniInformace.ZakladniInformace[0].CenaCelkem,
                },         
                defaultStav: data.data[1].find(x => x.StavID === ZakladniInformace.ZakladniInformace[0].StavID) !== undefined ? data.data[1].find(x => x.StavID === ZakladniInformace.ZakladniInformace[0].StavID) : null,
                defaultDruh: data.data[3].find(x => x.DruhID === ZakladniInformace.ZakladniInformace[0].DruhID) !== undefined ? data.data[3].find(x => x.DruhID === ZakladniInformace.ZakladniInformace[0].DruhID) : null                  
            });  
        
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [ZakladniInformace])   


  return (


<>
  

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        key="add"
        onClose={handleCloseAccept}
        closeAfterTransition
        BackdropComponent={Backdrop}
      >
        <Fade in={open}>
            <div className={classes.paper}>
                <Container maxWidth="sm">
                    <Grid container spacing={2} justifycontent="space-between">      

                        <Grid item xs={12} sm={12}> 
                            <Typography variant="h6" className={classes.nadpis}>Potvrzení zásilky</Typography>
                        </Grid>
                        
                        <Grid item xs={12} sm={12}> 
                          <TextFields name="Track" disabled={disable} className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Track" label="Číslo přeprace" required placeholder="Číslo přeprace" value={zakladniInformace.ZasilkyInfo.Track || ''} onChange={onChangeValue} />
                        </Grid>
                        <Grid item xs={12} sm={4}> 
                          
                          <input
                            accept="*"
                            className={classes.input}
                            id="contained-button-file"
                            multiple
                            type="file"
                            onChange={(e) => {onChangeFile(e)}} 
                          />
                          <label htmlFor="contained-button-file">
                            <GreenButton variant="contained" color="primary" component="span">
                              Přílohy
                            </GreenButton>
                          </label>
                        </Grid>     
                        <Grid item xs={12} sm={8}>  

                        {(Prilohy && Prilohy.length>0) && (
                          <>
            
                            {Prilohy[0].map(row => {
                              return (
                                
                                <Typography key={row.name} className={classes.typo}>{row.name}</Typography>
                              );
                            })}
                          </>
                        )}
                        </Grid>                             

                        <Grid item xs={12} sm={4}> 
                            <GreenButton fullWidth onClick={handleCloseAccept} > Zrušit </GreenButton>
                        </Grid>
                        <Grid item xs={12} sm={4}/> 
                        <Grid item xs={12} sm={4}> 
                            <GreenButton fullWidth onClick={(e) => akceptovatZasilku(e)}  >Potvrdit</GreenButton>
                        </Grid>
                    </Grid>

                </Container>
            </div>
        </Fade>
    </Modal>  
    <Grid container>   
          <Grid className={classes.gridM} container spacing={1} item xs={12} sm={12}>
            <Grid item xs={12} md={3} sm={4} lg={2}>        
            <Autocomplete
                    id="DruhX"
                    name="DruhX"
                    options={data.data[3] ? data.data[3]: null }
                    getOptionLabel={(option) => option.Nazev ? option.Nazev : ''}
                    fullWidth
                    disabled={disable}
                    value={zakladniInformace.defaultDruh?zakladniInformace.defaultDruh:[]}
                    getOptionSelected={(option, value) => option.DruhID === value.DruhID}                          
                    onChange={(event, newValue) => {
                    if (newValue) {                                  
                        setState({
                            ...zakladniInformace,
                            ZasilkyInfo:{
                                ...zakladniInformace.ZasilkyInfo,
                                DruhID:newValue.DruhID,
                                NazevDruhu:newValue.Nazev
                            },
                            defaultDruh:newValue,
                        });
                    }
                    }}       
                    renderOption={(option) => (
                    <React.Fragment>
                        {option.Nazev}
                    </React.Fragment>
                    )}
                    renderInput={(params) => <TextFields {...params} label="Druh zásilky" size="small" variant="outlined" />}
                />
            </Grid>  
            <Grid item xs={12} md={3} sm={4} lg={2}>                                           
                <TextFields name="PrepravceNazev" disabled className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="PrepravceNazev" label="Přepravce" required placeholder="Přepravce" value={zakladniInformace.ZasilkyInfo.PrepravceNazev || ''} onChange={onChangeValue} />
            </Grid>              
            <Grid item xs={12} md={3} sm={4} lg={2}>                                           
                <TextFields name="SluzbaNazev" disabled className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="SluzbaNazev" label="Služba" required placeholder="Služba" value={zakladniInformace.ZasilkyInfo.SluzbaNazev || ''} onChange={onChangeValue} />
            </Grid>  


            <Grid item xs={12} md={3} sm={4} lg={2}>                                              
                <TextFields  name="DatumZalozeni" disabled className={clsx(classes.textf,classes.margin)} type="date" InputLabelProps={{ shrink: true }} variant="outlined" size="small" fullWidth id="DatumZalozeni" label="Datum založení" required value={zakladniInformace.ZasilkyInfo.DatumZalozeni || ''} onChange={onChangeValue} />
            </Grid> 
            <Grid item xs={12} md={3} sm={4} lg={2}>                
            <Autocomplete
                    id="StavX"
                    name="StavX"
                    options={data.data[1] ? data.data[1]: null }
                    getOptionLabel={(option) => option.StavNazev ? option.StavNazev : ''}
                    fullWidth
                    disabled={disable}
                    value={zakladniInformace.defaultStav?zakladniInformace.defaultStav:[]}
                    getOptionSelected={(option) => option.StavID === zakladniInformace.ZasilkyInfo.StavID}                          
                    onChange={(event, newValue) => {
                    if (newValue) {                                  
                        setState({
                            ...zakladniInformace,
                            ZasilkyInfo:{
                                ...zakladniInformace.ZasilkyInfo,
                                StavID:newValue.StavID,
                                NazavStavu:newValue.StavNazev
                            },
                            defaultStav:newValue,
                        });
                    }
                    }}       
                    renderOption={(option) => (
                    <React.Fragment>
                        {option.StavNazev}
                    </React.Fragment>
                    )}
                    renderInput={(params) => <TextFields {...params} label="Stav zásilky" size="small" variant="outlined" />}
                />
            </Grid> 
            <Grid item xs={12} md={3} sm={4} lg={2}>                                           
                <TextFields name="CisloZasilky" disabled className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="CisloZasilky" label="Číslo zásilky" required placeholder="Číslo zásilky" value={zakladniInformace.ZasilkyInfo.CisloZasilky} />
            </Grid>  

            <Grid item xs={12} md={3} sm={4} lg={3}>                                           
                <TextFields name="Odesilatel" disabled className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Odesilatel" label="Odesílatel" required placeholder="Odesílatel" value={zakladniInformace.ZasilkyInfo.Odesilatel || ''} />
            </Grid>  
            <Grid item xs={12} md={3} sm={4} lg={3}>                                           
                <TextFields name="StatVyzvednuti" disabled className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="StatVyzvednuti" label="Stát vyzvednutí" required placeholder="Stát vyzvednutí" value={zakladniInformace.ZasilkyInfo.StatVyzvednuti || ''} />
            </Grid>  
            <Grid item xs={12} md={3} sm={4} lg={2}>                                              
                <TextFields  name="DatumVyzvednuti" disabled={disable} className={clsx(classes.textf,classes.margin)} type="date" InputLabelProps={{ shrink: true }} variant="outlined" size="small" fullWidth id="DatumVyzvednuti" label="Datum vyzvednutí" required value={zakladniInformace.ZasilkyInfo.DatumVyzvednuti || ''} onChange={onChangeValue} />
            </Grid>  
            <Grid item xs={12} md={3} sm={4} lg={2}>                                              
                <TextFields name="CasVyzvednutiOd" disabled={disable} className={clsx(classes.textf,classes.margin)} type="time" variant="outlined" size="small" fullWidth id="CasVyzvednutiOd" label="Čas vyzvednutí od" value={zakladniInformace.ZasilkyInfo.CasVyzvednutiOd} onChange={onChangeValue} />
            </Grid>  
            <Grid item xs={12} md={3} sm={4} lg={2}>                                              
                <TextFields name="CasVyzvednutiDo" disabled={disable} className={clsx(classes.textf,classes.margin)} type="time" variant="outlined" size="small" fullWidth id="CasVyzvednutiDo" label="Čas vyzvednutí do" value={zakladniInformace.ZasilkyInfo.CasVyzvednutiDo} onChange={onChangeValue} />
            </Grid>  
            <Grid item xs={12} md={3} sm={4} lg={3}>                                           
                <TextFields name="Prijemce" disabled className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Prijemce" label="Příjemce" required placeholder="Příjemce" value={zakladniInformace.ZasilkyInfo.Prijemce || ''} />
            </Grid>  
            <Grid item xs={12} md={3} sm={4} lg={3}>                                           
                <TextFields name="StatDoruceni" disabled className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="StatDoruceni" label="Stát doručení" required placeholder="Stát doručení" value={zakladniInformace.ZasilkyInfo.StatDoruceni} />
            </Grid>  
            <Grid item xs={12} md={3} sm={4} lg={2}>                                              
                <TextFields  name="Doruceno" disabled={disable} className={clsx(classes.textf,classes.margin)} type="date" InputLabelProps={{ shrink: true }} variant="outlined" size="small" fullWidth id="Doruceno" label="Datum doručení" required value={zakladniInformace.ZasilkyInfo.Doruceno || ''} onChange={onChangeValue} />
            </Grid>            
            <Grid item xs={12} md={3} sm={4} lg={2} className={classes.trckUrl}>                                           
                <TextFields name="Track" disabled={disable} className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Track" label="Číslo přeprace" required placeholder="Číslo přeprace" value={zakladniInformace.ZasilkyInfo.Track || ''} onChange={onChangeValue} />{(zakladniInformace.ZasilkyInfo.tackCisloURL !== '' && zakladniInformace.ZasilkyInfo.tackCisloURL !== null && zakladniInformace.ZasilkyInfo.tackCisloURL !== undefined) && (<Link href={zakladniInformace.ZasilkyInfo.tackCisloURL} target="_blank" rel="noopener" variant="body2" > <LaunchIcon className={classes.trckUrlic}/> </Link>)}
            </Grid> 

            <Grid item xs={12} md={3} sm={4} lg={2}>                                           
                <TextFields name="ReferencniCislo" disabled={disable} className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="ReferencniCislo" label="Referenční číslo" required placeholder="Referenční číslo" value={zakladniInformace.ZasilkyInfo.ReferencniCislo || ''} onChange={onChangeValue} />
            </Grid>       
            <Grid item xs={12} md={3} sm={4} lg={3}>                                           
                <TextFields name="Firma" disabled className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Firma" label="Plátce" required placeholder="Plátce" value={zakladniInformace.ZasilkyInfo.Firma || ''} />
            </Grid>   
            <Grid item xs={12} md={3} sm={4} lg={2}>                                              
                <TextFields  name="Vyzvednuto" disabled={disable} className={clsx(classes.textf,classes.margin)} type="date" InputLabelProps={{ shrink: true }} variant="outlined" size="small" fullWidth id="Vyzvednuto" label="Vyzvednuto dne" required value={zakladniInformace.ZasilkyInfo.Vyzvednuto || ''} onChange={onChangeValue} />
            </Grid>  
            <Grid item xs={12} md={3} sm={4} lg={2}>                                              
                <TextFields name="Hodnota" disabled className={clsx(classes.textf,classes.margin)} type="number" variant="outlined" size="small" fullWidth id="Hodnota" label="Hodnota" placeholder="Hodnota" InputProps={{ endAdornment: <InputAdornment position="end">Kč</InputAdornment> }}value={zakladniInformace.ZasilkyInfo.Hodnota || ''} onChange={onChangeValue} />
            </Grid>    
            <Grid item xs={12} md={3} sm={4} lg={2}>                                              
                <TextFields name="CenaZasilky" disabled={disable} className={clsx(classes.textf,classes.margin)} type="number" variant="outlined" size="small" fullWidth id="CenaZasilky" label="Cena" placeholder="Cena" InputProps={{ endAdornment: <InputAdornment position="end">Kč</InputAdornment> }}value={zakladniInformace.ZasilkyInfo.CenaZasilky || ''} onChange={onChangeValue} />
            </Grid>  
            <Grid item xs={12} md={3} sm={4} lg={3}>    
              <TextFields name="CenaCelkem" disabled className={clsx(classes.textf,classes.margin)} type="number" variant="outlined" size="small" fullWidth id="CenaCelkem" label="Cena celkem (s DPH)" placeholder="Cena celkem (s DPH)" InputProps={{ endAdornment: <InputAdornment position="end">Kč</InputAdornment> }}value={zakladniInformace.ZasilkyInfo.CenaCelkem || ''} onChange={onChangeValue}/>
            </Grid>     

            <Grid item xs={12} md={3} sm={4} lg={1}>    
                      

              <Typography className={classes.typo}>
                <GreenCheckbox
                    checked={zakladniInformace.ZasilkyInfo.DPH}
                    disabled
                    id="DPH"
                    key="DPH"
                    name="DPH"
                    color="primary"
                />
                DPH                                                  
              </Typography>
                  

            </Grid>
            <Grid item xs={12} md={3} sm={4} lg={2}>    
                
              {(zakladniInformace.ZasilkyInfo.DPH === true) && (
                <TextFields name="SazbaDPH" disabled className={clsx(classes.textf,classes.margin)} type="number" variant="outlined" size="small" fullWidth id="CenaCelkem" label="Sazba DPH" placeholder="Sazba DPH" InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}value={zakladniInformace.ZasilkyInfo.SazbaDPH || ''} onChange={onChangeValue}/>
              )}
            </Grid>

            <Grid item xs={12} md={12} sm={12} lg={9}>                                          
                <TextFields name="Popis" disabled={disable} className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Popis" label="Popis" placeholder="Popis" value={zakladniInformace.ZasilkyInfo.Popis || ''} onChange={onChangeValue} />
            </Grid>  
            <Grid item xs={12} sm={12}>                                           
                <TextFields name="PoznamkaDopravce" disabled={disable} className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="PoznamkaDopravce" label="Poznámka dopravci" placeholder="Poznámka dopravci" value={zakladniInformace.ZasilkyInfo.PoznamkaDopravce || ''} onChange={onChangeValue} />
            </Grid>  
            <Grid item xs={12} sm={8}/>  
            <Grid item  xs={12} sm={4} style={{textAlign:'end'}} >     
            {(disable === false) && (
                <>
                {(zakladniInformace.ZasilkyInfo.StavID === "1") && (
                  <GreenButton className={classes.btnm} onClick={(e) => {handleOpenAccept(e)}} >Akceptovat</GreenButton>
                )}
                <GreenButton className={classes.btnm} onClick={(e) => {updZasilky(e)}} >Uložit</GreenButton>
                </>
            )}

            </Grid>  
        </Grid>
    </Grid>
    
  </>
  );
}

export default ZakladniInformace


