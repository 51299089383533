import React, {useContext, useState, useEffect} from 'react'
import { useHistory  } from 'react-router-dom'
import {UserContext} from '../../contexts/UserContext'
import './Login.css';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { makeStyles,withStyles, } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import customConfig from '../../customConfig.json'
import { Alert } from '@material-ui/lab';

function Login(){

    const {loginUser,isLoggedIn} = useContext(UserContext);

    const useStyles = makeStyles((theme) => ({
      paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'none',
        alignItems: 'center',
      },
      paperError: {
        textAlign: 'center',
        display: 'flex',
        color: '#ff0000',
        padding:'5px 15px',
      },
      hide: {
        display: 'none',
      },      
      callContainer: {
        backgroundColor: 'none',
      },
      form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        backgroundColor: 'none',
      },
      boxStyle:{
        marginTop:'15px'
      },
      submit: {
        margin: theme.spacing(3, 0, 2),
      },
      textf: {
        backgroundColor:'white',
        
      },
    }));
    const GreenButton = withStyles((theme) => ({
      root: {
        color: customConfig.Colors.TextColor,
        backgroundColor: customConfig.Colors.PrimaryColor,
        '&:hover': {
          backgroundColor: customConfig.Colors.SecColor,
        },
      },
    }))(Button);
    const initialState = {
        userInfo:{
            email:'',
            password:'',
        },
        errorMsg:'',
    }

    const [state,setState] = useState(initialState);

    useEffect(() => {
      document.title = "SEBAplus - Přihlášení";
    }, []);
    
    // On change input value (email & password)
    const onChangeValue = (e) => {
        setState({
            ...state,
            userInfo:{
                ...state.userInfo,
                [e.target.name]:e.target.value
            }
        });
    }

    // On Submit Login From
    const submitForm = async (event) => {
        event.preventDefault();
        const data = await loginUser(state.userInfo);
        let datumToken = new Date(new Date().setHours(new Date().getHours() + 2));
        
        if(data.success && data.token){
            setState({
                ...initialState,
            });
            localStorage.setItem('loginToken', data.token);
            localStorage.setItem('tokenTime', datumToken);
            
            await isLoggedIn();
            history.push("/Home");

        }
        else{
            setState({
                ...state,
                errorMsg:data.message
            });
        }
    }
    let history = useHistory();

    const classes = useStyles();

    return(
      <Container className={classes.classContainer} component="main" maxWidth="xs">
            
            <div className={classes.paper}>

              <img src="../img/loga/LogoSEBAPlus.png" height="100px" alt="SEBA Logistics, s.r.o."/>
              
              <div style={{ visibility: state.errorMsg === '' ? 'hidden': 'visible'}}>             
                            <Alert severity="error">{state.errorMsg}</Alert>
              </div>

              <form className={classes.form} onSubmit={submitForm} noValidate>
              
                    <TextField name="email" 
                    className={classes.textf}
                    type="email" 
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="email"
                    label="Přihlašovací email"
                    autoFocus
                    required placeholder="Zadejte přihlašovací email" value={state.userInfo.email} onChange={onChangeValue} />

                    <TextField name="password"   
                      className={classes.textf}                  
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Heslo"
                      type="password"
                      id="Heslo" 
                      required placeholder="Zadejte heslo" value={state.userInfo.password} onChange={onChangeValue} />              

                    <GreenButton
                      classes={{
                        label: classes.btnlabel,
                      }}
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="submit">
                        Přihlásit se
                    </GreenButton>
                    
                    <Grid container>
                    <Grid item xs>
                      <Link href="/ForgottenPassword" variant="body2">
                        Zapomenuté heslo?
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link href="/Register" variant="body2">
                        {"Nemáte účet? Zaregistrujte se"}
                      </Link>
                    </Grid>
                  </Grid>
                </form>
              </div>
      </Container>                
    );
}

export default Login;