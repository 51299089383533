import React, { useContext,useEffect, useState } from 'react';
import {UserContext} from '../../contexts/UserContext'
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import InputAdornment from "@material-ui/core/InputAdornment";
import AppBar from '@material-ui/core/AppBar';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import customConfig from '../../customConfig.json'
import Autocomplete from '@material-ui/lab/Autocomplete';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Alert } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetail from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import {Add,Delete,Email} from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/Info';
import { v4 as uuidv4 } from 'uuid';
import IconButton from '@material-ui/core/IconButton';
import { Checkbox } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { ReactComponent as Calculate } from '../../icons/calculate.svg';


const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'white',
    color: 'Black',
    maxWidth: 550,
    fontSize: '1rem',
    border: '1px solid '+customConfig.Colors.PrimaryColor,
    padding: '15px'
  },
}))(Tooltip);

const GreenCheckbox = withStyles({
  root: {
    color: customConfig.Colors.PrimaryColor,
    '&$checked': {
      color: customConfig.Colors.PrimaryColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const TextFields = withStyles({
  root: {
    '& label.Mui-focused': {
      color: customConfig.Colors.PrimaryColor,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: customConfig.Colors.PrimaryColor,
    },
    '& .MuiOutlinedInput-root': {

      '&:hover fieldset': {
        borderColor: customConfig.Colors.SecColor,
      },
      '&.Mui-focused fieldset': {
        borderColor: customConfig.Colors.PrimaryColor,
      },
    },
  },
})(TextField);
const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: customConfig.Colors.PrimaryColor,
    '& > span': {
      width: '100%',
      backgroundColor: customConfig.Colors.PrimaryColor,
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const useStyles = makeStyles((theme) => ({
  appBar:{
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(3),
      borderTop:customConfig.Colors.BorderColor
      
      
  },
  tab:{
      
      color: "rgba(0, 0, 0, 0.54)",
      '&.Mui-selected': {
          backgroundColor: customConfig.Colors.PrimaryColor,
          color: customConfig.Colors.TextColor,
          
      },
      '&:hover': {
         backgroundColor: customConfig.Colors.PrimaryColor,
         color: customConfig.Colors.TextColor,
         opacity: 1,
      },       
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: customConfig.Colors.BorderColor,          
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  table: {
    minWidth: 200
  },
  tableRows: {
    height: '25px'
  },
  tableRow: {
    padding:'2px'
  },
  nabidkyacco: {
    '& .MuiAccordionSummary-content':{
      margin:'0px'
    }
  },
  typo: {
    color: 'Black',
    verticalAlign: 'middle',
    alignItems: 'center',

  },
  typo2: {
    color: 'Black',
    verticalAlign: 'middle',
    fontWeight:'600',
    alignItems: 'center',

  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  Topmg: {
    paddingTop: '15px'
  },
  bgGrid: {
    backgroundColor: customConfig.Colors.GridBackgroundColor,
  },
  iconBTN: {
    padding: '8px 8px 8px 8px',
  },
  textf: {
    padding: '0px 8px 0px 0px',
  },
  margin: {
    margin: theme.spacing(0),
  },
  iconColorSec:{
    color:customConfig.Colors.ButtonTextColor
  },
  iconColor:{
    color:customConfig.Colors.PrimaryColor
  },
  iconColortt:{
    color:customConfig.Colors.PrimaryColor,
    marginLeft:'10px',
    verticalAlign:'middle'

  },
  SummaryHead: {
    backgroundColor: customConfig.Colors.PrimaryColor,
    color:customConfig.Colors.ButtonTextColor,
    fontWeight:700
  },
  }));

export const ParametryZasilky = ({ formData, setForm, navigation }) => {
  const { 
    pocetBalik, 
    delkaBalik,    
    sirkaBalik,
    vyskaBalik,
    pocetPaleta, 
    delkaPaleta,    
    sirkaPaleta,
    vyskaPaleta,
    vyska,    
    sirka, 
    delka,
    hmotnostPaleta,
    hmotnostBalik,
    pocetDokument,
    hmotnostDokument,
    druh,
    cena,
    sluzba,
    adresTo,
    adresFrom,
    DefAdresFrom,
    DefAdresTo,
    objemovaHmotnostBalik,
    objemovaHmotnostPaleta,
    sluzbaNazev,
    prepravce,
    hmotnost,
    pocet,
    odesilatelStatNazev,
    prijemceStatNazev,
    prijemceTel,
    odesilatelTel,
    dokuemnty,
    baliky,
    palety,
    Pojistit,
    hodnotaZbozi,
    parametry,
    cenaZaklad,
    doplnky,
    ulozitAdrOdesilatel,
    ulozitAdrprijemce,
    BEZPECNAOSTATNI,
    ostatni,
    Defsluzba,
    Defprepravce,     
    proForma,
    obchodni
  } = formData;
  
  
  const classes = useStyles();  
  const [selectedTab, setSelectedTab] = useState(0);
  const [Kalkulace, setKalkulace] = useState([])  
  const [statFrom, setstatFrom] = React.useState(false);
  const [statTo, setstatTo] = React.useState(false);
  const {getStaty,postkalulovat2,getSluzby,getUserType,postEmailKalkulace,postEmailPoptavka,getPrepravci,getSlevyKlient,getFirmy} = useContext(UserContext);
  const [Staty, setStaty] = useState([]);
  const [errorMsg, seterrorMsg] = useState('') 
  const [UserType, setUserType] = useState('0') 
  const [hromadnaSleva, sethromadnaSleva] = useState('') 
  const [Slevy, setSlevy] = useState([]);
  const [Sluzby, setSluzby] = useState([])  
  const [Prepravci, setPrepravci] = useState([])  
  const [Frirmy, setFrirmy] = useState([])  
  const [open, setOpen] = React.useState(false);
  const [openPoptavka, setopenPoptavka] = React.useState(false);
  const [openMail, setOpenMail] = React.useState(false);
  const [openMailPoptavka, setOpenMailPoptavka] = React.useState(false);
  const [firma, setFirma] = useState({defaultFirma:null});
  const [Mail, setMail] = useState({EmailAdresat:'',TextMail:'Vážený/á pane/ní ................,\n\nděkujeme Vám za zájem o naše služby a na základě poptávky Vám zasíláme kalkulaci dopravy.'});
  const [MailPoptavka, setMailPoptavka] = useState({TextMail:''});

  // Function called anytime the tab changes
  function handleTabChange(event, value) {
    setSelectedTab(value);
    formData.druh = value;
    setKalkulace([]);
  }
  
  const Hmotnosti = [
    {value: '0.25', label: '0,25 kg',},
    { value: '0.49', label: '0,5 kg',},
    { value: '0.99', label: '1 kg',},
    { value: '1.49', label: '1,5 kg',},
    { value: '1.99', label: '2 kg',},
    { value: '2.49', label: '2,5 kg',},
  ];

  const longText = `Více o informací o zboží, na které se vztahují různá omezení Vám prověří a sdělí naše oddělení podpory.`;

  const longTextVaha = `Celková hmotnost - Uvedená váha se může lišit od zadaných hodnot, z důvodu, že při výpočtu ceny byla použita volumetrická hmotnost, případně kombinace volumetrické a reálné hmotnosti.`;

  const handleAddFieldsDokument = () => {
    setinputFieldsDokument([...inputFieldsDokument, { id: uuidv4(),  delka: '0', pocet: '', sirka:'0',vyska:'0',hmotnost:'0.99',objemovaHmotnost:'' }])
    formData.dokuemnty = inputFieldsDokument;
  };

  const handleAddFieldsBalik = () => {
    setinputFieldsBalik([...inputFieldsBalik, { id: uuidv4(),  delka: '', pocet: '', sirka:'',vyska:'',hmotnost:'',objemovaHmotnost:'' }])  
    formData.baliky = inputFieldsBalik;
  };

  const handleAddFieldsOstatni = () => {
    setinputFieldsOstatni([...inputFieldsOstatni, { id: uuidv4(),  delka: '', pocet: '', sirka:'',vyska:'',hmotnost:'',objemovaHmotnost:'' }])
    formData.Ostatniy = inputFieldsOstatni;
  };

  const handleAddFieldsPaleta = () => {
    setinputFieldsPaleta([...inputFieldsPaleta, { id: uuidv4(),  delka: '', pocet: '', sirka:'',vyska:'',hmotnost:'',objemovaHmotnost:'' }])
    
    formData.palety = inputFieldsPaleta;
  };

  const handleRemoveFieldsDokument = id => {
    const values  = [...inputFieldsDokument];
    values.splice(values.findIndex(value => value.id === id), 1);
    setinputFieldsDokument(values);    

    formData.dokuemnty = values;
  };

  const handleRemoveFieldsOstatni = id => {
    const values  = [...inputFieldsOstatni];
    values.splice(values.findIndex(value => value.id === id), 1);
    setinputFieldsOstatni(values);    

    formData.Ostatniy = values;
  };

  const handleRemoveFieldsBalik = id => {
    const values  = [...inputFieldsBalik];
    values.splice(values.findIndex(value => value.id === id), 1);
    setinputFieldsBalik(values);    

    formData.baliky = values;
  };

  const handleRemoveFieldsPaleta = id => {
    const values  = [...inputFieldsPaleta];
    values.splice(values.findIndex(value => value.id === id), 1);
    setinputFieldsPaleta(values);    
    formData.palety = values;
  };
	
  const [inputFieldsDokument, setinputFieldsDokument] = useState([
    { id: uuidv4(), delka: '0', pocet: '1', sirka:'0',vyska:'0',hmotnost:'0.99',objemovaHmotnost:'' },
  ]);

  const [inputFieldsPaleta, setinputFieldsPaleta] = useState([
    { id: uuidv4(), delka: '', pocet: '', sirka:'',vyska:'',hmotnost:'',objemovaHmotnost:''  },
  ]);

  const [inputFieldsBalik, setinputFieldsBalik] = useState([
    { id: uuidv4(), delka: '', pocet: '', sirka:'',vyska:'',hmotnost:'',objemovaHmotnost:'' },
  ]);
  
  const [inputFieldsOstatni, setinputFieldsOstatni] = useState([
    { id: uuidv4(), delka: '', pocet: '', sirka:'',vyska:'',hmotnost:'',objemovaHmotnost:'' },
  ]);

  const handleChangeInputBalik = (id, event) => {
    const newInputFields = inputFieldsBalik.map(i => {
      if(id === i.id) {
        i[event.target.name] = event.target.value
      }
      return i;
    })    
    setinputFieldsBalik(newInputFields);    
    formData.baliky = newInputFields;
  }

  const handleChangeInputOstatni = (id, event) => {
    const newInputFields = inputFieldsOstatni.map(i => {
      if(id === i.id) {
        i[event.target.name] = event.target.value
      }
      return i;
    })
    
    setinputFieldsOstatni(newInputFields);
    
    formData.Ostatniy = newInputFields;
  }

  const handleChangeInputPaleta = (id, event) => {
    const newInputFields = inputFieldsPaleta.map(i => {
      if(id === i.id) {
        i[event.target.name] = event.target.value
      }
      return i;
    })    
    setinputFieldsPaleta(newInputFields);    
    formData.palety = newInputFields;
  }

  const handleChangeInputDokument = (id, event) => {
    const newInputFields = inputFieldsDokument.map(i => {
      if(id === i.id) {
        i[event.target.name] = event.target.value
      }
      return i;
    })    
    setinputFieldsDokument(newInputFields);    
    formData.dokuemnty = newInputFields;
  }

  const [inputFieldsdoplnky, setinputFieldsdoplnky] = useState([
    { DoplnekNazev:'Pojištění zásilky', KodDoplnku: 'POJISTENI', DoplnekInt: false, DoplnekText:''},
    { DoplnekNazev:'Bezpečná zásilka', KodDoplnku: 'BEZPECNA', DoplnekInt: true, DoplnekText:''},
    { DoplnekNazev:'Suchý led', KodDoplnku: 'UN1845', DoplnekInt: false, DoplnekText:''},
    { DoplnekNazev:'Lithiové baterie - se zařízením', KodDoplnku: 'BATERIEZARIZENI', DoplnekInt: false, DoplnekText:''},
    { DoplnekNazev:'Lithiové baterie - v zařízení', KodDoplnku: 'BATERIEVZARIZENI', DoplnekInt: false, DoplnekText:''},
    { DoplnekNazev:'Lithiové baterie - samostatné', KodDoplnku: 'BATERIE', DoplnekInt: false, DoplnekText:''},
    { DoplnekNazev:'Nestohovatelná', KodDoplnku: 'STOHOVATELNA', DoplnekInt: false, DoplnekText:''},
    { DoplnekNazev:'Požadujeme zpoplatněné VDD', KodDoplnku: 'VDD', DoplnekInt: false, DoplnekText:''},
  ]);

  const handleChangeInputDoplnek = (event) => { 
    const newInputFields = inputFieldsdoplnky.map(i => {
      if(event.target.name === i.KodDoplnku) {
        i.DoplnekInt = event.target.checked
      }
      return i;
    })    
    setinputFieldsdoplnky(newInputFields);    
    formData.doplnky = newInputFields;
  }

  const handleChangeInputDoplnekTF = (event) => {
    
    const newInputFields = inputFieldsdoplnky.map(i => {
      if(event.target.id === i.KodDoplnku) {
        i.DoplnekText = event.target.value
      }
      return i;
    })    
    setinputFieldsdoplnky(newInputFields);    
    formData.doplnky = newInputFields;
  }

  async function MapovatSlevuDleKlienta() {
    if(firma.defaultFirma===null)
    {   
      const result = Sluzby.map(s => ({ SluzbaID: s.SluzbaID,SluzbaNazev: s.SluzbaNazev , sleva: '' }));    
      setSlevy(result);
    }
    else
    {
      
      const dataSlevy = await getSlevyKlient(firma.defaultFirma.UserID); 
      const result = dataSlevy.map(s => ({ SluzbaID: s.SluzbaID,SluzbaNazev: s.SluzbaNazev , sleva: s.Sleva }));    
      setSlevy(result);
    }
  }

  function MapovatSlevt(data) {
    const result = data.map(s => ({ SluzbaID: s.SluzbaID,SluzbaNazev: s.SluzbaNazev , sleva: '' }));    
    setSlevy(result);
  }
  
  function MapovatHromadnouSlevu() {


    removeCalculations()
   
    // const editrows = Slevy.map(item =>
    //   item.SluzbaID === id ? { ...item, sleva: hromadnaSleva } : item
    //   );
    // setSlevy(editrows);        
    
     const result = Sluzby.map(s => ({ SluzbaID: s.SluzbaID,SluzbaNazev: s.SluzbaNazev , sleva: hromadnaSleva }));    
     
     setSlevy(result);
  }

  function removeCalculations() {
    seterrorMsg('');  
    setopenPoptavka(false);
    setKalkulace([]);
  }
     
  const kalulovat = async (event) => {    
    
    setopenPoptavka(false);
    removeCalculations();

    if(BEZPECNAOSTATNI === true && doplnky[1].DoplnekInt === false )
    {
      
      seterrorMsg('V případě možnosti ostatní nebezpčené zásilky, vyplňte všechny dostupné položky a využijte možnost odeslat poptávku. Následně Vás kontaktuje obchodní oddělení.');
      setopenPoptavka(true);
    }
    else
    {

      if(errorMsg.length === 0)
      {
        setstatTo(false);
        setstatFrom(false);
        
        function validace (){
          let oud = false
          if(druh === 1)
          {
            oud = inputFieldsBalik.map(i => {
              if(Number(i.hmotnost) <= 0 || Number(i.delka) < 1 || Number(i.vyska) < 1 || Number(i.sirka) < 1 || Number(i.pocet) < 1){
                seterrorMsg('Vyplňte všechna pole.');
                return false;
              }
            })
          }
          else if(druh === 2){
            
            oud = inputFieldsPaleta.map(i => {
              if(Number(i.hmotnost) <= 0 || Number(i.delka) < 1 || Number(i.vyska) < 1 || Number(i.sirka) < 1 || Number(i.pocet) < 1){
                seterrorMsg('Vyplňte všechna pole.');
                return false;
              }
            })
          }
          else if(druh === 0){
            oud = inputFieldsDokument.map(i => {
              if(Number(i.hmotnost) <= 0 || Number(i.pocet) < 1){
                seterrorMsg('Vyplňte všechna pole.');
                return false;
              }
            })
          } 
          return oud;
        }

        if (adresFrom !== null && adresFrom !== '' && adresTo !== null && adresFrom !== '' )
        {
          if( adresFrom !== 'CZ' && adresTo !== 'CZ')
          {
            
            setstatTo(true);
            setstatFrom(true);
            seterrorMsg('Stát doručení nebo stát odeslání musí být Česká republuka.');

          }
          else if(doplnky[0].DoplnekInt === true && hodnotaZbozi < 300 ){
            seterrorMsg('V případě pojištění zásilky je potřeba uvést její hodnotu. Minimální hodnta zásilky pro pojištění je 300 Kč.');
          }
          else if(doplnky[0].DoplnekInt === true && hodnotaZbozi > 500000){
            seterrorMsg('Maximální možná hodnota zásilky pro automatickou kalkulaci je 500.000 Kč. Ujistěte se před odesláním poptávky, že máte všechna pole správně vyplněna.');
            setopenPoptavka(true);
          }
          else
          {
            let kalkulovat = validace();

            if(kalkulovat[0] !== false)
            {
              if(druh === 0)
              {
                formData.parametry = inputFieldsDokument
              }
              else if(druh === 1)
              {
                formData.parametry = inputFieldsBalik
              }
              else if(druh === 2)
              {
                formData.parametry = inputFieldsPaleta
              }

              const data = await postkalulovat2(formData,Slevy,open);

              if (data.length > 0)
              {
                setKalkulace(data); 
              }
              else
              {
                seterrorMsg('Nepodařilo se zkalkulovat Vaši zásilku. Překontrolujte zdali jsou zadané ůdaje správné, případně kontaktujte podporu.');
                setopenPoptavka(true);
              }
            }

          }     
        }
        else
        {
          if(adresFrom === null || adresFrom === '')
          {setstatFrom(true);seterrorMsg('Vyplnte stát odeslání.');}
          if(adresTo === null || adresTo === '')
          {setstatTo(true);seterrorMsg('Vyplnte stát doručení.');};
        }   
      } 
    }
  }

  const preskocitKalkulaci = async (event) => {    
    
    if(errorMsg.length === 0)
    {
      setstatTo(false);
      setstatFrom(false);
      
      if (adresFrom !== null && adresFrom !== '' && adresTo !== null && adresFrom !== '' )
      {
        if( adresFrom !== 'CZ' && adresTo !== 'CZ')
        {
          
          setstatTo(true);
          setstatFrom(true);
          seterrorMsg('Stát doručení nebo stát odeslání musí být Česká republuka.');

        }
        else if(doplnky[0].DoplnekInt === true && hodnotaZbozi < 300){
          seterrorMsg('V případě pojištění zásilky je potřeba uvést její hodnotu. Minimální hodnta zásilky pro pojištění je 300 Kč');
        }
        else
        {
          if(druh === 3){
            
            inputFieldsOstatni.map(i => {
              if(i.hmotnost <= 0 || i.delka < 1 || i.vyska < 1 || i.sirka < 1 || i.pocet < 1){
                seterrorMsg('Vyplňte všechna pole.');
              }
            })
          }

          if(errorMsg.length === 0)
          {
            if(druh === 3)
            {
              formData.parametry = inputFieldsOstatni;              
              navigation.next();

            }
          }
        }     
      }
      else
      {
        if(adresFrom === null || adresFrom === '')
        {setstatFrom(true);seterrorMsg('Vyplnte stát odeslání.');}
        if(adresTo === null || adresTo === '')
        {setstatTo(true);seterrorMsg('Vyplnte stát doručení.');};
      }   
    }     
  }

  function VybratKalkulaci(row){
    formData.SluzbaID = row.SluzbaID;
    formData.sluzbaNazev=row.SluzbnaNazev;
    formData.cena=row.CenaKonecna;
    formData.cenaZaklad=row.Cena;
    formData.prepravce=row.SpolecnostNazev;
    formData.sluzba=row.SluzbnaID;
    navigation.next()
      
  }

  useEffect(() => {
    setSelectedTab(druh ? druh : 0);  

    NactiParametry();

    setinputFieldsPaleta(formData.palety);
    setinputFieldsDokument(formData.dokuemnty);
    setinputFieldsBalik(formData.baliky);        
    setinputFieldsDokument(formData.dokuemnty);

      
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) 

  useEffect(() => {
    NactiParametryAdmin();      
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UserType]) 

  const NactiParametryAdmin = async () => {    
    if(UserType === '1'){
      const dataFirmy = await getFirmy(); 
      const dataSlevy = await getSlevyKlient(0);  
      setFrirmy(dataFirmy);
      MapovatSlevt(dataSlevy);
    }    
  }  

  const NactiParametry = async () => {
    
    const dataSluzby = await getSluzby();
    const dataPrepravci = await getPrepravci();
    const dataStaty = await getStaty();
    const dataUserType = await getUserType();  

    if(UserType === '1'){
      const dataFirmy = await getFirmy(); 
      const dataSlevy = await getSlevyKlient(0);  
      setFrirmy(dataFirmy);
      MapovatSlevt(dataSlevy);
    }

    //MapovatSlevt(dataSluzby);
    setSluzby(dataSluzby);
    setPrepravci(dataPrepravci);
    setStaty(dataStaty.theStaty.stat);
    setUserType(dataUserType[0].UserType);

    if(dataStaty)
    {
      formData.defaultTo = Staty.find(x => x.Kod === formData.adresTo) !== undefined ? Staty.find(x => x.Kod === formData.adresTo) : null;    
      formData.defaultFrom = Staty.find(x => x.Kod === formData.adresFrom) !== undefined ? Staty.find(x => x.Kod === formData.adresFrom) : null;   
    }
    if(dataPrepravci)
    {
      formData.Defprepravce = Prepravci.find(x => x.Nazev === formData.prepravce) !== undefined ? Prepravci.find(x => x.Nazev === formData.prepravce) : null;     
    }
    if(dataSluzby)
    {
      formData.Defsluzba = Sluzby.find(x => x.SluzbaID === formData.SluzbaID) !== undefined ? Sluzby.find(x => x.SluzbaID === formData.SluzbaID) : null;     
    }
    
  }  

  const onChangeValue = (e) => {
    setMail({
      ...Mail,
          ...Mail,
      [e.target.name]:e.target.value
    });        
  }

  const onChangeValuePoptavka = (e) => {
    setMailPoptavka({
      ...MailPoptavka,
          ...MailPoptavka,
      [e.target.name]:e.target.value
    });        
  }

  const restart = (e) => {window.location.reload();}

  const handleChangeInput = (id, event) => {
    removeCalculations()   
    const editrows = Slevy.map(item =>
      item.SluzbaID === id ? { ...item, sleva: event.target.value } : item
    );
    setSlevy(editrows);   
  }
  
  const handleOpen = (e) => {
    setOpen(!open);
  };


  const handleCloseMail = () => {
    setOpenMail(false);
    setMail({   
      EmailAdresat:'',
      TextMail:'Vážený/á pane/ní................,\n\nděkujeme Vám za zájem o naše služby a na základě poptávky Vám zasíláme kalkulaci dopravy.'
      })
  };

  const handleCloseMailPoptavka = () => {
    setOpenMailPoptavka(false);
    setMailPoptavka({   
      TextMail:''
      })
  };

  const sendMailKalkulace = () => {
    postEmailKalkulace({Mail, Kalkulace,formData});    
    handleCloseMail();
  };

  function trueFalse(params) 
  {
    if(params === true)
    { return false;}
    else 
    { return true;}    
  };
  
  const sendMailKalkulacePoptavka = () => {
    postEmailPoptavka({MailPoptavka,formData});    
    handleCloseMailPoptavka();
  };
  
  const handleOpenMail = () => {
    setOpenMail(true);
    setMail({   
      EmailAdresat:'',
      TextMail:'Vážený/á pane/ní................,\n\nděkujeme Vám za zájem o naše služby a na základě poptávky Vám zasíláme kalkulaci dopravy.'
      })
  };
  
  const handleOpenMailPoptavka = () => {
    setOpenMailPoptavka(true);
    setMailPoptavka({TextMail:''})
  };
  
const GreenButton = withStyles((theme) => ({
  root: {
    color: customConfig.Colors.TextColor,
    backgroundColor: customConfig.Colors.PrimaryColor,
    '&:hover': {
      backgroundColor: customConfig.Colors.SecColor,
    },
  },
}))(Button); 
  
const SecButton = withStyles((theme) => ({
  root: {
    color: customConfig.Colors.TextColor,
    backgroundColor: customConfig.Colors.SecColor2,
    '&:hover': {
      backgroundColor: customConfig.Colors.SecColor,
    },
  },
}))(Button); 

  return (
  <Container className={classes.Topmg} maxWidth="xl">  
  <form>
    <Grid className={classes.bgGrid} container spacing={1}>
      <Grid item xs={12} sm={6}>
      <Autocomplete
          id="adresFrom"
          name={"adresFrom"+Date()}
          label="Stát odeslání"
          options={Staty}
          getOptionLabel={(option) => option.Nazev ? option.Nazev : ''}
          fullWidth 
          clearOnBlur
          value={formData.DefAdresFrom}
          getOptionSelected={(option, value) => option.Kod === value.Kod}    
          onChange={(event, newValue) => {
            removeCalculations();
            if (newValue) {    
              formData.adresFrom = newValue.Kod;  
              formData.odesilatelStatNazev = newValue.Nazev;      
              formData.DefAdresFrom = newValue;
              formData.odesilatelTel = newValue.phone;
              setstatFrom(false)
            }
            else{        
              formData.adresFrom = '';       
              formData.odesilatelStatNazev = '';         
              formData.DefAdresFrom = null;
              formData.odesilatelTel = '';
              setstatFrom(true)
            }
          }} 
          renderOption={(option) => (
            <React.Fragment>
                <span><img src={"../img/flags/24/"+option.Kod+".png"} alt={option.Kod}/></span>
                {option.Nazev}
            </React.Fragment>
          )}
          renderInput={(params) => {            
            return (<TextFields {...params} 
              autoComplete="new-password"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password",
              }} error={statFrom} label="Stát odeslání" size="small" name={"adresFrom"+Date()} variant="outlined" />)}}
          />
          
      </Grid>
      <Grid item xs={12} sm={6}>
        <Autocomplete
            id="adresTo"
            label="Stát doručení"
            options={Staty}
            getOptionLabel={(option) => option.Nazev ? option.Nazev : ''}
            fullWidth         
            name={"adresTo"+Date()} 
            clearOnBlur 
            value={formData.DefAdresTo}      
            getOptionSelected={(option, value) => option.Kod === value.Kod}          
            onChange={(event, newValue) => {
              removeCalculations();
              
              if (newValue) {    
                formData.adresTo = newValue.Kod;     
                formData.prijemceStatNazev = newValue.Nazev;
                formData.DefAdresTo = newValue;
                formData.prijemceTel = newValue.phone;
                setstatTo(false)
              }
              else{        
                formData.adresTo = '';     
                formData.prijemceStatNazev = '';    
                formData.DefAdresTo = null;
                formData.prijemceTel = '';
                setstatTo(true)
              }
            }}       
            renderOption={(option) => (
              <React.Fragment>
                  <span><img src={"../img/flags/24/"+option.Kod+".png"} alt={option.Kod}/></span>
                  {option.Nazev}
              </React.Fragment>
            )}
            renderInput={(params) => {
              
              return (<TextFields {...params} 
                autoComplete='new-password'
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }} error={statTo} label="Stát doručení" size="small" name={"adresTo"+Date()}  variant="outlined" />)}}
            />

      </Grid>

      <Grid item xs={12} sm={12}>
        <Accordion defaultExpanded={true}>
            <AccordionSummary className={classes.SummaryHead} expandIcon={<ExpandMoreIcon className={classes.iconColorSec} />}>Doplňkové služby</AccordionSummary>
            <AccordionDetail>

            <Grid container item spacing={2}>
              <Grid item xs={12} sm={3}>
                  <Typography className={classes.typo}>
                          <GreenCheckbox
                            checked={doplnky[0].DoplnekInt}
                            onChange={(e) => {handleChangeInputDoplnek(e);removeCalculations() }}
                            id="POJISTENI"
                            key="POJISTENI"
                            name="POJISTENI"
                            color="primary"
                              />
                      Pojistit zásilku  
                      
                    <HtmlTooltip title={
                      <>
                      
                      <p>Pojistné je účtováno z pojistné hodnoty zásilky ve výši 1 %, min. 300 CZK.</p>
                      <p>Poškození vlivem neadekvátního balení vyhrazeno.</p>
                      <p>Pro vyšší hodnotu zásilky (nad 500 000 CZK), kontaktujte naši podporu. Ceny se mohou lišit dle vybraných destinací.</p>
                      </>}>
                      <InfoIcon className={classes.iconColortt} />
                    </HtmlTooltip>              
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextFields
                    required
                    id="POJISTENI"
                    key="POJISTENI"
                    name="hodnotaZbozi"
                    label="Hodnota zásilky"
                    type="number"                    
                    error ={ (doplnky[0].DoplnekInt===true && hodnotaZbozi < 300 ) ? true : false }
                    value={hodnotaZbozi}
                    style={{ visibility: doplnky[0].DoplnekInt === false ? 'hidden': 'visible'}}
                    fullWidth
                    variant="outlined"
                    size="small"
                    InputProps={{
                    startAdornment: <InputAdornment position="start">Kč</InputAdornment>
                    }}
                    onChange={(e) => {handleChangeInputDoplnekTF(e);setForm(e);removeCalculations() }}
                    /> 
                </Grid>
                <Grid item xs={12} sm={7}/>
                

                {(selectedTab === 2) && (
                  <>                
                  <Grid item xs={12} sm={4}>
                  <Typography className={classes.typo}>
                  <GreenCheckbox
                    checked={doplnky[6].DoplnekInt}
                    onChange={(e) => {handleChangeInputDoplnek(e);removeCalculations() }}
                    id="STOHOVATELNA"
                    key="STOHOVATELNA"
                    name="STOHOVATELNA"
                    color="primary"
                  />       
                    Nestohovatelná
                  </Typography>
                </Grid>

                    <Grid item xs={12} sm={8}/>
                  </>
                )}

                <Grid item xs={12} sm={5}>
                  <Typography className={classes.typo}>
                    <GreenCheckbox
                      checked={doplnky[1].DoplnekInt}
                      onChange={(e) => {handleChangeInputDoplnek(e);removeCalculations() }}
                      id="BEZPECNA"
                      key="BEZPECNA"
                      name="BEZPECNA"
                      color="primary"
                    />        
                    Prohlašuji, že zboží je bezpečné      
                    <HtmlTooltip title={longText}>
                      <InfoIcon className={classes.iconColortt} />
                    </HtmlTooltip>   
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7}/>


                {(doplnky[1].DoplnekInt === false) && (
                  <>

                    <Grid item xs={12} sm={1}/>


                    <Grid item xs={12} sm={4}>
                      <Typography className={classes.typo}>
                        <GreenCheckbox
                          checked={doplnky[2].DoplnekInt}
                          onChange={(e) => {handleChangeInputDoplnek(e);removeCalculations() }}
                          id="UN1845"
                          key="UN1845"
                          name="UN1845"
                          color="primary"
                        />        
                        Suchý led (UN1845)
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={7}>
                      <TextFields
                          id="UN1845"
                          name="DoplnekTextUN1845"
                          key="UN1845"
                          label="Popis"
                          type="text"
                          value={doplnky[2].DoplnekText}
                          style={{ visibility: doplnky[2].DoplnekInt === false ? 'hidden': 'visible'}}
                          fullWidth
                          variant="outlined"
                          size="small"
                          onChange={(e) => {handleChangeInputDoplnekTF(e);removeCalculations() }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={1}/>
                    <Grid item xs={12} sm={4}>
                      <Typography className={classes.typo}>
                        <GreenCheckbox
                          checked={doplnky[3].DoplnekInt}
                          onChange={(e) => {handleChangeInputDoplnek(e);removeCalculations() }}
                          id="BATERIEZARIZENI"
                          key="BATERIEZARIZENI"
                          name="BATERIEZARIZENI"
                          color="primary"
                        />        
                        Lithiové baterie - se zařízením
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={7}>
                      <TextFields
                          id="BATERIEZARIZENI"
                          name="DoplnekTextBATERIEZARIZENI"
                          key="BATERIEZARIZENI"
                          label="Popis"
                          type="text"
                          value={doplnky[3].DoplnekText}
                          style={{ visibility: doplnky[3].DoplnekInt === false ? 'hidden': 'visible'}}
                          fullWidth
                          variant="outlined"
                          size="small"
                          onChange={(e) => {handleChangeInputDoplnekTF(e);removeCalculations() }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={1}/>
                    <Grid item xs={12} sm={4}>
                      <Typography className={classes.typo}>
                        <GreenCheckbox
                          checked={doplnky[4].DoplnekInt}
                          onChange={(e) => {handleChangeInputDoplnek(e);removeCalculations() }}
                          id="BATERIEVZARIZENI"
                          key="BATERIEVZARIZENI"
                          name="BATERIEVZARIZENI"
                          color="primary"
                        />        
                        Lithiové baterie - v zařízení
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <TextFields
                          id="BATERIEVZARIZENI"
                          name="DoplnekTextBATERIEVZARIZENI"
                          key="BATERIEVZARIZENI"
                          label="Popis"
                          type="text"
                          value={doplnky[4].DoplnekText}
                          style={{ visibility: doplnky[4].DoplnekInt === false ? 'hidden': 'visible'}}
                          fullWidth
                          variant="outlined"
                          size="small"
                          onChange={(e) => {handleChangeInputDoplnekTF(e);removeCalculations() }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={1}/>
                    <Grid item xs={12} sm={4}>
                      <Typography className={classes.typo}>
                        <GreenCheckbox
                          checked={doplnky[5].DoplnekInt}
                          onChange={(e) => {handleChangeInputDoplnek(e);removeCalculations() }}
                          id="BATERIE"
                          key="BATERIE"
                          name="BATERIE"
                          color="primary"
                        />        
                        Lithiové baterie - samostatné
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <TextFields
                          id="BATERIE"
                          name="DoplnekTextBATERIE"
                          key="BATERIE"
                          label="Popis"
                          type="text"
                          value={doplnky[5].DoplnekText}
                          style={{ visibility: doplnky[5].DoplnekInt === false ? 'hidden': 'visible'}}
                          fullWidth
                          variant="outlined"
                          size="small"
                          onChange={(e) => {handleChangeInputDoplnekTF(e);removeCalculations() }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={1}/>
                    <Grid item xs={12} sm={4}>
                      <Typography className={classes.typo}>
                        <GreenCheckbox
                          checked={BEZPECNAOSTATNI}
                          onChange={(e) => {setForm(e);removeCalculations() }}
                          id="BEZPECNAOSTATNI"
                          key="BEZPECNAOSTATNI"
                          name="BEZPECNAOSTATNI"
                          color="primary"
                        />        
                        Ostatní nebezpčené zásilky
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={7}/>

                  </>
                )}



                    <Grid item xs={12} sm={4}>
                      <Typography className={classes.typo}>
                      <GreenCheckbox
                        checked={doplnky[7].DoplnekInt}
                        onChange={(e) => {handleChangeInputDoplnek(e);removeCalculations() }}
                        id="VDD"
                        key="VDD"
                        name="VDD"
                        color="primary"
                      />
                        Požadujeme zpoplatněné VDD
                      </Typography>
                    </Grid>                

                    <Grid item xs={12} sm={8}/>
              </Grid>
            </AccordionDetail>
          </Accordion>
        </Grid>

      {(UserType === '1' && Slevy!==[]) && (
      <>
        <Grid item container xs={12} sm={12}>
        
          <Grid item xs={12} sm={4}>
            <Typography className={classes.typo}>
                  <GreenCheckbox
                    checked={open}
                    onChange={handleOpen}
                    value="checked"
                    id="adminSlevy"
                    name="adminSlevy"
                  />          
              Vlastní sleva              
            </Typography>
          </Grid>
        </Grid>
        {(UserType === '1' && Slevy!==[] && open === true ) && (
        <>
          <Grid item xs={12} sm={12}>
            <Accordion defaultExpanded={true}>
              <AccordionSummary className={classes.SummaryHead} expandIcon={<ExpandMoreIcon className={classes.iconColorSec} />}>Slevy dle klienta</AccordionSummary>
              <AccordionDetail>

                <Grid container item spacing={2}>
                  <Grid item xs={12} sm={5}>
                    <Autocomplete
                      id="Firmax"
                      options={Frirmy}
                      getOptionLabel={(option) => option.Firma ? option.Firma +' ('+option.IC+')' : ''}                       
                      value={firma.defaultFirma}       
                      style={{ width: 300, marginRight:20, marginLeft:20}}
                      getOptionSelected={(option, value) => option.UserID === value.UserID}         
                      onChange={(event, newValue) => {
                        if (newValue) {                                  
                          setFirma({
                            defaultFirma:newValue,
                          });
                        }
                        else{                 
                          setFirma({
                              defaultFirma:null,
                          });
                        }
                        }}                                      
                      renderOption={(option) => (
                      <React.Fragment>
                          <span className={classes.left}>{option.Firma +' ('+option.IC+')'}</span>
                      </React.Fragment>
                      )}
                      renderInput={(params) => <TextField {...params} className={classes.tf} size="small" variant="outlined" />}
                    />   

                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <GreenButton
                      variant="contained"
                      fullWidth
                      color="primary"
                      onClick={(e) => {MapovatSlevuDleKlienta()}}
                    >
                      Nastavit slevu
                    </GreenButton> 
                  
                  </Grid>
                </Grid>
              </AccordionDetail>
            </Accordion>
          </Grid>        
          <Grid item xs={12} sm={12}>
            <Accordion defaultExpanded={true}>
              <AccordionSummary className={classes.SummaryHead} expandIcon={<ExpandMoreIcon className={classes.iconColorSec} />}>Sleva na vše</AccordionSummary>
              <AccordionDetail>

                <Grid container item spacing={2}>
                  <Grid item xs={12} sm={3}>

                    <TextFields
                      id="slevaProc"
                      name="hromadnaSleva"
                      type="number"
                      label="Sleva v %"
                      value={hromadnaSleva}
                      fullWidth
                      InputProps={{
                      startAdornment: <InputAdornment position="start">%</InputAdornment>
                      }}
                      variant="outlined"
                      size="small"
                      onChange={(e) => {sethromadnaSleva(e.target.value)}}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <GreenButton
                      variant="contained"
                      fullWidth
                      color="primary"
                      onClick={(e) => {MapovatHromadnouSlevu()}}
                    >
                      Nastavit slevu
                    </GreenButton> 
                  
                  </Grid>
                </Grid>
              </AccordionDetail>
            </Accordion>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Accordion defaultExpanded={false}>
              <AccordionSummary className={classes.SummaryHead} expandIcon={<ExpandMoreIcon className={classes.iconColorSec} />}>Jednotlivé služby</AccordionSummary>
              <AccordionDetail>

                <Grid container item spacing={2}>
                  <Grid item container xs={12} sm={12}>
                    <Grid item xs={12} sm={3}/>
                    <Grid item xs={12} sm={6}>
                      <Table className={classes.table}>
                        <TableBody>
                          {Slevy.map(row => {
                            return (
                              <TableRow key={row.SluzbaNazev}  className={classes.tableRows}>          
                                <TableCell className={classes.tableRow} width="150px">{row.SluzbaNazev}</TableCell>
                                <TableCell className={classes.tableRow} width="200px">                      
                                  <TextFields
                                    id="slevaProc"
                                    name="slevaPXroc"
                                    type="number"
                                    label="Sleva v %"
                                    style={{margin:"5px"}}
                                    fullWidth
                                    value={row.sleva}
                                    InputProps={{
                                    startAdornment: <InputAdornment position="start">%</InputAdornment>
                                    }}
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) => {handleChangeInput(row.SluzbaID,e)}}
                                  />            
                                </TableCell>                    
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>    
                    </Grid>
                    <Grid item xs={12} sm={3}/>
                  </Grid>
                </Grid>
              </AccordionDetail>
            </Accordion>
          </Grid>  
        </>  
        )} 
    </>      
      )}

      {(errorMsg !== '' && errorMsg !== null && errorMsg !== undefined) && (
        
        <Grid item xs={12} sm={12}>
          <div style={{ visibility: errorMsg === '' ? 'hidden': 'visible'}}>             
            <Alert severity="error">{errorMsg}</Alert>          
          </div>   
        </Grid>
        )}
      <AppBar className={classes.appBar} position="relative" style={{ background: 'transparent', boxShadow: 'none'}}>
        <StyledTabs
            value={selectedTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered
            className={classes.tabs}
            aria-label="scrollable force tabs example"
        >
            <Tab className={classes.tab} label="Dokument" icon={ <img src="img/base/dokument.png"  alt="Paleta" height="40px" />} />
            <Tab className={classes.tab} label="Balík" icon={<img src="img/base/balik.png"  alt="Balík" height="40px" />}/>
            <Tab className={classes.tab} label="Paleta" icon={<img src="img/base/paleta.png" alt="Paleta" height="40px" />} />

            {(UserType === '1') && (
            <Tab className={classes.tab} label="Ostatní" icon={<img src="img/base/mix.png" alt="Ostatní" height="40px" />} />
            )}
        </StyledTabs>

      </AppBar>    
      
      {selectedTab === 1 && (
          <Grid item container spacing={1}> 
            { inputFieldsBalik.map(inputField => (    
              <Grid item container  key={inputField.id} spacing={1}> 
                <Grid item xs={12} md={2} sm={2} lg={1}>
                  <TextFields
                      required
                      id="pocet"
                      key="pocet"
                      name="pocet"
                      label="Počet"
                      type="number"
                      error ={ inputField.pocet < 1 ? true : false }
                      value={inputField.pocet || ''}
                      fullWidth
                      variant="outlined"
                      size="small"
                      onChange={(e) => {handleChangeInputBalik(inputField.id, e);removeCalculations()}}
                  />
                </Grid>
                <Grid item xs={12} md={2} sm={3} lg={2}>
                  <TextFields
                      required
                      id="delka"
                      name="delka"
                      label="Délka"
                      type="number"
                      value={inputField.delka || ''}
                      fullWidth
                      error ={ inputField.delka < 1 ? true : false }
                      InputProps={{
                      startAdornment: <InputAdornment position="start">cm</InputAdornment>
                      }}
                      variant="outlined"
                      size="small"
                      onChange={(e) => {handleChangeInputBalik(inputField.id, e);removeCalculations()}}
                  />
                </Grid>
                <Grid item xs={12} md={2} sm={3} lg={2}>
                  <TextFields
                      required
                      id="sirka"
                      name="sirka"
                      type="number"
                      label="Šířka"
                      value={inputField.sirka || ''}
                      error ={ inputField.sirka < 1 ? true : false }
                      fullWidth
                      InputProps={{
                      startAdornment: <InputAdornment position="start">cm</InputAdornment>
                      }}
                      variant="outlined"
                      size="small"
                      onChange={(e) => {handleChangeInputBalik(inputField.id, e);removeCalculations()}}
                  />
                </Grid>
                <Grid item xs={12} md={2} sm={3} lg={2}>
                  <TextFields
                      required
                      id="vyska"
                      name="vyska"
                      label="Výška"
                      type="number"
                      value={inputField.vyska || ''}
                      error ={ inputField.vyska < 1 ? true : false }
                      fullWidth
                      InputProps={{
                      startAdornment: <InputAdornment position="start">cm</InputAdornment>
                      }}
                      variant="outlined"
                      size="small"
                      onChange={(e) => {handleChangeInputBalik(inputField.id, e);removeCalculations()}}
                  />
                </Grid>
                <Grid item xs={12} md={2} sm={3} lg={2}>
                  <TextFields
                      required
                      id="hmotnost"
                      name="hmotnost"
                      label="Hmotnost 1 ks"
                      type="number"
                      value={inputField.hmotnost || ''}
                      error ={ inputField.hmotnost <= 0 ? true : false }
                      fullWidth
                      InputProps={{
                      startAdornment: <InputAdornment position="start">Kg</InputAdornment>
                      }}
                      variant="outlined"
                      size="small"
                      onChange={(e) => {handleChangeInputBalik(inputField.id, e);removeCalculations()}}
                  />
                </Grid>
                <Grid item xs={12} md={2} sm={2} lg={1}>     
                  <IconButton aria-label="Add" className={classes.iconBTN} onClick={handleAddFieldsBalik}>
                      <Add className={classes.iconColor} />
                  </IconButton>
                  <IconButton aria-label="delete" className={classes.iconBTN} disabled={inputFieldsBalik.length === 1} onClick={() => handleRemoveFieldsBalik(inputField.id)}>
                      <Delete />
                  </IconButton>
                </Grid>
              </Grid>
            )) }
          </Grid>
        )}
        {selectedTab === 0 && (
        <Grid item container spacing={1}> 
          { inputFieldsDokument.map(inputField => (    
            <Grid item key={inputField.id} container spacing={1}>             
                <Grid item xs={12} md={3} sm={4} lg={2}>
                  <TextFields
                    required
                    id="pocet"
                    name="pocet"
                    key="pocet"
                    label="Počet"
                    type="number"
                    value={inputField.pocet ||''}   
                    error ={ inputField.pocet < 1 ? true : false }
                    fullWidth
                    onChange={(e) => {handleChangeInputDokument(inputField.id, e);removeCalculations()}}
                    variant="outlined"
                    size="small"                      
                  />
                </Grid>
                <Grid item xs={12} md={3} sm={4} lg={2} >
                  <TextFields
                    id="hmotnost"
                    name="hmotnost"
                    required
                    select
                    label="Hmotnost 1 ks do"
                    size="small"
                    fullWidth
                    value={inputField.hmotnost}
                    onChange={(e) => {handleChangeInputDokument(inputField.id, e);removeCalculations()}}
                    variant="outlined"
                  >
                    {Hmotnosti.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextFields>
                </Grid>
                <Grid item xs={12} sm={4}>     
                  <IconButton aria-label="Add" className={classes.iconBTN} onClick={handleAddFieldsDokument}>
                      <Add className={classes.iconColor} />
                  </IconButton>
                  <IconButton aria-label="delete" className={classes.iconBTN} disabled={inputFieldsDokument.length === 1} onClick={() => handleRemoveFieldsDokument(inputField.id)}>
                      <Delete />
                  </IconButton>
                </Grid>
            </Grid>
          )) }
        </Grid>
        )}
        {selectedTab === 2 && (
        <Grid item container spacing={1}>
        { inputFieldsPaleta.map(inputField => (    
          <Grid item key={inputField.id} container spacing={1}> 
            <Grid item xs={12} md={2} sm={2} lg={1}>
              <TextFields
                required
                id="pocet"
                name="pocet"
                label="Počet"
                type="number"
                value={inputField.pocet}
                error ={ inputField.pocet < 1 ? true : false }
                fullWidth
                variant="outlined"
                size="small"
                onChange={(e) => {handleChangeInputPaleta(inputField.id, e);removeCalculations()}}
              />
            </Grid>
            <Grid item xs={12} md={2} sm={3} lg={2}>
              <TextFields
                required
                id="delka"
                name="delka"
                label="Délka"
                type="number"
                value={inputField.delka}
                error ={ inputField.delka < 1 ? true : false }
                fullWidth
                InputProps={{
                startAdornment: <InputAdornment position="start">cm</InputAdornment>
                }}
                variant="outlined"
                size="small"
                onChange={(e) => {handleChangeInputPaleta(inputField.id, e);removeCalculations()}}
              />
            </Grid>
            <Grid item xs={12} md={2} sm={3} lg={2}>
              <TextFields
                required
                id="sirka"
                name="sirka"
                type="number"
                label="Šířka"
                value={inputField.sirka}
                error ={ inputField.sirka < 1 ? true : false }
                fullWidth
                InputProps={{
                startAdornment: <InputAdornment position="start">cm</InputAdornment>
                }}
                variant="outlined"
                size="small"
                onChange={(e) => {handleChangeInputPaleta(inputField.id, e);removeCalculations()}}
              />
            </Grid>
            <Grid item xs={12} md={2} sm={3} lg={2}>
              <TextFields
                required
                id="vyska"
                name="vyska"
                label="Výška"
                type="number"
                value={inputField.vyska}
                error ={ inputField.vyska < 1 ? true : false }
                fullWidth
                InputProps={{
                startAdornment: <InputAdornment position="start">cm</InputAdornment>
                }}
                variant="outlined"
                size="small"
                onChange={(e) => {handleChangeInputPaleta(inputField.id, e);removeCalculations()}}
              />
            </Grid>
            <Grid item xs={12} md={2} sm={3} lg={2}>
              <TextFields
                required
                id="hmotnost"
                name="hmotnost"
                label="Hmotnost 1 ks"
                type="number"
                value={inputField.hmotnost}
                error ={ inputField.hmotnost <= 0 ? true : false }
                fullWidth
                InputProps={{
                startAdornment: <InputAdornment position="start">Kg</InputAdornment>
                }}
                variant="outlined"
                size="small"
                onChange={(e) => {handleChangeInputPaleta(inputField.id, e);removeCalculations()}}
              />
            </Grid>
            <Grid item xs={12} md={2} sm={2} lg={1}>     
              <IconButton className={classes.iconBTN} aria-label="Add" onClick={handleAddFieldsPaleta}>
                  <Add className={classes.iconColor} />
              </IconButton>
              <IconButton className={classes.iconBTN} aria-label="delete" disabled={inputFieldsPaleta.length === 1} onClick={() => handleRemoveFieldsPaleta(inputField.id)}>
                  <Delete />
              </IconButton>
            </Grid>          
          </Grid>
          )) }
        </Grid>
        )}
        {selectedTab === 3 && (
            <Grid item container spacing={1}> 



              <Grid item container spacing={2} xs={12} sm={12}>
                <Grid item xs={12} md={3} sm={4} lg={2}>
                  <TextFields
                      id="cena"
                      name="cena"
                      label="Celková cena"
                      type="number"
                      value={cena}
                      fullWidth
                      InputProps={{
                        endAdornment: <InputAdornment position="end">Kč</InputAdornment>
                      }}
                      variant="outlined"
                      size="small"
                      onChange={setForm}
                  />
                </Grid>
                <Grid item xs={12} md={3} sm={4} lg={2}>
                  <TextFields
                      id="cenaZaklad"
                      name="cenaZaklad"
                      label="Cena náklady"
                      type="number"
                      value={cenaZaklad}
                      fullWidth
                      InputProps={{
                        endAdornment: <InputAdornment position="end">Kč</InputAdornment>
                      }}
                      variant="outlined"
                      size="small"
                      onChange={setForm}
                  />
                </Grid>


                <Grid item xs={12} md={3} sm={4} lg={2}> 


                  <Autocomplete
                    id="prepravce"
                    options={Prepravci}
                    getOptionLabel={(option) => option.Nazev ? option.Nazev : ''}
                    fullWidth        
                    value={formData.Defprepravce}
                    clearOnBlur   
                    getOptionSelected={(option, value) => option.Kod === value.Kod}                   
                    onChange={(event, newValue) => {
                        removeCalculations();
                        if (newValue) {    
                            formData.prepravce=newValue.Nazev;
                            formData.Defprepravce = newValue;
                        }
                        else{                 
                          formData.prepravce='';
                          formData.Defprepravce = null;
                        }
                    }}       
                    renderOption={(option) => (
                    <React.Fragment>
                        <span><img src={"../img/loga/"+option.Logo} height="20px" alt={option.Kod}/></span>
                        <span className={classes.left}>{option.Nazev}</span>
                    </React.Fragment>
                    )}
                    renderInput={(params) => <TextField {...params} label="Přepravce" size="small" variant="outlined" />}
                />        
                </Grid>

                <Grid item xs={12} md={3} sm={4} lg={2}>




                  <Autocomplete
                    id="sluzba"
                    options={Sluzby}
                    getOptionLabel={(option) => option.SluzbaNazev ? option.SluzbaNazev +' ('+option.PrepravceNazev+')' : ''}
                    fullWidth
                    clearOnBlur 
                    getOptionSelected={(option, value) => option.SluzbaKod === value.SluzbaKod}        
                    value={formData.Defsluzba}             
                    onChange={(event, newValue) => {
                      removeCalculations();
                      if (newValue) {    
                          formData.sluzba=newValue.SluzbaID;
                          formData.sluzbaID=newValue.SluzbaID;
                          formData.SluzbaID=newValue.SluzbaID;
                          formData.sluzbaNazev=newValue.SluzbaNazev;
                          formData.Defsluzba = newValue;
                      }
                      else{                 
                        formData.sluzba='';
                        formData.sluzbaID='';
                        formData.sluzbaNazev='';
                        formData.Defsluzba = null;
                      }
                    }}                       
                    renderOption={(option) => (
                    <React.Fragment>
                        <span><img src={"../img/loga/"+option.PrepravceLogo} height="20px" alt={option.Kod}/></span>
                        <span className={classes.left}>{option.SluzbaNazev +' ('+option.PrepravceNazev+')'}</span>
                    </React.Fragment>
                    )}
                    renderInput={(params) => <TextField {...params} label="Služba" size="small" variant="outlined" />}
                  />

                </Grid>
              </Grid>


              { inputFieldsOstatni.map(inputField => (    
                <Grid item container  key={inputField.id} spacing={1}> 
                  <Grid item xs={12} md={2} sm={2} lg={1}>
                    <TextFields
                        required
                        id="pocet"
                        key="pocet"
                        name="pocet"
                        label="Počet"
                        type="number"
                        error ={ inputField.pocet < 1 ? true : false }
                        value={inputField.pocet || ''}
                        fullWidth
                        variant="outlined"
                        size="small"
                        onChange={(e) => {handleChangeInputOstatni(inputField.id, e);removeCalculations()}}
                    />
                  </Grid>
                  <Grid item xs={12} md={2} sm={3} lg={2}>
                    <TextFields
                        required
                        id="delka"
                        name="delka"
                        label="Délka"
                        type="number"
                        value={inputField.delka || ''}
                        fullWidth
                        error ={ inputField.delka < 1 ? true : false }
                        InputProps={{
                        startAdornment: <InputAdornment position="start">cm</InputAdornment>
                        }}
                        variant="outlined"
                        size="small"
                        onChange={(e) => {handleChangeInputOstatni(inputField.id, e);removeCalculations()}}
                    />
                  </Grid>
                  <Grid item xs={12} md={2} sm={3} lg={2}>
                    <TextFields
                        required
                        id="sirka"
                        name="sirka"
                        type="number"
                        label="Šířka"
                        value={inputField.sirka || ''}
                        error ={ inputField.sirka < 1 ? true : false }
                        fullWidth
                        InputProps={{
                        startAdornment: <InputAdornment position="start">cm</InputAdornment>
                        }}
                        variant="outlined"
                        size="small"
                        onChange={(e) => {handleChangeInputOstatni(inputField.id, e);removeCalculations()}}
                    />
                  </Grid>
                  <Grid item xs={12} md={2} sm={3} lg={2}>
                    <TextFields
                        required
                        id="vyska"
                        name="vyska"
                        label="Výška"
                        type="number"
                        value={inputField.vyska || ''}
                        error ={ inputField.vyska < 1 ? true : false }
                        fullWidth
                        InputProps={{
                        startAdornment: <InputAdornment position="start">cm</InputAdornment>
                        }}
                        variant="outlined"
                        size="small"
                        onChange={(e) => {handleChangeInputOstatni(inputField.id, e);removeCalculations()}}
                    />
                  </Grid>
                  <Grid item xs={12} md={2} sm={3} lg={2}>
                    <TextFields
                        required
                        id="hmotnost"
                        name="hmotnost"
                        label="Hmotnost 1 ks"
                        type="number"
                        value={inputField.hmotnost || ''}
                        error ={ inputField.hmotnost <= 0 ? true : false }
                        fullWidth
                        InputProps={{
                        startAdornment: <InputAdornment position="start">Kg</InputAdornment>
                        }}
                        variant="outlined"
                        size="small"
                        onChange={(e) => {handleChangeInputOstatni(inputField.id, e);removeCalculations()}}
                    />
                  </Grid>
                  <Grid item xs={12} sm={1}>     
                    <IconButton aria-label="Add" className={classes.iconBTN} onClick={handleAddFieldsOstatni}>
                        <Add className={classes.iconColor} />
                    </IconButton>
                    <IconButton aria-label="delete" className={classes.iconBTN} disabled={inputFieldsOstatni.length === 1} onClick={() => handleRemoveFieldsOstatni(inputField.id)}>
                        <Delete />
                    </IconButton>
                  </Grid>
                </Grid>
              )) }
            </Grid>
          )}

        <Grid item xs={12} sm={12}>
          {Kalkulace.map(row => {


            return (      
            <Accordion key={row.SluzbnaNazev}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                className={classes.nabidkyacco}
                id={"panel1a-header_"+row.SluzbnaNazev}
              >
                <Grid container>
                  <Grid item xs={12} sm={2}> <img src={"../img/loga/"+row.PrepravceLogo} height="25px" alt={row.SluzbnaNazev}/></Grid>          
                  <Grid item xs={12} sm={3}> <Typography className={classes.typo}> {row.SluzbnaNazev} </Typography></Grid>  
                  <Grid item xs={12} sm={1}> <Typography className={classes.typo}> {row.Dny} </Typography></Grid>  
                  <Grid item xs={12} sm={2}> <Typography className={classes.typo}> {parseFloat(row.CenaKonecna*(1+(row.SazbaDPH/100))).toFixed(2)} Kč vč. DPH </Typography></Grid>  
                  <Grid item xs={12} sm={2}> <Typography className={classes.typo}> {row.hmotnostVypocet + ' kg'} <HtmlTooltip title={longTextVaha}><InfoIcon className={classes.iconColortt} /></HtmlTooltip>  </Typography> </Grid>                     
                  <Grid item xs={12} sm={1}>
                      <GreenButton
                        variant="contained"
                        color="primary"
                        onClick={(e) =>
                          VybratKalkulaci(row)
                        }
                      >Vybrat
                      </GreenButton> 
                  </Grid>  
                </Grid>
              </AccordionSummary>
              <AccordionDetail>
                <Table className={classes.table}>
                  <TableBody>
                    <TableRow  className={classes.tableRows}>       
                      <TableCell className={classes.tableRow} width="100px"><Typography className={classes.typo2}> Cena služby: </Typography></TableCell>     
                      <TableCell className={classes.tableRow} width="200px"><Typography className={classes.typo}>{' '+row.CenaZaklad + ' Kč'}</Typography></TableCell>     
                    </TableRow>

                    <TableRow  className={classes.tableRows}>       
                      <TableCell className={classes.tableRow} width="100px"><Typography className={classes.typo2}> DPH: </Typography></TableCell>     
                      <TableCell className={classes.tableRow} width="200px"><Typography className={classes.typo}>{' '+row.DPH == "0" ? "Osvobozeno od DPH": parseFloat(row.CenaKonecna*((row.SazbaDPH/100))).toFixed(2) + ' Kč'}</Typography></TableCell>     
                    </TableRow>

                    <TableRow className={classes.tableRows}>                 
                    <TableCell className={classes.tableRow} width="100px"><Typography className={classes.typo2}> Celková váha: </Typography></TableCell>     
                      <TableCell className={classes.tableRow} width="200px"><Typography className={classes.typo}>{' '+row.hmotnost + ' kg'}</Typography></TableCell>     
                    </TableRow>
                    <TableRow className={classes.tableRows}>       
                      <TableCell className={classes.tableRow} width="100px"><Typography className={classes.typo2}> Fakturovaná váha: </Typography></TableCell>     
                      <TableCell className={classes.tableRow} width="200px"><Typography className={classes.typo}>{' '+row.hmotnostVypocet + ' kg'}</Typography></TableCell>     
                    </TableRow>
                    {row.PriplatkyJson.map(rowX => {
                      return (
                        <TableRow key={rowX.Popis}  className={classes.tableRows}>          
                          <TableCell className={classes.tableRow} width="100px"><Typography className={classes.typo2}>{'Příplatek - '+rowX.Popis}</Typography></TableCell>
                          <TableCell className={classes.tableRow} width="200px"> <Typography className={classes.typo}>{rowX.Priplatek + ' Kč'} </Typography> </TableCell>                    
                        </TableRow>
                      );
                    })}
                    <TableRow className={classes.tableRows}>       
                      <TableCell className={classes.tableRow} width="100px"><Typography className={classes.typo2}> Příplatky celkem: </Typography></TableCell>     
                      <TableCell className={classes.tableRow} width="200px"><Typography className={classes.typo2}>{' '+row.Priplatek + ' Kč'}</Typography>  </TableCell>     
                    </TableRow>                    
                    <TableRow className={classes.tableRows}>       
                      <TableCell className={classes.tableRow} width="100px"><Typography className={classes.typo2}> Cena celkem: </Typography></TableCell>     
                      <TableCell className={classes.tableRow} width="200px"><Typography className={classes.typo2}>{' '+parseFloat(row.CenaKonecna*(1+(row.SazbaDPH/100))).toFixed(2) + ' Kč'}</Typography>  </TableCell>     
                    </TableRow>
                  </TableBody>
                </Table>                    
              </AccordionDetail>
            </Accordion>
            );
          })}
        </Grid>
        
        <Grid item xs={12} sm={2}>
          
        {(UserType === '1' && Kalkulace.length>0) && (
          <GreenButton
            variant="contained"
            fullWidth
            color="primary"
            startIcon={<Email />}
            style={{ marginTop: "1rem" }}
            onClick={handleOpenMail}>
                          
              Odeslat nabídku              
          </GreenButton>
        )}

        {(openPoptavka===true) && (
          <GreenButton
            variant="contained"
            fullWidth
            color="primary"
            style={{ marginTop: "1rem" }}
            startIcon={<Email />}
            onClick={handleOpenMailPoptavka}>Odeslat poptávku</GreenButton>

        )}   
        <GreenButton
          variant="contained"
          fullWidth
          color="primary"
          style={{ marginTop: "1rem" }}
          onClick={(e) => {restart()}}
        >
          Vymazat parametry
        </GreenButton> 

        </Grid>
        <Grid item xs={12} sm={8}/>
        <Grid item xs={12} sm={2}>
        {selectedTab !== 3 && (
          <GreenButton
            variant="contained"
            fullWidth
            color="primary"
            style={{ marginTop: "1rem" }}
            onClick={kalulovat}            
            startIcon={<Calculate style={{color:customConfig.Colors.TextColor}} />}
          >Spočítat cenu
          </GreenButton>
        )}

        {selectedTab === 3 && (
          <GreenButton
            variant="contained"
            fullWidth
            color="primary"
            style={{ marginTop: "1rem" }}
            onClick={preskocitKalkulaci}            
            startIcon={<Calculate style={{color:customConfig.Colors.TextColor}} />}
          >Pokračovat
          </GreenButton>
        )}

        </Grid>
      </Grid>


    </form>      



    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openMail}
        key="add"
        onClose={handleCloseMail}
        closeAfterTransition
        BackdropComponent={Backdrop}>
        <Fade in={openMail}>
            <div className={classes.paper}>
                <Container maxWidth="sm">
                    <Grid container spacing={2}>      
                        <Grid item xs={12} sm={12}> 
                            <Typography variant="h6" className={classes.nadpis}>Odeslat nabídku emailem</Typography>
                        </Grid>
                        
                        <Grid item xs={12} sm={12}>                                           
                          <TextFields name="EmailAdresat" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="EmailAdresat" label="Email adresát" required placeholder="Email adresát" value={Mail.Email} onChange={onChangeValue} />
                        </Grid>  
   
                        <Grid item xs={12} sm={12}>                                              
                            <TextFields name="TextMail" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth multiline rows={5} id="TextMail" label="Text emailu" value={Mail.TextMail} onChange={onChangeValue} />
                        </Grid>  

                        <Grid item xs={12} sm={4}> 
                            <GreenButton onClick={(e) => {handleCloseMail()}} fullWidth> Zrušit </GreenButton>
                        </Grid>
                        <Grid item xs={12} sm={4}/> 
                        <Grid item xs={12} sm={4}> 
                            <GreenButton onClick={(e) => {sendMailKalkulace(Kalkulace)}} startIcon={<Email />} fullWidth> Odeslat</GreenButton>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </Fade>
    </Modal> 


    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openMailPoptavka}
        key="poptavka"
        onClose={handleCloseMailPoptavka}
        closeAfterTransition
        BackdropComponent={Backdrop}>
        <Fade in={openMailPoptavka}>
            <div className={classes.paper}>
                <Container maxWidth="sm">
                    <Grid container spacing={2}>      
                        <Grid item xs={12} sm={12}> 
                            <Typography variant="h6" className={classes.nadpis}>Odeslat poptávku</Typography>
                        </Grid>

                        <Grid item xs={12} sm={12}>                                              
                            <TextFields name="TextMail" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth multiline rows={6} id="TextMail" label="Text emailu" value={MailPoptavka.TextMail} onChange={onChangeValuePoptavka} />
                        </Grid>  

                        <Grid item xs={12} sm={4}> 
                            <GreenButton onClick={(e) => {handleCloseMailPoptavka()}} fullWidth> Zrušit </GreenButton>
                        </Grid>
                        <Grid item xs={12} sm={4}/> 
                        <Grid item xs={12} sm={4}> 
                            <GreenButton onClick={(e) => {sendMailKalkulacePoptavka()}} startIcon={<Email />} fullWidth>Odeslat</GreenButton>                           
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </Fade>
    </Modal> 


  </Container>
  );
};