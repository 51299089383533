import React from 'react';
import {BrowserRouter,  Route,  Switch} from 'react-router-dom';

import Home from '././components/Home/Home';
import Login from '././components/Login/Login';
import NotFound from '././components/NotFound/NotFound';
import Register from './components/Register/Register'
import Priplatky from './components/Priplatky/Priplatky'
import Adresy from './components/Adresy/Adresy'
import Slevy from './components/Slevy/Slevy'
import Marze from './components/Marze/Marze'
import Sdeleni from './components/Sdeleni/Sdeleni'
import Sluzby from './components/Sluzby/Sluzby'
import Klient from './components/Klient/Klient'
import MojeZasilky from './components/MojeZasilky/MojeZasilky'
import Logout from './components/Logout/Logout'
import MujProfil from './components/MujProfil/MujProfil'
import Objednavky from './components/Objednavky/Objednavky'
import Podpora from './components/Podpora/Podpora'
import Faktury from './components/Faktury/Faktury'
import MojeFaktury from './components/MojeFaktury/MojeFaktury'
import {Kalkulace} from './components/Kalk2/Kalkulace'
import ForgottenPassword from './components/ForgottenPassword/ForgottenPassword'
import UserContext from './contexts/UserContext'
import { makeStyles } from '@material-ui/core/styles';
import { ProtectedRoute } from "./protected.route";
import HeaderMenu from './components/HeaderMenu/HeaderMenu'


const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%'
  },
}));


function Routes(){
  const classes = useStyles();
  
//

  return(
    <div className={classes.container} name = 'test'>
    <UserContext>    
      <BrowserRouter >
          <Switch>
              <Route exact path="/Login" component={Login}/>            
              <ProtectedRoute exact path="/Kalkulacka"><HeaderMenu/><Kalkulace/></ProtectedRoute>
              <ProtectedRoute exact path="/Adresar"><HeaderMenu/><Adresy/></ProtectedRoute>
              <ProtectedRoute exact path="/Slevy" ><HeaderMenu/><Slevy/></ProtectedRoute>
              <ProtectedRoute exact path="/Marze" ><HeaderMenu/><Marze/></ProtectedRoute>
              <ProtectedRoute exact path="/Sluzby"><HeaderMenu/><Sluzby/></ProtectedRoute>
              <ProtectedRoute exact path="/Zasilky" ><HeaderMenu/><MojeZasilky/></ProtectedRoute>
              <ProtectedRoute exact path="/mujucet" ><HeaderMenu/><MujProfil/></ProtectedRoute>
              <ProtectedRoute exact path="/Priplatky"><HeaderMenu/><Priplatky/></ProtectedRoute>
              <ProtectedRoute exact path="/Sdeleni"><HeaderMenu/><Sdeleni/></ProtectedRoute>
              <ProtectedRoute exact path="/Klientska-karta" ><HeaderMenu/><Klient/></ProtectedRoute>
              <ProtectedRoute exact path="/Objednavky" ><HeaderMenu/><Objednavky/></ProtectedRoute>
              <ProtectedRoute exact path="/Podpora" ><HeaderMenu/><Podpora/> </ProtectedRoute>
              <ProtectedRoute exact path="/Faktury"><HeaderMenu/><Faktury/></ProtectedRoute>
              <ProtectedRoute exact path="/Moje-faktury"><HeaderMenu/><MojeFaktury/></ProtectedRoute>
              <ProtectedRoute exact path="/"><HeaderMenu/><Home/></ProtectedRoute>
              <ProtectedRoute exact path="/Home"><HeaderMenu/><Home/></ProtectedRoute>
              <Route exact path="/Logout" ><Logout/></Route>
              <Route exact path="/Register" component={Register} />
              <Route path="/ForgottenPassword/:GID" component={ForgottenPassword} />
              <Route path="/ForgottenPassword" component={ForgottenPassword} />
              <Route path="*" component={NotFound}/>
          </Switch>
      </BrowserRouter>
    </UserContext>
    </div>
);

}

export default Routes;