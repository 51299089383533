import './Nahled.css';
import React, {useContext,useState,useEffect} from 'react';
import {UserContext} from '../../../contexts/UserContext'
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import customConfig from '../../../customConfig.json'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import * as moment from 'moment'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const GreenButton = withStyles((theme) => ({
    root: {
      color: customConfig.Colors.ButtonTextColor, 
      backgroundColor: customConfig.Colors.PrimaryColor,
      fontWeight:'520',
      '&:hover': {
        backgroundColor: customConfig.Colors.SecColor,
        
      },
    },
  }))(Button);


  const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: customConfig.Colors.BorderColor,          
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },    
  Stranka:{
    backgroundColor: "white",
    minWidth:'840px',
    minHeight:"500px",
    padding:'25px'

  },    
  Hlavicka:{
    width:"100%",
    minHeight:"80px",
    display:'table',
    borderBottom:'3px solid '+customConfig.Colors.PrimaryColor,
    marginBottom:'25px'
  }, 
  Osoby:{
    width:"100%",
    minHeight:"100px",
    display:'table',
    marginBottom:'25px',
    paddingBottom:'25px',
    borderBottom:'3px solid '+customConfig.Colors.PrimaryColor,
  }, 
  Souhrn:{
    width:"50%",
    display:'table',
    marginBottom:'25px',
    float:'right',
  }, 
  Polozky:{
    width:"100%",
    marginBottom:'25px',
  }, 
  PlatebniInfo:{
    width:"100%",
    marginBottom:'25px',
    display:'flex'
  }, 
  PlatebniInfoDatumy:{
    width:"50%",
    display: 'inline-block',
  }, 
  PlatebniInfoUcet:{
    width:"50%",
    display: 'inline-block',
  },
  PlatebniInfoQR:{
    width:"50%",
    minHeight:"180px",
    display: 'inline-block',
    textAlign:'center'

  },
  Logo:{
    width:"150px",
    height:"80px",
    paddingLeft:"10px",
    display:'table-cell',
    verticalAlign:"middle"
  }, 
  CisloFaktury:{
    width:"250px",
    height:"80px",
    display:'table-cell',
    verticalAlign:"middle",
    textAlign:'end',
    paddingRight:'25px'
  }, 
  baseText: {
      color:'#191919',
      fontWeight:'500',
      fontFamily: 'Work Sans, sans-serif',
      fontSize:'23px'
  },
  innerText: {
      fontWeight:'600',
      fontFamily: 'Work Sans, sans-serif',
      fontSize:'23px',
      color:customConfig.Colors.PrimaryColor,

  },
  Doklad: {
      color:'#414756',
      fontFamily: 'Work Sans, sans-serif',
      fontWeight:'400',
      fontSize:'18px'
    
  },
  NormalText: {
      color:'#191919',
      fontFamily: 'Work Sans, sans-serif',
      fontWeight:'400',
      fontSize:'17px'
    
  },
  NormalTextSmall: {
      color:'#191919',
      fontFamily: 'Work Sans, sans-serif',
      fontWeight:'400',
      fontSize:'14px'
    
  },
  NormalTextBlood: {
      color:'#191919',
      fontFamily: 'Work Sans, sans-serif',
      fontWeight:'600',
      fontSize:'17px'
    
  },
  tableCellPlatbaNadpis: {
      color:'#191919',
      border:'none',
      fontFamily: 'Work Sans, sans-serif',
      fontWeight:'600',
      fontSize:'14px',
      padding:'5px'
    
  },
  tableCellPlatba: {
      color:'#191919',
      border:'none',
      fontFamily: 'Work Sans, sans-serif',
      fontWeight:'700',
      fontSize:'14px',
      padding:'5px'
    
  },
  tableCellHeadPrehled: {
      border:'none',
      padding:'10px 0px 10px 10px',
      color:'#414756',
      fontFamily: 'Work Sans, sans-serif',
      fontWeight:'550',
      fontSize:'13px'
    
  },
  tableCellHead: {
      border:'none',
      borderBottom:'1px solid '+customConfig.Colors.PrimaryColor,
      padding:'10px',
      color:'#414756',
      fontFamily: 'Work Sans, sans-serif',
      fontWeight:'550',
      fontSize:'14px'
    
  },
  tableCellPrehled: {
      border:'none',
      borderTop:'1px solid '+customConfig.Colors.PrimaryColor,
      borderBottom:'1px solid '+customConfig.Colors.PrimaryColor,
      padding:'10px 0px 10px 10px',
      color:'#191919',
      fontFamily: 'Work Sans, sans-serif',
      fontWeight:'600',
      fontSize:'14px'     
    
  },
  PrehledText: {
      color:'#191919',
      fontFamily: 'Work Sans, sans-serif',
      fontWeight:'600',
      fontSize:'14px'     
    
  },

  tableCellSouhrn: {
      border:'none',
      borderTop:'3px solid '+customConfig.Colors.PrimaryColor,
      padding:'10px',
      color:customConfig.Colors.PrimaryColor,
      fontFamily: 'Work Sans, sans-serif',
      fontWeight:'600',
      fontSize:'17px'
    
  },
  tableCell: {
      border:'none',
      padding:'8px',
      color:'#191919',
      fontFamily: 'Work Sans, sans-serif',
      fontWeight:'600',
      fontSize:'15px'      
  },
  Osoba:{
      width:"50%",
      display:'flex',
      verticalAlign:"middle",
      float:'left',      
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      marginLeft:'-55px'
  }, 
  OsobaNadpis:{
      display: 'table-row',
      transform: 'rotate(-90deg)',
      height:'0px',
      color:customConfig.Colors.PrimaryColor,
      fontFamily: 'Work Sans, sans-serif',
      fontWeight:'600',
      fontSize:'22px',
      letterSpacing: '2px',

  }, 
  OsobaText:{
    display: 'table-row',
  }, 
  Paticka: {
      border:'none',
      borderTop:'1px solid '+customConfig.Colors.PrimaryColor,
      padding:'10px',
      color:'#414756',
      fontFamily: 'Work Sans, sans-serif',
      fontWeight:'550',
      fontSize:'14px'
    
  },
  
}));

  const Printpdf = (faktury) => {

  
    const classes = useStyles();   
  
   const platbaURL = () =>{
      let url = 'https://api.paylibo.com/paylibo/generator/czech/image?'+(faktury.data.FakturyInfo.PredcisliUctu.length>0?'accountPrefix='+faktury.data.FakturyInfo.PredcisliUctu+'&':'')+'accountNumber='+faktury.data.FakturyInfo.CisloUctu+'&bankCode='+faktury.data.FakturyInfo.KodBanky+'&amount='+faktury.data.FakturyInfo.CenaCelkem+'&currency=CZK'+(faktury.data.FakturyInfo.VS?'&vs='+faktury.data.FakturyInfo.VS:'')+'&date='+faktury.data.FakturyInfo.DatumSplatnosti+'';
      return url;
   }


    return(
      <>
        <Grid container alignItems="flex-start" display='block' spacing={1} >  
  
          <Grid item xs={12} sm={12} className={classes.paper}> 
            <div className={classes.Stranka} id="printDialog" >
                <div className={classes.Hlavicka}>   
                    <div className={classes.Logo}>
                        <img src="../img/loga/Logo.png" alt="SEBA Logistics, s.r.o." height="50px"/>     
                    </div>
                    <div className={classes.CisloFaktury}>    
                        <Typography display="inline" className={classes.baseText}>Faktura </Typography>
                        <Typography display="inline" className={classes.innerText}>{faktury.data.FakturyInfo.CisloFakturyNadpis}</Typography>    
                        <Typography className={classes.Doklad}>Daňový doklad</Typography>    
                    </div>
                </div>
              
                <div className={classes.Osoby}>
                    <div className={classes.Osoba}>
                        <div className={classes.OsobaNadpis}>
                            Dodavatel
                        </div>
                        <div className={classes.OsobaText}>
                            <Typography className={classes.NormalTextBlood}>{faktury.data.FakturyInfo.DodavatelNazev}</Typography>
                            <Typography className={classes.NormalTextBlood}>{faktury.data.FakturyInfo.DodavatelAdresa}</Typography>
                            <Typography className={classes.NormalTextBlood}>{faktury.data.FakturyInfo.DodavatelMesto}</Typography>
                            <Typography className={classes.NormalTextBlood}>IČ: {faktury.data.FakturyInfo.DodavatelIC}</Typography>
                            <Typography className={classes.NormalTextBlood}>DIČ: {faktury.data.FakturyInfo.DodavatelDIC}</Typography>
                            {(faktury.data.FakturyInfo.PlatceDPH !== "1") && (  
                                <Typography className={classes.NormalTextSmall}>Neplátce DPH</Typography>
                            )}

                        </div>  
                    </div>
                    <div className={classes.Osoba}>
                        <div className={classes.OsobaNadpis}>
                            Odběratel
                        </div>
                        <div className={classes.OsobaText}>
                            <Typography className={classes.NormalTextBlood}>{faktury.data.FakturyInfo.KlientNazev}</Typography>
                            <Typography className={classes.NormalTextBlood}>{faktury.data.FakturyInfo.KlientAdresa}</Typography>
                            <Typography className={classes.NormalTextBlood}>{faktury.data.FakturyInfo.KlientMesto}</Typography>
                            <Typography className={classes.NormalTextBlood}>IČ: {faktury.data.FakturyInfo.KlientIC}</Typography>
                            <Typography className={classes.NormalTextBlood}>DIČ: {faktury.data.FakturyInfo.KlientDIC}</Typography>
                        </div>
                    </div>
                </div>
  
  
  
                <div className={classes.PlatebniInfo}>   
                    <div className={classes.PlatebniInfoUcet}> 
                        <TableContainer>
                            <Table aria-label="caption table">          
                                <TableBody>
                                    <TableRow>
                                        <TableCell className={classes.tableCellPlatbaNadpis} align="left">Bankovní účet</TableCell>
                                        <TableCell className={classes.tableCellPlatba} align="left">{(faktury.data.FakturyInfo.PredcisliUctu?faktury.data.FakturyInfo.PredcisliUctu+'-':'')+faktury.data.FakturyInfo.CisloUctu+'/'+faktury.data.FakturyInfo.KodBanky}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.tableCellPlatbaNadpis} align="left">Variabilní symbol</TableCell>
                                        <TableCell className={classes.tableCellPlatba} align="left">{faktury.data.FakturyInfo.VS}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.tableCellPlatbaNadpis} align="left">Způsob platby</TableCell>
                                        <TableCell className={classes.tableCellPlatba} align="left">{faktury.data.FakturyInfo.ZpusobPlabtbyNazev}</TableCell>
                                    </TableRow>
                                </TableBody>     
                            </Table>
                        </TableContainer>
                    </div>  
                    <div className={classes.PlatebniInfoDatumy}> 
                        <TableContainer>
                            <Table aria-label="caption table">          
                                <TableBody>
                                    <TableRow>
                                        <TableCell className={classes.tableCellPlatbaNadpis} align="left">Datum vystavení</TableCell>
                                        <TableCell className={classes.tableCellPlatba} align="left">{moment(faktury.data.FakturyInfo.DatumVystaveni).format("DD.MM.YYYY")}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.tableCellPlatbaNadpis} align="left">Datum splatnosti</TableCell>
                                        <TableCell className={classes.tableCellPlatba} align="left">{moment(faktury.data.FakturyInfo.DatumSplatnosti).format("DD.MM.YYYY")}</TableCell>
                                    </TableRow>

                                    {(faktury.data.FakturyInfo.PlatceDPH === "1") && (  
                                    <>
                                    <TableRow>
                                        <TableCell className={classes.tableCellPlatbaNadpis} align="left">Zdanitelné plnění</TableCell>
                                        <TableCell className={classes.tableCellPlatba} align="left">{moment(faktury.data.FakturyInfo.DatumZdanObdobi).format("DD.MM.YYYY")}</TableCell>
                                    </TableRow>
                                    </>
                                  )}

                                </TableBody>     
                            </Table>
                        </TableContainer>
                    </div> 
                </div>
  
              <div className={classes.Polozky}>   
                  <Typography className={classes.NormalText}>Fakturujeme Vám za služby</Typography>
                  <TableContainer>
                      <Table aria-label="caption table">
                          <TableHead>
                              <TableRow>
                                  <TableCell className={classes.tableCellHeadPrehled} align="left">Služba</TableCell>
                                  <TableCell className={classes.tableCellHeadPrehled} width="130px" align="right">Celková hmotnost</TableCell>  
                                  <TableCell className={classes.tableCellHeadPrehled} width="130px" align="right">Hmotnost druh*</TableCell>                    
                                  <TableCell className={classes.tableCellHeadPrehled} width="130px" align="right">Cena přepravného</TableCell>
                                  {(faktury.data.FakturyInfo.PlatceDPH === "1") && (  
                                    <>
                                        <TableCell className={classes.tableCellHeadPrehled} width="100px" align="right">DPH</TableCell>
                                        <TableCell className={classes.tableCellHeadPrehled} width="110px" align="right">Celkem s DPH</TableCell>
                                    </>
                                  )}
                              </TableRow>
                          </TableHead>                
                          <TableBody>
  
  
                          {(faktury) && ( 
                              <>
                                  {faktury.data.zasilky.map(row => {
  
                                      return (
                                          <TableRow key={row.NazevZobrazit}>
                                              <TableCell className={classes.tableCellPrehled} align="left">
                                                <>
                                                    <Typography className={classes.PrehledText}>{row.NazevZobrazit}</Typography>
                                                    <Typography className={classes.PrehledText}>{(row.Track !== undefined && row.Track !== null && row.Track !== '' )  && ("Číslo zásilky: " + row.Track)}</Typography>
                                                    <Typography className={classes.PrehledText}>{(row.ReferencniCislo !== undefined && row.ReferencniCislo !== null && row.ReferencniCislo !== '' ) && ("Reference: " + row.ReferencniCislo)}</Typography>
                                                    {/* {(row.Track.length>0) ? (<Typography> "Číslo zásilky: "+ row.Track <Typography>)}
                                                    {(row.ReferencniCislo.length>0) && (<Typography> 'Reference: '+ row.ReferencniCislo <Typography>)} */}
                                                </>
                                              </TableCell>
                                              <TableCell className={classes.tableCellPrehled} align="right">{row.Hmotnost+' kg'}</TableCell>
                                              <TableCell className={classes.tableCellPrehled} align="right">{row.HmotnostDruh}</TableCell>
                                              <TableCell className={classes.tableCellPrehled} align="right">{parseFloat(row.Cena).toFixed(2)+' Kč'}</TableCell>
                                              {(faktury.data.FakturyInfo.PlatceDPH === "1") && (  
                                                    <>                                              
                                                    <TableCell className={classes.tableCellPrehled} align="right">{row.DPH>0 ? parseFloat(Number(row.Cena)*(Number(row.SazbaDPH)/100)).toFixed(2)+' Kč' : '-' }</TableCell>
                                                    <TableCell className={classes.tableCellPrehled} align="right">{row.DPH>0 ? parseFloat(Number(row.Cena)+(Number(row.Cena)*(Number(row.SazbaDPH)/100))).toFixed(2)+' Kč' : row.Cena+' Kč'  }</TableCell>

                                                    </>
                                                )}
                                          </TableRow>
                                      );
                                  })}
                              </>
                          )}             
                          {(faktury) && ( 
                              <>
                                  {faktury.data.polozky.map(row => {
  
                                      return (
  
  
                                          <TableRow  key={row.Nazev}>
                                              <TableCell className={classes.tableCellPrehled} align="left">{row.Nazev}</TableCell>
                                              <TableCell className={classes.tableCellPrehled} align="right">-</TableCell>
                                              <TableCell className={classes.tableCellPrehled} align="right">-</TableCell>
                                              <TableCell className={classes.tableCellPrehled} align="right">{row.Cena+' Kč'}</TableCell>
                                              {(faktury.data.FakturyInfo.PlatceDPH === "1") && (  
                                                <>                                              
                                                    <TableCell className={classes.tableCellPrehled} align="right">{row.DPH>0 ? parseFloat(Number(row.Cena)*(Number(row.SazbaDPH)/100)).toFixed(2)+' Kč' : '-' }</TableCell>
                                                    <TableCell className={classes.tableCellPrehled} align="right">{row.DPH>0 ? parseFloat(Number(row.Cena)+(Number(row.Cena)*(Number(row.SazbaDPH)/100))).toFixed(2)+' Kč' : row.Cena+' Kč'  }</TableCell>
                                                </>
                                                )}
                                          </TableRow>
                                      );
                                  })}
                              </>
                          )} 
                          </TableBody>     
                      </Table>
                  </TableContainer>
              </div>
  
              <div className={classes.PlatebniInfoQR}> 
                      <img src={platbaURL()} height="200px"/>   
              </div>
  
              <div className={classes.Souhrn}>             
  
                  <TableContainer>
                    <Table>
                        {(faktury.data.FakturyInfo.PlatceDPH === "1") && (  
                            <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableCellHead} align="left"></TableCell>
                                <TableCell className={classes.tableCellHead} align="right">Základ pro DPH</TableCell>
                                <TableCell className={classes.tableCellHead} align="right">DPH</TableCell>
                            </TableRow>
                            </TableHead>       
                        )}         
                        <TableBody>
                            {(faktury.data.FakturyInfo.PlatceDPH === "1") && (  
                                <>
                                    {(faktury) && ( 
                                        <>
                                            {faktury.data.souhrnCena.sort((a, b) => a.Popis > b.Popis ? 1 : -1).map(row => {
                
                                                return (
                                                <TableRow key={row.SazbaDPH}>
                                                    <TableCell className={classes.tableCell} align="left">{row.Popis}</TableCell>
                                                    <TableCell className={classes.tableCell} align="right">{parseFloat(row.Cena).toFixed(2)+ ' Kč'} </TableCell>
                                                    <TableCell className={classes.tableCell} align="right">{row.DPHCena.length>0 ? (parseFloat(row.DPHCena).toFixed(2)+' Kč') : '- Kč'}</TableCell>
                                                </TableRow>
                
                                                );
                                            })}
                                        </>
                                    )}                              
                                </>
                            )}                                     

                            <TableRow key={'last'}>
                                <TableCell colSpan="1" className={classes.tableCellSouhrn} align="left">Faktura celkem</TableCell>
                                <TableCell colSpan="2" className={classes.tableCellSouhrn} align="right">{parseFloat(faktury.data.FakturyInfo.CenaCelkem).toFixed(2)+' Kč'}</TableCell>
                            </TableRow>
    
                        </TableBody>     
                    </Table>
                  </TableContainer>  
              </div>
  
              <div className={classes.Paticka}>   
                  <Typography className={classes.NormalTextSmall}>* Hmotnost druh: V - Volumetrická hmotnost zásilky, S - Skutečná hmotnost zásilky, K - Kombinace Volumetrické a skutečné hmotnosti</Typography>
                  
              </div>
          </div>
  
          </Grid>
        </Grid>
      
      </>
    )      
    };


  const Nahled = React.forwardRef((faktury,ref) => {

    
    const {postFakturaEmail} = useContext(UserContext);
    const {rootState} = useContext(UserContext);
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [OpenError, setOpenError] = React.useState(false);
    const {isAdmin} = rootState;
    const classes = useStyles();

  useEffect(() => {
    document.title = "SEBAplus - Faktury"; 
    
    if(faktury.data.tisk)
    {
        if(faktury.data.tisk === true)
        {
            NahledPrint()
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) 

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(false);
  };


const NahledPrint = (e) => {     

    html2canvas(document.querySelector("#printDialog"),{useCORS: true, scale:1.25}).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm','a4');
        var imgWidth = 210; 
        var pageHeight = 295;  
        const imgProps= pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        var pdfHeight = (imgProps.height * imgWidth / imgProps.width)-7;
        //const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        var pdfHeightleft = pdfHeight;
        var position = 0;
        
        pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, pdfHeight);

        pdfHeightleft = pdfHeightleft - pageHeight;
        
        while (pdfHeightleft >= 0) {
          position = pdfHeightleft - pdfHeight;
          pdf.addPage();
          pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, pdfHeight);
          pdfHeightleft -= pdfHeight;
        }



        pdf.save("SEBALogistics_"+faktury.data.FakturyInfo.VS+".pdf"); 
    });


};

  const PoslatEmailem = async () => {     
    
    setLoading(true);
    
    async function posli() {  
        return html2canvas(document.querySelector("#printDialog"),{useCORS: true, scale:1.1}).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm','a4');
            var imgWidth = 210; 
            var pageHeight = 295;  
            const imgProps= pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            var pdfHeight = (imgProps.height * imgWidth / imgProps.width)-7;
            //const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            var pdfHeightleft = pdfHeight;
            var position = 0;
            
            pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, pdfHeight);
    
            pdfHeightleft = pdfHeightleft - pageHeight;
            
            while (pdfHeightleft >= 0) {
              position = pdfHeightleft - pdfHeight;
              pdf.addPage();
              pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, pdfHeight);
              pdfHeightleft -= pdfHeight;
            }


            const base = pdf.output('datauristring')

            
            return postFakturaEmail(faktury.data.FakturyInfo,base);

        });
    }

    const Vysledek = await posli();           

    if(Vysledek.success === 1)
    {
        setOpen(true);
        setLoading(false);
    }
    else
    {
        setLoading(false);
        setOpenError(true);
    }

};
  
  return(
    <div>
      <Grid container alignItems="flex-start" display='block' spacing={1} >
        <Grid item xs={12} sm={12}> 
            {(faktury.data.FakturyInfo.FakturaID.length>0) && ( 
                <GreenButton onClick={(e) => NahledPrint(e)} >Tisk</GreenButton>
            )}  
            {(isAdmin===true) && ( 
                <GreenButton onClick={(e) => PoslatEmailem(e)} >Email</GreenButton>
            )}  
        </Grid>

        <Modal open={loading} className={classes.modal}>
            <Fade
                in={loading}
                style={{
                    transitionDelay: loading ? '100ms' : '0ms',
                }}
                unmountOnExit
                >
                <CircularProgress />
            </Fade>

        </Modal>  
        <Printpdf data={faktury.data} />

      </Grid>
    
      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Faktura odeslána.
        </Alert>
      </Snackbar>
      <Snackbar open={OpenError} autoHideDuration={5000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="error">
          Chyba při odeslání faktury.
        </Alert>
      </Snackbar>
    </div>
  )

    
  });

export default Nahled;