import React, {useContext,useState, useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import customConfig from '../../customConfig.json'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {UserContext} from '../../contexts/UserContext'
import TextField from '@material-ui/core/TextField';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import {Email,Phone,AttachFile} from '@material-ui/icons';
import MenuItem from '@material-ui/core/MenuItem';
import { Alert } from '@material-ui/lab';

const GreenButton = withStyles((theme) => ({
    root: {
      color: customConfig.Colors.ButtonTextColor, 
      backgroundColor: customConfig.Colors.PrimaryColor,
      fontWeight:'520',
      '&:hover': {
        backgroundColor: customConfig.Colors.SecColor,
        
      },
    },
  }))(Button);


  const TextFields = withStyles({
    root: {
      '& label.Mui-focused': {
        color: customConfig.Colors.PrimaryColor,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: customConfig.Colors.PrimaryColor,
      },
      '& .MuiOutlinedInput-root': {
  
        '&:hover fieldset': {
          borderColor: customConfig.Colors.SecColor,
        },
        '&.Mui-focused fieldset': {
          borderColor: customConfig.Colors.PrimaryColor,
        },
      },
    },
  })(TextField);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },  
bgGrid: {
  backgroundColor: customConfig.Colors.GridBackgroundColor,
  padding:'0px',
  margin:'0px',
  width:'100%'
},
containerX: {
  width:'100%',
  padding:'0px',
  margin:'0px',
},
input: {
  display: 'none',
},
nadpis:{
  color: customConfig.Colors.PrimaryTextColor,
  verticalAlign:'middle',
  textDecoration:'none',
  lineHeight:'30px'
},

textKontakt:{
    color: customConfig.Colors.PrimaryTextColor,
    verticalAlign:'middle',
    textDecoration:'none',
    lineHeight:'30px',
    marginLeft:'15px'
  },
ikona:{
  color: customConfig.Colors.PrimaryColor,
  verticalAlign:'middle',
  marginRight:'10px'
},
gridSpace:{
  margin:'15px 0px 15px 0px'
},
ikonaBtn:{
  color: customConfig.Colors.TextColor,
  verticalAlign:'middle',
  marginRight:'10px'
},
nadpisH:{
  color: customConfig.Colors.ButtonTextColor,
  fontSize:'20px',
},
paperH: {
  textAlign: 'center',
  backgroundColor: customConfig.Colors.PrimaryColor,
  boxShadow: 'none',
  border:'1px solid'+customConfig.Colors.PrimaryColor,
  borderRadius: '0px'
},
shad:{
  boxShadow:'0px 1px 3px #777777c4',
  border:'1px solid'+customConfig.Colors.PrimaryColor,
},
}));

export default function Podpora() { 

    const {postEmailPodpora} = useContext(UserContext);
    const classes = useStyles();
    const [Prilohy, setPrilohy] = useState([]);
    const [MailPodpora, setMailPodpora] = useState({Mail:'podpora@sebalogistics.cz',TextMail:''});
    const [state,setState] = useState({ errorMsg:'', successMsg:''});

  
    useEffect(() => {
      document.title = "SEBAplus - Poppora";

      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])   
  
  const onChangeValuePodpora = (e) => {
    setMailPodpora({
      ...MailPodpora,
          ...MailPodpora,
      [e.target.name]:e.target.value
    });        
  }
  
  const Konakty = [
    {
        value: 'sales@sebalogistics.cz',
        label: 'Obchodní oddělení',
    },
    {
        label: 'Reklamace',
        value: 'reklamace@sebalogistics.cz',
    },
    {
        label: 'Fakturace',
        value: 'fakturace@sebalogistics.cz',
    },
    {
        label: 'Podpora',
        value: 'podpora@sebalogistics.cz',
    },
  ];


const odeslatPozadavek = async (e) => {

    let priloha = []
    if(Prilohy.length>0){
        priloha = Prilohy[0];
    }

    const data = await postEmailPodpora(MailPodpora,priloha);
    
    if(data.success){
        setState({
            ...state,
            successMsg:data.message,
            errorMsg:'',
        });
        setPrilohy([]);
        setMailPodpora({Mail:'podpora@sebalogistics.cz',TextMail:''});
    }
    else{
        setState({
            ...state,
            successMsg:'',
            errorMsg:data.message
        });
    }

    

}

const onChangeFile = (event) => {
  
    for (let i = 0; i < Prilohy.length; i++) {
      Prilohy[i] = undefined;
    }

    setPrilohy([]);

    let files = event.target.files;    
    let fil = [];

    
    for (let i = 0; i < files.length; i++) {
      
      (function(file) {
        let reader = new FileReader();
        reader.fileName = file.name;
        reader.fileType = file.type;
        reader.readAsDataURL(file); 

        reader.onload = function(eventX) {  
          fil[i] = {name:eventX.target.fileName,type:eventX.target.fileType,file:eventX.target.result};
          setPrilohy([fil]);
        }
      })(files[i]);
    }

  };

  return (
    <div className={classes.bgGrid,classes.containerX}>
      <Grid container spacing={2} className={classes.bgGrid}>
        
        <Grid item xs={12} sm={4}> 

            <Paper className={classes.shad}>    
                <Grid item xs={12} sm={12}> 
                    <Paper className={classes.paperH}>
                        <Typography className={classes.nadpisH}>Obchodní oddělení (poptávky a objednávky)</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12}> 
                    <Typography className={classes.textKontakt}> <a className={classes.nadpis} href="mailto:sales@sebalogistics.cz"><Email className={classes.ikona}/>sales@sebalogistics.cz</a> </Typography>
                </Grid>
                <Grid item xs={12} sm={12}> 
                    <Typography className={classes.textKontakt}><Phone className={classes.ikona}/>+420 721 455 911</Typography>
                </Grid>
            </Paper>


        </Grid>

        <Grid item xs={12} sm={4}> 

            <Paper className={classes.shad}>   

                <Grid item xs={12} sm={12}> 
                    <Paper className={classes.paperH}>
                        <Typography className={classes.nadpisH}>Reklamace</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12}> 
                    <Typography className={classes.textKontakt}> <a className={classes.nadpis} href="mailto:reklamace@sebalogistics.cz"><Email className={classes.ikona}/>reklamace@sebalogistics.cz</a> </Typography>
                </Grid>
                <Grid item xs={12} sm={12}> 
                    <Typography className={classes.textKontakt}><Phone className={classes.ikona}/>+420 721 455 911</Typography>
                </Grid>
            </Paper>


        </Grid>

        <Grid item xs={12} sm={4}> 
            <Paper className={classes.shad}>   

                <Grid item xs={12} sm={12}> 
                    <Paper className={classes.paperH}>
                        <Typography className={classes.nadpisH}>Fakturace</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12}> 
                    <Typography className={classes.textKontakt}> <a className={classes.nadpis} href="mailto:fakturace@sebalogistics.cz"><Email className={classes.ikona}/>fakturace@sebalogistics.cz</a> </Typography>
                </Grid>
                <Grid item xs={12} sm={12}> 
                    <Typography className={classes.textKontakt}><Phone className={classes.ikona}/>+420 721 455 911</Typography>
                </Grid>
            </Paper>

        </Grid>



        <Grid item xs={12} sm={12}> 
            <Paper className={classes.shad}>   
                <Grid item xs={12} sm={12}> 
                    <Paper className={classes.paperH}>
                        <Typography className={classes.nadpisH}>Kontaktní formulář podpory</Typography>
                    </Paper>
                </Grid>
                <Grid container spacing={2} item xs={12} sm={12} className={classes.gridSpace} justifycontent="space-between" alignItems="flex-start"> 

                    <Grid item xs={12} sm={12}>
                        {(state.errorMsg && state.errorMsg.length>0) && (     
                            <Alert severity="error">{state.errorMsg}</Alert>
                        )}
                        
                        {(state.successMsg && state.successMsg.length>0) && (                     
                            <Alert severity="success">{state.successMsg}</Alert>
                        )}
                    </Grid>  

                    <Grid item xs={12} sm={8}> 
                        <TextFields name="TextMail" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth multiline rows={10} id="TextMail" label="Text emailu" value={MailPodpora.TextMail} onChange={onChangeValuePodpora} />
                    </Grid>

                        <Grid item xs={12} container spacing={2} sm={4}> 
                            <Grid item xs={12} sm={12}>  

                                <TextFields
                                    id="Mail"
                                    name="Mail"
                                    required
                                    select
                                    label="Kontaktovat"
                                    size="small"
                                    fullWidth
                                    value={MailPodpora.Mail}
                                    onChange={(e) => {onChangeValuePodpora(e)}}
                                    variant="outlined"
                                >
                                    {Konakty.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                    ))}
                                </TextFields>

                            </Grid>  
                            <Grid item xs={12} sm={12}>  
                        
                                <input
                                    accept="*"
                                    className={classes.input}
                                    id="contained-button-file"
                                    multiple
                                    type="file"
                                    onChange={(e) => {onChangeFile(e)}} 
                                />
                                <label htmlFor="contained-button-file">
                                    <GreenButton variant="contained" color="primary" component="span"><AttachFile className={classes.ikonaBtn} />
                                    
                                    Přílohy
                                    </GreenButton>
                                </label>
                            </Grid>  
                            <Grid item xs={12} sm={12}>  
                                {(Prilohy && Prilohy.length>0) && (
                                <>

                                    {Prilohy[0].map(row => {
                                    return (
                                        
                                        <Typography key={row.name} className={classes.typo}>{row.name}</Typography>
                                    );
                                    })}
                                </>
                                )}
                            </Grid>           
                        </Grid>                               

                        <Grid item xs={12} sm={8}/> 
                        <Grid item xs={12} sm={4}> 
                            <GreenButton onClick={(e) => odeslatPozadavek(e)}> <Email className={classes.ikonaBtn} /> Odeslat požadavek</GreenButton>
                        </Grid>


                </Grid>
            </Paper>

        </Grid>        
      </Grid>
    </div>
  );
}
