import React from "react";
import { Route, Redirect } from "react-router-dom";

export const ProtectedRoute = ({
  children,
  ...rest
}) => {
    
    const loginToken = localStorage.getItem('loginToken');
     
    return (
      <Route
        {...rest}
        render={({ location }) =>
        loginToken ? (children) :
          (
            <Redirect to={{ pathname: '/login', state: { from: location } }} />
          )
        }
      />
    );
};
