import React, { useContext,useEffect, useState } from 'react';
import {UserContext} from '../../../contexts/UserContext'
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import customConfig from '../../../customConfig.json'
import Checkbox from '@material-ui/core/Checkbox';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from '@material-ui/core/TableHead';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import SaveIcon from '@material-ui/icons/Save';
import { Alert } from '@material-ui/lab';
import DeleteIcon from '@material-ui/icons/Delete';
import Paper from '@material-ui/core/Paper';



const TextFields = withStyles({
  root: {
    '& label.Mui-focused': {
      color: customConfig.Colors.PrimaryColor,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: customConfig.Colors.PrimaryColor,
    },
    '& .MuiOutlinedInput-root': {

      '&:hover fieldset': {
        borderColor: customConfig.Colors.SecColor,
      },
      '&.Mui-focused fieldset': {
        borderColor: customConfig.Colors.PrimaryColor,
      },
    },
  },
})(TextField);



const GreenCheckbox = withStyles({
    root: {
      color: customConfig.Colors.PrimaryColor,
      '&$checked': {
        color: customConfig.Colors.PrimaryColor,
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 200
  },
  tableRows: {
    height: '25px'
  },
  tableRow: {
    padding:'2px',
    backgroundColor: customConfig.Colors.GridBackgroundColor,

  },
  bgGrid: {
    backgroundColor: customConfig.Colors.GridBackgroundColor,
  },
  tableHeadRow: {
    padding:"15px 15px 15px 5px",
    backgroundColor: customConfig.Colors.PrimaryColor,
    color: customConfig.Colors.ButtonTextColor
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: customConfig.Colors.BorderColor,          
    boxShadow: theme.shadows[5],
    padding: '0px',
    borderRadius:'10px 10px 8px 8px',
    border:'none'
  },
  margin: {
    margin: theme.spacing(0),
  },
  hide: {
    display: 'none',
  },      
  callContainer: {
    backgroundColor: 'none',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    backgroundColor: 'none',
  },
  innerPadding:{
    padding: '20px',
  },  
  nadpisH:{
    color: customConfig.Colors.ButtonTextColor,
    fontSize:'20px'
  },
  paperH: {
    textAlign: 'left',
    paddingLeft:'20px',
    backgroundColor: customConfig.Colors.PrimaryColor,
    boxShadow: 'none',
    border:'1px',
    borderRadius: '8px 8px 0px 0px'
  },  
  withoutLabel: {
    marginTop: theme.spacing(1),
  },
  boxStyle:{
    marginTop:'15px'

  },
  nadpis:{
    color: customConfig.Colors.PrimaryTextColor
  },
  left:{
    paddingLeft: '10px',
  },        
  textf: {
    padding: '0px 8px 0px 0px',
  },
  AddBtn: {    
    marginBottom:'10px',
  },
  }));


function Pristupy(data){
  

  const classes = useStyles();
  
  const initialPristupy = {
    PristupyInfo:{
        Jmeno:'',
        Prijmeni:'',
        Email:'',            
        LoginMail:'',
        Telefon:'',
        IsActive:true,
        FirmaPristupID:'',
        OsobaID:'',
        Heslo:'',
        HesloCheck:'',
        FirmaID:'',
        SendMailObjednavka:true,
        SendMailAccept:true,
        SendMailFaktura:true
    },
    errorMsg:'',
    successMsg:'',
    PristupoveUdaje:{
        UzivatelID:'',
        heslo:'',
        HesloCheck:'',
    },
 };    


  const {getPristupy,postPristupy,delPristupy,HeslaZmena} = useContext(UserContext);
  const [Pristupy, setPristupy] = useState([]);
  const [pristupy, setState] = useState(initialPristupy);
  const [openDel, setopenDel] = React.useState(false);
  const [open, setopen] = React.useState(false);
  const [openNew, setopenNew] = React.useState(false);
  const [openPassword, setopenPassword] = React.useState(false);

  useEffect(() => {



    async function fetchData() {
      const dataOUD = await getPristupy(data.data); 
      setPristupy(dataOUD) 
    }
    
    fetchData()


    // getPristupy(data.data)
    // .then(oudData => setPristupy(oudData)
    // );   
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]) 
  
  function VybratZaznam(row){
    setState({
        ...pristupy,
        PristupyInfo:{
            ...pristupy.PristupyInfo,
            Jmeno:row.Jmeno,
            Prijmeni:row.Prijmeni,
            Email:row.Email,
            LoginMail:row.LoginMail,
            Telefon:row.Telefon,
            IsActive:row.IsActive==="1" ? true : false,
            OsobaID:row.OsobaID,
            FirmaPristupID:row.UserID,         
            FirmaID:row.FirmaID,
            SendMailObjednavka:row.SendMailObjednavka==='1'?true:false,
            SendMailAccept:row.SendMailAccept==='1'?true:false,
            SendMailFaktura:row.SendMailFaktura==='1'?true:false
        },
        PristupoveUdaje:{
            ...pristupy.PristupoveUdaje,
            UzivatelID:row.UserID,
            heslo:'',
            HesloCheck:'',
        },
    })      
  } 



  const handleOpenPassword = () => {
    setopenPassword(true);
  };

  const handleClosePassword = () => {
    setopenPassword(false);
  };
  
  
  const onChangePristupoveUdaje = (e) => {
    setState({
        ...pristupy,
        PristupoveUdaje:{
            ...pristupy.PristupoveUdaje,
            [e.target.name]:e.target.value
        },
    });        
    }
  
  const onChangeValue = (e) => {
    setState({
        ...pristupy,
        PristupyInfo:{
            ...pristupy.PristupyInfo,
            [e.target.name]:e.target.value
        },
    });        
    }

const CheckBoxhandleChange = (event) => {
    setState({
      ...pristupy,
      PristupyInfo:{
          ...pristupy.PristupyInfo, 
          [event.target.name]: event.target.checked 
      },   
    });            
  };

  const SubmitDelPristupy = async (event) => {   
    event.preventDefault();

    await delPristupy(pristupy.PristupyInfo);


    handleDelClose();

    async function fetchData() {
      const dataOUD = await getPristupy(data.data); 
      setPristupy(dataOUD) 
    }    
    fetchData()
    
    setState(initialPristupy);
}

const SubmitEditPristupy = async (event) => {   
    
  event.preventDefault();
  const dataX = await postPristupy(pristupy.PristupyInfo);
  if(dataX.success){
    setState(initialPristupy);

    async function fetchData() {
      const dataOUD = await getPristupy(data.data); 
      setPristupy(dataOUD) 
    }
    
    fetchData()

    handleClose();
    handleCloseNew();
  }
  else{
      setState({
          ...pristupy,
          successMsg:'',
          errorMsg:dataX.message
      });
  }

}

const handleDelClose = (e) => {
    setopenDel(!openDel);
    
    setState({
    ...pristupy,
        PristupyInfo:initialPristupy.PristupyInfo
      },
    )
};


const SubmitZmenaHesla = async (event) => {      
  await HeslaZmena(pristupy.PristupoveUdaje);
  setopenPassword(false);
}  

const handleDelOpen = (row) => {
    setopenDel(!openDel);
    VybratZaznam(row);
};

const handleClose = (e) => {
    setopen(false);
    
    setState(initialPristupy)
};
const handleCloseNew = (e) => {
    setopenNew(false);
    
    setState(initialPristupy)
};

const handleOpenNew = (e,Frima) => {
    setopenNew(!openNew);
    setState({
        ...pristupy,
        PristupyInfo:{
            ...pristupy.PristupyInfo,   
            FirmaID:Frima,
            IsActive:true,
            SendMailObjednavka:true,
            SendMailAccept:true,
            SendMailFaktura:true                                  
        },
    })
};

const handleOpen = (row) => {
    VybratZaznam(row);
    setopen(!open);
};

const GreenButton = withStyles((theme) => ({
  root: {
    color: customConfig.Colors.TextColor,
    backgroundColor: customConfig.Colors.PrimaryColor,
    '&:hover': {
      backgroundColor: customConfig.Colors.SecColor,
    },
  },
}))(Button); 
  
  return (
    <div>
    <Grid container>   
        <Grid item xs={12} sm={12}>
          {(data.data) && ( 
            <GreenButton className={classes.AddBtn} onClick={(e) => handleOpenNew(e,data.data)} startIcon={<PersonAddIcon />} >Přidat přístup</GreenButton>
          )} 
        </Grid>
        <Grid item xs={12} sm={12}>
            {(Pristupy.Pristupy!==[] && Pristupy.Pristupy!== undefined) && ( 
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeadRow} width="100px" align="left">Jméno</TableCell>
                            <TableCell className={classes.tableHeadRow} width="100px" align="left">Přijmení</TableCell>
                            <TableCell className={classes.tableHeadRow} width="200px" align="left">Email</TableCell>
                            <TableCell className={classes.tableHeadRow} width="200px" align="left">Přihlašovací email</TableCell>
                            <TableCell className={classes.tableHeadRow} width="100px" align="left">Telefon</TableCell>
                            <TableCell className={classes.tableHeadRow} width="50px" align="left">Aktivní</TableCell>
                            <TableCell className={classes.tableHeadRow} width="100px" align="left">Email - Objednávka</TableCell>
                            <TableCell className={classes.tableHeadRow} width="100px" align="left">Email - Akceptace</TableCell>
                            <TableCell className={classes.tableHeadRow} width="100px" align="left">Email - Faktura</TableCell>
                            <TableCell className={classes.tableHeadRow} width="100px"></TableCell>
                            <TableCell className={classes.tableHeadRow} width="80px"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Pristupy.Pristupy.map(row => {
                        return (
                            <TableRow key={row.OsobaID}  className={classes.tableRows}>
                                <TableCell className={classes.tableRow} width="100px">{row.Jmeno}</TableCell>        
                                <TableCell className={classes.tableRow} width="100px">{row.Prijmeni}</TableCell>           
                                <TableCell className={classes.tableRow} width="200px">{row.Email}</TableCell>
                                <TableCell className={classes.tableRow} width="200px">{row.LoginMail}</TableCell>
                                <TableCell className={classes.tableRow} width="100px">{row.Telefon}</TableCell>     
                                <TableCell className={classes.tableRow} width="50px" style={{textAlign:"center"}}><GreenCheckbox checked={row.IsActive==="1" ? true : false} /></TableCell>      
                                <TableCell className={classes.tableRow} width="100px" style={{textAlign:"center"}}><GreenCheckbox checked={row.SendMailObjednavka==="1" ? true : false} /></TableCell>    
                                <TableCell className={classes.tableRow} width="100px" style={{textAlign:"center"}}><GreenCheckbox checked={row.SendMailAccept==="1" ? true : false} /></TableCell>     
                                <TableCell className={classes.tableRow} width="100px" style={{textAlign:"center"}}><GreenCheckbox checked={row.SendMailFaktura==="1" ? true : false} /></TableCell>         
                                <TableCell className={classes.tableRow} width="100px"><GreenButton onClick={(e) => handleOpen(row)}>Editovat</GreenButton></TableCell>    
                                <TableCell className={classes.tableRow} width="80px"><GreenButton onClick={(e) => handleDelOpen(row)}>Smazat</GreenButton></TableCell>                     
                            </TableRow>
                        );
                        })}
                    </TableBody>
                </Table>  
            )}     
        </Grid>
    </Grid>


    <Dialog
        open={openDel}
        onClose={(e) => handleDelClose(e)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Smazat uživatelský přístup"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Opravdu si přejete smazat uživatelský přístup: {pristupy.PristupyInfo.LoginMail}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Grid container spacing={2}>      
                <Grid item xs={12} sm={4}> 
                    <GreenButton fullWidth onClick={(e) => handleDelClose(e)}>Zrušit</GreenButton>
                </Grid>
                <Grid item xs={12} sm={4}/> 
                <Grid item xs={12} sm={4}> 
                <   GreenButton fullWidth startIcon={<DeleteIcon />} onClick={(e) => SubmitDelPristupy(e)}>Smazat</GreenButton>
                </Grid>
            </Grid>
          
        </DialogActions>
      </Dialog>


      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        key="edit"
        onClose={(e) => handleClose(e)}
        closeAfterTransition
        BackdropComponent={Backdrop}
    >
        <Fade in={open}>
          <div className={classes.paper}>
            <Paper className={classes.paperH}>
              <Typography className={classes.nadpisH}>Upravit přístup</Typography>
            </Paper>
            <Container className={classes.innerPadding} maxWidth="sm">
              <Grid container spacing={2}>      
                {(pristupy.errorMsg.length>0) && ( 
                <Grid item xs={12} sm={12}> 
                  <Alert severity="error">{pristupy.errorMsg}</Alert>
                </Grid>
                )}
                <Grid item xs={12} sm={6}>                                           
                  <TextFields name="Jmeno" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Jmeno" label="Jméno" required placeholder="Jméno" value={pristupy.PristupyInfo.Jmeno} onChange={onChangeValue} />
                </Grid>  
                <Grid item xs={12} sm={6}>                                            
                    <TextFields name="Prijmeni" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Prijmeni" label="Přijmení" required placeholder="Přijmení" value={pristupy.PristupyInfo.Prijmeni} onChange={onChangeValue} />
                </Grid>  

                <Grid item xs={12} sm={6}>                                            
                    <TextFields name="Email" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Email" label="Email" required placeholder="Email" value={pristupy.PristupyInfo.Email} onChange={onChangeValue} />
                </Grid>  
                <Grid item xs={12} sm={6}>                                            
                    <TextFields name="Telefon" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Telefon" label="Telefon" required placeholder="Telefon" value={pristupy.PristupyInfo.Telefon} onChange={onChangeValue} />
                </Grid>                           
                <Grid item xs={12} sm={6}>                                              
                    <Typography variant="h6" className={classes.nadpis}><GreenCheckbox checked={pristupy.PristupyInfo.IsActive ? pristupy.PristupyInfo.IsActive : false } onChange={CheckBoxhandleChange} id="IsActive" name="IsActive" />Aktivní </Typography>
                </Grid>                   
                <Grid item xs={12} sm={6}>                                              
                    <Typography variant="h6" className={classes.nadpis}><GreenCheckbox checked={pristupy.PristupyInfo.SendMailObjednavka ? pristupy.PristupyInfo.SendMailObjednavka : false } onChange={CheckBoxhandleChange} id="SendMailObjednavka" name="SendMailObjednavka" />Email - Objednávka</Typography>
                </Grid>                      
                <Grid item xs={12} sm={6}>                                              
                    <Typography variant="h6" className={classes.nadpis}><GreenCheckbox checked={pristupy.PristupyInfo.SendMailAccept ? pristupy.PristupyInfo.SendMailAccept : false } onChange={CheckBoxhandleChange} id="SendMailAccept" name="SendMailAccept" />Email - Akceptace</Typography>
                </Grid>                 
                <Grid item xs={12} sm={6}>                                              
                    <Typography variant="h6" className={classes.nadpis}><GreenCheckbox checked={pristupy.PristupyInfo.SendMailFaktura ? pristupy.PristupyInfo.SendMailFaktura : false } onChange={CheckBoxhandleChange} id="SendMailFaktura" name="SendMailFaktura" />Email - Faktura</Typography>
                </Grid>             
                <Grid item xs={12} sm={4}> 
                    <GreenButton fullWidth onClick={(e) => handleClose(e)} >Zrušit</GreenButton>
                </Grid>
                <Grid item xs={12} sm={4}> 
                    <GreenButton fullWidth onClick={(e) => handleOpenPassword(e)} >Změnit heslo</GreenButton>
                  
                </Grid>
                <Grid item xs={12} sm={4}> 
                    <GreenButton fullWidth onClick={(e) => SubmitEditPristupy(e)} startIcon={<SaveIcon />} >Uložit</GreenButton>
                </Grid>
              </Grid>
            </Container>
          </div>
        </Fade>
    </Modal>  


    <Dialog
        open={openPassword}
        onClose={(e) => handleClosePassword()}
      >
        <DialogTitle>{"Změnit uživatelské heslo"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Změnit uživatelského hesla.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>      
              <Grid item xs={12} sm={6}>
                <TextFields name="heslo" className={clsx(classes.textf,classes.margin)} type="password" variant="outlined" size="small" fullWidth id="heslo" label="Heslo" required placeholder="Heslo" value={pristupy.PristupoveUdaje.heslo} onChange={onChangePristupoveUdaje} 
                  error ={pristupy.PristupoveUdaje.heslo<8?true:false || pristupy.PristupoveUdaje.heslo!==pristupy.PristupoveUdaje.HesloCheck?true:false }
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextFields name="HesloCheck" className={clsx(classes.textf,classes.margin)} type="password" variant="outlined" size="small" fullWidth id="HesloCheck" label="Kontrola hesla" required placeholder="Kontrola hesla" value={pristupy.PristupoveUdaje.HesloCheck} onChange={onChangePristupoveUdaje} 
                error ={pristupy.PristupoveUdaje.heslo<8?true:false || pristupy.PristupoveUdaje.heslo!==pristupy.PristupoveUdaje.HesloCheck?true:false }
                />
              </Grid>

              <Grid item xs={12} sm={4}> 
                  <GreenButton fullWidth onClick={(e) => handleClosePassword()}>Zrušit</GreenButton>
              </Grid>
              <Grid item xs={12} sm={4}/> 
              <Grid item xs={12} sm={4}> 
                <GreenButton fullWidth disabled={pristupy.PristupoveUdaje.heslo<8?true:false || pristupy.PristupoveUdaje.heslo!==pristupy.PristupoveUdaje.HesloCheck?true:false } startIcon={<SaveIcon />} onClick={(e) => SubmitZmenaHesla(e)}>Uložit</GreenButton>
              </Grid>
          </Grid>          
        </DialogActions>
      </Dialog>


    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openNew}
        key="add"
        onClose={(e) => handleCloseNew(e)}
        closeAfterTransition
        BackdropComponent={Backdrop}
    >
      <Fade in={openNew}>
        <div className={classes.paper}>
          <Paper className={classes.paperH}>
            <Typography className={classes.nadpisH}>Nový přístup</Typography>
          </Paper>
          <Container className={classes.innerPadding} maxWidth="sm">
            <Grid container spacing={2}>                
              {(pristupy.errorMsg.length>0) && ( 
              <Grid item xs={12} sm={12}> 
                <Alert severity="error">{pristupy.errorMsg}</Alert>
              </Grid>
              )}    
              <Grid item xs={12} sm={6}>                                           
                <TextFields name="Jmeno" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Jmeno" label="Jméno" required placeholder="Jméno" value={pristupy.PristupyInfo.Jmeno} onChange={onChangeValue} />
              </Grid> 
              <Grid item xs={12} sm={6}>                                            
                  <TextFields name="Prijmeni" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Prijmeni" label="Přijmení" required placeholder="Přijmení" value={pristupy.PristupyInfo.Prijmeni} onChange={onChangeValue} />
              </Grid>  

              <Grid item xs={12} sm={6}>                                            
                  <TextFields name="Email" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Email" label="Email" required placeholder="Email" value={pristupy.PristupyInfo.Email} onChange={onChangeValue} />
              </Grid>  
              <Grid item xs={12} sm={6}>                                            
                  <TextFields name="Telefon" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Telefon" label="Telefon" required placeholder="Telefon" value={pristupy.PristupyInfo.Telefon} onChange={onChangeValue} />
              </Grid>   
                              
              <Grid item xs={12} sm={6}>                                            
                  <TextFields name="LoginMail" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="LoginMail" label="Přihlašovací email" required placeholder="Přihlašovací email" value={pristupy.PristupyInfo.LoginMail} onChange={onChangeValue} />
              </Grid>     
              <Grid item xs={12} sm={6}/>         
              <Grid item xs={12} sm={6}>                                            
                  <TextFields name="Heslo" className={clsx(classes.textf,classes.margin)} type="password" variant="outlined" size="small" fullWidth id="Heslo" label="Heslo" required placeholder="Heslo" value={pristupy.PristupyInfo.Heslo} onChange={onChangeValue} />
              </Grid>  
              <Grid item xs={12} sm={6}>                                            
                  <TextFields name="HesloCheck" className={clsx(classes.textf,classes.margin)} type="password" variant="outlined" size="small" fullWidth id="HesloCheck" label="Heslo kontrola" required placeholder="Heslo kontrola" value={pristupy.PristupyInfo.HesloCheck} error={pristupy.PristupyInfo.Heslo<8?true:false || pristupy.PristupyInfo.Heslo!==pristupy.PristupyInfo.HesloCheck?true:false } onChange={onChangeValue} />
              </Grid>  
              
              <Grid item xs={12} sm={6}>                                              
                    <Typography variant="h6" className={classes.nadpis}><GreenCheckbox checked={pristupy.PristupyInfo.IsActive ? pristupy.PristupyInfo.IsActive : false } onChange={CheckBoxhandleChange} id="IsActive" name="IsActive" />Aktivní </Typography>
                </Grid>                   
                <Grid item xs={12} sm={6}>                                              
                    <Typography variant="h6" className={classes.nadpis}><GreenCheckbox checked={pristupy.PristupyInfo.SendMailObjednavka ? pristupy.PristupyInfo.SendMailObjednavka : false } onChange={CheckBoxhandleChange} id="SendMailObjednavka" name="SendMailObjednavka" />Email - Objednávka</Typography>
                </Grid>                      
                <Grid item xs={12} sm={6}>                                              
                    <Typography variant="h6" className={classes.nadpis}><GreenCheckbox checked={pristupy.PristupyInfo.SendMailAccept ? pristupy.PristupyInfo.SendMailAccept : false } onChange={CheckBoxhandleChange} id="SendMailAccept" name="SendMailAccept" />Email - Akceptace</Typography>
                </Grid>                 
                <Grid item xs={12} sm={6}>                                              
                    <Typography variant="h6" className={classes.nadpis}><GreenCheckbox checked={pristupy.PristupyInfo.SendMailFaktura ? pristupy.PristupyInfo.SendMailFaktura : false } onChange={CheckBoxhandleChange} id="SendMailFaktura" name="SendMailFaktura" />Email - Faktura</Typography>
                </Grid>                
              <Grid item xs={12} sm={4}> 
                  <GreenButton fullWidth onClick={(e) => handleCloseNew(e)} >Zrušit</GreenButton>
              </Grid>
              <Grid item xs={12} sm={4}/> 
              <Grid item xs={12} sm={4}> 
                  <GreenButton fullWidth onClick={(e) => SubmitEditPristupy(e)} disabled={pristupy.PristupyInfo.Heslo<8?true:false || pristupy.PristupyInfo.Heslo!==pristupy.PristupyInfo.HesloCheck?true:false } startIcon={<SaveIcon />}> Uložit</GreenButton>
              </Grid>
            </Grid>
          </Container>
        </div>
      </Fade>
    </Modal>  


    </div>
  );
}

export default Pristupy


