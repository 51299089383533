import React, {useContext,useState, useEffect} from 'react';
import { DataGrid } from '@material-ui/data-grid';
import {UserContext} from '../../contexts/UserContext'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import { makeStyles,withStyles, } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import customConfig from '../../customConfig.json'
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useHistory } from "react-router-dom";
import Paper from '@material-ui/core/Paper';


const TextFields = withStyles({
  root: {
    '& label.Mui-focused': {
      color: customConfig.Colors.PrimaryColor,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: customConfig.Colors.PrimaryColor,
    },
    '& .MuiOutlinedInput-root': {

      '&:hover fieldset': {
        borderColor: customConfig.Colors.SecColor,
      },
      '&.Mui-focused fieldset': {
        borderColor: customConfig.Colors.PrimaryColor,
      },
    },
  },
})(TextField);

function Sdeleni(){

  const {rootState} = useContext(UserContext);
  const {isAdmin} = rootState;


    const initialSdeleni = {
        SdeleniInfo:{
            NotifikaceID:'',
            Titulek:'',
            Notifikace:'',            
        },
    };    
    const useStyles = makeStyles((theme) => ({
        modal: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        paper: {
          backgroundColor: theme.palette.background.paper,
          border: customConfig.Colors.BorderColor,          
          boxShadow: theme.shadows[5],
          padding: '0px',
          borderRadius:'10px 10px 8px 8px'
        },
        margin: {
          margin: theme.spacing(0),
        },
        hide: {
          display: 'none',
        },      
        callContainer: {
          backgroundColor: 'none',
        },
        form: {
          width: '100%', // Fix IE 11 issue.
          marginTop: theme.spacing(1),
          backgroundColor: 'none',
        },
        withoutLabel: {
          marginTop: theme.spacing(1),
        },
        boxStyle:{
          marginTop:'15px'

        },
        nadpis:{
          color: customConfig.Colors.PrimaryTextColor
        },
        left:{
          paddingLeft: '10px',
        },        
        textf: {
          padding: '0px 8px 0px 0px',
        },
        innerPadding:{
          padding: '20px',
        },  
        nadpisH:{
          color: customConfig.Colors.ButtonTextColor,
          fontSize:'20px'
        },
        paperH: {
          textAlign: 'left',
          paddingLeft:'20px',
          backgroundColor: customConfig.Colors.PrimaryColor,
          boxShadow: 'none',
          border:'1px',
          borderRadius: '8px 8px 0px 0px'
        },
        bgGrid: {
          backgroundColor: customConfig.Colors.GridBackgroundColor,
          padding:'0px !important',
          width:'100% !important',
          margin:'0px !important'
        },
        containerX: {
          padding:'0px !important',
          width:'100% !important',
          margin:'0px !important'
        },
        datag: {
          '& .super-app-theme--header': {
            backgroundColor: customConfig.Colors.PrimaryColor,
            color:customConfig.Colors.ButtonTextColor,
          },
          '& .MuiDataGrid-columnsContainer': {
            backgroundColor: customConfig.Colors.PrimaryColor,
            color:customConfig.Colors.ButtonTextColor,
          },
          '& .MuiDataGrid-row': {
            cursor:'pointer'
          },
          borderBlockColor:customConfig.Colors.PrimaryColor
        },

        
      }));



    const {getSdeleni,postSdeleni,delSdeleni} = useContext(UserContext);
    const [Sdeleni, setSdeleni] = useState([])    
    const [sdeleni,setState] = useState(initialSdeleni);  
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [openDel, setOpenDel] = React.useState(false);
    let history = useHistory();

    useEffect(() => {
      if(isAdmin===false){
        history.push("/Home");
      }
      else 
      {
        document.title = "SEBAplus - Obchodní sdělení";
        // getSdeleni()
        // .then(data =>
        //     setSdeleni(
        //         data)
        // ); 

        async function fetchData() {
          const dataOUD = await getSdeleni(); 
          setSdeleni(dataOUD) 
        }
        fetchData()
          
      }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])   

    const GreenButton = withStyles((theme) => ({
        root: {
          color: customConfig.Colors.ButtonTextColor, 
          backgroundColor: customConfig.Colors.PrimaryColor,
          fontWeight:'520',
          '&:hover': {
            backgroundColor: customConfig.Colors.SecColor,
          },
        },
      }))(Button);

      const columns = [
        
    
        { field: 'NotifikaceID', type: 'number', hide: true },
        { field: 'Titulek', type: 'string', headerClassName: 'super-app-theme--header', headerName: 'Titulek', width: 200, },
        { field: 'Notifikace', type: 'string', headerClassName: 'super-app-theme--header', headerName: 'Notifikace', width: 500, },
        { field: 'Datum', type: 'date', headerClassName: 'super-app-theme--header', headerName: 'Datum', width: 200, },
        {
            field: "del",
            headerName: "Smazání",
            sortable: false,
            headerClassName: 'super-app-theme--header',
            disableColumnMenu:true, 
            width: 90,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                const onClick = () => {
                const api = params.api;
                const fields = api
                  .getAllColumns()
                  .map((c) => c.field)
                  .filter((c) => c !== "__check__" && !!c);
    
                const thisRow = {};
                fields.forEach((f) => {
                  thisRow[f] = params.getValue(f);
                });
    
                setState({
                    ...Sdeleni,
                    SdeleniInfo:{
                        ...Sdeleni.SdeleniInfo,
                        NotifikaceID:thisRow.NotifikaceID                        
                    },
                })
                setOpenDel(true);
            }
            
                return (<IconButton onClick={onClick}><DeleteIcon className="fa fa-plus-circle" style={{ color: customConfig.Colors.PrimaryColor }}  /></IconButton>);
            }
        },
      ];

      
    const onChangeValue = (e) => {
        setState({
            ...sdeleni,
            SdeleniInfo:{
                ...sdeleni.SdeleniInfo,
                [e.target.name]:e.target.value
            },
        });        
    }

    const SubmitPostSdeleni = async (event) => {
        
        event.preventDefault();

        await postSdeleni(sdeleni.SdeleniInfo);

        async function fetchData() {
          const dataOUD = await getSdeleni(); 
          setSdeleni(dataOUD) 
        }
        fetchData()

        setState({
            ...sdeleni,
                SdeleniInfo:initialSdeleni.SdeleniInfo
            },
        )
        setOpen(false);
    }

    const handleOpenNew = () => {
      setOpen(true);
      setState({
      ...sdeleni,
          SdeleniInfo:initialSdeleni.SdeleniInfo
        },
      )
    };
  
    const handleCloseNew = () => {
      setOpen(false);
      setState({
      ...sdeleni,
          SdeleniInfo:initialSdeleni.SdeleniInfo
        },
      )
    };

    const SubmitDelNotifikace = async (event) => {
        
        
        event.preventDefault();

        await delSdeleni(sdeleni.SdeleniInfo);

        async function fetchData() {
          const dataOUD = await getSdeleni(); 
          setSdeleni(dataOUD) 
        }
        fetchData()



        setOpenDel(false);
        setState({
            ...sdeleni,
                SdeleniInfo:initialSdeleni.SdeleniInfo
            },
        )
    }

    const handleDelClose = () => {
        setOpenDel(false);
        setState({
        ...sdeleni,
            SdeleniInfo:initialSdeleni.SdeleniInfo
          },
        )
    };

    function currentlySelected(v) {
      
        setState({
          ...sdeleni,
          SdeleniInfo:{
              ...sdeleni.SdeleniInfo,  
              NotifikaceID:v.NotifikaceID,
              Titulek:v.Titulek,
              Notifikace:v.Notifikace,
          },
      });        
      }

  return (


<div className={classes.bgGrid , classes.containerX}>
    <Grid container spacing={3} className={classes.bgGrid}> 
        <Grid item xs={12} sm={12} spacing={2} container className={classes.bgGrid}> 
            <div style={{ height: 400, width: '100%' }}>
                <DataGrid className={classes.datag} getRowId={(row) => row.NotifikaceID} pageSize={25} rowHeight={35} rows={Sdeleni?Sdeleni:[]} hideFooterPagination="true" hideFooterRowCount="true" hideFooter="true" hideFooterSelectedRowCount="true" columns={columns}
                  onRowSelected={(e) => currentlySelected(e.data)}                  
                />
            </div>
        </Grid>

        <Grid item xs={12} sm={12} spacing={2} container className={classes.bgGrid} direction="row" justify="space-between" alignItems="flex-start" >  

          <Grid item xs={12} sm={6}>                                           
              <TextFields name="Titulek" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Titulek" label="Titulek" required placeholder="Titulek" value={sdeleni.SdeleniInfo.Titulek} onChange={onChangeValue} />
          </Grid>  
          <Grid item xs={12} sm={6}/>  
          <Grid item xs={12} sm={12}>                                              
              <TextFields name="Notifikace" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth multiline rows={5} id="Notifikace" label="Obchodní sdělení" value={sdeleni.SdeleniInfo.Notifikace} onChange={onChangeValue} />
          </Grid>  
        <Grid item xs={12} sm={2}  > 
              <GreenButton onClick={handleOpenNew} fullWidth startIcon={<AddIcon />}>Přidat záznam</GreenButton>
        </Grid>
        <Grid item xs={12} sm={8} /> 
        <Grid item xs={12} sm={2}  > 
          <GreenButton fullWidth disabled={sdeleni.SdeleniInfo.NotifikaceID.length>0?false:true} onClick={SubmitPostSdeleni} startIcon={<SaveIcon />}>Uložit</GreenButton>
        </Grid>
      </Grid>


    </Grid>

    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        key="add"
        onClose={handleCloseNew}
        closeAfterTransition
        BackdropComponent={Backdrop}
    >
        <Fade in={open}>
            <div className={classes.paper}>
              <Paper className={classes.paperH}>
                  <Typography className={classes.nadpisH}>Nové obchodní sdělení</Typography>
              </Paper>
              <Container className={classes.innerPadding} maxWidth="sm">
                <Grid container spacing={2}>                              
                    <Grid item xs={12} sm={12}>                                           
                      <TextFields name="Titulek" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Titulek" label="Titulek" required placeholder="Titulek" value={sdeleni.SdeleniInfo.Titulek} onChange={onChangeValue} />
                    </Grid>                         
                    <Grid item xs={12} sm={12}>                                              
                        <TextFields name="Notifikace" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth multiline rows={5} id="Notifikace" label="Obchodní sdělení" value={sdeleni.SdeleniInfo.Notifikace} onChange={onChangeValue} />
                    </Grid>  

                    <Grid item xs={12} sm={4}> 
                        <GreenButton fullWidth onClick={handleCloseNew} > Zrušit </GreenButton>
                    </Grid>
                    <Grid item xs={12} sm={4}/> 
                    <Grid item xs={12} sm={4}> 
                        <GreenButton fullWidth onClick={SubmitPostSdeleni} startIcon={<SaveIcon/>}> Uložit sdělení </GreenButton>
                    </Grid>
                </Grid>
              </Container>
            </div>
        </Fade>
    </Modal>  
    <Dialog
        open={openDel}
        onClose={handleDelClose}
        fullWidth
        maxWidth='xs'
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Opravdu si přejete smazat obchodní sdělení?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Grid container spacing={2}>      
                <Grid item xs={12} sm={4}> 
                    <GreenButton fullWidth onClick={handleDelClose}>Zrušit</GreenButton>
                </Grid>
                <Grid item xs={12} sm={4}/> 
                <Grid item xs={12} sm={4}> 
                <GreenButton fullWidth onClick={SubmitDelNotifikace} startIcon={<DeleteIcon />} >Smazat</GreenButton>
                </Grid>
            </Grid>
          
        </DialogActions>
      </Dialog>

</div>
  );
}


export default Sdeleni