import { useForm, useStep } from "react-hooks-helper";
import React, { useEffect} from 'react';
import { ParametryZasilky } from "./ParametryZasilky";
import { Podrobnosti } from "./Podrobnosti";
import { Souhrn } from "./Souhrn";
import { Dokonceni } from "./Dokonceni";
import * as moment from 'moment'
import { v4 as uuidv4 } from 'uuid';



const defaultData = {
  adresFrom: '',
  adresTo: '',
  pocetBalik: "",
  delkaBalik: "",
  sirkaBalik: "",
  vyskaBalik: "",
  hmotnostBalik: "",
  pocetPaleta: "",
  delkaPaleta: "",
  sirkaPaleta: "",
  vyskaPaleta: "",
  hmotnostPaleta: "",
  pocetDokument: "",
  hmotnostDokument: "",
  druh:1,
  druhNazev:'',
  popisZbozi:"",
  hodnotaZbozi:"",
  hodnotaMena:"",
  ReferencniCislo:"",
  Pojistit: false,
  datumVyzvednuti:moment().add(2, 'day').format("YYYY-MM-DD"),
  casOd:"07:00",
  casDo:"15:00",
  poznamka:"",
  platce:"",
  odesilatelJmeno:"",
  odesilatelAdresa:"",
  odesilatelAdresa2:"",
  odesilatelAdresa3:"",
  odesilatelMesto:"",
  odesilatelPSC:"",
  odesilatelStat:"",
  odesilatelStatNazev:"",
  odesilatelKontOsoba:"",
  odesilatelEmail:"",
  odesilatelTel:"",
  prijemceJmeno:"",
  prijemceAdresa:"",
  prijemceAdresa2:"",
  prijemceAdresa3:"",
  prijemceMesto:"",
  prijemcePSC:"",
  prijemceStat:"",
  prijemceStatNazev:"",
  prijemceKontOsoba:"",
  prijemceEmail:"",
  prijemceTel:"",
  prijemceUloz:false,
  platceJmeno:"",
  platceAdresa:"",
  platceMesto:"",
  platcePSC:"",
  platceStat:"",
  platceStatNazev:"",
  platceKontOsoba:"",
  platceEmail:"",
  platceTel:"",
  cena:0,
  sluzba:'',
  sluzbaID:'',
  sluzbaNazev:'',
  prepravce: '',
  DefAdresTo:null,
  DefAdresFrom:null,
  Defprepravce:null,
  Defsluzba:null,
  objemovaHmotnostDokument:'',
  objemovaHmotnostBalik:'',
  objemovaHmotnostPaleta:'',
  vyska:0,    
  sirka:0, 
  delka:0,
  hmotnost:0,
  objemovahmotnost:0,
  pocet:0,
  SEBAplusCislo:'',
  parametry:[],
  doplnky:[
    { DoplnekNazev:'Pojištění zásilky', KodDoplnku: 'POJISTENI', DoplnekInt: false, DoplnekText:''},
    { DoplnekNazev:'Bezpečná zásilka', KodDoplnku: 'BEZPECNA', DoplnekInt: true, DoplnekText:''},
    { DoplnekNazev:'Suchý led', KodDoplnku: 'UN1845', DoplnekInt: false, DoplnekText:''},
    { DoplnekNazev:'Lithiové baterie - se zařízením', KodDoplnku: 'BATERIEZARIZENI', DoplnekInt: false, DoplnekText:''},
    { DoplnekNazev:'Lithiové baterie - v zařízení', KodDoplnku: 'BATERIEVZARIZENI', DoplnekInt: false, DoplnekText:''},
    { DoplnekNazev:'Lithiové baterie - samostatné', KodDoplnku: 'BATERIE', DoplnekInt: false, DoplnekText:''},
    { DoplnekNazev:'Nestohovatelná', KodDoplnku: 'STOHOVATELNA', DoplnekInt: false, DoplnekText:''},
    { DoplnekNazev:'Požadujeme zpoplatněné VDD', KodDoplnku: 'VDD', DoplnekInt: false, DoplnekText:''},
  ],
  dokuemnty:[{ id: uuidv4(), delka: '0', pocet: '', sirka:'0',vyska:'0',hmotnost:'0.99',objemovaHmotnost:'' },],
  baliky:[{ id: uuidv4(), delka: '', pocet: '', sirka:'',vyska:'',hmotnost:'',objemovaHmotnost:''  },],
  palety:[{ id: uuidv4(), delka: '', pocet: '', sirka:'',vyska:'',hmotnost:'',objemovaHmotnost:''  },],
  ostatni:[{ id: uuidv4(), delka: '', pocet: '', sirka:'',vyska:'',hmotnost:'',objemovaHmotnost:''  },],
  ulozitAdrOdesilatel: false,
  ulozitAdrprijemce: false,
  UseProForma:false,
  cenaZaklad:'',
  prilohy:[],
  PROFORMAdata:
    { 
      ByCompany: '', 
      ByAddress: '', 
      ByCity:'',
      ByCountry:'',
      ByTel:'',
      ByVAT:'',
      ByEORI:'',
      
      ToCompany: '', 
      ToAddress: '', 
      ToCity:'',
      ToCountry:'',
      ToTel:'',
      ToVAT:'',
      ToEORI:'',

      TotalValue:'',
      NumberofShipments:'',
      TotalGrossWeight:'',
      TotalNetWeight:'',
      WaybillNumber:'',
      INCOTERMS:'',
      ReasonForExport:'',
      VDDTDD:false,
      ATACARNET:false,
      VSCP:false,
      VDD:false,
      rows:[{ 
        id: uuidv4(), 
        Description: '',
        CommodityCode: '', 
        Country: '', 
        Qty: '', 
        UnitValue :'',
        SubTotal :''
      },],  
    },
};

const steps = [
  { id: "ParametryZasilky" },
  { id: "Podrobnosti" },
  { id: "Souhrn" },
  { id: "Dokonceni" },
];

export const Kalkulace = () => {
  const [formData, setForm] = useForm(defaultData);
  const { step, navigation } = useStep({
    steps,
    initialStep: 0,
  });



  useEffect(() => {
    document.title = "SEBAplus - Odeslat zásilku";
  }, []);
  



  const props = { formData, setForm, navigation};

  switch (step.id) {
    case "ParametryZasilky":
      return <ParametryZasilky {...props} />;
    case "Podrobnosti":
      return <Podrobnosti {...props} />;
    case "Souhrn":
      return <Souhrn {...props} />;
    case "Dokonceni":
      return <Dokonceni {...props} />;
  }

};