import React, {useContext,useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';
import {UserContext} from '../../contexts/UserContext';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import customConfig from '../../customConfig.json'
import clsx from 'clsx';
import * as Icons from '@material-ui/icons'
import { useHistory} from 'react-router-dom'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountBoxIcon from '@material-ui/icons/AccountBox';






const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  menuButton: {
    marginRight: 36,
  },
  menu: {
    paddingBottom:'5px',
    paddingTop:'5px'
  },
  menuButtonLogin: {
    marginRight: theme.spacing(2),
  },
  drawerOpen: {
    width: drawerWidth,
    borderRight: '0px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    borderRight: '0px',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8) + 1,
    },
  },
  hide: {
    display: 'none',
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },

  
  // necessary for content to be below app bar
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor:'white',
    padding: theme.spacing(0, 1),
    color:customConfig.Colors.PrimaryColor,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  toolbarList: {
    backgroundColor: customConfig.Colors.PrimaryColor,
    color:customConfig.Colors.ButtonTextColor,
    padding:'0px',
    margin:'0px',
    height:'100%'

    
    
  },
  iconColor:{
    color:customConfig.Colors.ButtonTextColor,
  },
  iconColorMenu:{
    color:customConfig.Colors.PrimaryColor,
  },
  PaperBG:{
    backgroundColor: customConfig.Colors.PrimaryColor,
    color:customConfig.Colors.ButtonTextColor,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
  },
}));

function HeaderMenu(props) {
  const classes = useStyles();  
  const [open, setOpen] = React.useState(true);
  const {getMenuItems} = useContext(UserContext);
  const [MenuItems, setMenuItems] = useState([])    

  useEffect(() => {

    async function fetchData() {

      const dataOUD = await getMenuItems();    
  
      setMenuItems(dataOUD) 
  }
  
  fetchData()


    // getMenuItems()
    // .then(data =>
    //   setMenuItems(
    //   data)
    // ) 




    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])   


  let history = useHistory();
  
    const handleDrawerClose = () => {
      setOpen(!open);
    };

  return (
    <div className={classes.root}>
      <Drawer
        variant="permanent"
        className={classes.PaperBG,clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      > 
        <div className={classes.toolbar}>   
          {open === false ? '' :  <img src="../img/loga/LogoSEBAPlus.png" alt="SEBA Logistics, s.r.o." height="50px"/> }
          <IconButton onClick={handleDrawerClose} className={classes.iconColorMenu} >          
            {open === false ? <MenuIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <div className={classes.toolbarList}>
          <Divider />
          <List className={classes.PaperBG}>


          {MenuItems.map(row => {
                let DynamicIcon = Icons[row.Ikona]
                return (
                <ListItem className={classes.menu} button key={row.Nazev} onClick={() => { history.push("/"+row.url); }}>                  
                  <ListItemIcon><DynamicIcon className={classes.iconColor}/> </ListItemIcon>
                  <ListItemText primary={row.Nazev}/>
                </ListItem>
                    
                );
              })}

          </List>
          <Divider />
          <List className={classes.PaperBG}>


                <ListItem button key={'mujucet'} onClick={() => { history.push("/mujucet"); }}>                  
                  <ListItemIcon><AccountBoxIcon className={classes.iconColor}/> </ListItemIcon>
                  <ListItemText primary={'Můj účet'}/>
                </ListItem>
                <ListItem button key={'logout'} onClick={() => { history.push("/logout"); }}>                  
                  <ListItemIcon><ExitToAppIcon className={classes.iconColor}/> </ListItemIcon>
                  <ListItemText primary={'Odhlásit se'}/>
                </ListItem>
                    

          </List>
        </div>
      </Drawer>
    </div>
  );
}

HeaderMenu.propTypes = {
  
  window: PropTypes.func,
};

export default HeaderMenu;

