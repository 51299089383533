import React, {useContext,useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import customConfig from '../../customConfig.json'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {UserContext} from '../../contexts/UserContext'
import ZalozitFakturu from './ZalozitFakturu/ZalozitFakturu'
import PrehledFaktur from './PrehledFaktur/PrehledFaktur'
import { Grid } from '@material-ui/core';
import { useHistory } from "react-router-dom";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{width: "100%"}}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
      <Box style={{padding:'0px'}} p={3}>
          {children}
      </Box>
      )}
    </div>
  );
}

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    flexDirection:"row",
    justifyContent: 'space-between',
    width:'auto',
    backgroundColor: customConfig.Colors.PrimaryColor,
    '& > span': {
      width: '100%',
      backgroundColor: customConfig.Colors.PrimaryColor,
    },
    '@media screen and (max-width:1400px)' : {
      flexDirection:"column"
    }
  },
})((props) => <Tabs {...props} style={{width: "auto"}} TabIndicatorProps={{ children: <span /> }} />);

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },  
  appBar:{
    padding:'0px',
    color:customConfig.Colors.PrimaryColor,
    backgroundColor:customConfig.Colors.PrimaryColor,
    flexDirection:"row",
    '@media screen and (max-width:1400px)' : {
      flexDirection:"column"
    }
    
    
},
tab:{
    
    color: customConfig.Colors.TextColor,
    backgroundColor:customConfig.Colors.PrimaryColor,
    '&.Mui-selected': {
        backgroundColor: customConfig.Colors.SecColor,
        color: customConfig.Colors.TextColor,
        
    },
    '&:hover': {
       backgroundColor: customConfig.Colors.SecColor,
       color: customConfig.Colors.TextColor,
       opacity: 1,
    },       
},
tf:{
  backgroundColor: customConfig.Colors.textfieldsbackground,
  border: customConfig.Colors.BorderColor,
  borderRadius: '5px'
},
bgGrid: {
  backgroundColor: customConfig.Colors.GridBackgroundColor,
  width:'100%',
  margin:'0px',
  padding:'0px'
},
containerX: {  
  width:'100%',
  margin:'0px',
  padding:'0px'
},
}));

export default function Faktury() {
  const {rootState} = useContext(UserContext);
  const {isAdmin} = rootState;

  const initialUser = {
    UserInfo:{
        UserID:'',
        IsCompany:'',
    },
  };   
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [User, setUser] = useState([])  
  const {getUser} = useContext(UserContext);
  const [user, setState] = useState(initialUser);
  let history = useHistory();

  
    useEffect(() => {
      if(isAdmin!==true){
        history.push("/Home");
      }
      else 
      {
        document.title = "SEBAplus - Faktury";

        async function fetchData() {
          const dataOUD = await getUser();          
          setUser(dataOUD) 
        }
      
        fetchData()

      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])     



  useEffect(() => {   
    
      if(User.User)
      {
        setState({
            ...user,
            UserInfo:{
                ...user.UserInfo,
                UserID:User.User[0].UserID,
                IsCompany:User.User[0].IsCompany
            },
        });     
      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [User])   



  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.bgGrid,classes.containerX}>
      <Grid container spacing={2} className={classes.bgGrid}>
        <AppBar className={classes.appBar} position="relative" style={{flexDirection: 'row', alignItems:'center'}}>             
          <StyledTabs
              value={value} 
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              centered
              aria-label="scrollable force tabs example"
          >
            <Tab className={classes.tab} label="Přehled faktur" {...a11yProps(0)} />
            <Tab className={classes.tab} label="Nastavení" {...a11yProps(1)} />
            <Tab className={classes.tab} label="Nová faktura" {...a11yProps(2)} />
          </StyledTabs>
    
        </AppBar>    
          <TabPanel value={value} index={0}>
            <PrehledFaktur />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <p>Nastaveni</p>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ZalozitFakturu />
          </TabPanel>
      </Grid>
    </div>
  );
}