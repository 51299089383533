import React, {useContext,useState, useEffect} from 'react';
import { DataGrid } from '@material-ui/data-grid';
import {UserContext} from '../../contexts/UserContext'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import { makeStyles,withStyles, } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import customConfig from '../../customConfig.json'
import SaveIcon from '@material-ui/icons/Save';
import Checkbox from '@material-ui/core/Checkbox';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { useHistory } from "react-router-dom";
import Paper from '@material-ui/core/Paper';


const TextFields = withStyles({
  root: {
    '& label.Mui-focused': {
      color: customConfig.Colors.PrimaryColor,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: customConfig.Colors.PrimaryColor,
    },
    '& .MuiOutlinedInput-root': {

      '&:hover fieldset': {
        borderColor: customConfig.Colors.SecColor,
      },
      '&.Mui-focused fieldset': {
        borderColor: customConfig.Colors.PrimaryColor,
      },
    },
  },
})(TextField);



function Sluzby(){
  const {rootState} = useContext(UserContext);
  const {isAdmin} = rootState;

  const GreenCheckbox = withStyles({
    root: {
      color: customConfig.Colors.PrimaryColor,
      '&$checked': {
        color: customConfig.Colors.PrimaryColor,
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

    const initialSluzby = {
        SluzbyInfo:{
            SluzbaID:'',
            SluzbaKod:'',   
            SluzbaNazev:'',            
            SluzbaImport:false,
            SluzbaExport:false,
            Dokument:false,
            Paleta:false,
            Balik:false,
            PrepravceID:'',
            PrepravceNazev:'',
        },
    };    
    const useStyles = makeStyles((theme) => ({
        modal: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        paper: {
          backgroundColor: theme.palette.background.paper,
          border: customConfig.Colors.BorderColor,          
          boxShadow: theme.shadows[5],
          padding: '0px',
          borderRadius:'10px 10px 8px 8px'
        },
        margin: {
          margin: theme.spacing(0),
        },
        hide: {
          display: 'none',
        },      
        callContainer: {
          backgroundColor: 'none',
        },
        innerPadding:{
            padding: '20px',
        },          
        nadpisH:{
          color: customConfig.Colors.ButtonTextColor,
          fontSize:'20px'
        },
        paperH: {
          textAlign: 'left',
          paddingLeft:'20px',
          backgroundColor: customConfig.Colors.PrimaryColor,
          boxShadow: 'none',
          border:'1px',
          borderRadius: '8px 8px 0px 0px'
        },
        form: {
          width: '100%', // Fix IE 11 issue.
          marginTop: theme.spacing(1),
          backgroundColor: 'none',
        },
        withoutLabel: {
          marginTop: theme.spacing(1),
        },
        boxStyle:{
          marginTop:'15px'

        },
        nadpis:{
          color: customConfig.Colors.PrimaryTextColor
        },
        left:{
          paddingLeft: '10px',
        },        
        textf: {
          padding: '0px 8px 0px 0px',
        },   
        text: {
          padding: '0px 8px 0px 0px',
        },
        datag: {
          '& .super-app-theme--header': {
            backgroundColor: customConfig.Colors.PrimaryColor,
            color:customConfig.Colors.ButtonTextColor,
          },
          '& .MuiDataGrid-columnsContainer': {
            backgroundColor: customConfig.Colors.PrimaryColor,
            color:customConfig.Colors.ButtonTextColor,
          },
          '& .MuiDataGrid-row': {
            cursor:'pointer'
          },          
          borderBlockColor:customConfig.Colors.PrimaryColor
        },
        table:{
          border: "none"
        },
        tableCell:{
          border: "none",
          padding:"5px"
        },
        bgGrid: {
          backgroundColor: customConfig.Colors.GridBackgroundColor,
          padding:'0px !important',
          width:'100% !important',
          margin:'0px !important'
        },
        containerX: {
          padding:'0px !important',
          width:'100% !important',
          margin:'0px !important'
        },

        
      }));



    const {getSluzbyDatagrid,postSluzby} = useContext(UserContext);
    const [Sluzby, setSluzby] = useState([])    
    const [sluzby,setState] = useState(initialSluzby);  
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    let history = useHistory();



    useEffect(() => {
      if(isAdmin===false){
        history.push("/Home");
      }
      else 
      {
        document.title = "SEBAplus - Služby";

        async function fetchData() {
          const dataOUD = await getSluzbyDatagrid(); 
          setSluzby(dataOUD) 
        }
        
        fetchData()
      }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])   
    async function currentlySelected(thisRow) { 

      setState({
        SluzbyInfo:{
            ...sluzby.SluzbyInfo,
              SluzbaID:thisRow.SluzbaID,
              SluzbaNazev:thisRow.SluzbaNazev,
              SluzbaKod:thisRow.SluzbaKod,
              SluzbaExport:thisRow.SluzbaExport === "1" ? true : false,
              SluzbaImport:thisRow.SluzbaImport === "1" ? true : false,
              Dokument:thisRow.Dokument === "1" ? true : false,
              Paleta:thisRow.Paleta === "1" ? true : false,
              Balik:thisRow.Balik === "1" ? true : false,
              PrepravceID:thisRow.PrepravceID,
              PrepravceNazev:thisRow.PrepravceNazev
            }
      })

    }

    const GreenButton = withStyles((theme) => ({
        root: {
          color: customConfig.Colors.ButtonTextColor, 
          backgroundColor: customConfig.Colors.PrimaryColor,
          fontWeight:'520',
          '&:hover': {
            backgroundColor: customConfig.Colors.SecColor,
          },
        },
      }))(Button);

    const columns = [ 
        { field: 'SluzbaID', type: 'number', hide: true },
        { field: 'PrepravceID', type: 'string', hide: true},  
        { field: 'SluzbaKod', type: 'string', headerClassName: 'super-app-theme--header', headerName: 'Kod skužby', width: 200, },
        { field: 'SluzbaNazev', type: 'string', headerClassName: 'super-app-theme--header', headerName: 'Název služby', width: 300, },
        { field: 'PrepravceNazev', type: 'string', headerClassName: 'super-app-theme--header', headerName: 'Přepravce', width: 200,},    
        {
            field: "SluzbaImport",
            headerName: "Import",
            headerClassName: 'super-app-theme--header',
            width: 130,
            renderCell: (params) => {          
                return (              
                  <GreenCheckbox checked={params.getValue("SluzbaImport") === "1" ? true : false} disabled={true} name="checkedTest" />              
                );
            }
          },
          {
              field: "SluzbaExport",
              headerName: "Export",
              headerClassName: 'super-app-theme--header',
              width: 130,
              renderCell: (params) => {          
                  return (              
                    <GreenCheckbox checked={params.getValue("SluzbaExport") === "1" ? true : false} disabled={true} name="checkedTest" />              
                  );
              }
            },
        {
            field: "Dokument",
            headerName: "Dokument",
            headerClassName: 'super-app-theme--header',
            width: 130,
            renderCell: (params) => {          
                return (              
                <GreenCheckbox checked={params.getValue("Dokument") === "1" ? true : false} disabled={true} name="checkedTest" />              
                );
            }
        },
        {
            field: "Balik",
            headerName: "Balík",
            headerClassName: 'super-app-theme--header',
            width: 130,
            renderCell: (params) => {          
                return (              
                    <GreenCheckbox checked={params.getValue("Balik") === "1" ? true : false} disabled={true} name="checkedTest" />              
                );
            }
        },
        {
            field: "Paleta",
            headerName: "Paleta",
            headerClassName: 'super-app-theme--header',
            width: 130,
            renderCell: (params) => {          
                return (              
                <GreenCheckbox checked={params.getValue("Paleta") === "1" ? true : false} disabled={true} name="checkedTest" />              
                );
            }
        },
      ];

      
    const onChangeValue = (e) => {
        setState({
            ...sluzby,
            SluzbyInfo:{
                ...sluzby.SluzbyInfo,
                [e.target.name]:e.target.value
            },
        });        
    }

    const CheckBoxhandleChange = (event) => {
      setState({
        ...sluzby,
        SluzbyInfo:{
            ...sluzby.SluzbyInfo, 
            [event.target.name]: event.target.checked 
        },   
      });            
    };

    const SubmitPostSluzby = async (event) => {
        
        event.preventDefault();
        
        await postSluzby(sluzby.SluzbyInfo);


        async function fetchData() {
          const dataOUD = await getSluzbyDatagrid(); 
          setSluzby(dataOUD) 
        }
        
        fetchData()
        
        setState({
            ...sluzby,
                SluzbyInfo:initialSluzby.SluzbyInfo
            },
        )
        setOpen(false);
    }

    const handleCloseEdit = () => {
      setOpen(false);
      setState({
      ...sluzby,
          SluzbyInfo:initialSluzby.SluzbyInfo
        },
      )
    };

  return (


<div className={classes.bgGrid,classes.containerX}>
  <Grid container spacing={3} className={classes.bgGrid}> 
    <Grid item xs={12} sm={12} spacing={2} container className={classes.bgGrid}> 
      <div style={{ height: 600, width: '100%' }}>
        <DataGrid className={classes.datag} getRowId={(row) => row.SluzbaID} pageSize={1000} rowHeight={35} rows={Sluzby?Sluzby:[]} hideFooterPagination="true" hideFooterRowCount="true" hideFooter="true" hideFooterSelectedRowCount="true" columns={columns} onRowSelected={(e) => currentlySelected(e.data)}/>
      </div>
    </Grid>
    <Grid item container xs={12} sm={12} spacing={2}>      
      <Grid item xs={12} sm={6}>                                              
        <TextFields name="SluzbaNazev" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="SluzbaNazev" label="Název služby" required placeholder="Název služby" value={sluzby.SluzbyInfo.SluzbaNazev} onChange={onChangeValue} />
      </Grid> 
      <Grid item xs={12} sm={6}>                                              
        <TextFields name="SluzbaKod" className={clsx(classes.textf,classes.margin)} type="text"  variant="outlined" size="small" fullWidth id="SluzbaKod" label="Kod služby" required placeholder="Kod služby" value={sluzby.SluzbyInfo.SluzbaKod} onChange={onChangeValue} />
      </Grid> 
      <Grid item xs={6} sm={2}>                    
        <Typography className={classes.text}>Import<GreenCheckbox checked={sluzby.SluzbyInfo.SluzbaImport} id="SluzbaImport" name="SluzbaImport" onChange={CheckBoxhandleChange} /></Typography>
      </Grid> 
      <Grid item xs={6} sm={2}>                    
        <Typography className={classes.text}>Export<GreenCheckbox checked={sluzby.SluzbyInfo.SluzbaExport} id="SluzbaExport" name="SluzbaExport" onChange={CheckBoxhandleChange} /></Typography>
      </Grid> 
      <Grid item xs={6} sm={2}>                    
        <Typography className={classes.text}>Dokument<GreenCheckbox checked={sluzby.SluzbyInfo.Dokument} id="Dokument" name="Dokument" onChange={CheckBoxhandleChange} /></Typography>
      </Grid> 
      <Grid item xs={6} sm={2}>                    
        <Typography className={classes.text}>Balík<GreenCheckbox checked={sluzby.SluzbyInfo.Balik} id="Balik" name="Balik" onChange={CheckBoxhandleChange} /></Typography>
      </Grid> 
      <Grid item xs={6} sm={2}>                    
        <Typography className={classes.text}>Paleta<GreenCheckbox checked={sluzby.SluzbyInfo.Paleta} id="Paleta" name="Paleta" onChange={CheckBoxhandleChange} /></Typography>
      </Grid>  
      <Grid item xs={12} sm={10}/> 
      <Grid item xs={12} sm={2}> 
          <GreenButton fullWidth onClick={SubmitPostSluzby} startIcon={<SaveIcon />}> Uložit </GreenButton>
      </Grid>
    </Grid>
  </Grid>
    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        key="add"
        onClose={handleCloseEdit}
        closeAfterTransition
        BackdropComponent={Backdrop}
    >
        <Fade in={open}>
            <div className={classes.paper}>
              <Paper className={classes.paperH}>
                <Typography className={classes.nadpisH}>Nastavení služby</Typography>
              </Paper>
              <Container className={classes.innerPadding} maxWidth="sm">
                <Grid container spacing={2}>      
                    <Grid item xs={12} sm={12}>                                              
                      <TextFields name="SluzbaNazev" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="SluzbaNazev" label="Název služby" required placeholder="Název služby" value={sluzby.SluzbyInfo.SluzbaNazev} onChange={onChangeValue} />
                    </Grid> 
                    <Grid item xs={12} sm={12}>                                              
                      <TextFields name="SluzbaKod" className={clsx(classes.textf,classes.margin)} type="text"  variant="outlined" size="small" fullWidth id="SluzbaKod" label="Kod služby" required placeholder="Kod služby" value={sluzby.SluzbyInfo.SluzbaKod} onChange={onChangeValue} />
                    </Grid> 
                    <Grid item xs={12} sm={12}> 
                      <TableContainer>
                        <Table className={classes.table} aria-label="caption table">
                          <TableBody>
                            <TableRow>
                              <TableCell className={classes.tableCell}  width="50px" align="left"><div className={classes.valueHeader}>{"Import"}</div></TableCell>
                              <TableCell className={classes.tableCell} width="50px" align="left"><GreenCheckbox checked={sluzby.SluzbyInfo.SluzbaImport} id="SluzbaImport" name="SluzbaImport" onChange={CheckBoxhandleChange} /></TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{"Export"}</div></TableCell>
                              <TableCell className={classes.tableCell} align="left"><GreenCheckbox checked={sluzby.SluzbyInfo.SluzbaExport} id="SluzbaExport" name="SluzbaExport"  onChange={CheckBoxhandleChange} /></TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{"Dokument"}</div></TableCell>
                              <TableCell className={classes.tableCell} align="left"><GreenCheckbox checked={sluzby.SluzbyInfo.Dokument} id="Dokument" name="Dokument" onChange={CheckBoxhandleChange} /></TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{"Balík"}</div></TableCell>
                              <TableCell className={classes.tableCell} align="left"><GreenCheckbox checked={sluzby.SluzbyInfo.Balik} id="Balik" name="Balik" onChange={CheckBoxhandleChange} /></TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{"Paleta"}</div></TableCell>
                              <TableCell className={classes.tableCell} align="left"><GreenCheckbox checked={sluzby.SluzbyInfo.Paleta} id="Paleta" name="Paleta" onChange={CheckBoxhandleChange} /></TableCell>
                            </TableRow>
                          </TableBody>     
                        </Table>
                      </TableContainer>
                    </Grid>
                  <Grid item xs={12} sm={4}> 
                      <GreenButton fullWidth onClick={handleCloseEdit} > Zrušit </GreenButton>
                  </Grid>
                  <Grid item xs={12} sm={4}/> 
                  <Grid item xs={12} sm={4}> 
                      <GreenButton fullWidth onClick={SubmitPostSluzby} startIcon={<SaveIcon />}> Uložit </GreenButton>
                  </Grid>
                </Grid>
            </Container>
          </div>
        </Fade>
    </Modal>  
</div>
  );
}


export default Sluzby