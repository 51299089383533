import React, { useContext,useEffect, useState } from 'react';
import {UserContext} from '../../../contexts/UserContext'
import Grid from '@material-ui/core/Grid';
import { makeStyles} from "@material-ui/core/styles";
import customConfig from '../../../customConfig.json'
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from '@material-ui/core/TableHead';




const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 200
  },
  tableRows: {
    height: '25px'
  },
  tableRow: {
    padding:"5px 15px 5px 25px",
    backgroundColor: customConfig.Colors.GridBackgroundColor,

  },
  bgGrid: {
    backgroundColor: customConfig.Colors.GridBackgroundColor,
    margin:'0px !important',
    padding:'0px !important',
    width:'100% !important'
  },
  tableHeadRow: {
    padding:"5px 15px 5px 25px",
    backgroundColor: customConfig.Colors.PrimaryColor,
    color: customConfig.Colors.ButtonTextColor
  },
  nadpis:{
    color: customConfig.Colors.PrimaryTextColor
  },
  left:{
    paddingLeft: '10px',
  },        
  }));


function Adresy(data){
  

  const classes = useStyles();
  
  const {getZasilkaAdresy} = useContext(UserContext);
  const [Adresy, setAdresy] = useState([]);

  useEffect(() => {

    if(data.data!==''&&data.data!==undefined)
    {
        // getZasilkaAdresy(data.data)
        // .then(oudData => setAdresy(oudData)
        // );   
        async function fetchData() {
          const dataOUD = await getZasilkaAdresy(data.data); 
          setAdresy(dataOUD) 
        }
        
        fetchData()



    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]) 
  
  return (
    <Grid spacing={2} container className={classes.bgGrid}>   
        <Grid item xs={12} sm={12} className={classes.bgGrid}>
            {(Adresy.Adresy!==[] && Adresy.Adresy!== undefined) && ( 
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeadRow} width="150px" align="left">Druh adresy</TableCell>
                            <TableCell className={classes.tableHeadRow} width="150px" align="left">Adresa</TableCell>
                            <TableCell className={classes.tableHeadRow} width="150px" align="left">Město</TableCell>
                            <TableCell className={classes.tableHeadRow} width="150px" align="left">PSČ</TableCell>
                            <TableCell className={classes.tableHeadRow} width="150px" align="left">Stát</TableCell>
                            <TableCell className={classes.tableHeadRow} width="150px" align="left">Kontaktní osoba</TableCell>
                            <TableCell className={classes.tableHeadRow} width="150px" align="left">Telefon</TableCell>
                            <TableCell className={classes.tableHeadRow} width="150px" align="left">Email</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Adresy.Adresy.map(row => {
                        return (
                            <TableRow key={row.AdresaID}  className={classes.tableRows}>
                                <TableCell className={classes.tableRow} width="150px">{row.DruhAdresy}</TableCell>  
                                <TableCell className={classes.tableRow} width="150px">{row.Adresa}</TableCell>  
                                <TableCell className={classes.tableRow} width="150px">{row.Mesto}</TableCell> 
                                <TableCell className={classes.tableRow} width="150px">{row.PSC}</TableCell>    
                                <TableCell className={classes.tableRow} width="150px">{row.StatNazev}</TableCell>   
                                <TableCell className={classes.tableRow} width="150px">{row.KonkaktniOsoba}</TableCell>   
                                <TableCell className={classes.tableRow} width="150px">{row.Telefon}</TableCell>   
                                <TableCell className={classes.tableRow} width="150px">{row.Email}</TableCell>                                  
                            </TableRow>
                        );
                        })}
                    </TableBody>
                </Table>  
            )}     
        </Grid>
    </Grid>
  );
}

export default Adresy


