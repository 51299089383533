import React, {useContext,useState, useEffect} from 'react';
import {UserContext} from '../../../contexts/UserContext'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles,withStyles, } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import customConfig from '../../../customConfig.json'
import { Alert } from '@material-ui/lab';
import Paper from '@material-ui/core/Paper';


const TextFields = withStyles({
    root: {
      '& label.Mui-focused': {
        color: customConfig.Colors.PrimaryColor,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: customConfig.Colors.PrimaryColor,
      },
      '& .MuiOutlinedInput-root': {
  
        '&:hover fieldset': {
          borderColor: customConfig.Colors.SecColor,
        },
        '&.Mui-focused fieldset': {
          borderColor: customConfig.Colors.PrimaryColor,
        },
      },
    },
  })(TextField);

function NovyKlient(){

    
    const initialState = {
        userInfo:{
            jmeno:'',
            prijmeni:'',
            IC:'',
            dic:'',
            loginemail:'',
            password:'',
            konEmail:'',
            phone:'',
            adresa:'',
            mesto:'',
            psc:'',
            stat:'CZ',
            firma:'',
        },
        errorMsg:'',
        successMsg:'',
        defaultStat:null,

    };        
      
    const useStyles = makeStyles((theme) => ({
        paper: {
          marginTop: theme.spacing(0),
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          alignItems: 'center',
          paddingBottom: theme.spacing(5),
        },
        margin: {
          margin: theme.spacing(0),
          padding:'25px'
        },
        paperError: {
          textAlign: 'center',
          display: 'flex',
          color: '#ff0000',
          padding:'5px 15px',
        },
        paperSuccess: {
          textAlign: 'center',
          display: 'flex',
          color: '#4e8c2d0',
          backgroundColor:"red",
          padding:'5px 15px',
        },
        paperH: {
          textAlign: 'center',
          backgroundColor: customConfig.Colors.PrimaryColor,
          boxShadow: 'none',
          border:'1px solid'+customConfig.Colors.PrimaryColor,
          borderRadius: '0px'
        },
        hide: {
          display: 'none',
        },      
        callContainer: {
          backgroundColor: 'none',
        },
        form: {
          width: '100%', // Fix IE 11 issue.
          marginTop: theme.spacing(1),
          backgroundColor: 'none',
        },
        withoutLabel: {
          marginTop: theme.spacing(1),
        },
        boxStyle:{
          marginTop:'15px'

        },
        nadpis:{
          marginTop:'15px'
        },
        textf: {
          padding: '0px 8px 0px 0px',
        },
        nadpisH:{
          color: customConfig.Colors.ButtonTextColor,
          fontSize:'20px',
          textAlign:'left',
          paddingLeft:'20px'
        },
      }));

      const GreenButton = withStyles((theme) => ({
        root: {
          color: 'white',
          backgroundColor: customConfig.Colors.PrimaryColor,
          '&:hover': {
            backgroundColor: customConfig.Colors.SecColor,
          },
        },
      }))(Button);

         
      
    const submitForm = async (event) => {
        
        event.preventDefault();
        
        setState({
            ...state,
            successMsg:'',
            errorMsg:''
        });

        const data = await ZalozitFirmu(state.userInfo);


        if(data.success){
            setState({
                ...initialState,
                successMsg:data.message,
            });
        }
        else{
            setState({
                ...state,
                successMsg:'',
                errorMsg:data.message
            });
        }
    }


    const DohledatIC = async (event) => {
        

        const data = await getAres(state.userInfo);
        if(data!==undefined){
            setState({
                ...state,
                userInfo:{
                    ...state.userInfo,
                    dic:data.Ares.DIC,
                    firma:data.Ares.OF,
                    psc:data.Ares.AA.PSC,
                    mesto:data.Ares.AA.N,
                    adresa:data.Ares.AA.NU + " " + data.Ares.AA.CD,

                },
                defaultStat: Staty.find(x => x.CisloStatu === data.Ares.AA.KS),
                errorMsg:''
            });
        }
        else{
            setState({
                ...state,
                errorMsg:"Zadané IČ se nepodařilo dohledat."
            });
        }

        
    }
        
    

    const {ZalozitFirmu,getStaty,getAres} = useContext(UserContext);
    const [Staty, setStaty] = useState([])    
    const [nameError, setNameError] = useState({});
    const [stat, setstat] = React.useState(false);

    useEffect(() => {
        document.title = "SEBAplus - Nový klient";

        async function fetchData() {
            const dataOUD = await getStaty(); 
            setStaty(dataOUD.theStaty.stat) 
        }
        
        fetchData()



        // getStaty()
        // .then(data =>
        //     setStaty(
        //         data.theStaty.stat)
        // ) 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])   


    function validovatPole(e) {

        if(e.target.value!=="" && e.target.value !== undefined && e.target.value !== null)
        {
          setNameError({
            ...nameError,
                ...nameError,
                [e.target.name]: false,
          });
    
        }
        else
        {
          setNameError({
            ...nameError,
                ...nameError,
                [e.target.name]: true,
          });
        }
      }

    useEffect(() => {
       setState({
        ...state,
        defaultStat: Staty.find(x => x.Kod === 'CZ')
        })

    // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [Staty])   


    const [state,setState] = useState(initialState);

    const onChangeValue = (e) => {
        setState({
            ...state,
            userInfo:{
                ...state.userInfo,
                [e.target.name]:e.target.value
            },
        });

    }
    const classes = useStyles();

    return(
        <Container component="main" maxWidth="lg" >
            <div className={classes.paper} >              
                <form onSubmit={submitForm} noValidate id="registrace">

                <FormControl className={classes.margin}>

                    <Grid container spacing={2} justify="center" alignItems="center">
                        
                        <Grid item xs={12} sm={2}/>   
                        <Grid item xs={12} sm={8}>                                     
                            {(state.errorMsg.length>0) && ( 
                                <Alert style={{alignItems:"center"}} severity="error">{state.errorMsg}</Alert>                            
                            )}                                                
                            {(state.successMsg.length>0) && ( 
                            <Alert style={{alignItems:"center"}} severity="success">{state.successMsg}</Alert>       
                            )}    
                            
                            
                        </Grid> 
                        <Grid item xs={12} sm={2}/>    

                        <Grid item xs={12} sm={12}>  
                            <Paper className={classes.paperH}>
                                <Typography className={classes.nadpisH}>Přihlašovací údaje</Typography>
                            </Paper>                                
                        </Grid>

                        <Grid item xs={12} sm={6}>                                              
                            <TextFields name="loginemail" className={clsx(classes.textf,classes.margin)} type="email" variant="outlined" size="small" fullWidth id="loginemail" label="Přihlašovací email" required placeholder="Přihlašovací email" value={state.userInfo.loginemail} onClick={(e) => {validovatPole(e)}} onChange={(e) => {validovatPole(e);onChangeValue(e)}} error ={(nameError.loginemail===undefined ? false : nameError.loginemail) ? true : false } />
                        </Grid>
                        <Grid item xs={12} sm={6}>                                              
                            <TextFields name="password" className={clsx(classes.textf,classes.margin)} type="password" margin="normal" variant="outlined" size="small" fullWidth id="password" label="Heslo" required placeholder="Heslo" value={state.userInfo.password} onClick={(e) => {validovatPole(e)}} onChange={(e) => {validovatPole(e);onChangeValue(e)}} error ={(nameError.password===undefined ? false : nameError.password) ? true : false } />
                        </Grid>



                        <Grid item xs={12} sm={12}>  
                            <Paper className={classes.paperH}>
                                <Typography className={classes.nadpisH}>Kontaktní údaje</Typography>
                            </Paper>                                       
                        </Grid>


                        <Grid item xs={12} sm={6}>                                              
                            <TextFields name="jmeno" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="jmeno" label="Jméno" required placeholder="Jméno" value={state.userInfo.jmeno} onClick={(e) => {validovatPole(e)}} onChange={(e) => {validovatPole(e);onChangeValue(e)}} error ={(nameError.jmeno===undefined ? false : nameError.jmeno) ? true : false } />
                        </Grid>
                        <Grid item xs={12} sm={6}>                                              
                            <TextFields name="prijmeni" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="prijmeni" label="Přijmení" required placeholder="Přijmení" value={state.userInfo.prijmeni} onClick={(e) => {validovatPole(e)}} onChange={(e) => {validovatPole(e);onChangeValue(e)}} error ={(nameError.prijmeni===undefined ? false : nameError.prijmeni) ? true : false } />
                        </Grid>

                        <Grid item xs={12} sm={6}>                                              
                            <TextFields name="phone" className={clsx(classes.textf,classes.margin)} type="tel" variant="outlined" size="small" fullWidth id="phone" label="Telefon" required placeholder="Telefon" value={state.userInfo.phone} onClick={(e) => {validovatPole(e)}} onChange={(e) => {validovatPole(e);onChangeValue(e)}} error ={(nameError.phone===undefined ? false : nameError.phone) ? true : false } />
                        </Grid>
                        <Grid item xs={12} sm={6}>                                              
                            <TextFields name="konEmail" className={clsx(classes.textf,classes.margin)} type="email" variant="outlined" size="small" fullWidth id="konEmail" label="Kontaktní email" required placeholder="Kontaktní email" value={state.userInfo.konEmail} onClick={(e) => {validovatPole(e)}} onChange={(e) => {validovatPole(e);onChangeValue(e)}} error ={(nameError.konEmail===undefined ? false : nameError.konEmail) ? true : false } />
                        </Grid>


                        <Grid item xs={12} sm={12}>  
                            <Paper className={classes.paperH}>
                                <Typography className={classes.nadpisH}>Základní údaje</Typography>
                            </Paper>                                         
                        </Grid>
                        
                        <Grid item xs={12} sm={10}>
                            <TextFields name="IC" 
                            className={clsx(classes.textf,classes.margin)}
                            type="text" 
                            variant="outlined"
                            size="small"
                            fullWidth
                            id="IC"
                            label="IČ"
                            required placeholder="IČ" value={state.userInfo.IC} onClick={(e) => {validovatPole(e)}} onChange={(e) => {validovatPole(e);onChangeValue(e)}} error ={(nameError.IC===undefined ? false : nameError.IC) ? true : false } />
                        </Grid>

                        <Grid item xs={12} sm={2}>
                            <GreenButton
                                fullWidth
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={DohledatIC}
                                >
                                    Dohledat údaje
                            </GreenButton>
                        </Grid>


                        <Grid item xs={12} sm={12}>                        
                            <TextFields name="firma" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="firma" label="Firma" required placeholder="Název firmy" value={state.userInfo.firma} onClick={(e) => {validovatPole(e)}} onChange={(e) => {validovatPole(e);onChangeValue(e)}} error ={(nameError.firma===undefined ? false : nameError.firma) ? true : false } />
                        </Grid>
                        

                        <Grid item xs={12} sm={6}>                                              
                            <TextFields name="dic" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="dic" label="DIČ" placeholder="DIČ" value={state.userInfo.dic} onClick={(e) => {validovatPole(e)}} onChange={(e) => {onChangeValue(e)}} />
                        </Grid>


                        <Grid item xs={12} sm={6}>                                              
                            <TextFields name="adresa" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="adresa" label="Adresa" required placeholder="Adresa" value={state.userInfo.adresa} onClick={(e) => {validovatPole(e)}} onChange={(e) => {validovatPole(e);onChangeValue(e)}} error ={(nameError.adresa===undefined ? false : nameError.adresa) ? true : false } />
                        </Grid>
                        <Grid item xs={12} sm={6}>                                              
                            <TextFields name="mesto" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="mesto" label="Město" required placeholder="Město" value={state.userInfo.mesto} onClick={(e) => {validovatPole(e)}} onChange={(e) => {validovatPole(e);onChangeValue(e)}} error ={(nameError.mesto===undefined ? false : nameError.mesto) ? true : false } />
                        </Grid>


                        <Grid item xs={12} sm={6}>                                              
                            <TextFields name="psc" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="psc" label="PSČ" required placeholder="PSČ" value={state.userInfo.psc} onClick={(e) => {validovatPole(e)}} onChange={(e) => {validovatPole(e);onChangeValue(e)}} error ={(nameError.psc===undefined ? false : nameError.psc) ? true : false } />
                        </Grid>

                        <Grid item xs={12} sm={6}> 

                        <Autocomplete
                            id="stat"
                            options={Staty}
                            getOptionLabel={(option) => option.Nazev ? option.Nazev : ''}                
                            fullWidth         
                            clearOnBlur 
                            value={state.defaultStat ? state.defaultStat : null}   
                            onChange={(event, newValue) => {                                
                              if (newValue) {                                     
                                setState({
                                    ...state,
                                    userInfo:{
                                        ...state.userInfo,
                                        stat:newValue.Kod
                                    },
                                    defaultStat:newValue
                                }); 
                                setstat(false)
                              }
                              else{        
                                setState({
                                    ...state,
                                    userInfo:{
                                        ...state.userInfo,
                                        stat:''
                                    },
                                    defaultStat:null
                                }); 
                                setstat(true)
                              }
                            }}   
                            renderOption={(option) => (
                              <React.Fragment>
                                  <span><img src={"../img/flags/24/"+option.Kod+".png"} alt={option.Kod}/></span>
                                  {option.Nazev}
                              </React.Fragment>
                            )}
                            renderInput={(params) => <TextFields {...params} label="Stát" size="small" error={stat} variant="outlined" />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}/>                                              
                        
                        

                        <Grid item xs={12} sm={4}/>   
                        <Grid item xs={12} sm={4}>                                              
                        <GreenButton
                                fullWidth
                                size="small"
                                variant="contained"
                                color="primary"
                                form="registrace"
                                type="submit">
                                    Vytvořit účet
                            </GreenButton>
                        </Grid>
                        <Grid item xs={12} sm={4}/>   


                    </Grid>

                </FormControl>
                </form>
            </div>
      </Container>  
    );
}

export default NovyKlient