import { makeStyles } from '@material-ui/core/styles';
import React, {useState, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import customConfig from '../../customConfig.json'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paperin: {
    height: 70,
    textAlign: 'center',
    backgroundColor: customConfig.Colors.textfieldsbackground,
    boxShadow: 'none',    
    border:'1px solid'+customConfig.Colors.PrimaryColor,
    borderRadius: '0px'
  },
  paperL: {
    height: 70,
    textAlign: 'center',
    backgroundColor: customConfig.Colors.textfieldsbackground,
    boxShadow: 'none',
    border:'1px solid'+customConfig.Colors.PrimaryColor,
    borderRadius: '0px'
  },
  paperR: {
    height: 70,
    textAlign: 'center',
    backgroundColor: customConfig.Colors.textfieldsbackground,
    boxShadow: 'none',
    border:'1px solid'+customConfig.Colors.PrimaryColor,
    borderRadius: '0px'
  },
  paperH: {
    textAlign: 'center',
    backgroundColor: customConfig.Colors.PrimaryColor,
    boxShadow: 'none',
    border:'1px solid'+customConfig.Colors.PrimaryColor,
    borderRadius: '0px'
  },
  nadpisH:{
    color: customConfig.Colors.ButtonTextColor,
    fontSize:'20px'
  },
  nadpis:{
    color: customConfig.Colors.PrimaryColor,
    fontSize:'20px'
  },
  hodnota:{
    color: customConfig.Colors.PrimaryTextColor,
    fontSize:'28px'
  },
  shad:{

    boxShadow:'0px 1px 3px #777777c4',
    border:'1px solid'+customConfig.Colors.PrimaryColor,
  },
}));

function Prehled(data){

    
  const classes = useStyles();


  const [souhrn, setSouhrn] = useState([{
    Nova:'-',
    Akceptovano:'-',
    NaCeste:'-',
    Doruceno:'-',
  }]) 


  useEffect(() => {
    if(data.data)
    {
      if(data.data.success===1){
        if(data.data.Souhrn!==undefined) 
        {
          setSouhrn(data.data.Souhrn);
        }
      }
    }  

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]) 

  
  //const souhrn = data.data.Souhrn;
  
  
  return (
    <Paper className={classes.shad}>
      <div>
          {(souhrn) && ( 
            
          <Grid>
            <Grid key={1} item item xs={12} sm={12}> 
              <Paper className={classes.paperH}>
                <Typography className={classes.nadpisH}>Přehled zásilek</Typography>
              </Paper>
            </Grid>
                
            <Grid container justify="center" item xs={12}>              
              <Grid key={1} xs={12} sm={3} item>
              <Paper className={classes.paperL}>
                  <Typography className={classes.nadpis}>Nová</Typography>
                  <Typography className={classes.hodnota}>{souhrn[0].Nova ? souhrn[0].Nova : '-'}</Typography>
                </Paper>
              </Grid>
              <Grid key={2} xs={12} sm={3} item>
                <Paper className={classes.paperin}>
                  <Typography className={classes.nadpis}>Akceptováno</Typography>
                  <Typography className={classes.hodnota}>{souhrn[0].Akceptovano ? souhrn[0].Akceptovano : '-'}</Typography>
                </Paper>
              </Grid>
              <Grid key={3} xs={12} sm={3} item>
                <Paper className={classes.paperin}>
                  <Typography className={classes.nadpis}>Na cestě</Typography>
                  <Typography className={classes.hodnota}>{souhrn[0].NaCeste ? souhrn[0].NaCeste : '-'}</Typography>
                </Paper>
              </Grid>
              <Grid key={4} xs={12} sm={3} item>
                <Paper className={classes.paperR}>
                  <Typography className={classes.nadpis}>Doručeno</Typography>
                  <Typography className={classes.hodnota}>{souhrn[0].Doruceno ? souhrn[0].Doruceno : '-'}</Typography>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
      )}   
    </div>
    </Paper>
  );






}

export default Prehled
