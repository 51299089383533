import React, {useContext,useState, useEffect} from 'react';
import { DataGrid } from '@material-ui/data-grid';
import {UserContext} from '../../contexts/UserContext'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import { makeStyles,withStyles, } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import customConfig from '../../customConfig.json'
import Autocomplete from '@material-ui/lab/Autocomplete';
import SaveIcon from '@material-ui/icons/Save';
import InputAdornment from "@material-ui/core/InputAdornment";
import AddIcon from '@material-ui/icons/Add';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as moment from 'moment'
import { useHistory } from "react-router-dom";
import Paper from '@material-ui/core/Paper';




const TextFields = withStyles({
  root: {
    '& label.Mui-focused': {
      color: customConfig.Colors.PrimaryColor,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: customConfig.Colors.PrimaryColor,
    },
    '& .MuiOutlinedInput-root': {

      '&:hover fieldset': {
        borderColor: customConfig.Colors.SecColor,
      },
      '&.Mui-focused fieldset': {
        borderColor: customConfig.Colors.PrimaryColor,
      },
    },
  },
})(TextField);




function Priplatky(){
  const {rootState} = useContext(UserContext);
  const {isAdmin} = rootState;



    const initialPriplatky = {
        PriplatkyInfo:{
            PriplatekID:'',
            SluzbaID:'',
            SluzbaNazev:'',            
            PriplatekNazev:'',
            SazbaProcenta:'',
            SazbaCastka:'',
            PlatnostOd:'',
            PlatnostDo:'',
            PrepravceID:'',
            PrepravceNazev:'',
            Popis:'',
        },
        defaultPrepravce: null,
        defaultSluzba: null,

    };    
    const useStyles = makeStyles((theme) => ({
        modal: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        paper: {
          backgroundColor: theme.palette.background.paper,
          border: customConfig.Colors.BorderColor,          
          boxShadow: theme.shadows[5],
          padding: '0px',
          borderRadius:'10px 10px 8px 8px',
          border:'none'
        },
        margin: {
          margin: theme.spacing(0),
        },
        hide: {
          display: 'none',
        },      
        callContainer: {
          backgroundColor: 'none',
        },
        form: {
          width: '100%', // Fix IE 11 issue.
          marginTop: theme.spacing(1),
          backgroundColor: 'none',
        },
        withoutLabel: {
          marginTop: theme.spacing(1),
        },
        boxStyle:{
          marginTop:'15px'

        },
        nadpis:{
          color: customConfig.Colors.PrimaryTextColor
        },
        left:{
          paddingLeft: '10px',
        },        
        textf: {
          padding: '0px 8px 0px 0px',
        },
        bgGrid: {
          backgroundColor: customConfig.Colors.GridBackgroundColor,
          padding:'0px !important',
          width:'100% !important',
          margin:'0px !important'
        },
        containerX: {
          padding:'0px !important',
          width:'100% !important',
          margin:'0px !important'
        },
        innerPadding:{
          padding: '20px',
        },  
        nadpisH:{
          color: customConfig.Colors.ButtonTextColor,
          fontSize:'20px'
        },
        paperH: {
          textAlign: 'left',
          paddingLeft:'20px',
          backgroundColor: customConfig.Colors.PrimaryColor,
          boxShadow: 'none',
          border:'1px',
          borderRadius: '8px 8px 0px 0px'
        },
          
        datag: {
          '& .super-app-theme--header': {
            backgroundColor: customConfig.Colors.PrimaryColor,
            color:customConfig.Colors.ButtonTextColor,
          },
          '& .MuiDataGrid-columnsContainer': {
            backgroundColor: customConfig.Colors.PrimaryColor,
            color:customConfig.Colors.ButtonTextColor,
          },
          '& .MuiDataGrid-row': {
            cursor:'pointer'
          },
          borderBlockColor:customConfig.Colors.PrimaryColor
        },

        
      }));



    const {getPriplatky,postPriplatky,getSluzby,delPriplatky,getPrepravci} = useContext(UserContext);
    const [Priplatky, setPriplatky] = useState([])    
    const [Sluzby, setSluzby] = useState([])  
    const [Prepravci, setPrepravci] = useState([])  
    const [priplatky,setState] = useState(initialPriplatky);  
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [openDel, setOpenDel] = React.useState(false);
    let history = useHistory();
  

    

    useEffect(() => {
      
      if(isAdmin!==true){
        history.push("/Home");
      }
      else 
      {
        document.title = "SEBAplus - Příplatky";

        async function fetchData() {
          const dataOUD = await getPriplatky(); 
          const dataOUD2 = await getPrepravci(); 
          const dataOUD3 = await getSluzby(); 
          setPriplatky(dataOUD) 
          setPrepravci(dataOUD2) 
          setSluzby(dataOUD3) 
      }
      
      fetchData()

        // getPriplatky()
        // .then(data =>
        //     setPriplatky(
        //         data)
        // ); 
        // getPrepravci()
        // .then(data =>
        //     setPrepravci(
        //         data)
        // );
        // getSluzby()
        // .then(data =>
        //     setSluzby(
        //         data)
        // );
      }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])   


    const GreenButton = withStyles((theme) => ({
        root: {
          color: customConfig.Colors.ButtonTextColor, 
          backgroundColor: customConfig.Colors.PrimaryColor,
          fontWeight:'520',
          '&:hover': {
            backgroundColor: customConfig.Colors.SecColor,
          },
        },
      }))(Button);

    const columns = [
        
    
        { field: 'PriplatekID', type: 'number', hide: true },
        { field: 'PriplatekNazev', type: 'string', headerClassName: 'super-app-theme--header', headerName: 'Název příplatku', width: 200, },
        { field: 'SluzbaNazev', type: 'string', headerClassName: 'super-app-theme--header', headerName: 'Název služby', width: 200, },
        { field: 'PrepravceNazev', type: 'string', headerClassName: 'super-app-theme--header', headerName: 'Název přepravce', width: 200, },
        { field: 'SazbaProcenta', type: 'number', headerClassName: 'super-app-theme--header', headerName: 'Sazba procenta', width: 150, disableColumnMenu:true, sortable: false,},
        { field: 'SazbaCastka', type: 'number', headerClassName: 'super-app-theme--header', headerName: 'Sazba částka', width: 150, disableColumnMenu:true, sortable: false,},
        {
            field: "PlatnostOd",
            headerName: "Platnost od",
            headerClassName: 'super-app-theme--header',
            disableColumnMenu:false, 
            sortable: true,
            width: 150,
            renderCell: (params) => {
                
                const api = params.api;
                const fields = api
                  .getAllColumns()
                  .map((c) => c.field)
                  .filter((c) => c !== "__check__" && !!c);
    
                const thisRow = {};
                fields.forEach((f) => {
                  thisRow[f] = params.getValue(f);
                });
                if(thisRow.PlatnostOd)
                {
                    return (moment(thisRow.PlatnostOd).format("DD.MM.YYYY"));

                }
                else
                {
                    return("");
                }
            }
        },
        {
            field: "PlatnostDo",
            headerName: "Platnost do",
            headerClassName: 'super-app-theme--header',
            disableColumnMenu:false, 
            sortable: true,
            width: 150,
            renderCell: (params) => {
                
                const api = params.api;
                const fields = api
                  .getAllColumns()
                  .map((c) => c.field)
                  .filter((c) => c !== "__check__" && !!c);
    
                const thisRow = {};
                fields.forEach((f) => {
                  thisRow[f] = params.getValue(f);
                });
                if(thisRow.PlatnostDo)
                {
                    return (moment(thisRow.PlatnostDo).format("DD.MM.YYYY"));

                }
                else
                {
                    return("");
                }
            }
        },
        {
            field: "del",
            headerName: "Smazání",
            sortable: false,
            headerClassName: 'super-app-theme--header',
            disableColumnMenu:true, 
            width: 90,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                const onClick = () => {
                const api = params.api;
                const fields = api
                  .getAllColumns()
                  .map((c) => c.field)
                  .filter((c) => c !== "__check__" && !!c);
    
                const thisRow = {};
                fields.forEach((f) => {
                  thisRow[f] = params.getValue(f);
                });

                setState({
                    ...priplatky,
                    PriplatkyInfo:{
                        ...priplatky.PriplatkyInfo,
                        PriplatekID:thisRow.PriplatekID                        
                    },
                })
                setOpenDel(true);
            }
            
                return (<IconButton onClick={onClick}><DeleteIcon className="fa fa-plus-circle" style={{ color: customConfig.Colors.PrimaryColor }}  /></IconButton>);
            }
        },
      ];

      
    const onChangeValue = (e) => {
        setState({
            ...priplatky,
            PriplatkyInfo:{
                ...priplatky.PriplatkyInfo,
                [e.target.name]:e.target.value
            },
        });        
    }

    const SubmitPostPriplatky = async (event) => {
        
        event.preventDefault();
        
        await postPriplatky(priplatky.PriplatkyInfo);

        // getPriplatky()
        // .then(data =>
        //     setPriplatky(
        //         data)
        // ); 

        async function fetchData() {
          const dataOUD = await getPriplatky(); 
          setPriplatky(dataOUD) 
        }
        
        fetchData()

        setState({
            ...priplatky,
                PriplatkyInfo:initialPriplatky.PriplatkyInfo
            },
        )
        setOpen(false);
    }

    const handleOpenNew = () => {
      setOpen(true);
      setState({
      ...priplatky,
          PriplatkyInfo:initialPriplatky.PriplatkyInfo,           
          defaultPrepravce:null,
          defaultSluzba:null,
        },
      )
    };
  
    const handleCloseNew = () => {
      setOpen(false);
      setState({
      ...priplatky,
          PriplatkyInfo:initialPriplatky.PriplatkyInfo,           
          defaultPrepravce:null,
          defaultSluzba:null,
        },
      )
    };

    const SubmitDelPriplatky = async (event) => {
        
        
        event.preventDefault();

        await delPriplatky(priplatky.PriplatkyInfo);
        async function fetchData() {
          const dataOUD = await getPriplatky(); 
          setPriplatky(dataOUD) 
        }
        
        fetchData()
        // getPriplatky()
        // .then(data =>
        //     setPriplatky(
        //         data)
        // ); 
        setOpenDel(false);
        setState({
            ...priplatky,
                PriplatkyInfo:initialPriplatky.PriplatkyInfo,                      
                defaultPrepravce:null,
                defaultSluzba:null,
            },
        )
    }

    const handleDelClose = () => {
        setOpenDel(false);
        setState({
        ...priplatky,
            PriplatkyInfo:initialPriplatky.PriplatkyInfo
          },
        )
    };

    function currentlySelected(v) {
      
        setState({
          ...priplatky,
          PriplatkyInfo:{
              ...priplatky.PriplatkyInfo,
              PriplatekID:v.PriplatekID,
              SluzbaID:v.SluzbaID !== null ? v.SluzbaID : '' ,
              SluzbaNazev:v.SluzbaNazev,
              PriplatekNazev:v.PriplatekNazev,
              SazbaProcenta:v.SazbaProcenta !== null ? v.SazbaProcenta : '',
              SazbaCastka:v.SazbaCastka !== null ? v.SazbaCastka : '',
              PlatnostOd:v.PlatnostOd !== null ? v.PlatnostOd : '' ,
              PlatnostDo:v.PlatnostDo !== null ? v.PlatnostDo : '',
              PrepravceID:v.PrepravceID !== null ? v.PrepravceID : '',
              PrepravceNazev:v.PrepravceNazev,
              Popis:v.Popis !== null ? v.Popis : '',
          },    
          defaultSluzba:Sluzby.find(x => x.SluzbaID === v.SluzbaID) !== undefined ? Sluzby.find(x => x.SluzbaID === v.SluzbaID) : null,     
          defaultPrepravce:Sluzby.find(x => x.PrepravceID === v.PrepravceID) !== undefined ? Prepravci.find(x => x.PrepravceID === v.PrepravceID) : null,   
      });   
      }
  return (


<div className={classes.bgGrid,classes.containerX}>
    <Grid container spacing={3} className={classes.bgGrid}> 
        <Grid item xs={12} sm={12} spacing={2} container className={classes.bgGrid}> 
            <div style={{ height: 400, width: '100%' }}>
                <DataGrid className={classes.datag} getRowId={(row) => row.PriplatekID} pageSize={25} rowHeight={35} rows={Priplatky} hideFooterPagination="true" hideFooterRowCount="true" hideFooter="true" hideFooterSelectedRowCount="true" columns={columns}
                  onRowSelected={(e) => currentlySelected(e.data)}                  
                />
            </div>
        </Grid>
        <Grid item xs={12} sm={12} spacing={2} container className={classes.bgGrid} direction="row" justify="space-between" alignItems="flex-start" >  

          <Grid item xs={12} sm={8}>                                           
              <TextFields name="PriplatekNazev" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="PriplatekNazev" label="Název" required placeholder="Název" value={priplatky.PriplatkyInfo.PriplatekNazev} onChange={onChangeValue} />
          </Grid>  

          <Grid item xs={12} sm={4}>                                              
              <TextFields  name="PlatnostOd" className={clsx(classes.textf,classes.margin)} type="date" InputLabelProps={{ shrink: true }} variant="outlined" size="small" fullWidth id="PlatnostOd" label="Platnost od" required value={priplatky.PriplatkyInfo.PlatnostOd} onChange={onChangeValue} />
          </Grid>     

          <Grid item xs={12} sm={4}>                                              
            <TextFields  
              name="SazbaProcenta" className={clsx(classes.textf,classes.margin)} type="number" variant="outlined" size="small" fullWidth id="SazbaProcenta" label="Procentuální sazba" placeholder="Procentuální sazba" InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}value={priplatky.PriplatkyInfo.SazbaProcenta} onChange={onChangeValue}
            />
          </Grid>  

                          
          <Grid item xs={12} sm={4}>                                              
              <TextFields name="SazbaCastka" className={clsx(classes.textf,classes.margin)} type="number" variant="outlined" size="small" fullWidth id="SazbaCastka" label="Příplatek částka" placeholder="Příplatek částka"  InputProps={{ endAdornment: <InputAdornment position="end">Kč</InputAdornment> }} value={priplatky.PriplatkyInfo.SazbaCastka} onChange={onChangeValue} />
          </Grid>  
          <Grid item xs={12} sm={4}>                                              
              <TextFields name="PlatnostDo" className={clsx(classes.textf,classes.margin)} type="date" InputLabelProps={{ shrink: true }} variant="outlined" size="small" fullWidth id="PlatnostDo" label="Platnost do" value={priplatky.PriplatkyInfo.PlatnostDo} onChange={onChangeValue} />
          </Grid>   
          
          <Grid item xs={12} sm={4} container justify="center"> 
            <Autocomplete
                id="sluzba"
                options={Sluzby}
                getOptionLabel={(option) => option.SluzbaNazev ? option.SluzbaNazev +' ('+option.PrepravceNazev+')' : ''}
                fullWidth
                clearOnBlur
                value={priplatky.defaultSluzba}       
                        
                onChange={(event, newValue) => {
                if (newValue) {                                  
                  setState({
                    ...priplatky,
                    PriplatkyInfo:{
                        ...priplatky.PriplatkyInfo,
                        SluzbaID:newValue.SluzbaID,    
                    },
                    defaultSluzba:newValue,
                  });
                }
                else{                 
                  setState({
                      ...priplatky,
                      PriplatkyInfo:{
                          ...priplatky.PriplatkyInfo,
                          SluzbaID: '',    
                      },
                      defaultSluzba:null,
                  });
                }
                }}                      
                renderOption={(option) => (
                <React.Fragment>
                    <span><img src={"../img/loga/"+option.PrepravceLogo} height="20px" alt={option.Kod}/></span>
                    <span className={classes.left}>{option.SluzbaNazev +' ('+option.PrepravceNazev+')'}</span>
                </React.Fragment>
                )}
                renderInput={(params) => <TextFields {...params} label="Služba" size="small" variant="outlined" />}
            />
          </Grid>
          <Grid item xs={12} sm={4} container justify="center"> 
              <Autocomplete
                  id="prepravce"
                  options={Prepravci}
                  getOptionLabel={(option) => option.Nazev ? option.Nazev : ''}
                  fullWidth        
                  value={ priplatky.defaultPrepravce}      
                  
                  onChange={(event, newValue) => {
                      if (newValue) {                                  
                        setState({
                          ...priplatky,
                          PriplatkyInfo:{
                              ...priplatky.PriplatkyInfo,
                              PrepravceID:newValue.PrepravceID,    
                          },
                          defaultPrepravce:newValue,
                        });
                      }
                      else{                 
                        setState({
                            ...priplatky,
                            PriplatkyInfo:{
                                ...priplatky.PriplatkyInfo,
                                PrepravceID: '',    
                            },
                            defaultPrepravce:null,
                        });
                      }
                  }}       
                  renderOption={(option) => (
                  <React.Fragment>
                      <span><img src={"../img/loga/"+option.Logo} height="20px" alt={option.Kod}/></span>
                      <span className={classes.left}>{option.Nazev}</span>
                  </React.Fragment>
                  )}
                  renderInput={(params) => <TextFields {...params} label="Přepravce" size="small" variant="outlined" />}
              />
          </Grid>
          
          <Grid item xs={12} sm={4}/>  
          <Grid item xs={12} sm={12}>                                              
              <TextFields name="Popis" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth multiline rows={3} id="Popis" label="Popis" value={priplatky.PriplatkyInfo.Popis} onChange={onChangeValue} />
          </Grid>  
        <Grid item xs={12} sm={2}  > 
              <GreenButton onClick={handleOpenNew} fullWidth startIcon={<AddIcon />}>Přidat příplatek</GreenButton>
        </Grid>
        <Grid item xs={12} sm={8} /> 
        <Grid item xs={12} sm={2}  > 
          <GreenButton fullWidth disabled={priplatky.PriplatkyInfo.PriplatekID.length>0?false:true}  onClick={SubmitPostPriplatky} startIcon={<SaveIcon />}>Uložit</GreenButton>
        </Grid>
      </Grid>


    </Grid>

    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        key="add"
        onClose={handleCloseNew}
        closeAfterTransition
        BackdropComponent={Backdrop}
    >
        <Fade in={open}>
            <div className={classes.paper}>
              <Paper className={classes.paperH}>
                <Typography className={classes.nadpisH}>Přidání nový příplatek</Typography>
              </Paper>
              <Container className={classes.innerPadding} maxWidth="sm">
                    <Grid container spacing={2}>                              
                        <Grid item xs={12} sm={12}>                                           
                          <TextFields name="PriplatekNazev" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="PriplatekNazev" label="Název" required placeholder="Název" value={priplatky.PriplatkyInfo.PriplatekNazev} onChange={onChangeValue} />
                        </Grid>  
   

                        <Grid item xs={12} sm={6}>                                              
                          <TextFields name="SazbaProcenta" className={clsx(classes.textf,classes.margin)} type="number" variant="outlined" size="small" fullWidth id="SazbaProcenta" label="Procentuální sazba" placeholder="Procentuální sazba" InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }} value={priplatky.PriplatkyInfo.HmotnostDo} onChange={onChangeValue}/>
                        </Grid>  

                        <Grid item xs={12} sm={6}>                                              
                            <TextFields  name="PlatnostOd" className={clsx(classes.textf,classes.margin)} type="date" InputLabelProps={{ shrink: true }} variant="outlined" size="small" fullWidth id="PlatnostOd" label="Platnost od" required value={priplatky.PriplatkyInfo.PlatnostOd} onChange={onChangeValue} />
                        </Grid>  
                                        
                        <Grid item xs={12} sm={6}>                                              
                            <TextFields name="SazbaCastka" className={clsx(classes.textf,classes.margin)} type="number" variant="outlined" size="small" fullWidth id="SazbaCastka" label="Příplatek částka" placeholder="Příplatek částka" InputProps={{ endAdornment: <InputAdornment position="end">Kč</InputAdornment> }} value={priplatky.PriplatkyInfo.SazbaCastka} onChange={onChangeValue} />
                        </Grid>  
                        <Grid item xs={12} sm={6}>                                              
                            <TextFields name="PlatnostDo" className={clsx(classes.textf,classes.margin)} type="date" InputLabelProps={{ shrink: true }} variant="outlined" size="small" fullWidth id="PlatnostDo" label="Platnost do" value={priplatky.PriplatkyInfo.PlatnostDo} onChange={onChangeValue} />
                        </Grid>   
                        
                        <Grid item xs={12} sm={6} container justify="center"> 
                          <Autocomplete
                              id="sluzba"
                              options={Sluzby}
                              getOptionLabel={(option) => option.SluzbaNazev ? option.SluzbaNazev +' ('+option.PrepravceNazev+')' : ''}
                              fullWidth                     
                              onChange={(event, newValue) => {
                              if (newValue) {                                  
                                  setState({
                                      ...priplatky,
                                      PriplatkyInfo:{
                                          ...priplatky.PriplatkyInfo,
                                          SluzbaID:newValue.SluzbaID
                                      },
                                  });
                              }
                              }}                                   
                              renderOption={(option) => (
                              <React.Fragment>
                                  <span><img src={"../img/loga/"+option.PrepravceLogo} height="20px" alt={option.Kod}/></span>
                                  <span className={classes.left}>{option.SluzbaNazev +' ('+option.PrepravceNazev+')'}</span>
                              </React.Fragment>
                              )}
                              renderInput={(params) => <TextFields {...params} label="Služba" size="small" variant="outlined" />}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} container justify="center"> 
                            <Autocomplete
                                id="prepravce"
                                options={Prepravci}
                                getOptionLabel={(option) => option.Nazev ? option.Nazev : ''}
                                fullWidth    
                                onChange={(event, newValue) => {
                                if (newValue) {                                  
                                    setState({
                                        ...priplatky,
                                        PriplatkyInfo:{
                                            ...priplatky.PriplatkyInfo,
                                            PrepravceID:newValue.PrepravceID
                                        },
                                    });
                                }
                                }}       
                                renderOption={(option) => (
                                <React.Fragment>
                                    <span><img src={"../img/loga/"+option.Logo} height="20px" alt={option.Kod}/></span>
                                    <span className={classes.left}>{option.Nazev}</span>
                                </React.Fragment>
                                )}
                                renderInput={(params) => <TextFields {...params} label="Přepravce" size="small" variant="outlined" />}
                                          />
                        </Grid>
                        
                        <Grid item xs={12} sm={4}/>  
                        <Grid item xs={12} sm={12}>                                              
                            <TextFields name="Popis" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth multiline rows={3} id="Popis" label="Popis" value={priplatky.PriplatkyInfo.Popis} onChange={onChangeValue} />
                        </Grid>  

                        <Grid item xs={12} sm={4}> 
                            <GreenButton fullWidth onClick={handleCloseNew} > Zrušit </GreenButton>
                        </Grid>
                        <Grid item xs={12} sm={4}/> 
                        <Grid item xs={12} sm={4}> 
                            <GreenButton fullWidth onClick={SubmitPostPriplatky} startIcon={<SaveIcon />}>Uložit</GreenButton>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </Fade>
    </Modal>  
    <Dialog
        open={openDel}
        onClose={handleDelClose}
        fullWidth
        maxWidth='xs'
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Opravdu si přejete smazat příplatek?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Grid container spacing={2}>      
                <Grid item xs={12} sm={4}> 
                    <GreenButton fullWidth onClick={handleDelClose}>Zrušit</GreenButton>
                </Grid>
                <Grid item xs={12} sm={4}/> 
                <Grid item xs={12} sm={4}> 
                  <GreenButton fullWidth onClick={SubmitDelPriplatky} startIcon={<DeleteIcon />}>Smazat</GreenButton>
                </Grid>
            </Grid>
          
        </DialogActions>
      </Dialog>

</div>
  );
}


export default Priplatky