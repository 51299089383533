import React, {useContext, useEffect, useState} from 'react'
import { useHistory} from 'react-router-dom'
import {UserContext} from '../../contexts/UserContext'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles,withStyles, } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Alert } from '@material-ui/lab';
import Link from '@material-ui/core/Link';

function ForgottenPassword(){

    const {kontrolaZmenyPW, restartHeslaMail,restartHeslaZmena } = useContext(UserContext);

    const useStyles = makeStyles((theme) => ({
      paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'none',
        alignItems: 'center',
      },
      paperError: {
        textAlign: 'center',
        display: 'flex',
        color: '#ff0000',
        padding:'5px 15px',
      },
      hide: {
        display: 'none',
      },      
      callContainer: {
        backgroundColor: 'none',
      },
      form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        backgroundColor: 'none',
      },
      boxStyle:{
        marginTop:'15px'
      },
      submit: {
        margin: theme.spacing(3, 0, 2),
      },
      textf: {
        backgroundColor:'white',
        
      },
    }));
    const GreenButton = withStyles((theme) => ({
      root: {
        color: 'white',
        backgroundColor: '#4e8c2d',
        '&:hover': {
          backgroundColor: '#70cc40',
        },
      },
    }))(Button);

    const initialState = {
        userInfo:{
            loginemail:'',
            gid:'',
            pw:'',
            pwCheck:'',
        },
        errorMsg:'',
        successMsg:'',
        PovolenaZmena:false,
    }

    const [state,setState] = useState(initialState);
    
    const onChangeValue = (e) => {
        setState({
            ...state,
            userInfo:{
                ...state.userInfo,
                [e.target.name]:e.target.value
            },
        });

    }
    
    // On Submit Login From
    const submitFormNEWPW = async (event) => {
        event.preventDefault();
        const data = await restartHeslaZmena(state.userInfo);
        if(data.success){
            setState({
                ...initialState,
                successMsg:'Heslo bylo změněno můžete se přihlásit. '
            });
        }
        else{
            setState({
                ...state,
                errorMsg:data.message
            });
        }
    }
    const submitFormMail = async (event) => {
        event.preventDefault();      
        const data = await restartHeslaMail(state.userInfo);
        if(data.success){
            setState({
                ...initialState,
                successMsg:'Na zadaný email byl zaslán email pro změnu hesla. '
            });
        }
        else{
            setState({
                ...state,
                errorMsg:data.message
            });
        }
    }
        
    useEffect(()  => {
      document.title = "SEBAplus - Zapomenuté heslo";
      

      async function fetchData() {
        const URLgid = new URLSearchParams(window.location.search).get("GID")
        
        if(URLgid !== null)
        {
          await kontrolaZmeny(URLgid)
        }  
      }
    
      fetchData()          

      // eslint-disable-next-line
    }, [])



    const kontrolaZmeny = async (URLgid) => {
      
      if(URLgid.length>0)
      {
        const data =  await kontrolaZmenyPW(URLgid)    
        
        if(data.success===1)
        {          
          setState({
              ...state,
              PovolenaZmena:true,              
              userInfo:{
                ...state.userInfo,
                gid:URLgid
              }
          })
        }
        else
        {          
          setState({
            ...state,
            errorMsg:data.message,
            PovolenaZmena:false,              
            userInfo:{
              ...state.userInfo,
              gid:URLgid
            }
          })
        }
      }


    }

    console.log(state);


    function Znovu() {
      history.push("/ForgottenPassword");
      window.location.reload();
    }

    let history = useHistory();
    const classes = useStyles();

    if(state.PovolenaZmena===true){
      return(
        <Container className={classes.classContainer} component="main" maxWidth="md">

          <div className={classes.paper}>
            <Grid container  direction="row" justify="space-between" alignItems="flex-start" style={{textAlign:"left"}} alignContent="center">

              <img src="../img/loga/LogoSEBAPlus.png" height="100px" alt="SEBA Logistics, s.r.o."/>

              <Grid item xs={12} sm={3}/>
              <Grid item xs={12} sm={6}>
                  <div style={{ visibility: (state.errorMsg === '' || state.errorMsg === undefined)  ? 'hidden': 'visible'}}>             
                      <Alert severity="error">{state.errorMsg}  Požádejte o novou změnu hesla.                             
                        <GreenButton size="small" variant="contained" color="primary" onClick={Znovu}>
                          Znovu zažádat o změnu hesla 
                        </GreenButton> 
                    </Alert>
                  </div>
                  
                  <div style={{ visibility: state.successMsg === '' ? 'hidden': 'visible'}}>                    
                  <Alert severity="success">{state.successMsg} 
                    <GreenButton size="small" variant="contained" color="primary" onClick={() => { history.push("/Login") }}>
                          Přihlásit se 
                    </GreenButton> 
                  </Alert>
                  </div> 
              </Grid>     
              <Grid item xs={12} sm={3}/>  
              <Grid item xs={12} sm={3}/>  
              <Grid item xs={12} sm={6}>
                <form name="resetPW" className={classes.form} onSubmit={submitFormNEWPW} noValidate>
                
                  <TextField 
                    name="pw" 
                    className={classes.textf}
                    type="password" 
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="pw"
                    label="Zadejte nové heslo"
                    error={state.userInfo.pw.length<8?true:false}
                    disabled={!state.PovolenaZmena}
                    autoFocus
                    required placeholder="Zadejte nové heslo" value={state.userInfo.pw} onChange={onChangeValue} 
                  />

                  <TextField 
                    name="pwCheck" 
                    className={classes.textf}
                    type="password" 
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    error={state.userInfo.pwCheck!==state.userInfo.pw?true:false||state.userInfo.pw.length<8?true:false}
                    id="pwCheck"
                    label="Kontrola hesla"
                    disabled={!state.PovolenaZmena}
                    autoFocus
                    required placeholder="Kontrola hesla"
                    value={state.userInfo.pwCheck} 
                    onChange={onChangeValue} 
                  />
                  
                  <GreenButton
                    classes={{
                      label: classes.btnlabel,
                    }}
                    fullWidth
                    disabled={!state.PovolenaZmena||state.userInfo.pwCheck!==state.userInfo.pw?true:false||state.userInfo.pw.length<8?true:false}
                    variant="contained"
                    color="primary"
                    type="submit">
                      Změnit heslo
                  </GreenButton>
                </form>
              </Grid>  
              <Grid item xs={12} sm={3}/>
              <Grid item xs={12} sm={3}/>
              <Grid item xs={12} sm={6}>
                <Link href="/Login" variant="body2">
                  Přihlásit se
                </Link>
              </Grid>     
              <Grid item xs={12} sm={3}/>                            
            </Grid>
          </div> 

        </Container>     
      )


    }
    else
    {
      return(
        <Container className={classes.classContainer} component="main" maxWidth="md">
              
              <div className={classes.paper}>
  
                <img src="../img/loga/LogoSEBAPlus.png" height="100px" alt="SEBA Logistics, s.r.o."/>


              
                <Grid container  direction="row" justify="space-between" alignItems="flex-start" style={{textAlign:"left"}} alignContent="center">
                                
                  <Grid item xs={12} sm={3}/>
                  <Grid item xs={12} sm={6}>
                    <div style={{ visibility: state.errorMsg === '' ? 'hidden': 'visible'}}>             
                        <Alert severity="error">{state.errorMsg}</Alert>
                    </div>                              
                    <div style={{ visibility: state.successMsg === '' ? 'hidden': 'visible'}}>                    
                      <Alert severity="success">{state.successMsg}</Alert>
                    </div> 
                  </Grid>    

                  <Grid item xs={12} sm={3}/>  
                  <Grid item xs={12} sm={3}/>  
                  <Grid item xs={12} sm={6}>
                    <form className={classes.form} onSubmit={submitFormMail} name="ZaslatMail" noValidate>                
                      <TextField name="loginemail" 
                        className={classes.textf}
                        type="email" 
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="loginemail"
                        label="Přihlašovací email"
                        autoFocus
                        required placeholder="Zadejte přihlašovací email" value={state.userInfo.loginemail} onChange={onChangeValue} />
                          
                      <GreenButton
                        classes={{label: classes.btnlabel}}
                        fullWidth
                        variant="contained"
                        color="primary"
                        type="submit">
                          Restartovat heslo
                      </GreenButton>
                    </form>
                  </Grid>  
                  <Grid item xs={12} sm={3}/> 
                  <Grid item xs={12} sm={3}/>
                  <Grid item xs={12} sm={6}>
                    <Link href="/Login" variant="body2">
                      Přihlásit se
                    </Link>
                  </Grid>     
                  <Grid item xs={12} sm={3}/>     

                </Grid>
              </div>
                  
          </Container>                     
      );
  }
}

export default ForgottenPassword;