import React, {useContext,useState, useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import customConfig from '../../../customConfig.json'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {UserContext} from '../../../contexts/UserContext'
import TextField from '@material-ui/core/TextField';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Container from '@material-ui/core/Container';
import SaveIcon from '@material-ui/icons/Save';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const TextFields = withStyles({
  root: {
    '& label.Mui-focused': {
      color: customConfig.Colors.PrimaryColor,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: customConfig.Colors.PrimaryColor,
    },
    '& .MuiOutlinedInput-root': {

      '&:hover fieldset': {
        borderColor: customConfig.Colors.SecColor,
      },
      '&.Mui-focused fieldset': {
        borderColor: customConfig.Colors.PrimaryColor,
      },
    },
  },
})(TextField);

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const GreenCheckbox = withStyles({
    root: {
      color: customConfig.Colors.PrimaryColor,
      '&$checked': {
        color: customConfig.Colors.PrimaryColor,
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

const GreenButton = withStyles((theme) => ({
    root: {
      color: customConfig.Colors.TextColor,
      backgroundColor: customConfig.Colors.PrimaryColor,
      '&:hover': {
        backgroundColor: customConfig.Colors.SecColor,
      },
    },
  }))(Button); 

const useStyles = makeStyles((theme) => ({

bgGrid: {
  backgroundColor: customConfig.Colors.GridBackgroundColor,
},
paperH: {
  textAlign: 'center',
  backgroundColor: customConfig.Colors.PrimaryColor,
  boxShadow: 'none',
  border:'1px solid'+customConfig.Colors.PrimaryColor,
  borderRadius: '0px'
},
nadpisH:{
  color: customConfig.Colors.ButtonTextColor,
  fontSize:'20px',
  textAlign:'left',
  paddingLeft:'20px'
},
modal: {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
},
paper: {
  backgroundColor: theme.palette.background.paper,
  border: customConfig.Colors.BorderColor,          
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
},
}));

export default function ZakladniInfo(dataUser) {
  const initialUser = {
    UserInfo:{
        UserID:'',
        FirmaID:'',
        Firma:'',
        IC:'',
        OsobaID:'',
        Jmeno:'',
        Prijmeni:'',
        DIC:'',
        Telefon:'',
        Email:'',
        IsCompany:'',
        IsActive:true,
        SendMailObjednavka:true,
        SendMailAccept:true,
        SendMailFaktura:true
    },
    AdresyInfo:{
        UserID:'',
        OsobaID:'',
        stat:'',
        AdresaID:'',
        Adresa:'',
        Adresa2:'',
        Adresa3:'',
        Mesto:'',
        psc:'',
        StatID:'',
        Nazev:'',
        Oznaceni:'',
        Email:'',
        Telefon:'',
        KonkaktniOsoba:'',
    },
    defaultStat: null,
    PristupoveUdaje:{
        UzivatelID:null,
        heslo:''
    },
  };   


  const classes = useStyles();
  const [User, setUser] = useState([])  
  const [Staty, setStaty] = useState([])  
  const {getUser,postUser,getStaty,postAdresy,HeslaZmena} = useContext(UserContext);
  const [user, setState] = useState(initialUser);
  const [nameError, setNameError] = useState({});
  const [open, setOpen] = React.useState(false);
  const [openPassword, setopenPassword] = React.useState(false);
  


  const CheckBoxhandleChange = (e) => {
    setState({
        ...user,
        UserInfo:{
            ...user.UserInfo,
            [e.target.name]: e.target.checked 
      },   
    });            
  };

  const handleOpenPassword = () => {
    setopenPassword(true);
  };

  const handleClosePassword = () => {
    setopenPassword(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const onChangeValue = (e) => {
    setState({
        ...user,
        UserInfo:{
            ...user.UserInfo,
            [e.target.name]:e.target.value
        },
    });
    }

    const onChangeValueAdresy = (e) => {
      setState({
          ...user,
          AdresyInfo:{
              ...user.AdresyInfo,
              [e.target.name]:e.target.value
          },
      });
    }

    const onChangeValueHeslo = (e) => {
      setState({
          ...user,
          PristupoveUdaje:{
              ...user.PristupoveUdaje,
              [e.target.name]:e.target.value
          },
      });
    }
  
    const NastavAdresy = async () => {        
        setState({
            ...user,
            AdresyInfo:{
                ...user.AdresyInfo,
                Email:user.UserInfo.Email,
                Telefon:user.UserInfo.Telefon,
                KonkaktniOsoba:user.UserInfo.Jmeno + ' ' + user.UserInfo.Prijmeni,
            },
        });

        return 1;

    }

    useEffect(() => {
        
        async function fetchData() {
          const dataOUD = await getStaty(); 
          setStaty(dataOUD.theStaty.stat) 
      }
      
      fetchData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])     


  
    useEffect(() => {

      async function fetchData() {
        const dataOUD = await getUser(dataUser.data); 
        setUser(dataOUD) 

      }      
      fetchData()

      
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataUser])     
    
    console.log(user)

  function validovatPole(e) {

    if(e.target.value!=="" && e.target.value !== undefined && e.target.value !== null)
    {
      setNameError({
        ...nameError,
            ...nameError,
            [e.target.name]: false,
      });

    }
    else
    {
      setNameError({
        ...nameError,
            ...nameError,
            [e.target.name]: true,
      });
    }
  }


  const SubmitEdit = async (event) => {   

    setOpen(false);
    
    event.preventDefault();

    const nastav = await NastavAdresy();

    await postUser(user.UserInfo);  
  
    if(user.UserInfo.IsCompany === "1" && nastav === 1)
    {
        await postAdresy(user.AdresyInfo);
    }    
  }  


  const SubmitZmenaHesla = async (event) => {   

    
    await HeslaZmena(user.PristupoveUdaje);

    setopenPassword(false);


  }  

  useEffect(() => {
      
    if(User.Adresa)
    {
        
        setState({
            ...user,
            AdresyInfo:{
                ...user.AdresyInfo,
                UserID:User.Adresa[0].UserID,
                OsobaID:User.Adresa[0].OsobaID,
                stat:User.Adresa[0].StatKod,
                AdresaID:User.Adresa[0].AdresaID,
                Adresa:User.Adresa[0].Adresa,
                Adresa2:User.Adresa[0].Adresa2,
                Adresa3:User.Adresa[0].Adresa3,
                Mesto:User.Adresa[0].Mesto,
                psc:User.Adresa[0].PSC,
                StatID:User.Adresa[0].StatID,
                Nazev:User.User[0].Firma,
                Oznaceni:User.User[0].Firma,
                Email:User.User[0].Email,
                Telefon:User.User[0].Telefon,
                KonkaktniOsoba:User.User[0].Jmeno+ ' ' + User.User[0].Prijmeni,
            },
            UserInfo:{
                ...user.UserInfo,
                UserID:User.User[0].UserID,
                IsCompany:User.User[0].IsCompany,
                FirmaID:User.User[0].FirmaID,
                Firma:User.User[0].Firma,
                IC:User.User[0].IC,
                OsobaID:User.User[0].OsobaID,
                Jmeno:User.User[0].Jmeno,
                Prijmeni:User.User[0].Prijmeni,
                DIC:User.User[0].DIC,
                Telefon:User.User[0].Telefon,
                Email:User.User[0].Email,
                IsActive:User.User[0].IsActive==='1'?true:false,
                SendMailObjednavka:User.User[0].SendMailObjednavka==='1'?true:false,
                SendMailAccept:User.User[0].SendMailAccept==='1'?true:false,
                SendMailFaktura:User.User[0].SendMailFaktura==='1'?true:false
            },
            defaultStat: Staty.find(x => x.Kod === User.Adresa[0].StatKod) !== undefined ? Staty.find(x => x.Kod === User.Adresa[0].StatKod) : null,     
            PristupoveUdaje:{
                ...user.PristupoveUdaje,
                UzivatelID:User.User[0].UserID,
                heslo:'',
                HesloCheck:'',
            },

        });     
    }
    else if(User.User)
    {        
        setState({
            ...user,
            UserInfo:{
                ...user.UserInfo,
                UserID:User.User[0].UserID,
                IsCompany:User.User[0].IsCompany,
                FirmaID:User.User[0].FirmaID,
                Firma:User.User[0].Firma,
                IC:User.User[0].IC,
                OsobaID:User.User[0].OsobaID,
                Jmeno:User.User[0].Jmeno,
                Prijmeni:User.User[0].Prijmeni,
                DIC:User.User[0].DIC,
                Telefon:User.User[0].Telefon,
                Email:User.User[0].Email,
                IsActive:User.User[0].IsActive==='1'?true:false,
                SendMailObjednavka:User.User[0].SendMailObjednavka==='1'?true:false,
                SendMailAccept:User.User[0].SendMailAccept==='1'?true:false,
                SendMailFaktura:User.User[0].SendMailFaktura==='1'?true:false
            },     
            PristupoveUdaje:{
                ...user.PristupoveUdaje,
                UzivatelID:User.User[0].UserID,
                heslo:'',
                HesloCheck:'',
            },
        });     
    }      
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [User])   

  return (
    <Container component="main" maxWidth="md" >
        <Grid container spacing={2} className={classes.bgGrid}>         
            <Grid item xs={12} sm={12}> 
                <Paper className={classes.paperH}>
                    <Typography className={classes.nadpisH}>Základní údaje</Typography>
                </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>                                              
                <TextFields name="Jmeno" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Jmeno" label="Jméno" required placeholder="Jméno" value={user.UserInfo.Jmeno || ''} onClick={(e) => {validovatPole(e)}} onChange={(e) => {validovatPole(e);onChangeValue(e)}} error ={(nameError.Jmeno===undefined ? false : nameError.Jmeno) ? true : false } />
            </Grid>
            <Grid item xs={12} sm={6}>                                              
                <TextFields name="Prijmeni" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Prijmeni" label="Přijmení" required placeholder="Přijmení" value={user.UserInfo.Prijmeni || ''} onClick={(e) => {validovatPole(e)}} onChange={(e) => {validovatPole(e);onChangeValue(e)}} error ={(nameError.Prijmeni===undefined ? false : nameError.Prijmeni) ? true : false } />
            </Grid>

            <Grid item xs={12} sm={6}>                                              
                <TextFields name="Telefon" className={clsx(classes.textf,classes.margin)} type="tel" variant="outlined" size="small" fullWidth id="Telefon" label="Telefon" required placeholder="Telefon" value={user.UserInfo.Telefon || ''} onClick={(e) => {validovatPole(e)}} onChange={(e) => {validovatPole(e);onChangeValue(e)}} error ={(nameError.Telefon===undefined ? false : nameError.Telefon) ? true : false } />
            </Grid>
            <Grid item xs={12} sm={6}>                                              
                <TextFields name="Email" className={clsx(classes.textf,classes.margin)} type="email" variant="outlined" size="small" fullWidth id="Email" label="Kontaktní email" required placeholder="Kontaktní email" value={user.UserInfo.Email || ''} onClick={(e) => {validovatPole(e)}} onChange={(e) => {validovatPole(e);onChangeValue(e)}} error ={(nameError.Email===undefined ? false : nameError.Email) ? true : false } />
            </Grid>
            <Grid item xs={12} sm={6}>     
              <Typography className={classes.typo}>
                <GreenCheckbox
                  checked={user.UserInfo.IsActive}
                  onChange={(e) => {CheckBoxhandleChange(e);}}
                  id="IsActive"
                  key="IsActive"
                  name="IsActive"
                  color="primary"
                    />
                Aktivní                                        
              </Typography>
                  
            </Grid>
            <Grid item xs={10} sm={12}> 
              <Typography className={classes.typo}>
                <GreenCheckbox
                  checked={user.UserInfo.SendMailObjednavka}
                  onChange={(e) => {CheckBoxhandleChange(e);}}
                  id="SendMailObjednavka"
                  key="SendMailObjednavka"
                  name="SendMailObjednavka"
                  color="primary"
                />          
                Email - Objednávka                            
              </Typography>
            </Grid>
            <Grid item xs={10} sm={12}> 
              <Typography className={classes.typo}>
                <GreenCheckbox
                  checked={user.UserInfo.SendMailAccept}
                  onChange={(e) => {CheckBoxhandleChange(e);}}
                  id="SendMailAccept"
                  key="SendMailAccept"
                  name="SendMailAccept"
                  color="primary"
                />        
                Email - Akceptace objednávky                              
              </Typography>
            </Grid>
            <Grid item xs={10} sm={12}> 
              <Typography className={classes.typo}>
                <GreenCheckbox
                  checked={user.UserInfo.SendMailFaktura}
                  onChange={(e) => {CheckBoxhandleChange(e);}}
                  id="SendMailFaktura"
                  key="SendMailFaktura"
                  name="SendMailFaktura"
                  color="primary"
                />       
                Email - Faktura                               
              </Typography>
            </Grid>


        </Grid>

        <Grid container spacing={2} className={classes.bgGrid}>
            <Grid item xs={12} sm={12}> 
                <Paper className={classes.paperH}>
                    <Typography className={classes.nadpisH}>Firemní údaje</Typography>
                </Paper>
            </Grid>

            <Grid item xs={10} sm={6}> 
                <TextFields name="Firma" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined"size="small" fullWidth id="Firma" label="Název firmy" required placeholder="Název firmy" value={user.UserInfo.Firma || ''} onClick={(e) => {validovatPole(e)}} onChange={(e) => {validovatPole(e);onChangeValue(e)}} error ={(nameError.Firma===undefined ? false : nameError.Firma) ? true : false } />
            </Grid>

            <Grid item xs={10} sm={3}> 
                <TextFields name="IC" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined"size="small" fullWidth id="IC" label="IČ" required placeholder="IČ" value={user.UserInfo.IC || ''} onClick={(e) => {validovatPole(e)}} onChange={(e) => {validovatPole(e);onChangeValue(e)}} error ={(nameError.IC===undefined ? false : nameError.IC) ? true : false } />
            </Grid>

            <Grid item xs={10} sm={3}> 
                <TextFields name="DIC" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined"size="small" fullWidth id="DIC" label="DIČ" placeholder="DIČ" value={user.UserInfo.DIC || ''} onClick={(e) => {validovatPole(e)}} onChange={(e) => {validovatPole(e);onChangeValue(e)}} />
            </Grid>



            <Grid item xs={12} sm={12}> 
                <Paper className={classes.paperH}>
                    <Typography className={classes.nadpisH}>Adresa</Typography>
                </Paper>
            </Grid>      

            <Grid item xs={12} sm={6}> 
                <TextFields name="Adresa" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Adresa" label="Adresa" required placeholder="Adresa" value={user.AdresyInfo.Adresa || ''} onChange={onChangeValueAdresy} />
            </Grid>
            <Grid item xs={12} sm={6}> 
                <TextFields name="Adresa2" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Adresa2" label="Adresa 2" placeholder="Adresa 2" value={user.AdresyInfo.Adresa2 || ''} onChange={onChangeValueAdresy} />
            </Grid>
            <Grid item xs={12} sm={6}> 
                <TextFields name="Adresa3" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Adresa3" label="Adresa 3" placeholder="Adresa 3" value={user.AdresyInfo.Adresa3 || ''} onChange={onChangeValueAdresy} />
            </Grid>
            <Grid item xs={12} sm={6}> 
                <TextFields name="Mesto" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Mesto" label="Město" required placeholder="Město" value={user.AdresyInfo.Mesto || ''} onChange={onChangeValueAdresy} />
            </Grid>
            <Grid item xs={12} sm={6}> 
                <TextFields name="psc" className={clsx(classes.textf,classes.margin)} type="number" variant="outlined" size="small" fullWidth id="psc" label="PSČ" required placeholder="PSČ" value={user.AdresyInfo.psc || ''} onChange={onChangeValueAdresy} />
            </Grid>   
            <Grid item xs={12} sm={6} container justify="center"> 
                <Autocomplete
                    id="stat"
                    options={Staty}
                    getOptionLabel={(option) => option.Nazev ? option.Nazev : ''}
                    fullWidth
                    value={user.defaultStat}
                    getOptionSelected={(option, value) => option.Kod === value.Kod}                          
                    onChange={(event, newValue) => {
                    if (newValue) {                                  
                        setState({
                            ...user,
                            AdresyInfo:{
                                ...user.AdresyInfo,
                                stat:newValue.Kod
                            },
                            defaultStat:newValue,
                        });
                    }
                    }}       
                    renderOption={(option) => (
                    <React.Fragment>
                        <span><img src={"../img/flags/24/"+option.Kod+".png"} alt={option.Kod}/></span>
                        {option.Nazev}
                    </React.Fragment>
                    )}
                    renderInput={(params) => <TextFields {...params} label="Stát" size="small" variant="outlined" />}
                />
            </Grid>    
        </Grid>    

        <Grid container spacing={2} className={classes.bgGrid}>         

          <Grid item xs={12} sm={2}>                                              
            <GreenButton fullWidth onClick={(e) => handleOpenPassword()}>Změnit heslo</GreenButton>
          </Grid>
          <Grid item xs={12} sm={8}/>       
          <Grid item xs={12} sm={2}>                                              
            <GreenButton fullWidth onClick={(e) => SubmitEdit(e)}  startIcon={<SaveIcon />} >Uložit</GreenButton>
          </Grid>
        </Grid>


        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">Záznam uložen</Alert>
      </Snackbar>


      <Dialog
        open={openPassword}
        onClose={(e) => handleClosePassword()}
      >
        <DialogTitle>{"Změnit uživatelské heslo"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Změnit uživatelského hesla.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>      
              <Grid item xs={12} sm={6}>
                <TextFields name="heslo" className={clsx(classes.textf,classes.margin)} type="password" variant="outlined" size="small" fullWidth id="heslo" label="Heslo" required placeholder="Heslo" value={user.PristupoveUdaje.heslo} onChange={onChangeValueHeslo} 
                  error ={user.PristupoveUdaje.heslo<8?true:false || user.PristupoveUdaje.heslo!==user.PristupoveUdaje.HesloCheck?true:false }
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextFields name="HesloCheck" className={clsx(classes.textf,classes.margin)} type="password" variant="outlined" size="small" fullWidth id="HesloCheck" label="Kontrola hesla" required placeholder="Kontrola hesla" value={user.PristupoveUdaje.HesloCheck} onChange={onChangeValueHeslo} 
                error ={user.PristupoveUdaje.heslo<8?true:false || user.PristupoveUdaje.heslo!==user.PristupoveUdaje.HesloCheck?true:false }
                />
              </Grid>

              <Grid item xs={12} sm={4}> 
                  <GreenButton fullWidth onClick={(e) => handleClosePassword()}>Zrušit</GreenButton>
              </Grid>
              <Grid item xs={12} sm={4}/> 
              <Grid item xs={12} sm={4}> 
                <GreenButton fullWidth disabled={user.PristupoveUdaje.heslo<8?true:false || user.PristupoveUdaje.heslo!==user.PristupoveUdaje.HesloCheck?true:false } startIcon={<SaveIcon />} onClick={(e) => SubmitZmenaHesla(e)}>Uložit</GreenButton>
              </Grid>
          </Grid>          
        </DialogActions>
      </Dialog>

    </Container>  
  );
}
