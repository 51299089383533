import React, {useContext,useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import customConfig from '../../customConfig.json'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {UserContext} from '../../contexts/UserContext'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Pristupy from '../Klient/Pristupy/Pristupy'
import ZakladniInfo from '../Klient/ZakladniInfo/ZakladniInfo'
import Sluzby from '../Klient/Sluzby/Sluzby'
import Slevy from '../Klient/Slevy/Slevy'
import NovyKlient from '../Klient/NovyKlient/NovyKlient'
import { Grid } from '@material-ui/core';
import { useHistory } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{width: "auto"}}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    flexDirection:"row",
    justifyContent: 'space-between',
    width:'auto',
    backgroundColor: customConfig.Colors.PrimaryColor,
    '& > span': {
      width: '100%',
      backgroundColor: customConfig.Colors.PrimaryColor,
    },
    '@media screen and (max-width:1400px)' : {
      flexDirection:"column"
    }
  },
})((props) => <Tabs {...props} style={{width: "auto"}} TabIndicatorProps={{ children: <span /> }} />);

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },  
  appBar:{
    padding:'0px',
    color:customConfig.Colors.PrimaryColor,
    backgroundColor:customConfig.Colors.PrimaryColor,
    flexDirection:"row",
    '@media screen and (max-width:1400px)' : {
      flexDirection:"column"
    }
    
    
},
tab:{
    
    color: customConfig.Colors.TextColor,
    backgroundColor:customConfig.Colors.PrimaryColor,
    '&.Mui-selected': {
        backgroundColor: customConfig.Colors.SecColor,
        color: customConfig.Colors.TextColor,
        
    },
    '&:hover': {
       backgroundColor: customConfig.Colors.SecColor,
       color: customConfig.Colors.TextColor,
       opacity: 1,
    },       
},
tf:{
  backgroundColor: customConfig.Colors.textfieldsbackground,
  border: customConfig.Colors.BorderColor,
  borderRadius: '5px'
},
bgGrid: {
  backgroundColor: customConfig.Colors.GridBackgroundColor,
  padding:'0px !important',
  width:'100% !important',
  margin:'0px !important'
},
containerX: {
  padding:'0px !important',
  width:'100% !important',
  margin:'0px !important',
},
}));

export default function Klient() {

  const {rootState} = useContext(UserContext);
  const {isAdmin} = rootState;

  const initialFirmy = {
    FirmyInfo:{
        UserID:'',
        Firma:'',
        IC:'',            
        OsobaID:'',
    },
    defaultFirma: null,

  };   
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [Frirmy, setFrirmy] = useState([])  
  const {getFirmy} = useContext(UserContext);
  const [firma, setFirma] = useState(initialFirmy);
  let history = useHistory();

  
    useEffect(() => {
      if(isAdmin!==true){
        history.push("/Home");
      }
      else 
      {
        document.title = "SEBAplus - Zákazníci";
        async function fetchData() {
          const dataOUD = await getFirmy(); 
          setFrirmy(dataOUD) 
        }      
        fetchData()
      }
      
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])   

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.bgGrid,classes.containerX}>
      <Grid container spacing={2} className={classes.bgGrid}>
        <AppBar className={classes.appBar} position="relative" style={{alignItems:'center'}}>        
          <Autocomplete
            id="Firmax"
            options={Frirmy}
            getOptionLabel={(option) => option.Firma ? option.Firma +' ('+option.IC+')' : ''}                       
            value={firma.defaultFirma}       
            style={{ width: 300, marginRight:20, marginLeft:20}}
            getOptionSelected={(option, value) => option.UserID === value.UserID}         
            onChange={(event, newValue) => {
              if (newValue) {                                  
                setFirma({
                  ...firma,
                  FirmyInfo:{
                      ...firma.FirmyInfo,
                      UserID:newValue.UserID,    
                  },
                  defaultFirma:newValue,
                });
              }
              else{                 
                setFirma({
                  ...firma,
                  FirmyInfo:{
                      ...firma.FirmyInfo,
                      UserID: '',    
                    },
                    defaultFirma:null,
                });
              }
              }}                                      
            renderOption={(option) => (
            <React.Fragment>
                <span className={classes.left}>{option.Firma +' ('+option.IC+')'}</span>
            </React.Fragment>
            )}
            renderInput={(params) => <TextField {...params} className={classes.tf} size="small" variant="outlined" />}
          />        
          <StyledTabs
              value={value} 
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              centered
              aria-label="scrollable force tabs example"
          >
            <Tab className={classes.tab} label="Informace" {...a11yProps(0)} />
            <Tab className={classes.tab} label="Přístupy" {...a11yProps(1)} />
            <Tab className={classes.tab} label="Služby" {...a11yProps(2)} />
            <Tab className={classes.tab} label="Slevy" {...a11yProps(3)} />
            <Tab className={classes.tab} label="Nový klient" {...a11yProps(4)} />
          </StyledTabs>

        </AppBar>      
        <div>
          <TabPanel value={value} index={0}>
            {(firma.FirmyInfo.UserID !== '') && ( 

            <ZakladniInfo data={firma.FirmyInfo.UserID} />

            )}

          </TabPanel>
          <TabPanel value={value} index={1}>
            <Pristupy data={firma.FirmyInfo.UserID} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Sluzby data={firma.FirmyInfo.UserID} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Slevy data={firma.FirmyInfo.UserID} />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <NovyKlient/>
          </TabPanel>
        </div>
      </Grid>
    </div>
  );
}
