import React, {useContext,useState, useEffect} from 'react';
import { DataGrid } from '@material-ui/data-grid';
import {UserContext} from '../../../contexts/UserContext'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import { makeStyles,withStyles, } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import customConfig from '../../../customConfig.json'
import Autocomplete from '@material-ui/lab/Autocomplete';
import SaveIcon from '@material-ui/icons/Save';
import InputAdornment from "@material-ui/core/InputAdornment";
import AddIcon from '@material-ui/icons/Add';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';

const TextFields = withStyles({
  root: {
    '& label.Mui-focused': {
      color: customConfig.Colors.PrimaryColor,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: customConfig.Colors.PrimaryColor,
    },
    '& .MuiOutlinedInput-root': {

      '&:hover fieldset': {
        borderColor: customConfig.Colors.SecColor,
      },
      '&.Mui-focused fieldset': {
        borderColor: customConfig.Colors.PrimaryColor,
      },
    },
  },
})(TextField);


  const GreenButton = withStyles((theme) => ({
    root: {
      color: customConfig.Colors.ButtonTextColor, 
      backgroundColor: customConfig.Colors.PrimaryColor,
      fontWeight:'520',
      '&:hover': {
        backgroundColor: customConfig.Colors.SecColor,
      },
    },
  }))(Button);


function Slevy(data){


    const initialSlevy = {
        SlevyInfo:{
            SlevaID:'',
            SluzbaID:'',
            SluzbaNazev:'',            
            SluzbaImport:'',
            SluzbaExport:'',
            Dokument:'',
            Paleta:'',
            Balik:'',
            Sleva:'',
            UserID:'',
            IsDefault:0,
            HmotnostOd:'',
            HmotnostDo:'',
        },
    };    
    const useStyles = makeStyles((theme) => ({
        modal: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        paper: {
          backgroundColor: theme.palette.background.paper,
          border: customConfig.Colors.BorderColor,          
          boxShadow: theme.shadows[5],
          padding: '0px',
          borderRadius:'10px 10px 8px 8px',
          border:'none'
        },
        margin: {
          margin: theme.spacing(0),
        },
        innerPadding:{
          padding: '20px',
      },        
      nadpisH:{
          color: customConfig.Colors.ButtonTextColor,
          fontSize:'20px'
      },
      paperH: {
          textAlign: 'left',
          paddingLeft:'20px',
          backgroundColor: customConfig.Colors.PrimaryColor,
          boxShadow: 'none',
          border:'1px',
          borderRadius: '8px 8px 0px 0px'
      },        
        hide: {
          display: 'none',
        },      
        callContainer: {
          backgroundColor: 'none',
        },
        form: {
          width: '100%', // Fix IE 11 issue.
          marginTop: theme.spacing(1),
          backgroundColor: 'none',
        },
        withoutLabel: {
          marginTop: theme.spacing(1),
        },
        boxStyle:{
          marginTop:'15px'

        },
        nadpis:{
          color: customConfig.Colors.PrimaryTextColor
        },
        left:{
          paddingLeft: '10px',
        },        
        textf: {
          padding: '0px 8px 0px 0px',
        },
        bgGrid: {
          backgroundColor: customConfig.Colors.GridBackgroundColor,
        },
        containerX: {
          width:'100%',
          paddingRight:theme.spacing(1),
        },
        datag: {
          '& .super-app-theme--header': {
            backgroundColor: customConfig.Colors.PrimaryColor,
            color:customConfig.Colors.ButtonTextColor,
          },
          '& .MuiDataGrid-columnsContainer': {
            backgroundColor: customConfig.Colors.PrimaryColor,
            color:customConfig.Colors.ButtonTextColor,
          },
          '& .MuiDataGrid-row': {
            cursor:'pointer'
          },
          borderBlockColor:customConfig.Colors.PrimaryColor
        },

        
      }));



    const {getKlientSlevy,postSlevy,getSluzby,delSlevy} = useContext(UserContext);
    const [Slevy, setSlevy] = useState([])    
    const [Sluzby, setSluzby] = useState([])  
    const [slevy,setState] = useState(initialSlevy);  
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [openDel, setOpenDel] = React.useState(false);


    useEffect(() => {
        
      async function fetchData() {
        const dataOUD = await getKlientSlevy(data.data); 
        setSlevy(dataOUD) 
      }
      
      fetchData()


        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [data]) 
      



    useEffect(() => {
        document.title = "SEBAplus - Slevy";

        async function fetchData() {
          const dataOUD = await getSluzby(); 
          setSluzby(dataOUD) 
        }        
        fetchData()  

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])   



    const columns = [
        
    
        { field: 'SlevaID', type: 'number', hide: true },
        { field: 'SluzbaNazev', type: 'string', headerClassName: 'super-app-theme--header', headerName: 'Název služby', width: 300, },
        { field: 'UserID', type: 'number', hide: true },    
        { field: 'HmotnostOd', type: 'number', headerClassName: 'super-app-theme--header', headerName: 'Hmotnost od', width: 120,disableColumnMenu:true, sortable: false,},    
        { field: 'HmotnostDo', type: 'number', headerClassName: 'super-app-theme--header', headerName: 'Hmotnost do', width: 120, disableColumnMenu:true, sortable: false,},
        { field: 'Sleva', type: 'number', headerClassName: 'super-app-theme--header', headerName: 'Selva', width: 110, disableColumnMenu:true, sortable: false,},
        {
            field: "del",
            headerName: "Smazání",
            sortable: false,
            headerClassName: 'super-app-theme--header',
            disableColumnMenu:true, 
            width: 90,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                const onClick = () => {
                const api = params.api;
                const fields = api
                  .getAllColumns()
                  .map((c) => c.field)
                  .filter((c) => c !== "__check__" && !!c);
    
                const thisRow = {};
                fields.forEach((f) => {
                  thisRow[f] = params.getValue(f);
                });

                setState({
                    ...slevy,
                    SlevyInfo:{
                        ...slevy.SlevyInfo,
                        SlevaID:thisRow.SlevaID                        
                    },
                })
                setOpenDel(true);
            }
            
                return (<IconButton onClick={onClick}><DeleteIcon className="fa fa-plus-circle" style={{ color: customConfig.Colors.PrimaryColor }}  /></IconButton>);
            }
        },

      ];

      
    const onChangeValue = (e) => {
        setState({
            ...slevy,
            SlevyInfo:{
                ...slevy.SlevyInfo,
                [e.target.name]:e.target.value
            },
        });        
    }

    const SubmitPostSlevy = async (event) => {
        
        event.preventDefault();

      await postSlevy(slevy.SlevyInfo);
        
      async function fetchData() {
        const dataOUD = await getKlientSlevy(data.data); 
        setSlevy(dataOUD) 
      }
      
      fetchData()

        setState({
            ...slevy,
                SlevyInfo:initialSlevy.SlevyInfo
            },
        )
        setOpen(false);
    }

    const handleOpenNew = () => {
      setOpen(true);
      setState({
      ...slevy,
          SlevyInfo:initialSlevy.SlevyInfo
        },
      )
      
    setState({
        ...slevy,
        SlevyInfo:{
            ...slevy.SlevyInfo,
            UserID:data.data                        
        },
    })
    
    };
  
    const handleCloseNew = () => {
      setOpen(false);
      setState({
      ...slevy,
          SlevyInfo:initialSlevy.SlevyInfo
        },
      )
    };

    const SubmitDelAdresy = async (event) => {
        
        
        event.preventDefault();

        await delSlevy(slevy.SlevyInfo);

        async function fetchData() {
          const dataOUD = await getKlientSlevy(data.data); 
          setSlevy(dataOUD) 
        }
        
        fetchData()
        setOpenDel(false);
        setState({
            ...slevy,
                SlevyInfo:initialSlevy.SlevyInfo
            },
        )
    }

    const handleDelClose = () => {
        setOpenDel(false);
        setState({
        ...slevy,
            SlevyInfo:initialSlevy.SlevyInfo
          },
        )
    };

    function currentlySelected(v) {
      
        setState({
          ...slevy,
          SlevyInfo:{
              ...slevy.SlevyInfo,
              SluzbaID:v.SluzbaID,
              SluzbaNazev:v.SluzbaNazev,
              SlevaID:v.SlevaID,
              Sleva:v.Sleva,
              UserID:v.UserID!== null ? v.UserID : '' ,
              SluzbaImport:v.SluzbaImport,
              SluzbaExport:v.SluzbaExport,
              Paleta:v.Paleta,
              Balik:v.Balik,
              Dokument:v.Dokument,
              IsDefault:0,
              HmotnostOd: v.HmotnostOd != null ? v.HmotnostOd : '' ,
              HmotnostDo: v.HmotnostDo != null ? v.HmotnostDo : '' 
          },
      });        
      }

  return (


<div className={classes.bgGrid,classes.containerX}>
    <Grid container spacing={3}  > 
      <Grid item xs={12} sm={12} spacing={2} container className={classes.bgGrid}> 
          <div style={{ height: 400, width: '100%' }}>
              <DataGrid className={classes.datag} getRowId={(row) => row.SlevaID} pageSize={1000} rowHeight={35} rows={Slevy?Slevy:[]} hideFooterPagination="true" hideFooterRowCount="true" hideFooter="true" hideFooterSelectedRowCount="true" columns={columns}
                onRowSelected={(e) => currentlySelected(e.data)}                  
              />
          </div>
      </Grid>
      <Grid item xs={12} sm={12} spacing={2} container className={classes.bgGrid} direction="row" justify="space-between" alignItems="flex-start" >  
        <Grid item xs={12} sm={3}>                                           
          <TextFields 
            name="SluzbaNazev" 
            className={clsx(classes.textf,classes.margin)} 
            type="text" 
            variant="outlined" 
            size="small" 
            fullWidth 
            id="SluzbaNazev" 
            placeholder="Název služby" 
            disabled
            value={slevy.SlevyInfo.SluzbaNazev} 
          />
        </Grid> 
        <Grid item xs={12} sm={3}>                                           
          <TextFields 
            name="HmotnostOd" 
            className={clsx(classes.textf,classes.margin)} 
            type="number" 
            variant="outlined" 
            size="small" 
            fullWidth 
            id="HmotnostOd" 
            label="Hmotnost od" 
            placeholder="Hmotnost od" 
            InputProps={{
            startAdornment: <InputAdornment position="start">Kg</InputAdornment>
            }}
            value={slevy.SlevyInfo.HmotnostOd} 
            onChange={onChangeValue} 
          />
        </Grid> 
        <Grid item xs={12} sm={3}>                                              
          <TextFields  
            name="HmotnostDo" 
            className={clsx(classes.textf,classes.margin)} 
            type="number" 
            variant="outlined" 
            size="small" 
            fullWidth 
            id="HmotnostDo" 
            label="Hmotnost do" 
            placeholder="Hmotnost do" 
            InputProps={{
            startAdornment: <InputAdornment position="start">Kg</InputAdornment>
            }}
            value={slevy.SlevyInfo.HmotnostDo} 
            onChange={onChangeValue}
          />
        </Grid>        
        <Grid item xs={12} sm={3}>                                              
            <TextFields name="Sleva" className={clsx(classes.textf,classes.margin)} type="number" InputProps={{ startAdornment: <InputAdornment position="start">%</InputAdornment>}} variant="outlined" size="small" fullWidth id="Sleva" label="Sleva export" required placeholder="Sleva" value={slevy.SlevyInfo.Sleva} onChange={onChangeValue} />
        </Grid>   
        <Grid item item xs={12} sm={2}  > 
            <GreenButton fullWidth onClick={handleOpenNew} startIcon={<AddIcon />}>Přidat slevu</GreenButton>
        </Grid>        
        <Grid item xs={12} sm={8} /> 
        <Grid item xs={12} sm={2} > 
          <GreenButton fullWidth onClick={SubmitPostSlevy} startIcon={<SaveIcon />}>Uložit</GreenButton>
        </Grid>
      </Grid>

    </Grid>

    <Modal
        className={classes.modal}
        open={open}
        key="add"
        onClose={handleCloseNew}
        closeAfterTransition
        BackdropComponent={Backdrop}
    >
        <Fade in={open}>
            <div className={classes.paper}>
              <Paper className={classes.paperH}>
                <Typography className={classes.nadpisH}>Nová sleva</Typography>
              </Paper>
              <Container className={classes.innerPadding} maxWidth="sm">

                <Grid container spacing={2}>                          
                  <Grid item xs={12} sm={12} container justify="center"> 
                    <Autocomplete
                        id="sluzba"
                        options={Sluzby}
                        getOptionLabel={(option) => option.SluzbaNazev ? option.SluzbaNazev +' ('+option.PrepravceNazev+')' : ''}
                        fullWidth    
                        getOptionSelected={(option) => option.SluzbaID === slevy.SlevyInfo.SluzbaID}                            
                        onChange={(event, newValue) => {
                        if (newValue) {                                  
                            setState({
                                ...slevy,
                                SlevyInfo:{
                                    ...slevy.SlevyInfo,
                                    SluzbaID:newValue.SluzbaID
                                },
                            });
                        }
                        }}       
                        renderOption={(option) => (
                        <React.Fragment>
                            <span><img src={"../img/loga/"+option.PrepravceLogo} height="20px" alt={option.Kod}/></span>
                            <span className={classes.left}>{option.SluzbaNazev +' ('+option.PrepravceNazev+')'}</span>
                        </React.Fragment>
                        )}
                        renderInput={(params) => <TextFields {...params} label="Služba" size="small" variant="outlined" />}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>                                           
                      <TextFields name="Sleva" className={clsx(classes.textf,classes.margin)} type="number" InputProps={{ startAdornment: <InputAdornment position="start">%</InputAdornment>}} variant="outlined" size="small" fullWidth id="Sleva" label="Sleva" required placeholder="Sleva" value={slevy.SlevyInfo.Sleva} onChange={onChangeValue} />
                    </Grid>  
                    <Grid item xs={12} sm={6}/>  
                    <Grid item xs={12} sm={6}>                                           
                      <TextFields 
                        name="HmotnostOd" 
                        className={clsx(classes.textf,classes.margin)} 
                        type="number" 
                        variant="outlined" 
                        size="small" 
                        fullWidth 
                        id="HmotnostOd" 
                        label="Hmotnost od" 
                        placeholder="Hmotnost od" 
                        InputProps={{
                        startAdornment: <InputAdornment position="start">Kg</InputAdornment>
                        }}
                        value={slevy.SlevyInfo.HmotnostOd} 
                        onChange={onChangeValue} 
                      />
                    </Grid>  
                    <Grid item xs={12} sm={6}>                                    
                      <TextFields  
                        name="HmotnostDo" 
                        className={clsx(classes.textf,classes.margin)} 
                        type="number" 
                        variant="outlined" 
                        size="small" 
                        fullWidth 
                        id="HmotnostDo" 
                        label="Hmotnost do" 
                        placeholder="Hmotnost do" 
                        InputProps={{
                        startAdornment: <InputAdornment position="start">Kg</InputAdornment>
                        }}
                        value={slevy.SlevyInfo.HmotnostDo} 
                        onChange={onChangeValue}
                      />
                    </Grid>   

                    <Grid item xs={12} sm={4}> 
                        <GreenButton fullWidth onClick={handleCloseNew} > Zrušit </GreenButton>
                    </Grid>
                    <Grid item xs={12} sm={4}/> 
                    <Grid item xs={12} sm={4}> 
                        <GreenButton fullWidth onClick={SubmitPostSlevy} startIcon={<SaveIcon />}>Uložit</GreenButton>
                    </Grid>
                </Grid>
              </Container>
            </div>
        </Fade>
    </Modal>  
    <Dialog
        open={openDel}
        onClose={handleDelClose}
        fullWidth
        maxWidth='xs'
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Opravdu si přejete smazat slevu?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Grid container spacing={2}>      
                <Grid item xs={12} sm={4}> 
                    <GreenButton fullWidth onClick={handleDelClose}>Zrušit</GreenButton>
                </Grid>
                <Grid item xs={12} sm={4}/> 
                <Grid item xs={12} sm={4}> 
                <GreenButton fullWidth onClick={SubmitDelAdresy} startIcon={<DeleteIcon />}>Smazat</GreenButton>
                </Grid>
            </Grid>
          
        </DialogActions>
      </Dialog>
</div>
  );
}


export default Slevy