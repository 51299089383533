import React, {useContext,useState, useEffect} from 'react';
import {UserContext} from '../../contexts/UserContext'
import Container from "@material-ui/core/Container";
import { withStyles,makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import customConfig from '../../customConfig.json'
import TextField from "@material-ui/core/TextField";
import * as moment from 'moment'
import BookIcon from '@material-ui/icons/Book';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Autocomplete from '@material-ui/lab/Autocomplete';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from "@material-ui/core/InputAdornment";
import { Checkbox } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import {AttachFile} from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import SaveIcon from '@material-ui/icons/Save';
import { v4 as uuidv4 } from 'uuid';
import {Add,Delete} from '@material-ui/icons';
import { Alert } from '@material-ui/lab';



const TextFields = withStyles({
  root: {
    '& label.Mui-focused': {
      color: customConfig.Colors.PrimaryColor,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: customConfig.Colors.PrimaryColor,
    },
    '& .MuiOutlinedInput-root': {

      '&:hover fieldset': {
        borderColor: customConfig.Colors.SecColor,
      },
      '&.Mui-focused fieldset': {
        borderColor: customConfig.Colors.PrimaryColor,
      },
    },
  },
})(TextField);

const WhiteCheckbox = withStyles({
  root: {
    color: customConfig.Colors.TextColor,
    '&$checked': {
      color: customConfig.Colors.TextColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);



const GreenCheckbox = withStyles({
  root: {
    color: customConfig.Colors.PrimaryColor,
    '&$checked': {
      color: customConfig.Colors.PrimaryColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);



const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    backgroundColor: customConfig.Colors.PrimaryColor,
    color: customConfig.Colors.TextColor,
    fontWeight:'bold'
  },
  typo: {
    color: 'Black',
  },
  GridColor: {
    backgroundColor: customConfig.Colors.GridBackgroundColor
}, 
modal: {
  display: 'flex',
  justifyContent: 'center',
  overflow:'scroll',
},
iconColor:{
  color:customConfig.Colors.ButtonTextColor,
  padding:'0px',
  float:'right'
},
iconColor2:{
  color:customConfig.Colors.PrimaryColor,
  padding:'0px',
  float:'right'
},
mainCont:{
  padding:'10px'
},
Cont:{
  padding:'10px',
  backgroundColor: customConfig.Colors.GridBackgroundColor
},
input: {
  display: 'none',
},
valueHeader:{
  fontWeight: 700,
  color: customConfig.Colors.RecesrTextColor,

},
valueText:{
  fontWeight: 500,
  paddingLeft:'10px',
  color: customConfig.Colors.RecesrTextColor
},
paperModal: {
    backgroundColor: theme.palette.background.paper,
    border: customConfig.Colors.BorderColor,          
    boxShadow: theme.shadows[5],
    padding: '0px',
    borderRadius:'10px 10px 8px 8px',
    border:'none'
},
table:{
  border: "none"
},

paperH: {
  textAlign: 'left',
  paddingLeft:'20px',
  backgroundColor: customConfig.Colors.PrimaryColor,
  boxShadow: 'none',
  border:'1px',
  borderRadius: '8px 8px 0px 0px'
},
nadpisH:{
  color: customConfig.Colors.ButtonTextColor,
  fontSize:'20px'
},
gridX:{
  height:"45px",
  margin:"5px"
},
tableCell:{
  border: "none",
  padding:"5px"
},
iconBTN: {
  padding: '8px 8px 8px 8px',
},
}));



const GreenButton = withStyles((theme) => ({
  root: {
    color: customConfig.Colors.TextColor,
    backgroundColor: customConfig.Colors.PrimaryColor,
    '&:hover': {
      backgroundColor: customConfig.Colors.SecColor,
    },
  },
}))(Button); 
  

export const Podrobnosti = ({ formData, setForm, navigation }) => {
  const { 

    popisZbozi,
    hodnotaZbozi,
    ReferencniCislo,
    datumVyzvednuti,
    casOd,
    casDo,
    platce,
    poznamka,
    odesilatelJmeno,
    odesilatelAdresa,
    odesilatelAdresa2,
    odesilatelAdresa3,
    odesilatelMesto,
    odesilatelPSC,
    odesilatelKontOsoba,
    odesilatelEmail,
    odesilatelTel,
    prijemceJmeno,
    prijemceAdresa,
    prijemceAdresa2,
    prijemceAdresa3,
    prijemceMesto,
    prijemcePSC,
    prijemceStat,
    prijemceKontOsoba,
    prijemceEmail,
    prijemceTel,
    DefAdresFrom,
    DefAdresTo,
    adresTo,
    adresFrom,
    platceJmeno,
    platceAdresa,
    platceMesto,
    platcePSC,
    platceStat,
    platceStatNazev,
    platceKontOsoba,
    platceEmail,
    platceTel,
    ulozitAdrOdesilatel,
    ulozitAdrprijemce,
    doplnky,
    PROFORMAdata,
    UseProForma,
    prilohy
   } = formData;


   const [inputFieldsPolozky, setinputFieldsPolozky] = useState([{ id: uuidv4(),Description: '', CommodityCode: '', Country: '', Qty: '' ,UnitValue:'',SubTotal:''}]   );       

   const classes = useStyles();
   const {getStaty,getAdresyByStat,getUserType,getSpolecnost,getFirmy,getUser} = useContext(UserContext);
   const [Adresy, setAdresy] = useState([])    
   const [Platce, setPlatce] = useState([])      
   const [defAdresa, setDefAdresy] = useState([])    
   const [defPlatce, setdefPlatce] = useState([])    
   const [OpenPrijemce, setOpenPrijemce] = React.useState(false);
   const [OpenOdesilatel, setOpenOdesilatel] = React.useState(false);
   const [OpenPlatce, setOpenPlatce] = React.useState(false);
   const [OpenPROFORMA, setOpenPROFORMA] = React.useState(false);
   const [Staty, setStaty] = useState([])    
   const [UserType, setUserType] = useState('0') 
   const [errorMsg, seterrorMsg] = useState('');
   const [Firmy, setFirmy] = useState([])  
   const [Prilohy, setPrilohy] = useState([]);
   const [Dis, setDis] = React.useState(false);
   const [PROFORMA, setPROFORMA] = useState(
      { 
        ByCompany: '', 
        ByAddress: '', 
        ByCity:'',
        ByCountry:'',
        ByTel:'',
        ByVAT:'',
        ByEORI:'',
        
        ToCompany: '', 
        ToAddress: '', 
        ToCity:'',
        ToCountry:'',
        ToTel:'',
        ToVAT:'',
        ToEORI:'',
  
        TotalValue:'',
        NumberofShipments:'',
        TotalGrossWeight:'',
        TotalNetWeight:'',
        WaybillNumber:'',
        INCOTERMS:'',
        ReasonForExport:'',
        VDDTDD:false,
        ATACARNET:false,
        VSCP:false,
        VDD:false,
        rows:[{ 
          id: uuidv4(), 
          Description: '',
          CommodityCode: '', 
          Country: '', 
          Qty: '', 
          UnitValue :'',
          SubTotal :''
        },],  
      },);


 

const handleCloseOdesilatel = () => {
  setOpenOdesilatel(false);
};  

const handleClosePrijemce = () => {
  setOpenPrijemce(false);
};  

const handleClosePlatce = () => {
setOpenPlatce(false);
};  

const handleClosePROFORMA = () => {
  setOpenPROFORMA(false);
  formData.PROFORMAdata = PROFORMA;
}; 

const handleOpemPROFORMA = () => {
  setOpenPROFORMA(true);
}; 

const openOdesilatelPlatce = async () => {
  if(Firmy.length===0)
  {
    const dataOud = await getFirmy();
    setFirmy(dataOud);    
  }
  setOpenPlatce(true);

};  

const handlePotvrditPrijemce = () => {
  
   setOpenPrijemce(false);
   
   if(defAdresa.newValue)
   {     
   formData.prijemceAdresa=defAdresa.newValue.Adresa;
   formData.prijemceMesto=defAdresa.newValue.Mesto;
   formData.prijemcePSC=defAdresa.newValue.PSC;
   formData.prijemceJmeno=defAdresa.newValue.Oznaceni;  
   formData.prijemceEmail=defAdresa.newValue.Email;  
   formData.prijemceTel=defAdresa.newValue.Telefon;  
   formData.prijemceKontOsoba=defAdresa.newValue.KonkaktniOsoba;  
  }
};  

const openOdesilatelAdresa = () => {
   setOpenOdesilatel(true);        
   setDefAdresy([]);

  async function fetchData() {
    const dataOUD = await getAdresyByStat(formData.adresFrom); 
    setAdresy(dataOUD) 
  }

  fetchData()  
};  



const validace = () => {
  



  if(
    hodnotaZbozi===''
  || odesilatelJmeno.length<1
  || odesilatelAdresa.length<1
  || odesilatelMesto.length<1
  || odesilatelPSC.length<1
  || odesilatelKontOsoba.length<1
  || odesilatelEmail.length<1
  || odesilatelTel.length<1
  || prijemceJmeno.length<1
  || prijemceAdresa.length<1
  || prijemceMesto.length<1
  || prijemcePSC.length<1
  || prijemceKontOsoba.length<1
  || prijemceEmail.length<1
  || prijemceTel.length<1)
  {

    const text ="Vyplňte pole:" + (odesilatelJmeno.length<1?' Odesílatel firma;':'')
    +(odesilatelAdresa.length<1?' Odesílatel adresa;':'')
    +(odesilatelMesto.length<1?' Odesílatel město;':'')
    +(odesilatelPSC.length<1?' Odesílatel PSČ;':'')
    +(odesilatelKontOsoba.length<1?' Odesílatel kontaktní osoba;':'')
    +(odesilatelEmail.length<1?' Odesílatel email;':'')
    +(odesilatelTel.length<1?' Odesílatel telefon;':'')
    +(prijemceJmeno.length<1?' Příjemce firma;':'')
    +(prijemceAdresa.length<1?' Příjemce adresa;':'')
    +(prijemceMesto.length<1?' Příjemce město;':'')
    +(prijemcePSC.length<1?' Příjemce PSČ;':'')
    +(prijemceKontOsoba.length<1?' Příjemce kontaktní osoba;':'')
    +(prijemceEmail.length<1?' Příjemce email;':'')
    +(prijemceTel.length<1?' Příjemce telefon;':'')
    +(hodnotaZbozi===''?' Hodnota zboží;':'')
    
    seterrorMsg(text);
  }
  else
  {
    seterrorMsg('')
    navigation.next();
  }



};  


const handlePotvrditOdesilatel = () => {
  setOpenOdesilatel(false);

  if(defAdresa.newValue)
  { 
    formData.odesilatelAdresa=defAdresa.newValue.Adresa;
    formData.odesilatelMesto=defAdresa.newValue.Mesto;
    formData.odesilatelPSC=defAdresa.newValue.PSC;
    formData.odesilatelJmeno=defAdresa.newValue.Oznaceni;  
    formData.odesilatelEmail=defAdresa.newValue.Email;  
    formData.odesilatelTel=defAdresa.newValue.Telefon;  
    formData.odesilatelKontOsoba=defAdresa.newValue.KonkaktniOsoba;  
  }
};  

const handlePotvrditPlatce = async () => {

  async function fetchData() {
      const dataOUD = await getUser(defPlatce.newValue.UserID); 
      
      setPlatce(dataOUD.User[0]) 
  }

  fetchData()
  setOpenPlatce(false);      
  
  if(defPlatce.newValue)
  {       
    if(defPlatce.newValue.UserID)
    { 
      fetchData()
    }
  }

};  

const openPrijemceAdresa = () => {          
  setDefAdresy([]);

  async function fetchData() {
    const dataOUD = await getAdresyByStat(formData.adresTo); 
    setAdresy(dataOUD) 
  }    
  fetchData()

  setOpenPrijemce(true);
                          
}; 

const NastavTyp = async () => {        

  const xUserType = await getUserType();
  setUserType(xUserType[0].UserType);
  
  if (UserType === '1' && xUserType[0].UserType)
  {
    const xFirmy = await getFirmy();
    setFirmy(xFirmy);    
  }


  return 1;

}

useEffect(() => {
  
  async function fetchData() {
    const dataOUD = await getStaty(); 
    const dataOUD2 = await getSpolecnost(); 
    
    setStaty(dataOUD.theStaty.stat) 
    setPlatce(dataOUD2[0]) 
  }

  fetchData()    
  NastavTyp();  

  if(doplnky[7].DoplnekInt === true)
  {
    formData.UseProForma = true;
    formData.VDD = true;
  }
  setPROFORMA(formData.PROFORMAdata);  
  setinputFieldsPolozky(formData.PROFORMAdata.rows);
  setPrilohy(formData.prilohy);

  formData.defaultTo = Staty.find(x => x.Kod === adresTo) !== undefined ? Staty.find(x => x.Kod === adresTo) : null;    
  formData.defaultFrom = Staty.find(x => x.Kod === adresFrom) !== undefined ? Staty.find(x => x.Kod === adresFrom) : null;   
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []) 

useEffect(() => {

    formData.platce = Platce.UserID
    formData.platceJmeno = Platce.Firma
    formData.platceKontOsoba = (Platce.Jmeno+" "+Platce.Prijmeni)
    formData.platceEmail = Platce.Email
    formData.platceTel = Platce.Telefon
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [Platce]) 

const onChangeFile = (event) => {
  
  for (let i = 0; i < Prilohy.length; i++) {
    Prilohy[i] = undefined;
  }

  setPrilohy([]);

  let files = event.target.files;    
  let fil = [];

  
  for (let i = 0; i < files.length; i++) {
    
    (function(file) {
      let reader = new FileReader();
      reader.fileName = file.name;
      reader.fileType = file.type;
      reader.readAsDataURL(file); 

      reader.onload = function(eventX) {  
        fil[i] = {name:eventX.target.fileName,type:eventX.target.fileType,file:eventX.target.result};
        setPrilohy([fil]);
      }
    })(files[i]);
  }

};

const onChangeValuePROFORMA = (e) => {
  setPROFORMA({
      ...PROFORMA,
      [e.target.name]:e.target.value
  });        
}

const CheckBoxhandleChange = (e) => {
  setPROFORMA({
      ...PROFORMA,
      [e.target.name]: e.target.checked 
  });            
};
const PridejPrilohy = e=>{
  formData.prilohy = Prilohy;
  formData.PROFORMAdata.rows = inputFieldsPolozky;
}
const handleChangeInputPolozky = (id, event) => {
  const newInputFields = inputFieldsPolozky.map(i => {
    if(id === i.id) {
      i[event.target.name] = event.target.value
    }
    return i;
  })    
  setinputFieldsPolozky(newInputFields);
}

const handleRemoveFieldsPolozky = id => {
  const values  = [...inputFieldsPolozky];
  values.splice(values.findIndex(value => value.id === id), 1);
  setinputFieldsPolozky(values);    
};

const handleAddFieldsPolozky = () => {
  setinputFieldsPolozky([...inputFieldsPolozky, { id: uuidv4(), Description: '', CommodityCode: '', Country: '', Qty: '' ,UnitValue:'',SubTotal:'' }])  
};

  return (
    <Container className={classes.mainCont} maxWidth="xl">
      
      <Dialog
        open={OpenOdesilatel}
        onClose={handleCloseOdesilatel}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
      <Grid item xl={12} sm={12} container justify="center"> 
        <Grid item xl={12} sm={12}> 
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Vyberte kontakt
            </DialogContentText>
              <Autocomplete
                  id="adresaOdesilatel"
                  options={Adresy}
                  getOptionLabel={(option) => option.Nazev ? option.Nazev +' ('+option.StatKod+')' : ''}
                  fullWidth                      
                  onChange={(event, newValue) => {
                  if (newValue) {                                  
                    setDefAdresy({
                      newValue
                    });
                  }
                  }}       
                  renderOption={(option) => (
                  <React.Fragment>
                      <span>{option.Nazev +' ('+option.StatKod+')'}</span>
                  </React.Fragment>
                  )}
                  renderInput={(params) => <TextField {...params} label="Adresy" size="small" variant="outlined" />}
              />

          </DialogContent>
          </Grid>
          <Grid item xl={12} sm={12}> 
            <DialogActions>
              <Grid container spacing={2}>      
                  <Grid item xs={12} sm={4}> 
                      <GreenButton fullWidth onClick={handleCloseOdesilatel}>Zrušit</GreenButton>
                  </Grid>
                  <Grid item xs={12} sm={4}/> 
                  <Grid item xs={12} sm={4}> 
                  <GreenButton fullWidth onClick={handlePotvrditOdesilatel}>Potvrdit</GreenButton>
                  </Grid>
              </Grid>              
            </DialogActions>
          </Grid>
        </Grid>

      </Dialog>


      <Modal
        className={classes.modal}
        open={OpenPROFORMA}
        onClose={(e) => handleClosePROFORMA()}
        closeAfterTransition
        BackdropComponent={Backdrop}
      >
        <Fade in={OpenPROFORMA}>
          <div className={classes.paperModal}>
            <Paper className={classes.paperH}>
              <Typography className={classes.nadpisH}>PROFORMA INVOICE</Typography>
            </Paper>            
            <Container className={classes.Cont} maxWidth="lg">
              <Grid container spacing={2}>    
                <Grid item container spacing={1} xs={12} sm={6}>   

                  <Grid item xs={12} sm={12}> 
                    <Paper className={classes.paperH}>
                      <Typography className={classes.nadpisH}>Odesílatel</Typography>
                    </Paper>    
                  </Grid>
                  <Grid item xs={12} sm={12}> 
                    <TextFields
                      id="ByCompany"
                      key="ByCompany"
                      name="ByCompany"
                      label="Společnost/Jméno"
                      type="text"
                      value={PROFORMA.ByCompany}
                      fullWidth
                      required
                      variant="outlined"
                      size="small"
                      onChange={(e) => onChangeValuePROFORMA(e)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}> 
                    <TextFields
                      id="ByAddress"
                      key="ByAddress"
                      name="ByAddress"
                      label="Adresa"
                      type="text"
                      value={PROFORMA.ByAddress}
                      fullWidth
                      required
                      variant="outlined"
                      size="small"
                      onChange={(e) => onChangeValuePROFORMA(e)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}> 
                    <TextFields
                      id="ByCity"
                      key="ByCity"
                      name="ByCity"
                      label="Město, PSČ"
                      type="text"
                      value={PROFORMA.ByCity}
                      fullWidth
                      required
                      variant="outlined"
                      size="small"
                      onChange={(e) => onChangeValuePROFORMA(e)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}> 
                    <TextFields
                      id="ByCountry"
                      key="ByCountry"
                      name="ByCountry"
                      label="Země"
                      type="text"
                      value={PROFORMA.ByCountry}
                      fullWidth
                      required
                      variant="outlined"
                      size="small"
                      onChange={(e) => onChangeValuePROFORMA(e)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}> 
                    <TextFields
                      id="ByTel"
                      key="ByTel"
                      name="ByTel"
                      label="Tel., fax"
                      type="text"
                      value={PROFORMA.ByTel}
                      fullWidth
                      required
                      variant="outlined"
                      size="small"
                      onChange={(e) => onChangeValuePROFORMA(e)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}> 
                    <TextFields
                      id="ByVAT"
                      key="ByVAT"
                      name="ByVAT"
                      label="DIČ/IČO odesílatele"
                      type="text"
                      value={PROFORMA.ByVAT}
                      fullWidth
                      required
                      variant="outlined"
                      size="small"
                      onChange={(e) => onChangeValuePROFORMA(e)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}> 
                    <TextFields
                      id="ByEORI"
                      key="ByEORI"
                      name="ByEORI"
                      label="EORI číslo"
                      type="text"
                      value={PROFORMA.ByEORI}
                      fullWidth
                      variant="outlined"
                      size="small"
                      onChange={(e) => onChangeValuePROFORMA(e)}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} container spacing={1} sm={6}>   
                  <Grid item xs={12} sm={12}> 
                    <Paper className={classes.paperH}>
                      <Typography className={classes.nadpisH}>Příjemce</Typography>
                    </Paper>    
                  </Grid>
                  <Grid item xs={12} sm={12}> 
                      <TextFields
                      id="ToCompany"
                      key="ToCompany"
                      name="ToCompany"
                      label="Společnost/Jméno"
                      type="text"
                      value={PROFORMA.ToCompany}
                      fullWidth
                      required
                      variant="outlined"
                      size="small"
                      onChange={(e) => onChangeValuePROFORMA(e)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}> 
                    <TextFields
                      id="ToAddress"
                      key="ToAddress"
                      name="ToAddress"
                      label="Adresa"
                      type="text"
                      value={PROFORMA.ToAddress}
                      fullWidth
                      required
                      variant="outlined"
                      size="small"
                      onChange={(e) => onChangeValuePROFORMA(e)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}> 
                    <TextFields
                      id="ToCity"
                      key="ToCity"
                      name="ToCity"
                      label="Město, PSČ"
                      type="text"
                      value={PROFORMA.ToCity}
                      fullWidth
                      required
                      variant="outlined"
                      size="small"
                      onChange={(e) => onChangeValuePROFORMA(e)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}> 
                    <TextFields
                      id="ToCountry"
                      key="ToCountry"
                      name="ToCountry"
                      label="Země"
                      type="text"
                      value={PROFORMA.ToCountry}
                      fullWidth
                      required
                      variant="outlined"
                      size="small"
                      onChange={(e) => onChangeValuePROFORMA(e)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}> 
                    <TextFields
                      id="ToTel"
                      key="ToTel"
                      name="ToTel"
                      label="Tel., fax"
                      type="text"
                      value={PROFORMA.ToTel}
                      fullWidth
                      required
                      variant="outlined"
                      size="small"
                      onChange={(e) => onChangeValuePROFORMA(e)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}> 
                    <TextFields
                      id="ToVAT"
                      key="ToVAT"
                      name="ToVAT"
                      label="DIČ/IČO příjemce"
                      type="text"
                      value={PROFORMA.ToVAT}
                      fullWidth
                      required
                      variant="outlined"
                      size="small"
                      onChange={(e) => onChangeValuePROFORMA(e)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}> 
                    <TextFields
                      id="ToEORI"
                      key="ToEORI"
                      name="ToEORI"
                      label="EORI číslo"
                      type="text"
                      value={PROFORMA.ToEORI}
                      fullWidth
                      variant="outlined"
                      size="small"
                      onChange={(e) => onChangeValuePROFORMA(e)}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12}/> 
                

                <Grid item container spacing={1} xs={12} sm={12}> 
                  { inputFieldsPolozky.map(inputField => (    
                    <Grid item container key={inputField.id} spacing={1}> 
                      <Grid item xs={12} sm={6}>
                        <TextFields
                          id="Description"
                          key="Description"
                          name="Description"
                          label="Popis zboží"
                          type="text"
                          value={inputField.Description}
                          fullWidth
                          variant="outlined"
                          size="small"
                          onChange={(e) => {handleChangeInputPolozky(inputField.id, e);}}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <TextFields
                          id="CommodityCode"
                          key="CommodityCode"
                          name="CommodityCode"
                          label="Položka dle celního sazebníku"
                          type="text"
                          value={inputField.CommodityCode}
                          fullWidth
                          variant="outlined"
                          size="small"
                          onChange={(e) => {handleChangeInputPolozky(inputField.id, e);}}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <TextFields
                          id="Country"
                          key="Country"
                          name="Country"
                          label="Země původu"
                          type="text"
                          value={inputField.Country}
                          fullWidth
                          required
                          variant="outlined"
                          size="small"
                          onChange={(e) => {handleChangeInputPolozky(inputField.id, e);}}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <TextFields
                          id="Qty"
                          key="Qty"
                          name="Qty"
                          label="Počet kusů"
                          type="number"
                          value={inputField.Qty}
                          fullWidth
                          required
                          variant="outlined"
                          size="small"
                          onChange={(e) => {handleChangeInputPolozky(inputField.id, e);}}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <TextFields
                          id="UnitValue"
                          key="UnitValue"
                          name="UnitValue"
                          label="Cena za kus"
                          type="number"
                          value={inputField.UnitValue}
                          fullWidth
                          required
                          variant="outlined"
                          size="small"
                          onChange={(e) => {handleChangeInputPolozky(inputField.id, e);}}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <TextFields
                          id="SubTotal"
                          key="SubTotal"
                          name="SubTotal"
                          label="Celková hodnota"
                          type="number"
                          value={inputField.SubTotal}
                          fullWidth
                          required
                          variant="outlined"
                          size="small"
                          onChange={(e) => {handleChangeInputPolozky(inputField.id, e);}}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>     
                        <IconButton aria-label="Add" className={classes.iconBTN} onClick={handleAddFieldsPolozky}>
                            <Add className={classes.iconColor2} />
                        </IconButton>
                        <IconButton aria-label="delete" className={classes.iconBTN} disabled={inputFieldsPolozky.length === 1} onClick={() => handleRemoveFieldsPolozky(inputField.id)}>
                            <Delete />
                        </IconButton>
                      </Grid>                      
                      <Grid item xs={12} sm={12}/> 
                    </Grid>
                  )) }

                </Grid>

                <Grid item xs={12} sm={6}> 
                  <Typography className={classes.typo}>
                    <GreenCheckbox
                      checked={PROFORMA.VDDTDD}
                      onChange={(e) => {CheckBoxhandleChange(e);}}
                      id="VDDTDD"
                      key="VDDTDD"
                      name="VDDTDD"
                      color="primary"
                    />          
                    Přikládáme vlastní celní prohlášení (VDD/TDD)                             
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}> 
                  <Typography className={classes.typo}>
                    <GreenCheckbox
                      checked={PROFORMA.ATACARNET}
                      onChange={(e) => {CheckBoxhandleChange(e);}}
                      id="ATACARNET"
                      key="ATACARNET"
                      name="ATACARNET"
                      color="primary"
                    />          
                    Přikládáme ATA CARNET                            
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}> 
                  <Typography className={classes.typo}>
                    <GreenCheckbox
                      checked={doplnky[7].DoplnekInt}
                      id="VDD"
                      key="VDD"
                      name="VDD"
                      color="primary"
                      disabled
                    />          
                    Požadujeme zpoplatněné VDD (300 Kč)                            
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}> 
                  <Typography className={classes.typo}>
                    <GreenCheckbox
                      checked={PROFORMA.VSCP}
                      onChange={(e) => {CheckBoxhandleChange(e);}}
                      id="VSCP"
                      key="VSCP"
                      name="VSCP"
                      color="primary"
                    />          
                    Požadujeme zdarma VSCP                            
                  </Typography>
                </Grid>

                
                <Grid item xs={6} sm={4}> 
                  <TextFields
                    id="NumberofShipments"
                    key="NumberofShipments"
                    name="NumberofShipments"
                    label="Počet zásilek"
                    type="number"
                    value={PROFORMA.NumberofShipments}
                    fullWidth
                    required
                    variant="outlined"
                    size="small"
                    onChange={(e) => onChangeValuePROFORMA(e)}
                  />

                </Grid>
                <Grid item xs={6} sm={4}> 
                  <TextFields
                    id="TotalGrossWeight"
                    key="TotalGrossWeight"
                    name="TotalGrossWeight"
                    label="Celková hmotnost po zabalení"
                    type="text"
                    value={PROFORMA.TotalGrossWeight}
                    fullWidth
                    required
                    variant="outlined"
                    size="small"
                    onChange={(e) => onChangeValuePROFORMA(e)}
                  />

                </Grid>
                <Grid item xs={6} sm={4}> 
                  <TextFields
                    id="TotalNetWeight"
                    key="TotalNetWeight"
                    name="TotalNetWeight"
                    label="Celková hmotnost zboží"
                    type="text"
                    value={PROFORMA.TotalNetWeight}
                    fullWidth
                    required
                    variant="outlined"
                    size="small"
                    onChange={(e) => onChangeValuePROFORMA(e)}
                  />

                </Grid>
                <Grid item xs={6} sm={4}> 
                  <TextFields
                    id="WaybillNumber"
                    key="WaybillNumber"
                    name="WaybillNumber"
                    label="Nákladový list č."
                    type="text"
                    value={PROFORMA.WaybillNumber}
                    fullWidth
                    required
                    variant="outlined"
                    size="small"
                    onChange={(e) => onChangeValuePROFORMA(e)}
                  />

                </Grid>
                <Grid item xs={6} sm={4}> 
                  <TextFields
                    id="INCOTERMS"
                    key="INCOTERMS"
                    name="INCOTERMS"
                    label="Dodací podmínky"
                    type="text"
                    value={PROFORMA.INCOTERMS}
                    fullWidth
                    required
                    variant="outlined"
                    size="small"
                    onChange={(e) => onChangeValuePROFORMA(e)}
                  />

                </Grid>
                <Grid item xs={6} sm={4}> 
                  <TextFields
                    id="ReasonForExport"
                    key="ReasonForExport"
                    name="ReasonForExport"
                    label="Účel vývozu"
                    type="text"
                    value={PROFORMA.ReasonForExport}
                    fullWidth
                    required
                    variant="outlined"
                    size="small"
                    onChange={(e) => onChangeValuePROFORMA(e)}
                  />

                </Grid>
                <Grid item xs={12} sm={12}/> 
                <Grid item xs={6} sm={4}> 
                  <TextFields
                    id="TotalValue"
                    key="TotalValue"
                    name="TotalValue"
                    label="Celková hodnota"
                    type="text"
                    value={PROFORMA.TotalValue}
                    fullWidth
                    required
                    variant="outlined"
                    size="small"
                    onChange={(e) => onChangeValuePROFORMA(e)}
                  />

                </Grid>


                <Grid item xs={12} sm={12}> 
                </Grid>



                <Grid item xs={12} sm={8}/> 
                <Grid item xs={12} sm={4}> 
                    <GreenButton fullWidth onClick={(e) => handleClosePROFORMA()} startIcon={<SaveIcon />} >OK</GreenButton>
                </Grid>
              </Grid>
            </Container>
          </div>
        </Fade>
      </Modal>  

      <Dialog
        open={OpenPrijemce}
        onClose={handleClosePrijemce}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Grid item xl={12} sm={12} container justify="center"> 
          <Grid item xl={12} sm={12}> 
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Vyberte kontakt
            </DialogContentText>
              <Autocomplete
                  id="adresaOdesilatel"
                  options={Adresy}
                  getOptionLabel={(option) => option.Nazev ? option.Nazev +' ('+option.StatKod+')' : ''}
                  fullWidth                      
                  onChange={(event, newValue) => {
                  if (newValue) {                                  
                    setDefAdresy({
                      newValue
                    });
                  }
                  }}       
                  renderOption={(option) => (
                  <React.Fragment>
                      <span>{option.Nazev +' ('+option.StatKod+')'}</span>
                  </React.Fragment>
                  )}
                  renderInput={(params) => <TextField {...params} label="Adresy" size="small" variant="outlined" />}
              />

          </DialogContent>
          </Grid>
          <Grid item xl={12} sm={12}> 
            <DialogActions>
              <Grid container spacing={2}>      
                  <Grid item xs={12} sm={4}> 
                      <GreenButton fullWidth onClick={handleClosePrijemce}>Zrušit</GreenButton>
                  </Grid>
                  <Grid item xs={12} sm={4}/> 
                  <Grid item xs={12} sm={4}> 
                  <GreenButton fullWidth onClick={handlePotvrditPrijemce}>Potvrdit</GreenButton>
                  </Grid>
              </Grid>              
            </DialogActions>
          </Grid>
        </Grid>

      </Dialog>



      <Dialog
        open={OpenPlatce}
        onClose={handleClosePlatce}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Grid item xl={12} sm={12} container justify="center"> 
          <Grid item xl={12} sm={12}> 
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Vyberte plátce
            </DialogContentText>
            <Autocomplete
              id="Firmax"
              options={Firmy}
              getOptionLabel={(option) => option.Firma ? option.Firma +' ('+option.IC+')' : ''}       
              getOptionSelected={(option) => option.UserID === formData.platce}   
              onChange={(event, newValue) => {
              if (newValue) {                                  
                setdefPlatce({
                  newValue
                });
              }
              }}         
              renderOption={(option) => (
              <React.Fragment>
                  <span className={classes.left}>{option.Firma +' ('+option.IC+')'}</span>
              </React.Fragment>
              )}
              renderInput={(params) => <TextField {...params} className={classes.tf} size="small" variant="outlined" />}
            /> 

          </DialogContent>
          </Grid>
          <Grid item xl={12} sm={12}> 
            <DialogActions>
              <Grid container spacing={2}>      
                  <Grid item xs={12} sm={4}> 
                      <GreenButton fullWidth onClick={handleClosePlatce}>Zrušit</GreenButton>
                  </Grid>
                  <Grid item xs={12} sm={4}/> 
                  <Grid item xs={12} sm={4}> 
                    <GreenButton fullWidth onClick={handlePotvrditPlatce}>Potvrdit</GreenButton>
                  </Grid>
              </Grid>              
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>


      <form>
        <Grid container spacing={2} alignItems="flex-start" className={classes.GridColor}>      

            
          <Grid container item spacing={2} xs={12} sm={6}>

            <Grid container item spacing={2} xs={12} sm={12}>
              <Grid item xs={12} sm={12}>
                
                <Paper className={classes.paper}>
                  <Grid
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      container

                  >
                    <Grid item xs={12} sm={4} style={{textAlign:'left'}}>
                      Uložit adresu
                      <WhiteCheckbox
                        checked={ulozitAdrOdesilatel}
                        onChange={(e) => {setForm(e)}}
                        value="checked"
                        id="ulozitAdrOdesilatel"
                        name="ulozitAdrOdesilatel"
                        color="primary"
                      />
                    </Grid>
                    <Grid  item xs={12} sm={4} style={{textAlign:'center'}}>
                  
                      Odesílatel
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <IconButton onClick={openOdesilatelAdresa} className={classes.iconColor} ><BookIcon /></IconButton>     
                    </Grid>   
                  </Grid>                
                </Paper>
                
              </Grid>
              <Grid item xs={12}  sm={12}>          
                <TextFields
                  required
                  id="odesilatelJmeno"
                  key="odesilatelJmeno"
                  name="odesilatelJmeno"
                  label="Firma"
                  type="text"
                  value={odesilatelJmeno}
                  fullWidth
                  variant="outlined"
                  size="small"
                  onChange={setForm}  
                  error={odesilatelJmeno.length<1}
                  />
              </Grid>
              <Grid item xs={12}  sm={12}>          
                <TextFields
                  required
                  id="odesilatelAdresa"
                  key="odesilatelAdresa"
                  name="odesilatelAdresa"
                  label="Adresa"
                  type="text"
                  value={odesilatelAdresa}
                  fullWidth
                  variant="outlined"
                  size="small"
                  onChange={setForm}                  
                  error={odesilatelAdresa.length<1}
                  />
              </Grid>
              <Grid item xs={12}  sm={12}>          
                <TextFields
                  id="odesilatelAdresa2"
                  key="odesilatelAdresa2"
                  name="odesilatelAdresa2"
                  label="Adresa 2"
                  type="text"
                  value={odesilatelAdresa2}
                  fullWidth
                  variant="outlined"
                  size="small"
                  onChange={setForm}
                  />
              </Grid>
              <Grid item xs={12}  sm={12}>          
                <TextFields
                  id="odesilatelAdresa3"
                  key="odesilatelAdresa3"
                  name="odesilatelAdresa3"
                  label="Adresa 3"
                  type="text"
                  value={odesilatelAdresa3}
                  fullWidth
                  variant="outlined"
                  size="small"
                  onChange={setForm}
                  />
              </Grid>
              <Grid item xs={12}  sm={12}>          
                <TextFields
                  required
                  id="odesilatelMesto"
                  key="odesilatelMesto"
                  name="odesilatelMesto"
                  label="Město"
                  type="text"
                  value={odesilatelMesto}
                  fullWidth
                  variant="outlined"
                  size="small"
                  onChange={setForm}  
                  error={odesilatelMesto.length<1}
                  />
              </Grid>
              <Grid item xs={12}  sm={12}>          
                <TextFields
                  required
                  id="odesilatelPSC"
                  key="odesilatelPSC"
                  name="odesilatelPSC"
                  label="PSČ"
                  type="text"
                  value={odesilatelPSC}
                  fullWidth
                  variant="outlined"
                  size="small"
                  onChange={setForm}  
                  error={odesilatelPSC.length<1}
                  />
              </Grid>
              <Grid item xs={12}  sm={12}>        
              <Autocomplete
                  id="adresFrom"
                  name="adresFrom"
                  label="Stát odeslání"
                  options={Staty}
                  getOptionLabel={(option) => option.Nazev ? option.Nazev : ''}
                  fullWidth         
                  clearOnBlur 
                  disabled
                  value={DefAdresFrom}     
                  getOptionSelected={(option, value) => option.value === value.value}               
                  renderOption={(option) => (
                    <React.Fragment>
                        <span><img src={"../img/flags/24/"+option.Kod+".png"} alt={option.Kod}/></span>
                        {option.Nazev}
                    </React.Fragment>
                  )}
                  renderInput={(params) => <TextField {...params} label="Stát odeslání" size="small" variant="outlined" />}
                />
              </Grid>
              <Grid item xs={12}  sm={12}>          
                <TextFields
                  required
                  id="odesilatelKontOsoba"
                  key="odesilatelKontOsoba"
                  name="odesilatelKontOsoba"
                  label="Kontaktní osoba"
                  type="text"
                  value={odesilatelKontOsoba}
                  fullWidth
                  variant="outlined"
                  size="small"
                  onChange={setForm}  
                  error={odesilatelKontOsoba.length<1}
                  />
              </Grid>
              <Grid item xs={12}  sm={12}>          
                <TextFields
                  required
                  id="odesilatelEmail"
                  key="odesilatelEmail"
                  name="odesilatelEmail"
                  label="Email"
                  type="text"
                  value={odesilatelEmail}
                  fullWidth
                  variant="outlined"
                  size="small"
                  onChange={setForm}  
                  error={odesilatelEmail.length<1}
                  />
              </Grid>
              <Grid item xs={12}  sm={12}>          
                <TextFields
                  required
                  id="odesilatelTel"
                  key="odesilatelTel"
                  name="odesilatelTel"
                  label="Telefon"
                  type="text"
                  value={odesilatelTel}
                  fullWidth
                  variant="outlined"
                  size="small"
                  onChange={setForm}  
                  error={odesilatelTel.length<1}
                  />
              </Grid>
            </Grid>            
            <Grid container item spacing={2} xs={12} sm={12}>
              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>Podrobnosti</Paper>
              </Grid>
              <Grid item xs={12}  sm={12}>          
                <TextFields
                  id="popisZbozi"
                  key="popisZbozi"
                  name="popisZbozi"
                  label="Popis zboží"
                  type="text"
                  value={popisZbozi}
                  fullWidth
                  variant="outlined"
                  size="small"
                  onChange={setForm}
                  />
              </Grid>
              <Grid item xs={12}  sm={12}>          
                <TextFields
                  required
                  disabled={doplnky[0].DoplnekInt}
                  id="hodnotaZbozi"
                  key="hodnotaZbozi"
                  name="hodnotaZbozi"
                  label="Hodnota zásilky"
                  type="number"
                  value={hodnotaZbozi}
                  fullWidth
                  variant="outlined"
                  size="small"
                  InputProps={{
                  startAdornment: <InputAdornment position="start">Kč</InputAdornment>
                  }}
                  onChange={setForm}
                  error={hodnotaZbozi===''}
                  />
              </Grid>
              <Grid item xs={12}  sm={12}>          
                <TextFields
                  id="ReferencniCislo"
                  key="ReferencniCislo"
                  name="ReferencniCislo"
                  label="Referenční číslo"
                  type="text"
                  value={ReferencniCislo}
                  fullWidth
                  variant="outlined"
                  size="small"
                  onChange={setForm}
                  />
              </Grid>




              
            </Grid>
            <Grid container item spacing={2} xs={12}sm={12}>
              <Grid item xs={12} sm={12}>
                < Paper className={classes.paper}>Datum a čas vyzvednutí</Paper>
              </Grid>
              <Grid item xs={12} sm={6}>     
                <TextFields
                  required
                  id="datumVyzvednuti"
                  key="datumVyzvednuti"
                  name="datumVyzvednuti"
                  label="Datum vyzvednutí"
                  type="date"
                  size="small"
                  inputProps={{
                    min: moment().format("YYYY-MM-DD")
                  }}
                  fullWidth
                  onChange={setForm}
                  variant="outlined"
                  value={datumVyzvednuti}
                />       
              </Grid>
              <Grid item xs={12} sm={6}/>  
              <Grid item xs={12} sm={6}>         
                <TextFields
                  required
                  id="casOd"
                  key="casOd"
                  name="casOd"
                  label="Čas vyzvednutí od"
                  type="time"
                  size="small"
                  fullWidth
                  onChange={setForm}
                  variant="outlined"
                  value={casOd} 
                />
              </Grid>
              <Grid item xs={12} sm={6}>         
                <TextFields
                  required
                  id="casDo"
                  key="casDo"
                  name="casDo"
                  label="Čas vyzvednutí do"
                  type="time"
                  size="small"
                  fullWidth
                  onChange={setForm}
                  variant="outlined"
                  value={casDo}
                />
              </Grid>
              <Grid item xs={12}  sm={12}>         
                <TextFields
                  id="poznamka"
                  key="poznamka"
                  name="poznamka"
                  label="Pokyny pro kurýra"
                  type="text"
                  size="small"
                  fullWidth
                  onChange={setForm}
                  variant="outlined"
                  value={poznamka}
                />
              </Grid>
            </Grid>
          </Grid>




          <Grid container item spacing={2} xs={12} sm={6}>

            <Grid container item spacing={2} xs={12} sm={12}>
              <Grid item xs={12} sm={12} >
                <Paper className={classes.paper}>
                  <Grid
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    container
                  >
                    <Grid item xs={12} sm={4} style={{textAlign:'left'}}>
                      Uložit adresu
                      <WhiteCheckbox
                        checked={ulozitAdrprijemce}
                        onChange={(e) => {setForm(e)}}
                        value="checked"
                        id="ulozitAdrprijemce"
                        name="ulozitAdrprijemce"
                        color="primary"
                      />
                      </Grid>
                      <Grid item xs={12} sm={4} style={{textAlign:'center'}}>                    
                        Příjemce
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <IconButton onClick={openPrijemceAdresa} className={classes.iconColor} ><BookIcon /></IconButton>  
                    </Grid>
                  </Grid>
                </Paper>      
              </Grid>
              <Grid item xs={12}  sm={12}>          
                <TextFields
                  required
                  id="prijemceJmeno"
                  key="prijemceJmeno"
                  name="prijemceJmeno"
                  label="Firma"
                  type="text"
                  value={prijemceJmeno}
                  fullWidth
                  variant="outlined"
                  size="small"
                  onChange={setForm}  
                  error={prijemceJmeno.length<1}
                  />
              </Grid>
              <Grid item xs={12}  sm={12}>          
                <TextFields
                  required
                  id="prijemceAdresa"
                  key="prijemceAdresa"
                  name="prijemceAdresa"
                  label="Adresa"
                  type="text"
                  value={prijemceAdresa}
                  fullWidth
                  variant="outlined"
                  size="small"
                  onChange={setForm}  
                  error={prijemceAdresa.length<1}
                  />
              </Grid>
              <Grid item xs={12}  sm={12}>          
                <TextFields
                  id="prijemceAdresa2"
                  key="prijemceAdresa2"
                  name="prijemceAdresa2"
                  label="Adresa 2"
                  type="text"
                  value={prijemceAdresa2}
                  fullWidth
                  variant="outlined"
                  size="small"
                  onChange={setForm}
                  />
              </Grid>
              <Grid item xs={12}  sm={12}>          
                <TextFields
                  id="prijemceAdresa3"
                  key="prijemceAdresa3"
                  name="prijemceAdresa3"
                  label="Adresa 3"
                  type="text"
                  value={prijemceAdresa3}
                  fullWidth
                  variant="outlined"
                  size="small"
                  onChange={setForm}
                  />
              </Grid>
              <Grid item xs={12}  sm={12}>          
                <TextFields
                  required
                  id="prijemceMesto"
                  key="prijemceMesto"
                  name="prijemceMesto"
                  label="Město"
                  type="text"
                  value={prijemceMesto}
                  fullWidth
                  variant="outlined"
                  size="small"
                  onChange={setForm}  
                  error={prijemceMesto.length<1}
                  />
              </Grid>
              <Grid item xs={12}  sm={12}>          
                <TextFields
                  required
                  id="prijemcePSC"
                  key="prijemcePSC"
                  name="prijemcePSC"
                  label="PSČ"
                  type="text"
                  value={prijemcePSC}
                  fullWidth
                  variant="outlined"
                  size="small"
                  onChange={setForm}  
                  error={prijemcePSC.length<1}
                  />
              </Grid>
              <Grid item xs={12}  sm={12}>     
              <Autocomplete
                  id="adresTo"
                  name="adresTo"
                  label="Stát doručení"
                  options={Staty}
                  getOptionLabel={(option) => option.Nazev ? option.Nazev : ''}
                  fullWidth         
                  clearOnBlur 
                  disabled
                  value={formData.DefAdresTo}     
                  getOptionSelected={(option, value) => option.value === value.value}               
                  renderOption={(option) => (
                    <React.Fragment>
                        <span><img src={"../img/flags/24/"+option.Kod+".png"} alt={option.Kod}/></span>
                        {option.Nazev}
                    </React.Fragment>
                  )}
                  renderInput={(params) => <TextField {...params} label="Stát doručení" size="small" variant="outlined" />}
                />
              </Grid>
              <Grid item xs={12}  sm={12}>          
                <TextFields
                  required
                  id="prijemceKontOsoba"
                  key="prijemceKontOsoba"
                  name="prijemceKontOsoba"
                  label="Kontaktní osoba"
                  type="text"
                  value={prijemceKontOsoba}
                  fullWidth
                  variant="outlined"
                  size="small"
                  onChange={setForm}  
                  error={prijemceKontOsoba.length<1}
                  />
              </Grid>
              <Grid item xs={12}  sm={12}>          
                <TextFields
                  required
                  id="prijemceEmail"
                  key="prijemceEmail"
                  name="prijemceEmail"
                  label="Email"
                  type="text"
                  value={prijemceEmail}
                  fullWidth
                  variant="outlined"
                  size="small"
                  onChange={setForm}  
                  error={prijemceEmail.length<1}
                  />
              </Grid>
              <Grid item xs={12}  sm={12}>          
                <TextFields
                  required
                  id="prijemceTel"
                  key="prijemceTel"
                  name="prijemceTel"
                  label="Telefon"
                  type="text"
                  value={prijemceTel}
                  fullWidth
                  variant="outlined"
                  size="small"
                  onChange={setForm}  
                  error={prijemceTel.length<1}
                />
              </Grid>
            </Grid>            
            
            <Grid container item spacing={2} xs={12} sm={12}>
              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>

                <Grid
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      container

                  >
                    <Grid item xs={12} sm={4} />
                    <Grid  item xs={12} sm={4} style={{textAlign:'center'}}>
                  
                      Plátce dopravy
                    </Grid>                
                    < Grid item xs={12} sm={4}>                    
                      {(UserType === '1') && (
                        <IconButton onClick={openOdesilatelPlatce} className={classes.iconColor} ><PersonIcon /></IconButton>     
                      )}
                    </Grid> 
                  </Grid> 
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12}>      
                <TableContainer>
                  <Table className={classes.table} aria-label="caption table">
                      <TableBody>
                        <TableRow>
                          <TableCell className={classes.tableCell}  width="120px" align="left"><div className={classes.valueHeader}>{"Firma:"}</div></TableCell>
                          <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{Platce.Firma}</div></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{"Email:"}</div></TableCell>
                          <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{Platce.Email}</div></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{"Telefon:"}</div></TableCell>
                          <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{Platce.Telefon}</div></TableCell>
                        </TableRow>
                      </TableBody>     
                    </Table>
                  </TableContainer>
              </Grid>
            </Grid>


            
            <Grid container item spacing={2} xs={12} sm={12}>
              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>Doplňující informace</Paper>
              </Grid>
              <Grid item xs={12} sm={4}>
              <Typography className={classes.valueHeader}>
                <GreenCheckbox
                  checked={UseProForma}
                  disabled={doplnky[7].DoplnekInt}
                  onChange={(e) => {setForm(e)}}
                  value="checked"
                  id="UseProForma"
                  name="UseProForma"
                  color="primary"
                />
                Proforma invoice
              </Typography>
              </Grid>        
              
              {(UseProForma === true) && (      
              <Grid item xs={12} sm={12}>
                <GreenButton onClick={handleOpemPROFORMA}>PROFORMA INVOICE</GreenButton>                
              </Grid>
              )}


            </Grid>

            <Grid container item spacing={2} xs={12} sm={12}>
              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>Přílohy</Paper>
              </Grid>            
              <Grid item xs={12} sm={12}>                          
                <input
                    accept="*"
                    className={classes.input}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={(e) => {onChangeFile(e)}} 
                />
                <label htmlFor="contained-button-file">
                  <GreenButton variant="contained" color="primary" component="span"><AttachFile className={classes.ikonaBtn} />
                  
                  Přílohy
                  </GreenButton>
                </label>
              </Grid>  
              <Grid item xs={12} sm={12}>  
                {(Prilohy && Prilohy.length>0) && (
                <>

                    {Prilohy[0].map(row => {
                    return (
                        
                        <Typography key={row.name} className={classes.typo}>{row.name}</Typography>
                    );
                    })}
                </>
                )}
              </Grid>                
            </Grid>
          </Grid>
          
          
          
          {(errorMsg !== '' && errorMsg !== null && errorMsg !== undefined) && (      
          <Grid item xs={12} sm={12}>
            <div style={{ visibility: errorMsg === '' ? 'hidden': 'visible'}}>             
                  <Alert severity="error">{errorMsg}</Alert>          
            </div>   
          </Grid>
          )}
          
          <Grid container item xs={12} sm={12}>
            <Grid item xs={12} sm={2}>
                <GreenButton
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={() => navigation.previous()}
                >Zpět
                </GreenButton>
              </Grid>
              <Grid item xs={12} sm={8}/>               
              <Grid item xs={12} sm={2}>
                <GreenButton
                    variant="contained"
                    fullWidth
                    disabled={Dis}
                    color="primary"
                    onClick={() => {validace();PridejPrilohy()}}
                  >Shrnutí
                </GreenButton>
              </Grid>
          </Grid>
        </Grid>
      </form>      
    </Container>
  );
};