import React, {useContext,useState, useEffect} from 'react';
import { DataGrid } from '@material-ui/data-grid';
import {UserContext} from '../../contexts/UserContext'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles,withStyles, } from '@material-ui/core/styles';
import customConfig from '../../customConfig.json'
import Checkbox from '@material-ui/core/Checkbox';
import * as moment from 'moment'
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Stavy from '../Zasilky/Stavy/Stavy'
import Adresy from '../Zasilky/Adresy/Adresy'
import Sluzby from '../Zasilky/Sluzby/Sluzby'
import LaunchIcon from '@material-ui/icons/Launch';
import Link from '@material-ui/core/Link';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Container from '@material-ui/core/Container';
import InputAdornment from "@material-ui/core/InputAdornment";
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Paper from '@material-ui/core/Paper';
import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from '@material-ui/core/TableHead';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const TextFields = withStyles({
  root: {
    '& label.Mui-focused': {
      color: customConfig.Colors.PrimaryColor,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: customConfig.Colors.PrimaryColor,
    },
    '& .MuiOutlinedInput-root': {

      '&:hover fieldset': {
        borderColor: customConfig.Colors.SecColor,
      },
      '&.Mui-focused fieldset': {
        borderColor: customConfig.Colors.PrimaryColor,
      },
    },
  },
})(TextField);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{padding:'0px'}} p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
} 

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    flexDirection:"row",
    justifyContent: 'center',
    backgroundColor: customConfig.Colors.PrimaryColor,
    '& > span': {
      width: '100%',
      backgroundColor: customConfig.Colors.PrimaryColor,
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const GreenCheckbox = withStyles({
  root: {
    color: customConfig.Colors.PrimaryColor,
    '&$checked': {
      color: customConfig.Colors.PrimaryColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);



const GreenButton = withStyles((theme) => ({
  root: {
    color: customConfig.Colors.ButtonTextColor, 
    backgroundColor: customConfig.Colors.PrimaryColor,
    fontWeight:'520',
    '&:hover': {
      backgroundColor: customConfig.Colors.SecColor,
    },
  },
}))(Button);



export default function Objednavky(){
  const {rootState} = useContext(UserContext);
  const {isAdmin} = rootState;

    const initialZasilky = {
      ZasilkyInfo:{    
        ZasilkaID:'',
        SluzbaID:'',
        SluzbaNazev:'',    
        PrepravceID:'',
        PrepravceNazev:'',            
        DruhID:null, 
        NazevDruhu:'',            
        StatVyzvednutiKod:'',
        StatVyzvednuti:'',
        StatDoruceni:'',
        StatDoruceniKod:'',
        DatumVyzvednuti:'',
        DatumZalozeni:'',
        StavID:null,
        NazavStavu:'',
        Hodnota:'',
        ReferencniCislo:'',
        CisloZasilky:'',
        Track:'',
        CenaZasilky:'',
        CenaZaklad:'',
        PoznamkaDopravce:'',
        Popis:'',
        tackCisloURL:'',
        CasVyzvednutiDo:'00:00',
        CasVyzvednutiOd:'00:00',
        Prijemce:'',
        Odesilatel:'',
        Firma:'',
        Vyzvednuto:'',
        Doruceno:'',

        CenaCelkem:'',
        DPH:false,
        SazbaDPH:0,
    },
    defaultStav:[],
    defaultDruh:[],
    defaultSluzba:[],
    defaultFirma:[]
  };


    const useStyles = makeStyles((theme) => ({
        modal: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
          
        },
        nadpisH:{
          color: customConfig.Colors.ButtonTextColor,
          fontSize:'20px'
        },
        paperH: {
          textAlign: 'left',
          paddingLeft:'20px',
          backgroundColor: customConfig.Colors.PrimaryColor,
          boxShadow: 'none',
          border:'1px',
          borderRadius: '8px 8px 0px 0px'
        },
        table: {
          minWidth: 200
        },
        tableRows: {
          height: '25px'
        },
        tableRow: {
          padding:"5px 15px 5px 25px",
          backgroundColor: customConfig.Colors.GridBackgroundColor,
      
        },
        margin: {
          margin: '0px',
        },
        hide: {
          display: 'none',
        },      
        callContainer: {
          backgroundColor: 'none',
        },
        form: {
          width: '100%', // Fix IE 11 issue.
          marginTop: theme.spacing(1),
          backgroundColor: 'none',
        },
        withoutLabel: {
          marginTop: theme.spacing(1),
        },
        boxStyle:{
          marginTop:'15px'

        },
        nadpis:{
          color: customConfig.Colors.PrimaryTextColor
        },
        left:{
          paddingLeft: '10px',
        },        
        textf: {
          padding: '0px 8px 0px 0px',
        },
        bgGrid: {
          backgroundColor: customConfig.Colors.GridBackgroundColor,
          padding:'0px !important',
          width:'100% !important',
          margin:'0px !important'
        },
        containerX: {
          width:'100% !important',
          padding:'0px !important',
          margin:'0px !important'
        },
        datag: {
          '& .super-app-theme--header': {
            backgroundColor: customConfig.Colors.PrimaryColor,
            color:customConfig.Colors.ButtonTextColor,
          },
          '& .MuiDataGrid-columnsContainer': {
            backgroundColor: customConfig.Colors.PrimaryColor,
            color:customConfig.Colors.ButtonTextColor,
          },
          '& .MuiDataGrid-row': {
            cursor:'pointer'
          },
          borderBlockColor:customConfig.Colors.PrimaryColor,
          padding:'0px'
        },
        appBar:{
          padding:'0px',
          color:customConfig.Colors.PrimaryColor,
          backgroundColor:customConfig.Colors.PrimaryColor,
      },
      tableHeadRow: {
        padding:"5px 15px 5px 25px",
        backgroundColor: customConfig.Colors.PrimaryColor,
        color: customConfig.Colors.ButtonTextColor
      },
      nadpis:{
        color: customConfig.Colors.PrimaryTextColor
      },
      innerPadding:{
        padding: '20px',
      },  
      left:{
        paddingLeft: '10px',
      },  
      input: {
        display: 'none',
      },
      typo: {
        color: 'Black',
      },   
      gridM: {
        margin: '10px',
      },   
      trckUrl: {
        display: 'flex',
        alignItems:'center'
      },   
      btnm: {
        margin:'0px 0px 0px 25px'
      },   
      btnmFiltr: {
        margin:'15px'
      },   
      trckUrlic: {
        color: customConfig.Colors.PrimaryColor,
        margin:'0px',
        padding:'0px',
        fontSize:30
      },  
      paper: {
        backgroundColor: theme.palette.background.paper,
        border: customConfig.Colors.BorderColor,          
        boxShadow: theme.shadows[5],
        padding: '0px',
        borderRadius:'10px 10px 8px 8px',
        border:'none'
      },
      tab:{
          
          color: customConfig.Colors.TextColor,
          backgroundColor:customConfig.Colors.PrimaryColor,
          textTransform:'none',
          '&.Mui-selected': {
              backgroundColor: customConfig.Colors.SecColor,
              color: customConfig.Colors.TextColor,
              
          },
          '&:hover': {
             backgroundColor: customConfig.Colors.SecColor,
             color: customConfig.Colors.TextColor,
             opacity: 1,
          },       
      },
      root: {
        flexGrow: 1,
      },  

        
      }));

 

    const {getZasilkyAdminFiltr,getStavy,getDruhy,getZasilkaZakladniInformace,postEditZasilky,postAkceptace,getSluzby,getPrepravci,getFirmy,getPolozky,postPolozka,postDeleteZasilky} = useContext(UserContext);
    const [zasilky,setState] = useState(initialZasilky);  
    const [Zasilky, setZasilky] = useState([])    
    const classes = useStyles();
    const [Prilohy, setPrilohy] = useState([]);
    const [value, setValue] = React.useState(0);
    const [StavyZasilek, setStavyZasilek] = useState([]);
    const [DruhyZasilek, setDruhyZasilek] = useState([]);
    const [sluzby, setSluzby] = useState([]);
    const [Prepravci, setPrepravci] = useState([]);
    const [Firmy, setFirmy] = useState([]);
    const [disable] = React.useState(false);
    let history = useHistory();
    const [open, setOpen] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [openFiltr, setOpenFiltr] = React.useState(true);
    const [openEditPolozky, setOpenEditPolozky] = React.useState(false);
    const [loadData, setloadData] = React.useState(true);
    const [openMSG, setOpenMSG] = React.useState(false);
    const [Polozky, setPolozky] = useState([]);
    const [Polozka, setPolozka] = useState({
      Delka: "",
      DruhID: "",
      Hmotnost: "",
      HmotnostDruh: "",
      ObjemovaHmotnost: "",
      Pocet: "",
      Sirka: "",
      Vyska: "",
      ZasilkaID: "",
      ZasilkaPolozkyID: "",
      defaultDruh:null
    });
    const [filtr,setFiltr] = useState({
      SluzbaID:'',
      StavID:'',
      DruhID:'',
      FirmaID:'',
      PrepravceID:'',
      CisloZasilky:'',
      DatumZalozeniOd:'',
      DatumZalozeniDo:'',
      defaultPrepravce: null,
      defaultFirma: null,
      defaultStav: null,
      defaultDruh: null ,
      defaultSluzba: null    
    });  


    const handleOpenDelete = (e) => {setOpenDelete(true);};  
    const handleCloseDelete = (e) => {setOpenDelete(false);}
    const handleOpenAccept = (e) => {setOpen(true);};  
    const handleCloseAccept = (e) => {setOpen(false);}
    const handleOpenFiltr = (e) => { setOpenFiltr(true);};  
    const handleCloseFiltr = (e) => {setOpenFiltr(false);}
    const handleOpenEditPolozky = (e) => { setOpenEditPolozky(true);};  
    const handleCloseEditPolozky = (e) => {setOpenEditPolozky(false);}

    const handleCloseAlert = (event, reason) => {
      if (reason === 'clickaway') {return;}  
      setOpenMSG(false);
    };
    
    useEffect(() => {
      if(isAdmin!==true){
        history.push("/Home");
      }
      else 
      {
        document.title = "SEBAplus - Objednávky";

        async function fetchData() {
          const dataStavy = await getStavy(); 
          const dataDruhy = await getDruhy(); 
          const dataSluzby = await getSluzby(); 
          const dataPrepravci = await getPrepravci(); 
          const dataFrimy = await getFirmy(); 

          setStavyZasilek(dataStavy) 
          setDruhyZasilek(dataDruhy) 
          setSluzby(dataSluzby) 
          setPrepravci(dataPrepravci) 
          setFirmy(dataFrimy) 
        }  
        fetchData()
      }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])   


    const columns = [    
        { field: 'ZasilkaID', type: 'string', hide: true },
        { field: 'StavID', type: 'string', hide: true },
        { field: 'CisloZasilky', type: 'string', headerClassName: 'super-app-theme--header', headerName: 'Číslo SEBAplus', width: 160,}, 
        { field: 'NazavStavu', type: 'string', headerClassName: 'super-app-theme--header', headerName: 'Stav', width: 120}, 
        { field: 'NazevDruhu', type: 'string', headerClassName: 'super-app-theme--header', headerName: 'Druh', width: 120}, 
        { field: 'SluzbaNazev', type: 'string', headerClassName: 'super-app-theme--header', headerName: 'Název služby', width: 200},
        { field: 'Track', type: 'string', headerClassName: 'super-app-theme--header', headerName: 'Track', width: 150,  disableColumnMenu:true},
        {
            field: "DatumZalozeni",
            headerName: "Objednáno",
            headerClassName: 'super-app-theme--header',
            disableColumnMenu:false, 
            sortable: true,
            width: 150,
            renderCell: (params) => {
                
                const api = params.api;
                const fields = api
                  .getAllColumns()
                  .map((c) => c.field)
                  .filter((c) => c !== "__check__" && !!c);
    
                const thisRow = {};
                fields.forEach((f) => {
                  thisRow[f] = params.getValue(f);
                });
                if(thisRow.DatumZalozeni)
                {
                    return (moment(thisRow.DatumZalozeni).format("DD.MM.YYYY"));

                }
                else
                {
                    return("");
                }
            }
        },
        { field: 'Firma', type: 'string', headerClassName: 'super-app-theme--header', headerName: 'Objednavatel', width: 200,},         
        { field: 'Odesilatel', type: 'string', headerClassName: 'super-app-theme--header', headerName: 'Odesílatel', width: 200,}, 
        { field: 'Prijemce', type: 'string', headerClassName: 'super-app-theme--header', headerName: 'Příjemce', width: 200,},
        {
            field: "Vyzvednuto",
            headerName: "Vyzvednuto",
            headerClassName: 'super-app-theme--header',
            disableColumnMenu:false, 
            sortable: true,
            width: 150,
            renderCell: (params) => {
                
                const api = params.api;
                const fields = api
                  .getAllColumns()
                  .map((c) => c.field)
                  .filter((c) => c !== "__check__" && !!c);
    
                const thisRow = {};
                fields.forEach((f) => {
                  thisRow[f] = params.getValue(f);
                });
                
                if(thisRow.Vyzvednuto)
                {
                    return (moment(thisRow.Vyzvednuto).format("DD.MM.YYYY"));

                }
                else
                {
                    return("");
                }
            }
        }, 
        {
            field: "Doruceno",
            headerName: "Doručeno",
            headerClassName: 'super-app-theme--header',
            disableColumnMenu:false, 
            sortable: true,
            width: 150,
            renderCell: (params) => {
                
                const api = params.api;
                const fields = api
                  .getAllColumns()
                  .map((c) => c.field)
                  .filter((c) => c !== "__check__" && !!c);
    
                const thisRow = {};
                fields.forEach((f) => {
                  thisRow[f] = params.getValue(f);
                });
                
                if(thisRow.Doruceno)
                {
                    return (moment(thisRow.Doruceno).format("DD.MM.YYYY"));

                }
                else
                {
                    return("");
                }
            }
        },
        { field: 'Popis', type: 'string', headerClassName: 'super-app-theme--header', headerName: 'Popis', width: 200,disableColumnMenu:true},
      ];


    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    async function currentlySelected(v) {  
      async function fetchData(v) {

        const dataOUD = await getZasilkaZakladniInformace(v.ZasilkaID);         
        const dataOUD2 = await getPolozky(v.ZasilkaID); 

        if(dataOUD2)
        {
          setPolozky(dataOUD2)
        }
        
        if(dataOUD)
        {
          if(dataOUD.ZakladniInformace)
          {          
            if(dataOUD.ZakladniInformace[0]!==[] && dataOUD!== undefined)
            {
              setState({
                ...zasilky,
                ZasilkyInfo:{
                    ...zasilky.ZasilkyInfo,
                    ZasilkaID:dataOUD.ZakladniInformace[0].ZasilkaID,
                    SluzbaID:dataOUD.ZakladniInformace[0].SluzbaID,
                    FirmaID:dataOUD.ZakladniInformace[0].FirmaID,
                    SluzbaNazev:dataOUD.ZakladniInformace[0].SluzbaNazev,    
                    PrepravceID:dataOUD.ZakladniInformace[0].PrepravceID,
                    PrepravceNazev:dataOUD.ZakladniInformace[0].PrepravceNazev,            
                    DruhID:dataOUD.ZakladniInformace[0].DruhID, 
                    NazevDruhu:dataOUD.ZakladniInformace[0].NazevDruhu,            
                    StatVyzvednutiKod:dataOUD.ZakladniInformace[0].StatVyzvednutiKod,
                    StatVyzvednuti:dataOUD.ZakladniInformace[0].StatVyzvednuti,
                    StatDoruceni:dataOUD.ZakladniInformace[0].StatDoruceni,
                    StatDoruceniKod:dataOUD.ZakladniInformace[0].StatDoruceniKod,
                    DatumVyzvednuti:dataOUD.ZakladniInformace[0].DatumVyzvednuti,
                    DatumZalozeni:dataOUD.ZakladniInformace[0].DatumZalozeni,
                    StavID:dataOUD.ZakladniInformace[0].StavID,
                    NazavStavu:dataOUD.ZakladniInformace[0].NazavStavu,
                    Hodnota:dataOUD.ZakladniInformace[0].Hodnota,
                    ReferencniCislo:dataOUD.ZakladniInformace[0].ReferencniCislo,
                    CisloZasilky:dataOUD.ZakladniInformace[0].CisloZasilky,
                    Track:dataOUD.ZakladniInformace[0].Track,
                    CenaZasilky:dataOUD.ZakladniInformace[0].CenaZasilky,
                    CenaZaklad:dataOUD.ZakladniInformace[0].CenaZaklad,
                    PoznamkaDopravce:dataOUD.ZakladniInformace[0].PoznamkaDopravce,
                    Popis:dataOUD.ZakladniInformace[0].Popis,
                    tackCisloURL:dataOUD.ZakladniInformace[0].tackCisloURL,
                    CasVyzvednutiDo:dataOUD.ZakladniInformace[0].CasVyzvednutiDo,
                    CasVyzvednutiOd:dataOUD.ZakladniInformace[0].CasVyzvednutiOd,
                    Prijemce:dataOUD.ZakladniInformace[0].Prijemce,
                    Odesilatel:dataOUD.ZakladniInformace[0].Odesilatel,
                    Firma:dataOUD.ZakladniInformace[0].Firma,
                    Doruceno:dataOUD.ZakladniInformace[0].Doruceno,
                    Vyzvednuto:dataOUD.ZakladniInformace[0].Vyzvednuto,
                    CenaCelkem:dataOUD.ZakladniInformace[0].CenaCelkem,
                    DPH:dataOUD.ZakladniInformace[0].DPH == "1" ? true : false,
                    SazbaDPH:dataOUD.ZakladniInformace[0].SazbaDPH,
                },         
                defaultStav: StavyZasilek.find(x => x.StavID === dataOUD.ZakladniInformace[0].StavID) !== undefined ? StavyZasilek.find(x => x.StavID === dataOUD.ZakladniInformace[0].StavID) : null,
                defaultDruh: DruhyZasilek.find(x => x.DruhID === dataOUD.ZakladniInformace[0].DruhID) !== undefined ? DruhyZasilek.find(x => x.DruhID === dataOUD.ZakladniInformace[0].DruhID) : null,
                defaultSluzba: sluzby.find(x => x.SluzbaID === dataOUD.ZakladniInformace[0].SluzbaID) !== undefined ? sluzby.find(x => x.SluzbaID === dataOUD.ZakladniInformace[0].SluzbaID) : null,
                defaultFirma: Firmy.find(x => x.UserID === dataOUD.ZakladniInformace[0].FirmaID) !== undefined ? Firmy.find(x => x.UserID === dataOUD.ZakladniInformace[0].FirmaID) : null                  
              });              
            }
          }
        }

      }

      fetchData(v) 
    }

console.log(zasilky)

    const onChangeFile = (event) => {
  
      for (let i = 0; i < Prilohy.length; i++) {
        Prilohy[i] = undefined;
      }
  
      setPrilohy([]);
  
      let files = event.target.files;    
      let fil = [];
  
      
      for (let i = 0; i < files.length; i++) {
        
        (function(file) {
          let reader = new FileReader();
          reader.fileName = file.name;
          reader.fileType = file.type;
          reader.readAsDataURL(file); 
  
          reader.onload = function(eventX) {  
            fil[i] = {name:eventX.target.fileName,type:eventX.target.fileType,file:eventX.target.result};
            setPrilohy([fil]);
          }
        })(files[i]);
      }
  
    };
  
    const onChangeValue = (e) => {
      setState({
          ...zasilky,
          ZasilkyInfo:{
              ...zasilky.ZasilkyInfo,
              [e.target.name]:e.target.value
          },
      });        
    }
    

    const handleChangeDPH = (e) => {
      
      setState({
          ...zasilky,
          ZasilkyInfo:{
              ...zasilky.ZasilkyInfo,
              [e.target.name]:e.target.checked,
              SazbaDPH: e.target.checked === false? 0:zasilky.ZasilkyInfo.SazbaDPH
          },
      });
  }

    const onChangeValuePolozky = (e) => {
      setPolozka({
          ...Polozka,
          [e.target.name]:e.target.value
      });        
    }
      
    const onChangeValueFiltr = (e) => {
      setFiltr({
          ...filtr,
          [e.target.name]:e.target.value
      });        
    }
      
    const editRow = (event,data) => {
      setPolozka(
        {
          Delka: data.Delka,
          DruhID: data.DruhID,
          Hmotnost: data.Hmotnost,
          ObjemovaHmotnost: data.ObjemovaHmotnost,
          Pocet: data.Pocet,
          Sirka: data.Sirka,
          Vyska: data.Vyska,
          ZasilkaID: data.ZasilkaID,
          ZasilkaPolozkyID: data.ZasilkaPolozkyID,          
          defaultDruh: DruhyZasilek.find(x => x.DruhID === data.DruhID) !== undefined ? DruhyZasilek.find(x => x.DruhID === data.DruhID) : null ,
        }
      )  ;
      handleOpenEditPolozky();
    }


    const VyhledatZasilky = async (e)  => {
      setloadData(true)

      const dataOUD = await getZasilkyAdminFiltr(filtr); 

      if(dataOUD){
        setZasilky(dataOUD) ;
        setState(initialZasilky) ;
        setloadData(false);
        handleCloseFiltr();
      }
    }


    const submitDeleteZasilka = async (e) => {
  
      const dataOUD = await postDeleteZasilky(zasilky.ZasilkyInfo);  
      
      if(dataOUD)
      {
        VyhledatZasilky() 
      }
      handleCloseDelete()
      
    }





    const updZasilky = async (e) => {
  
      const dataOUD = await postEditZasilky(zasilky.ZasilkyInfo);  
      
      if(dataOUD)
      {
        VyhledatZasilky() 
      }
      
    }
  
    const akceptovatZasilku = async (e) => {
      let prloha = []
      if(Prilohy.length>0){
        prloha = Prilohy[0];
      }
      const dataOUD = await postAkceptace(zasilky.ZasilkyInfo,prloha); 

      if(dataOUD.success)
      {
        VyhledatZasilky() 
        handleCloseAccept();
        setOpenMSG(true);
      }
    }
  const savePolozka = async (e) => {
    
      const dataOUD = await postPolozka(Polozka); 

      if(dataOUD.success)
      {
        const dataOUD2 = await getPolozky(zasilky.ZasilkyInfo.ZasilkaID); 
        if(dataOUD2)
        {
          setPolozky(dataOUD2)
        }

        handleCloseEditPolozky();
      }
  }


  return (


<div className={classes.bgGrid,classes.containerX}>
  <Grid container spacing={2} className={classes.bgGrid}> 
    <Grid item xs={12} sm={12} container className={classes.bgGrid}>     
      <div style={{ minHeight:500, height: '100%', width: '100%' }}>
        <DataGrid className={classes.datag} 
          getRowId={(row) => row.ZasilkaID} 
          rowHeight={30} 
          loading={loadData}
          rows={Zasilky?Zasilky:[]} 
          rowsPerPageOptions={[2000]}
          columns={columns}
          hideFooterSelectedRowCount="true" 
          pagination
          onRowSelected={(e) => currentlySelected(e.data)}                  
          />
      </div>
      </Grid>

      <Grid item xs={12} sm={12} spacing={2} container className={classes.bgGrid}>     
      <AppBar className={classes.appBar} position="relative" style={{flexDirection: 'row', alignItems:'center'}}>        
          <StyledTabs
              value={value} 
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              centered
              className={classes.tabs}
              aria-label="scrollable force tabs example"
          >
            <Tab className={classes.tab} label="Základní informace" {...a11yProps(0)} />
            <Tab className={classes.tab} label="Obsah zásilky" {...a11yProps(1)} />
            <Tab className={classes.tab} label="Adresy" {...a11yProps(2)} />
            <Tab className={classes.tab} label="Stavy" {...a11yProps(3)} />
            <Tab className={classes.tab} label="Doplňkové služby" {...a11yProps(4)} />
          </StyledTabs>

        </AppBar>      
        <div style={{width: '100%',paddingRight:'0px',marginRight:'0px'}}>
          <TabPanel value={value} index={0}>
 
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          key="add"
          onClose={handleCloseAccept}
          closeAfterTransition
          BackdropComponent={Backdrop}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              
            <Paper className={classes.paperH}>
                <Typography className={classes.nadpisH}>Potvrzení zásilky</Typography>
              </Paper>
              <Container className={classes.innerPadding} maxWidth="sm">
                <Grid container spacing={2} justifyContent="space-between">                       
                  <Grid item xs={12} sm={12}> 
                    <TextFields name="Track"  className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Track" label="Číslo přeprace" required placeholder="Číslo přeprace" value={zasilky.ZasilkyInfo.Track || ''} onChange={onChangeValue} />
                  </Grid>
                  <Grid item xs={12} sm={4}> 
                    
                    <input
                      accept="*"
                      className={classes.input}
                      id="contained-button-file"
                      multiple
                      type="file"
                      onChange={(e) => {onChangeFile(e)}} 
                    />
                    <label htmlFor="contained-button-file">
                      <GreenButton variant="contained" color="primary" component="span">
                        Přílohy
                      </GreenButton>
                    </label>
                  </Grid>     
                  <Grid item xs={12} sm={8}>  

                  {(Prilohy && Prilohy.length>0) && (
                    <>
      
                      {Prilohy[0].map(row => {
                        return (
                          
                          <Typography key={row.name} className={classes.typo}>{row.name}</Typography>
                        );
                      })}
                    </>
                  )}
                  </Grid>                             

                  <Grid item xs={12} sm={4}> 
                      <GreenButton fullWidth onClick={(e) => handleCloseAccept()} > Zrušit </GreenButton>
                  </Grid>
                  <Grid item xs={12} sm={4}/> 
                  <Grid item xs={12} sm={4}> 
                      <GreenButton fullWidth onClick={(e) => akceptovatZasilku(e)} startIcon={<SendIcon />} >Potvrdit</GreenButton>
                  </Grid>
                </Grid>
              </Container>
            </div>
          </Fade>
        </Modal>  
        <Grid container>   
              <Grid className={classes.gridM} container spacing={1} item xs={12} sm={12}>
                <Grid item xs={12} md={3} sm={4} lg={2}>  
                  <Autocomplete
                    id="Druh"
                    name="Druh"
                    options={DruhyZasilek}
                    getOptionLabel={(option) => option.Nazev ? option.Nazev : ''}
                    fullWidth                    
                    value={zasilky.defaultDruh}
                    getOptionSelected={(option) => option.DruhID === zasilky.ZasilkyInfo.DruhID}                          
                    onChange={(event, newValue) => {
                      if (newValue) 
                      {                                   
                        setState({
                          ...zasilky,
                          ZasilkyInfo:{
                              ...zasilky.ZasilkyInfo,
                          DruhID:newValue.DruhID,
                          NazevDruhu:newValue.Nazev,
                        },
                          defaultDruh:newValue
                        });
                      }
                    }}    
                    renderOption={(option) => (
                    <React.Fragment>
                        {option.Nazev}
                    </React.Fragment>
                    )}
                    renderInput={(params) => <TextFields {...params} label="Druh zásilky" size="small" variant="outlined" />}
                  />

                </Grid>    
                <Grid item xs={12} md={3} sm={4} lg={2}>                                           
                    <TextFields name="PrepravceNazev" disabled className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="PrepravceNazev" label="Přepravce" required placeholder="Přepravce" value={zasilky.ZasilkyInfo.PrepravceNazev || ''} onChange={onChangeValue} />
                </Grid>              
                <Grid item xs={12} md={3} sm={4} lg={2}>        
                  <Autocomplete
                    id="Sluzby"
                    name="Sluzby"
                    options={sluzby}
                    getOptionLabel={(option) => option.SluzbaNazev ? option.SluzbaNazev : ''}
                    fullWidth                    
                    value={zasilky.defaultSluzba}
                    getOptionSelected={(option) => option.SluzbaID === zasilky.ZasilkyInfo.SluzbaID}                          
                    onChange={(event, newValue) => {
                    if (newValue) {                                  
                      setState({
                          ...zasilky,
                          ZasilkyInfo:{
                              ...zasilky.ZasilkyInfo,
                              SluzbaID:newValue.SluzbaID,
                              SluzbaNazev:newValue.SluzbaNazev
                          },
                          defaultSluzba:newValue,
                      });
                    }
                    }}       
                    renderOption={(option) => (
                    <React.Fragment>
                        {option.SluzbaNazev}
                    </React.Fragment>
                    )}
                    renderInput={(params) => <TextFields {...params} label="Služba" size="small" variant="outlined" />}
                  />
                </Grid>  

                <Grid item xs={12} md={3} sm={4} lg={2}>                                              
                    <TextFields  name="DatumZalozeni" disabled className={clsx(classes.textf,classes.margin)} type="date" InputLabelProps={{ shrink: true }} variant="outlined" size="small" fullWidth id="DatumZalozeni" label="Datum založení" required value={zasilky.ZasilkyInfo.DatumZalozeni || ''} onChange={onChangeValue} />
                </Grid> 
                <Grid item xs={12} md={3} sm={4} lg={2}>                
                  <Autocomplete
                    id="Stav"
                    name="Stav"
                    options={StavyZasilek}
                    getOptionLabel={(option) => option.StavNazev ? option.StavNazev : ''}
                    fullWidth
                    value={zasilky.defaultStav}
                    getOptionSelected={(option) => option.StavID === zasilky.ZasilkyInfo.StavID}                          
                    onChange={(event, newValue) => {
                      if (newValue) {                                  
                          setState({
                              ...zasilky,
                              ZasilkyInfo:{
                                  ...zasilky.ZasilkyInfo,
                                  StavID:newValue.StavID
                              },
                              defaultStav:newValue,
                          });
                      }
                    }}       
                    renderOption={(option) => (
                    <React.Fragment>
                        {option.StavNazev}
                    </React.Fragment>
                    )}
                    renderInput={(params) => <TextFields {...params} label="Stav zásilky" size="small" variant="outlined" />}
                  />
                </Grid> 
                <Grid item xs={12} md={3} sm={4} lg={2}>                                           
                    <TextFields name="CisloZasilky" disabled className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="CisloZasilky" label="Číslo zásilky" required placeholder="Číslo zásilky" value={zasilky.ZasilkyInfo.CisloZasilky} />
                </Grid>  

                <Grid item xs={12} md={3} sm={4} lg={3}>                                           
                    <TextFields name="Odesilatel" disabled className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Odesilatel" label="Odesílatel" required placeholder="Odesílatel" value={zasilky.ZasilkyInfo.Odesilatel || ''} />
                </Grid>  
                <Grid item xs={12} md={3} sm={4} lg={3}>                                           
                    <TextFields name="StatVyzvednuti" disabled className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="StatVyzvednuti" label="Stát vyzvednutí" required placeholder="Stát vyzvednutí" value={zasilky.ZasilkyInfo.StatVyzvednuti || ''} />
                </Grid>  
                <Grid item xs={12} md={3} sm={4} lg={2}>                                              
                    <TextFields  name="DatumVyzvednuti"  className={clsx(classes.textf,classes.margin)} type="date" InputLabelProps={{ shrink: true }} variant="outlined" size="small" fullWidth id="DatumVyzvednuti" label="Datum vyzvednutí" required value={zasilky.ZasilkyInfo.DatumVyzvednuti || ''} onChange={onChangeValue} />
                </Grid>  
                <Grid item xs={12} md={3} sm={4} lg={2}>                                              
                    <TextFields name="CasVyzvednutiOd"  className={clsx(classes.textf,classes.margin)} type="time" variant="outlined" size="small" fullWidth id="CasVyzvednutiOd" label="Čas vyzvednutí od" value={zasilky.ZasilkyInfo.CasVyzvednutiOd} onChange={onChangeValue} />
                </Grid>  
                <Grid item xs={12} md={3} sm={4} lg={2}>                                              
                    <TextFields name="CasVyzvednutiDo"  className={clsx(classes.textf,classes.margin)} type="time" variant="outlined" size="small" fullWidth id="CasVyzvednutiDo" label="Čas vyzvednutí do" value={zasilky.ZasilkyInfo.CasVyzvednutiDo} onChange={onChangeValue} />
                </Grid>  
                <Grid item xs={12} md={3} sm={4} lg={3}>                                           
                    <TextFields name="Prijemce" disabled className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Prijemce" label="Příjemce" required placeholder="Příjemce" value={zasilky.ZasilkyInfo.Prijemce || ''} />
                </Grid>  
                <Grid item xs={12} md={3} sm={4} lg={3}>                                           
                    <TextFields name="StatDoruceni" disabled className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="StatDoruceni" label="Stát doručení" required placeholder="Stát doručení" value={zasilky.ZasilkyInfo.StatDoruceni} />
                </Grid>  
                <Grid item xs={12} md={3} sm={4} lg={2} >                                              
                    <TextFields  name="Doruceno"  className={clsx(classes.textf,classes.margin)} type="date" InputLabelProps={{ shrink: true }} variant="outlined" size="small" fullWidth id="Doruceno" label="Datum doručení" required value={zasilky.ZasilkyInfo.Doruceno || ''} onChange={onChangeValue} />
                </Grid>            
                <Grid item xs={12} md={3} sm={4} lg={2} className={classes.trckUrl}>                                           
                    <TextFields name="Track"  className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Track" label="Číslo přeprace" required placeholder="Číslo přeprace" value={zasilky.ZasilkyInfo.Track || ''} onChange={onChangeValue} />{(zasilky.ZasilkyInfo.tackCisloURL !== '' && zasilky.ZasilkyInfo.tackCisloURL !== null && zasilky.ZasilkyInfo.tackCisloURL !== undefined) && (<Link href={zasilky.ZasilkyInfo.tackCisloURL} target="_blank" rel="noopener" variant="body2" > <LaunchIcon className={classes.trckUrlic}/> </Link>)}
                </Grid> 

                <Grid item xs={12} md={3} sm={4} lg={2}>                                           
                    <TextFields name="ReferencniCislo"  className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="ReferencniCislo" label="Referenční číslo" required placeholder="Referenční číslo" value={zasilky.ZasilkyInfo.ReferencniCislo || ''} onChange={onChangeValue} />
                </Grid>       
                <Grid item xs={12} md={3} sm={4} lg={3}> 

                <Autocomplete
                      id="FirmaID"
                      name="FirmaID"
                      options={Firmy}
                      getOptionLabel={(option) => option.Firma ? option.Firma + "("+option.IC+")" : ''}
                      fullWidth                      
                      value={zasilky.defaultFirma}
                      getOptionSelected={(option) => option.UserID === zasilky.ZasilkyInfo.FirmaID}
                      onChange={(event, newValue) => {
                        if (newValue) {                                  
                            setState({
                                ...zasilky,
                                ZasilkyInfo:{
                                    ...zasilky.ZasilkyInfo,
                                    FirmaID:newValue.UserID,
                                    Firma:newValue.Firma
                                },
                                defaultFirma:newValue,
                            });
                        }
                      }}       
                      renderOption={(option) => (
                      <React.Fragment>
                          {option.Firma + " ("+option.IC+")"}
                      </React.Fragment>
                      )}
                      renderInput={(params) => <TextFields {...params} label="Firma" size="small" variant="outlined" />}
                    />



                    {/* <TextFields name="Firma" disabled className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Firma" label="Plátce" required placeholder="Plátce" value={zasilky.ZasilkyInfo.Firma || ''} /> */}
                </Grid>   
                <Grid item xs={12} md={3} sm={4} lg={3}>                                              
                    <TextFields  name="Vyzvednuto"  className={clsx(classes.textf,classes.margin)} type="date" InputLabelProps={{ shrink: true }} variant="outlined" size="small" fullWidth id="Vyzvednuto" label="Vyzvednuto dne" required value={zasilky.ZasilkyInfo.Vyzvednuto || ''} onChange={onChangeValue} />
                </Grid>  
                <Grid item xs={12} md={3} sm={4} lg={2}>                                              
                    <TextFields name="Hodnota" disabled className={clsx(classes.textf,classes.margin)} type="number" variant="outlined" size="small" fullWidth id="Hodnota" label="Hodnota" placeholder="Hodnota" InputProps={{ endAdornment: <InputAdornment position="end">Kč</InputAdornment> }}value={zasilky.ZasilkyInfo.Hodnota || ''} onChange={onChangeValue} />
                </Grid>    
                <Grid item xs={12} md={3} sm={4} lg={2}>                                              
                    <TextFields name="CenaZasilky"  className={clsx(classes.textf,classes.margin)} type="number" variant="outlined" size="small" fullWidth id="CenaZasilky" label="Cena" placeholder="Cena" InputProps={{ endAdornment: <InputAdornment position="end">Kč</InputAdornment> }}value={zasilky.ZasilkyInfo.CenaZasilky || ''} onChange={onChangeValue} />
                </Grid>  
                <Grid item xs={12} md={3} sm={4} lg={2}>    
                  {(disable === false) && (
                      <TextFields name="CenaZaklad" className={clsx(classes.textf,classes.margin)} type="number" variant="outlined" size="small" fullWidth id="CenaZaklad" label="Cena (náklady)" placeholder="Cena (náklady)" InputProps={{ endAdornment: <InputAdornment position="end">Kč</InputAdornment> }}value={zasilky.ZasilkyInfo.CenaZaklad || ''} onChange={onChangeValue}/>
                  )}
                </Grid>   
  
                <Grid item xs={12} md={3} sm={4} lg={1}>    
                      

                  <Typography className={classes.typo}>
                    <GreenCheckbox
                        checked={zasilky.ZasilkyInfo.DPH}
                        onChange={(e) => {handleChangeDPH(e); }}
                        id="DPH"
                        key="DPH"
                        name="DPH"
                        color="primary"
                    />
                    DPH                                                  
                  </Typography>
                      

                </Grid>
                <Grid item xs={12} md={3} sm={4} lg={2}>    
                    
                  {(zasilky.ZasilkyInfo.DPH === true) && (
                    <TextFields name="SazbaDPH" className={clsx(classes.textf,classes.margin)} type="number" variant="outlined" size="small" fullWidth id="CenaCelkem" label="Sazba DPH" placeholder="Sazba DPH" InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}value={zasilky.ZasilkyInfo.SazbaDPH || ''} onChange={onChangeValue}/>
                  )}
                </Grid>
                <Grid item xs={12} md={3} sm={4} lg={2}>    
                      <TextFields name="CenaCelkem" className={clsx(classes.textf,classes.margin)} type="number" variant="outlined" size="small" fullWidth id="CenaCelkem" label="Cena celkem (s DPH)" placeholder="Cena celkem (s DPH)" InputProps={{ endAdornment: <InputAdornment position="end">Kč</InputAdornment> }}value={zasilky.ZasilkyInfo.CenaCelkem || ''} onChange={onChangeValue}/>
                </Grid>

                <Grid item xs={12} md={12} sm={12} lg={7}>                                           
                    <TextFields name="Popis"  className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="Popis" label="Popis" placeholder="Popis" value={zasilky.ZasilkyInfo.Popis || ''} onChange={onChangeValue} />
                </Grid>  
                <Grid item xs={12} sm={12}>                                           
                    <TextFields name="PoznamkaDopravce"  className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="PoznamkaDopravce" label="Poznámka dopravci" placeholder="Poznámka dopravci" value={zasilky.ZasilkyInfo.PoznamkaDopravce || ''} onChange={onChangeValue} />
                </Grid>  
              </Grid>
            </Grid>

          </TabPanel>
          <TabPanel value={value} index={1}>
            

            <Grid spacing={2} container className={classes.bgGrid}>   
              <Grid item xs={12} sm={12} className={classes.bgGrid}>
                {(Polozky.Polozky!==[] && Polozky.Polozky!== undefined) && ( 
                  <Table className={classes.table}>
                      <TableHead>
                          <TableRow>
                              <TableCell className={classes.tableHeadRow} width="150px" align="left">Druh</TableCell>
                              <TableCell className={classes.tableHeadRow} width="150px" align="left">Počet</TableCell>
                              <TableCell className={classes.tableHeadRow} width="150px" align="left">Hmotnost</TableCell>
                              <TableCell className={classes.tableHeadRow} width="150px" align="left">Volumetrická Hmotnost</TableCell>
                              <TableCell className={classes.tableHeadRow} width="150px" align="left">Délka</TableCell>
                              <TableCell className={classes.tableHeadRow} width="150px" align="left">Výška</TableCell>
                              <TableCell className={classes.tableHeadRow} width="150px" align="left">Šířka</TableCell>
                              <TableCell className={classes.tableHeadRow} align="left"></TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                          {Polozky.Polozky.map(row => {
                          return (
                              <TableRow key={row.ZasilkaPolozkyID}  className={classes.tableRows}>
                                  <TableCell className={classes.tableRow} width="150px">{row.DruhNazev}</TableCell>   
                                  <TableCell className={classes.tableRow} width="150px">{row.Pocet+' ks'}</TableCell> 
                                  <TableCell className={classes.tableRow} width="150px">{row.Hmotnost+' kg'}</TableCell>   
                                  <TableCell className={classes.tableRow} width="150px">{row.ObjemovaHmotnost+' kg'}</TableCell>      
                                  <TableCell className={classes.tableRow} width="150px">{row.Delka+' cm'}</TableCell>   
                                  <TableCell className={classes.tableRow} width="150px">{row.Vyska+' cm'}</TableCell>   
                                  <TableCell className={classes.tableRow} width="150px">{row.Sirka+' cm'}</TableCell>     
                                  <TableCell className={classes.tableRow} width="150px"><IconButton onClick={(e) => {editRow(e,row)}}><EditIcon className="fa fa-plus-circle" style={{ color: customConfig.Colors.PrimaryColor }}  /></IconButton></TableCell>                             
                              </TableRow>
                          );
                          })}
                      </TableBody>
                  </Table>  
                )}     
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Adresy data={zasilky.ZasilkyInfo.ZasilkaID}/>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Stavy data={zasilky.ZasilkyInfo.ZasilkaID}/>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Sluzby data={zasilky.ZasilkyInfo.ZasilkaID}/>
          </TabPanel>
        </div>
      </Grid>

      
      <Grid item xs={12} spacing={2} container justifyContent="space-between" alignItems="flex-start">   
        <Grid item xs={4} sm={2}>
          <GreenButton className={classes.btnm} onClick={(e) => {handleOpenFiltr()}} startIcon={<SearchIcon />} >Filtr</GreenButton> 
        </Grid>             
        <Grid item xs={4} sm={7}/>    
        <Grid item xs={2} sm={1}>     
          {(zasilky.ZasilkyInfo.StavID === "5") && (
            <GreenButton className={classes.btnm} onClick={(e) => {handleOpenDelete(e)}} startIcon={<DeleteIcon />} >Smazat</GreenButton>
          )}
        </Grid>      
        <Grid item xs={2} sm={1}>     
          {(zasilky.ZasilkyInfo.StavID === "1") && (
            <GreenButton className={classes.btnm} onClick={(e) => {handleOpenAccept(e)}} startIcon={<SendIcon />} >Akceptovat</GreenButton>
          )}
        </Grid>    
        <Grid item xs={2} sm={1} >     
          <GreenButton className={classes.btnm} onClick={(e) => {updZasilky(e)}} startIcon={<SaveIcon />} >Uložit</GreenButton>
        </Grid>        
      </Grid>

    </Grid>
    <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openFiltr}
            key="Filtr"
            onClose={handleCloseFiltr}
            closeAfterTransition
            BackdropComponent={Backdrop}
          >
          <Fade in={openFiltr}>
            <div className={classes.paper}>
              <Paper className={classes.paperH}>
                <Typography className={classes.nadpisH}>Objednávky - filtr</Typography>
              </Paper>
              <Container className={classes.innerPadding} maxWidth="sm">
                <Grid container spacing={2} justifycontent="space-between">    
                  <Grid item xs={12} sm={12}> 
                    <TextFields name="CisloZasilky" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="CisloZasilky" label="Číslo zásilky" required placeholder="Číslo zásilky" value={filtr.CisloZasilky} onChange={onChangeValueFiltr} />
                  </Grid>
                  <Grid item xs={12} sm={6}> 
                    <TextFields  name="DatumZalozeniOd" className={clsx(classes.textf,classes.margin)} type="date" InputLabelProps={{ shrink: true }} variant="outlined" size="small" fullWidth id="DatumZalozeniOd" label="Datum založení od" required value={filtr.DatumZalozeniOd || ''} onChange={onChangeValueFiltr} />
                  </Grid>
                  <Grid item xs={12} sm={6}> 
                    <TextFields  name="DatumZalozeniDo" className={clsx(classes.textf,classes.margin)} type="date" InputLabelProps={{ shrink: true }} variant="outlined" size="small" fullWidth id="DatumZalozeniDo" label="Datum založení do" required value={filtr.DatumZalozeniDo || ''} onChange={onChangeValueFiltr} />
                  </Grid>
                  <Grid item xs={12} sm={12}> 
                    <Autocomplete
                      id="Stav"
                      name="Stav"
                      options={StavyZasilek}
                      getOptionLabel={(option) => option.StavNazev ? option.StavNazev : ''}
                      fullWidth
                      value={filtr.defaultStav}
                      getOptionSelected={(option) => option.StavID === filtr.StavID}                          
                      onChange={(event, newValue) => {
                        if (newValue) 
                        {                                  
                          setFiltr({
                            ...filtr,
                            StavID:newValue.StavID,
                            defaultStav:newValue
                          });
                        }
                        else
                        {          
                          setFiltr({
                            ...filtr,
                            StavID:'',
                            defaultStav:null
                          });
                        }
                      }}       
                      renderOption={(option) => (
                      <React.Fragment>
                          {option.StavNazev}
                      </React.Fragment>
                      )}
                      renderInput={(params) => <TextFields {...params} label="Stav zásilky" size="small" variant="outlined" />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}> 
                    <Autocomplete
                      id="Firma"
                      name="Firma"
                      options={Firmy}
                      getOptionLabel={(option) => option.Firma ? option.Firma + "("+option.IC+")" : ''}
                      fullWidth                      
                      value={filtr.defaultFirma}
                      getOptionSelected={(option) => option.UserID === filtr.FirmaID}                          
                      onChange={(event, newValue) => {
                        if (newValue) 
                        {                                   
                          setFiltr({
                            ...filtr,
                            FirmaID:newValue.UserID,
                            defaultFirma:newValue
                          });
                        }
                        else
                        {
                          setFiltr({
                            ...filtr,
                            FirmaID:'',
                            defaultFirma:null
                          });
                        }
                      }}       
                      renderOption={(option) => (
                      <React.Fragment>
                          {option.Firma + " ("+option.IC+")"}
                      </React.Fragment>
                      )}
                      renderInput={(params) => <TextFields {...params} label="Klient" size="small" variant="outlined" />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}> 
                    <Autocomplete
                      id="Druh"
                      name="Druh"
                      options={DruhyZasilek}
                      getOptionLabel={(option) => option.Nazev ? option.Nazev : ''}
                      fullWidth
                      value={filtr.defaultDruh}
                      getOptionSelected={(option) => option.DruhID === filtr.DruhID}                          
                      onChange={(event, newValue) => {
                        if (newValue) 
                        {                                   
                          setFiltr({
                            ...filtr,
                            DruhID:newValue.DruhID,
                            defaultDruh:newValue
                          });
                        }
                        else
                        {
                          setFiltr({
                            ...filtr,
                            DruhID:'',
                            defaultDruh:null
                          });
                        }
                      }}       
                      renderOption={(option) => (
                      <React.Fragment>
                          {option.Nazev}
                      </React.Fragment>
                      )}
                      renderInput={(params) => <TextFields {...params} label="Druh zásilky" size="small" variant="outlined" />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}> 
                    <Autocomplete
                      id="Sluzby"
                      name="Sluzby"
                      options={sluzby}
                      getOptionLabel={(option) => option.SluzbaNazev ? option.SluzbaNazev : ''}
                      fullWidth
                      value={filtr.defaultSluzba}
                      getOptionSelected={(option) => option.SluzbaID === filtr.SluzbaID}                          
                      onChange={(event, newValue) => {
                      if (newValue) {                                                
                        setFiltr({
                          ...filtr,
                          SluzbaID:newValue.SluzbaID,
                          defaultSluzba:newValue,
                        });
                      }
                      else
                      {                                             
                        setFiltr({
                          ...filtr,
                          SluzbaID:'',
                          defaultSluzba:null,
                        });

                      }
                      }}       
                      renderOption={(option) => (
                      <React.Fragment>
                          {option.SluzbaNazev}
                      </React.Fragment>
                      )}
                      renderInput={(params) => <TextFields {...params} label="Služba" size="small" variant="outlined" />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}> 
                    <Autocomplete
                      id="Prepravce"
                      name="Prepravce"
                      options={Prepravci}
                      getOptionLabel={(option) => option.Nazev ? option.Nazev : ''}
                      fullWidth
                      value={filtr.defaultPrepravce}
                      getOptionSelected={(option) => option.PrepravceID === filtr.PrepravceID}                          
                      onChange={(event, newValue) => {
                        if (newValue) {                                                
                          setFiltr({
                            ...filtr,
                            PrepravceID:newValue.PrepravceID,
                            defaultPrepravce:newValue,
                          });
                        }
                        else
                        {                                  
                          setFiltr({
                            ...filtr,
                            PrepravceID:'',
                            defaultPrepravce:null,
                          });

                        }
                      }}       
                      renderOption={(option) => (
                      <React.Fragment>
                          {option.Nazev}
                      </React.Fragment>
                      )}
                      renderInput={(params) => <TextFields {...params} label="Přepravce" size="small" variant="outlined" />}
                    />
                  </Grid>


                  <Grid item xs={12} sm={12}> 
                  
                  </Grid>

                  <Grid item xs={12} sm={4}> 
                    <GreenButton fullWidth onClick={handleCloseFiltr} > Zrušit </GreenButton>
                  </Grid>
                  <Grid item xs={12} sm={4}/> 
                  <Grid item xs={12} sm={4}> 
                    <GreenButton fullWidth onClick={(e) => VyhledatZasilky()} startIcon={<SearchIcon />}  >Vyhledat</GreenButton>
                  </Grid>
                </Grid>
              </Container>
            </div>
          </Fade>
        </Modal>     








        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openEditPolozky}
            key="EditPolozky"
            onClose={handleCloseEditPolozky}
            closeAfterTransition
            BackdropComponent={Backdrop}
          >
          <Fade in={openEditPolozky}>
            <div className={classes.paper}>
              <Paper className={classes.paperH}>
                <Typography className={classes.nadpisH}>Editace položky zásilky</Typography>
              </Paper>
              <Container className={classes.innerPadding} maxWidth="sm">
                <Grid container spacing={2} justifycontent="space-between">    
                  <Grid item xs={12} sm={12}> 
                    <TextFields name="Delka" className={clsx(classes.textf,classes.margin)} type="number" variant="outlined" size="small" fullWidth id="Delka" label="Delka" required placeholder="Délka" value={Polozka.Delka} onChange={onChangeValuePolozky} />
                  </Grid>
                  <Grid item xs={12} sm={12}> 
                    <TextFields name="Sirka" className={clsx(classes.textf,classes.margin)} type="number" variant="outlined" size="small" fullWidth id="Sirka" label="Šířka" required placeholder="Šířka" value={Polozka.Sirka} onChange={onChangeValuePolozky} />
                  </Grid>
                  <Grid item xs={12} sm={12}> 
                    <TextFields name="Vyska" className={clsx(classes.textf,classes.margin)} type="number" variant="outlined" size="small" fullWidth id="Vyska" label="Výška" required placeholder="Výška" value={Polozka.Vyska} onChange={onChangeValuePolozky} />
                  </Grid>
                  <Grid item xs={12} sm={12}> 
                    <TextFields name="Hmotnost" className={clsx(classes.textf,classes.margin)} type="number" variant="outlined" size="small" fullWidth id="Hmotnost" label="Hmotnost" required placeholder="Hmotnost" value={Polozka.Hmotnost} onChange={onChangeValuePolozky} />
                  </Grid>
                  <Grid item xs={12} sm={12}> 
                    <TextFields name="ObjemovaHmotnost" className={clsx(classes.textf,classes.margin)} type="number" variant="outlined" size="small" fullWidth id="ObjemovaHmotnost" label="Objemová hmotnost" required placeholder="Objemová hmotnost" value={Polozka.ObjemovaHmotnost} onChange={onChangeValuePolozky} />
                  </Grid>
                  <Grid item xs={12} sm={12}> 
                    <TextFields name="Pocet" className={clsx(classes.textf,classes.margin)} type="number" variant="outlined" size="small" fullWidth id="Pocet" label="Počet" required placeholder="Počet" value={Polozka.Pocet} onChange={onChangeValuePolozky} />
                  </Grid>
                  <Grid item xs={12} sm={12}> 
                    <Autocomplete
                      id="DruhID"
                      name="DruhID"
                      options={DruhyZasilek}
                      getOptionLabel={(option) => option.Nazev ? option.Nazev : ''}
                      fullWidth             
                      autoSelect 
                      value={Polozka.defaultDruh}
                      getOptionSelected={(option) => option.DruhID === Polozka.DruhID}                          
                      onChange={(event, newValue) => {
                        if (newValue) 
                        {                                   
                          setPolozka({
                            ...Polozka,
                            DruhID:newValue.DruhID,
                            defaultDruh:newValue
                          });
                        }
                        else
                        {           
                          setPolozka({
                            ...Polozka,
                            DruhID:'',
                            defaultDruh:null
                          });
                        }
                      }}       
                      renderOption={(option) => (
                      <React.Fragment>
                          {option.Nazev}
                      </React.Fragment>
                      )}
                      renderInput={(params) => <TextFields {...params} label="Druh zásilky" size="small" variant="outlined" />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}> 
                  
                  </Grid>

                  <Grid item xs={12} sm={4}> 
                    <GreenButton fullWidth onClick={(e) => handleCloseEditPolozky()} > Zrušit </GreenButton>
                  </Grid>
                  <Grid item xs={12} sm={4}/> 
                  <Grid item xs={12} sm={4}> 
                    <GreenButton fullWidth onClick={(e) => savePolozka()} startIcon={<SaveIcon />}  >Uložit</GreenButton>
                  </Grid>
                </Grid>
              </Container>
            </div>
          </Fade>
        </Modal>     









      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        fullWidth
        maxWidth='xs'
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Opravdu si přejete smazat zásilku?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Grid container spacing={2}>      
                <Grid item xs={12} sm={4}> 
                    <GreenButton fullWidth onClick={handleCloseDelete}>Zrušit</GreenButton>
                </Grid>
                <Grid item xs={12} sm={4}/> 
                <Grid item xs={12} sm={4}> 
                <GreenButton fullWidth onClick={submitDeleteZasilka} startIcon={<DeleteIcon />}>Smazat</GreenButton>
                </Grid>
            </Grid>
          
        </DialogActions>
      </Dialog>



      <Snackbar open={openMSG} autoHideDuration={6000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity="success">
          Akceptace odeslána.
        </Alert>
      </Snackbar>    
</div>
  );
}
