import React, { useContext,useEffect, useState } from 'react';
import {UserContext} from '../../../contexts/UserContext'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from "@material-ui/core/styles";
import customConfig from '../../../customConfig.json'
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from '@material-ui/core/TableHead';




const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 200
  },
  tableRows: {
    height: '25px'
  },
  tableRow: {
    padding:"5px 15px 5px 25px",
    backgroundColor: customConfig.Colors.GridBackgroundColor,

  },
  bgGrid: {
    backgroundColor: customConfig.Colors.GridBackgroundColor,
    margin:'0px !important',
    padding:'0px !important',
    width:'100% !important'
  },
  tableHeadRow: {
    padding:"5px 15px 5px 25px",
    backgroundColor: customConfig.Colors.PrimaryColor,
    color: customConfig.Colors.ButtonTextColor
  },
  nadpis:{
    color: customConfig.Colors.PrimaryTextColor
  },
  left:{
    paddingLeft: '10px',
  },        
  }));


function Sluzby(data){
  

  const classes = useStyles();
  
  const {getZasilkaSluzby} = useContext(UserContext);
  const [Sluzby, setSluzby] = useState([]);

  useEffect(() => {

    if(data.data!==''&&data.data!==undefined)
    {

      async function fetchData() {
        const dataOUD = await getZasilkaSluzby(data.data); 
        setSluzby(dataOUD) 
      }
      
      fetchData()   
        // getZasilkaSluzby(data.data)
        // .then(oudData => setSluzby(oudData)
        // );   
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]) 

  function anone (data){

    if(data === "1" )
    {
      return 'Ano'
    }
    else if (data === "0" )
    {
      return 'Ne'
    }
    else
    {
      return '';
    }
  }
  function kcValue (kod,data){

    if(kod === "POJISTENI" && data !== '' )
    {
      return data+' Kč';
    }
    else
    {
      return data;
    }
  }


  return (
    <Grid spacing={2} className={classes.bgGrid} container>   
        <Grid item xs={12} sm={12} className={classes.bgGrid}>
            {(Sluzby.Doplnky!==[] && Sluzby.Doplnky!== undefined) && ( 
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeadRow} width="150px" align="left">Název</TableCell>
                            <TableCell className={classes.tableHeadRow} width="50px" align="left">Hodnota</TableCell>
                            <TableCell className={classes.tableHeadRow} width="350px" align="left">Popis</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Sluzby.Doplnky.map(row => {
                        return (
                            <TableRow key={row.ZasilkaDoplnekID}  className={classes.tableRows}>
                                <TableCell className={classes.tableRow} width="150px">{row.NazevDoplnku}</TableCell>   
                                <TableCell className={classes.tableRow} width="50px">{anone(row.Hodnota)}</TableCell>       
                                <TableCell className={classes.tableRow} width="350px">{kcValue(row.KodDoplnku,row.Popis)}</TableCell>                        
                            </TableRow>
                        );
                        })}
                    </TableBody>
                </Table>  
            )}     
        </Grid>
    </Grid>
  );
}

export default Sluzby


