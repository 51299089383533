import './Home.css';
import Prehled from '../Prehled/Prehled'
import Notifikace from '../PrehledNotifikace/PrehledNotifikace'
import React, {useContext,useState, useEffect} from 'react';
import {UserContext} from '../../contexts/UserContext'
import PrehledGraf from '../PrehledGraf/PrehledGraf'
import PrehledFinance from '../PrehledFinance/PrehledFinance'
import VyhledatZasilku from '../VyhledatZasilku/VyhledatZasilku'
import { Grid } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";


function Home(){


  const useStyles = makeStyles((theme) => ({
    main: {
      margin: '0px',
    },   
    margin0: {
      margin: '0px',
      width:'100%'
    },       
    }));


  const {getDashboard} = useContext(UserContext);
  const [Dashboard, setDashboard] = useState([])    
  const classes = useStyles();

  useEffect(() => {

    document.title = "SEBAplus";

    async function fetchData() {

      const dataOUD = await getDashboard();    
  
      setDashboard(dataOUD) 
  }
  
  fetchData()

    // getDashboard()
    // .then(data =>
    //   setDashboard(
    //         data)
    // );   

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) 

  
  return(
    <div className={classes.main} name="test2">
      <Grid container spacing={2} className={classes.margin0} alignItems="flex-start" >
        <Grid alignItems="flex-start" item container spacing={1} sm={12} xs={12} md={12} lg={6}> 
          <Grid item xs={12} sm={12}> 

            <VyhledatZasilku/>

          </Grid>
          <Grid item xs={12} sm={12}> 

            <Prehled data={Dashboard} />

          </Grid>
          <Grid item xs={12} sm={12}> 

            <Notifikace data={Dashboard} />

          </Grid>
        </Grid>
        <Grid item alignItems="flex-start" container spacing={1} sm={12} xs={12} md={12} lg={6}> 
          <Grid item xs={12} sm={12}> 

            <PrehledGraf data={Dashboard}/>

          </Grid>
          <Grid item xs={12} sm={12}> 

            <PrehledFinance data={Dashboard}/>

          </Grid>
        </Grid>
      

      </Grid>
    
    </div>
  )

    
} 

export default Home;