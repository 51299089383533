import React, { useContext,useEffect, useState } from 'react';
import {UserContext} from '../../../contexts/UserContext'
import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import customConfig from '../../../customConfig.json'
import Checkbox from '@material-ui/core/Checkbox';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from '@material-ui/core/TableHead';
import SaveIcon from '@material-ui/icons/Save';



const GreenCheckbox = withStyles({
    root: {
      color: customConfig.Colors.PrimaryColor,
      '&$checked': {
        color: customConfig.Colors.PrimaryColor,
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 200
  },
  tableRows: {
    height: '25px'
  },
  tableRow: {
    padding:'2px',
    backgroundColor: customConfig.Colors.GridBackgroundColor,

  },
  tableHeadRow: {
    padding:"15px 15px 15px 5px",
    backgroundColor: customConfig.Colors.PrimaryColor,
    color: customConfig.Colors.ButtonTextColor
  },    
  }));


function Sluzby(data){
  

  const classes = useStyles();
  const {getFirmySluzby,postFirmySluzby} = useContext(UserContext);
  const [Sluzby, setSluzby] = useState([]);



  useEffect(() => {
    async function fetchData() {
        const dataOUD = await getFirmySluzby(data.data); 
        MapovatSluzby(dataOUD) 
    }

    fetchData()
    
    // getFirmySluzby(data.data)
    // .then(oudData => MapovatSluzby(oudData)
    // );   
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]) 
  

  
  function MapovatSluzby(data) {

    const result = data.map(s => ({ SluzbaID: s.SluzbaID,SluzbaNazev: s.SluzbaNazev , IsActive: (s.IsActive==="1" ? true:false) }));
    
    setSluzby(result);
  }
  

  const handleChangeInput = (id, event) => {
         
    const editrows = Sluzby.map(item =>
      item.SluzbaID === id ? { ...item, IsActive: event.target.checked } : item
    );
    setSluzby(editrows);    

  }

  

const SubmitEditPristupy = async (FirmaID,event) => {   
    
  event.preventDefault();
  
  await postFirmySluzby(FirmaID,Sluzby);

  async function fetchData() {
    const dataOUD = await getFirmySluzby(data.data); 
    MapovatSluzby(dataOUD) 
  }

  fetchData()

  //  getFirmySluzby(data.data)
  //  .then(oudData => MapovatSluzby(oudData)
  //  );   
  
  
}

const GreenButton = withStyles((theme) => ({
  root: {
    color: customConfig.Colors.TextColor,
    marginTop:'10px',
    backgroundColor: customConfig.Colors.PrimaryColor,
    '&:hover': {
      backgroundColor: customConfig.Colors.SecColor,
    },
  },
}))(Button); 
  
  return (
    <div>
    <Grid container>   
        <Grid item xs={12} sm={12}>
            {(Sluzby.length>0) && ( 
                <Grid item container xs={12} sm={12}>
                    <Grid item xs={12} sm={12}>
                      <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableHeadRow} width="500px" align="left">Služba</TableCell>
                                <TableCell className={classes.tableHeadRow} width="150px" align="left">Aktivní</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                          {Sluzby.map(row => {
                            return (
                              <TableRow key={row.SluzbaID}  className={classes.tableRows}>          
                                <TableCell className={classes.tableRow} width="500px">{row.SluzbaNazev}</TableCell>
                                <TableCell className={classes.tableRow} width="150px">                                              
                                  <GreenCheckbox onChange={(e) => {handleChangeInput(row.SluzbaID,e)}} checked={row.IsActive} />
                                </TableCell>                    
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>    
                    </Grid>
                    
                    <Grid item xs={12} sm={10}/>
                    <Grid item xs={12} sm={2}>
                        <GreenButton onClick={(e) => {SubmitEditPristupy(data.data,e)}} fullWidth startIcon={<SaveIcon/>} >Uložit</GreenButton>
                    </Grid>

            </Grid>
            )}     
        </Grid>
    </Grid>



    </div>
  );
}

export default Sluzby


