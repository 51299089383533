
import React, {useState, useEffect} from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles} from '@material-ui/core/styles';
import customConfig from '../../customConfig.json'
import Chart from "react-apexcharts";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    shad:{
      boxShadow:'0px 1px 3px #777777c4',
      border:'1px solid'+customConfig.Colors.PrimaryColor,
    },
    table:{
      border:'1px solid'+customConfig.Colors.PrimaryColor,
    },
    tableRows:{
      borderBottom:'1px solid'+customConfig.Colors.PrimaryColor,
    },
    tableRow:{
      border: "none",
      padding:"5px"
    },
    paperH: {
      textAlign: 'center',
      backgroundColor: customConfig.Colors.PrimaryColor,
      boxShadow: 'none',
      border:'1px solid'+customConfig.Colors.PrimaryColor,
      borderRadius: '0px'
    },
    nadpisH:{
      color: customConfig.Colors.ButtonTextColor,
      fontSize:'20px'
    },
  }));



function PrehledGraf(data) {

const classes = useStyles();
const [GrafPrehled, setGrafPrehled] = useState([{
          
  series: [],
  options: {
    chart: {
      type: 'bar',
      height: 350,
      width: '100%',
      stacked: true,
      toolbar: {
        show: true
      },
      zoom: {
        enabled: true
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0
        }
      }
    }],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10
      },
    },
    xaxis: {
      type: 'text',
      categories: [],
    },
    legend: {
      position: 'right',
      offsetY: 40
    },
    fill: {
      opacity: 1
    }
  }}])  



useEffect(() => {
  if(data.data){
      
    if(data.data.success===1){
      if(GrafPrehled.series===undefined) 
      {
        setGrafPrehled({
          ...GrafPrehled[0],

          series:data.data.GrafPrehled.pocty,
          options:{
            ...GrafPrehled[0].options,
            xaxis:{
              ...GrafPrehled[0].options.xaxis,
              categories:data.data.GrafPrehled.mesice
            }
          }
        });
      }
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]) 
  

  //

return (
    <div>    
      <Paper className={classes.shad}>    
        <Grid>          
          <Grid key={1} item xs={12} sm={12}> 
              <Paper className={classes.paperH}>
                  <Typography className={classes.nadpisH}>Přehled za poslední 4 měsíce</Typography>
              </Paper>
          </Grid>
          <Grid key={2} item xs={12} sm={12}> 
            {(GrafPrehled.series!==[] && GrafPrehled.series!== undefined) && ( 
              <Chart options={GrafPrehled.options} series={GrafPrehled.series} type="bar" height={350} />    
            )}   
          </Grid>        
        </Grid>
      </Paper>
    </div>
    );

};


export default PrehledGraf;