import React, {useContext,useState} from 'react';
import Container from '@material-ui/core/Container';
import { withStyles,makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetail from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import customConfig from '../../customConfig.json'
import {UserContext} from '../../contexts/UserContext'

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import * as moment from 'moment'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import { Alert } from '@material-ui/lab';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({

modal: {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
},  
typo: {
  color: 'Black',
},
iconColor:{
  color:customConfig.Colors.PrimaryColor,
  padding:'0px',
  float:'right'
},
iconColorSec:{
  color:customConfig.Colors.ButtonTextColor
},
valueHeader:{
  fontWeight: 700,
  color: customConfig.Colors.RecesrTextColor,
  display:'flex',

},
valueText:{
  fontWeight: 500,
  paddingLeft:'10px',
  color: customConfig.Colors.RecesrTextColor
},
table:{
  border: "none"
},
tableCell:{
  border: "none",
  padding:"5px"
},
SummaryHead: {
  backgroundColor: customConfig.Colors.PrimaryColor,
  color:customConfig.Colors.ButtonTextColor,
  fontWeight:700
},
paper: {
  padding: theme.spacing(2),
  textAlign: 'center',
  backgroundColor: customConfig.Colors.PrimaryColor,
  color: customConfig.Colors.TextColor,
},
bgGrid: {
  backgroundColor: customConfig.Colors.GridBackgroundColor,
},
cont: {
  marginBottom: theme.spacing(2),
},
}));

const GreenButton = withStyles((theme) => ({
  root: {
    color: customConfig.Colors.TextColor,
    backgroundColor: customConfig.Colors.PrimaryColor,
    '&:hover': {
      backgroundColor: customConfig.Colors.SecColor,
    },
  },
}))(Button); 
  

export const Souhrn = ({ formData, navigation }) => {
  const { go } = navigation;
  const {
    adresFrom,
    adresTo,
    druh,
    hodnotaZbozi,
    ReferencniCislo,
    Pojistit,
    bezpecne,
    datumVyzvednuti,
    casOd,
    casDo,
    poznamka,
    odesilatelJmeno,
    odesilatelAdresa,
    odesilatelMesto,
    odesilatelPSC,
    odesilatelStat,
    odesilatelKontOsoba,
    odesilatelEmail,
    odesilatelTel,
    prijemceJmeno,
    prijemceAdresa,
    prijemceMesto,
    prijemcePSC,
    prijemceStat,
    prijemceKontOsoba,
    prijemceEmail,
    prijemceTel,
    popisZbozi,
    sluzbaNazev,
    prepravce,
    DefAdresTo,
    DefAdresFrom,
    cena,
    SEBAplusCislo,
    odesilatelStatNazev,
    prijemceStatNazev,
    doplnky,
    parametry
  } = formData;

  function trueFalse (data){
    switch(data) {
      case true:
        return "Ano"
      case false:
        return "Ne"
    }
  }
  
  function restartovat (){    
    window.location.reload();
  }
  const {postZalozitZasilku} = useContext(UserContext);
  const [errorMsg, seterrorMsg] = useState('');
  const [loading, setLoading] = React.useState(false);
  
  const ZalozitKalkulaci = async (event) => 
  {
    setLoading(true);
    const result = await postZalozitZasilku(formData)

    if(result.success === 1)
    {
  
      if(result.SEBAplusCislo[0].SEBAplusCislo!==null && result.SEBAplusCislo[0].SEBAplusCislo!==undefined && result.SEBAplusCislo[0].SEBAplusCislo!=='')
      {
        seterrorMsg('');
        setLoading(false);
        formData.SEBAplusCislo = result.SEBAplusCislo[0].SEBAplusCislo;
        go('Dokonceni');
      }
      else
      {
        seterrorMsg('Chyba při založení zásilky. Došlo k chybě.');
        setLoading(false);
      }
    
    }
    else 
    {
      seterrorMsg(result.message);
      setLoading(false);
    }  
  }

  function druhZasilky (data){
    switch(data) {
      case 0:
        return "Dokument"
      case 1:
        return "Balík"
      case 2:
        return "Paleta"
        case 3:
          return "Ostatní"
    }
  }

  
  const classes = useStyles();

  return (

  <Container className={classes.cont} maxWidth='lg'>  
    <Grid className={classes.bgGrid} container spacing={3}>
      <Grid item xs={12} sm={12}>
              
      {(errorMsg !== '' && errorMsg !== null && errorMsg !== undefined) && (
        
        <Grid item xs={12} sm={12}>
          <div style={{ visibility: errorMsg === '' ? 'hidden': 'visible'}}>             
                <Alert severity="error">{errorMsg}</Alert>          
          </div>   
        </Grid>
        )}
        
        <Accordion>
          <AccordionSummary className={classes.SummaryHead} expandIcon={<ExpandMoreIcon className={classes.iconColorSec} />}>Základní údaje</AccordionSummary>
          <AccordionDetail>

          <TableContainer>
            <Table className={classes.table} aria-label="caption table">
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.tableCell}  width="120px" align="left"><div className={classes.valueHeader}>{"Stát odeslání:"}</div></TableCell>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{odesilatelStatNazev}</div></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{"Stát doručení:"}</div></TableCell>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{prijemceStatNazev}</div></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{"Druh:"}</div></TableCell>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{druhZasilky(druh)}</div></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{"Přepravce:"}</div></TableCell>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{prepravce}</div></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{"Služba:"}</div></TableCell>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{sluzbaNazev}</div></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{"Cena přepravy:"}</div></TableCell>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{cena} Kč</div></TableCell>
                  </TableRow>
                </TableBody>     
              </Table>
            </TableContainer>
            <IconButton
              className={classes.iconColor}
              onClick={() => go('ParametryZasilky')}
              >
                <EditIcon />
            </IconButton>    
          </AccordionDetail>
        </Accordion>


        <Accordion>
          <AccordionSummary className={classes.SummaryHead} expandIcon={<ExpandMoreIcon className={classes.iconColorSec}/>}>Položky zásilky</AccordionSummary>
          <AccordionDetail>
          <TableContainer>
            <Table className={classes.table} aria-label="caption table">
                <TableBody>

                {formData.parametry.map(row => {

                  return (
                    <TableRow key={row.id} >
                      <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{row.pocet+" ks - "+row.delka+"x"+row.sirka+"x"+row.vyska+" cm - "+row.hmotnost+" kg" }</div></TableCell>
                    </TableRow>
                  );
                })}
                </TableBody>     
              </Table>
            </TableContainer>
            <IconButton
              className={classes.iconColor}
              onClick={() => go('ParametryZasilky')}
              >
                <EditIcon />
            </IconButton>   
          </AccordionDetail>
        </Accordion>     


        <Accordion>
          <AccordionSummary className={classes.SummaryHead} expandIcon={<ExpandMoreIcon className={classes.iconColorSec}/>}>Doplňkové služby</AccordionSummary>
          <AccordionDetail>
          <TableContainer>
            <Table className={classes.table} aria-label="caption table">
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.tableCell} width="250px" align="left"><div className={classes.valueHeader}>{"Pojištění:"}</div></TableCell>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{trueFalse(doplnky[0].DoplnekInt)}</div></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{"Bezpečná zásilka:"}</div></TableCell>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{trueFalse(doplnky[1].DoplnekInt)}</div></TableCell>
                  </TableRow>

                  {(doplnky[1].DoplnekInt === false ) && (
                  <>    
                    <TableRow>
                      <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{"Suchý led (UN1845):"}</div></TableCell>
                      <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{trueFalse(doplnky[2].DoplnekInt)}</div></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{"Lithiové baterie - se zařízením:"}</div></TableCell>
                      <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{trueFalse(doplnky[3].DoplnekInt)}</div></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{"Lithiové baterie - v zařízení:"}</div></TableCell>
                      <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{trueFalse(doplnky[4].DoplnekInt)}</div></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{"Lithiové baterie - samostatné:"}</div></TableCell>
                      <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{trueFalse(doplnky[5].DoplnekInt)}</div></TableCell>
                    </TableRow>

                  </>
                  )}


                </TableBody>     
              </Table>
            </TableContainer>
            <IconButton
              className={classes.iconColor}
              onClick={() => go('ParametryZasilky')}
              >
                <EditIcon />
            </IconButton>   
          </AccordionDetail>
        </Accordion> 

        <Accordion>
          <AccordionSummary className={classes.SummaryHead}  expandIcon={<ExpandMoreIcon className={classes.iconColorSec}/>}>Odesílatel</AccordionSummary>
          <AccordionDetail>
          <TableContainer>
            <Table className={classes.table} aria-label="caption table">
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.tableCell}  width="120px" align="left"><div className={classes.valueHeader}>{"Firma:"}</div></TableCell>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{odesilatelJmeno}</div></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{"Kontaktní:"}</div></TableCell>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{odesilatelKontOsoba}</div></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{"Adresa:"}</div></TableCell>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{odesilatelAdresa}</div></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{"Město:"}</div></TableCell>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{odesilatelMesto}</div></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{"PSČ:"}</div></TableCell>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{odesilatelPSC}</div></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{"Stát:"}</div></TableCell>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{DefAdresFrom.Nazev}</div></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{"Email:"}</div></TableCell>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{odesilatelEmail}</div></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{"Telefon:"}</div></TableCell>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{odesilatelTel}</div></TableCell>
                  </TableRow>
                </TableBody>     
              </Table>
            </TableContainer>
            <IconButton
              className={classes.iconColor}
              onClick={() => go('Podrobnosti')}
              >
                <EditIcon />
            </IconButton> 
          </AccordionDetail>
        </Accordion>
        <Accordion>
          <AccordionSummary className={classes.SummaryHead} expandIcon={<ExpandMoreIcon className={classes.iconColorSec}/>}>Příjemce</AccordionSummary>
          <AccordionDetail>
          <TableContainer>
            <Table className={classes.table} aria-label="caption table">
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.tableCell}  width="120px" align="left"><div className={classes.valueHeader}>{"Firma:"}</div></TableCell>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{prijemceJmeno}</div></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{"Kontaktní:"}</div></TableCell>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{prijemceKontOsoba}</div></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{"Adresa:"}</div></TableCell>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{prijemceAdresa}</div></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{"Město:"}</div></TableCell>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{prijemceMesto}</div></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{"PSČ:"}</div></TableCell>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{prijemcePSC}</div></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{"Stát:"}</div></TableCell>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{DefAdresTo.Nazev}</div></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{"Email:"}</div></TableCell>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{prijemceEmail}</div></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{"Telefon:"}</div></TableCell>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{prijemceTel}</div></TableCell>
                  </TableRow>
                </TableBody>     
              </Table>
            </TableContainer>
            <IconButton
              className={classes.iconColor}
              onClick={() => go('Podrobnosti')}
              >
                <EditIcon />
            </IconButton>     
          </AccordionDetail>
        </Accordion>
        <Accordion>
          <AccordionSummary className={classes.SummaryHead} expandIcon={<ExpandMoreIcon className={classes.iconColorSec}/>}>Doplňující informace</AccordionSummary>
          <AccordionDetail>
          <TableContainer>
            <Table className={classes.table} aria-label="caption table">
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.tableCell}  width="180px" align="left"><div className={classes.valueHeader}>{"Popis zásilky:"}</div></TableCell>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{popisZbozi}</div></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{"Referenční číslo:"}</div></TableCell>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{ReferencniCislo}</div></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{"Hodnota zásilky:"}</div></TableCell>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{hodnotaZbozi} Kč</div></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{"Datum vyzvednutí:"}</div></TableCell>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{moment(datumVyzvednuti).format("DD.MM.YYYY")}</div></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{"Čas vyzvednutí od:"}</div></TableCell>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{casOd}</div></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{"Čas vyzvednutí do:"}</div></TableCell>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{casDo}</div></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueHeader}>{"Poznámka:"}</div></TableCell>
                    <TableCell className={classes.tableCell} align="left"><div className={classes.valueText}>{poznamka}</div></TableCell>
                  </TableRow>
                </TableBody>     
              </Table>
            </TableContainer>
            <IconButton
              className={classes.iconColor}
              onClick={() => go('Podrobnosti')}
              >
                <EditIcon />
            </IconButton>   
          </AccordionDetail>
        </Accordion>
   
      </Grid>

      <Grid item container xs={12} sm={12}>
        <Grid item xs={12} sm={3}>
          <GreenButton
            fullWidth
            variant="contained"
            style={{ marginTop: '1.5rem' }}
            onClick={() => restartovat()}
          >
            Zrušit
          </GreenButton>
        </Grid>
        <Grid item xs={12} sm={6}/>
        <Grid item xs={12} sm={3}>
          <GreenButton
            fullWidth
            disabled={loading}
            variant="contained"
            style={{ marginTop: '1.5rem' }}
            onClick={() => ZalozitKalkulaci()}
          >
            Odeslat
          </GreenButton>
        </Grid>
      </Grid>
    </Grid>



    <Modal open={loading} className={classes.modal}>
      <Fade
          in={loading}
          style={{
              transitionDelay: loading ? '100ms' : '0ms',
          }}
          unmountOnExit
          >
          <CircularProgress />
      </Fade>

    </Modal>      
  </Container>
  );
};
