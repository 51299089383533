import React, { createContext,Component } from "react";
import axios from 'axios';
import customConfig from '../customConfig.json'
export const UserContext = createContext();


// Define the base URL
const Axios = axios.create({    
    baseURL: customConfig.connect.connectApi,
});

let axiosConfig = {
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
    }
};


class UserContextProvider extends Component{
    constructor(){
        super();
        this.isLoggedIn();
    }

    // Root State
    state = {
        showLogin:true,
        isAuth:false,
        isAdmin:false,
        theUser:null
    }

    
    // On Click the Log out button
    IsUserAuth = () => {
        if(this.isAuth!==true)
        {
            return (this.isLoggedIn());
        }
        else
        {
            return (this.isAuth)
        }
    }
    
    
    // On Click the Log out button
    logoutUser = () => {
        
        localStorage.removeItem('loginToken');
        localStorage.removeItem('currentUser');
        localStorage.removeItem('tokenTime');
        this.setState({
            ...this.state,
            isAuth:false,
            isAdmin:false
        })
    }
    

    registerUser = async (user) => {

        // Sending the user registration request
        let response = await Axios.post('register.php',{
            adresa:user.userInfo.adresa,
            dic:user.userInfo.dic,
            firma:user.userInfo.firma,
            ic:user.userInfo.IC,
            jmeno:user.userInfo.jmeno,
            konEmail:user.userInfo.konEmail,
            loginemail:user.userInfo.loginemail,
            mesto:user.userInfo.mesto,
            password:user.userInfo.password,
            phone:user.userInfo.phone,
            prijmeni:user.userInfo.prijmeni,
            psc:user.userInfo.psc,
            stat:user.userInfo.stat,
            dokumenty:user.Formular.dokumenty,
            baliky:user.Formular.baliky,
            palety:user.Formular.palety,
            kontejnery:user.Formular.kontejnery,
            ostatni:user.Formular.ostatni,
            export:user.Formular.export,
            import:user.Formular.import,
            tuzemsko:user.Formular.tuzemsko,
            denne:user.Formular.denne,
            tydne:user.Formular.tydne,
            mesice:user.Formular.mesice,
            dennePocet:user.Formular.dennePocet,
            tydnePocet:user.Formular.tydnePocet,
            mesicePocet:user.Formular.mesicePocet,
            hmotnost:user.Formular.hmotnost,
            bezpecne:user.Formular.bezpecne,
            pojistit:user.Formular.pojistit
        },axiosConfig);

        let register = (await response).data;
        

        return register;
    }

    ZalozitFirmu = async (user) => {

        // Sending the user registration request
        let response = await Axios.post('zalozit-firmu.php',{
            adresa:user.adresa,
            dic:user.dic,
            firma:user.firma,
            ic:user.IC,
            jmeno:user.jmeno,
            konEmail:user.konEmail,
            loginemail:user.loginemail,
            mesto:user.mesto,
            password:user.password,
            phone:user.phone,
            prijmeni:user.prijmeni,
            psc:user.psc,
            stat:user.stat
        },axiosConfig);

        let register = (await response).data;
        

        return register;
    }

    kontrolaZmenyPW = async (indata) => {

        // Sending the user registration request
        const kontrola = await Axios.post('kontrolaZmenyPW.php',{
            gid:indata,
        });

        return kontrola.data;
    }

    restartHeslaMail = async (indata) => {

        // Sending the user registration request
        const mail = await Axios.post('restartHeslaMail.php',{
            loginemail:indata.loginemail,
        });

        return mail.data;
    }

    restartHeslaZmena = async (indata) => {

        // Sending the user registration request
        const zmenaPW = await Axios.post('restartHeslaZmena.php',{
            gid:indata.gid,
            pw:indata.pw,
        });

        return zmenaPW.data;
    }




    getMenuItems = async () => {     

        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const data = await Axios.get('usermenu.php');
            this.refreshLogin();
            if(data.data.success && data.data.menuItems){                
                return data.data.menuItems;
            }
            else
            {         
                this.isLoggedIn();
                return [];
            }
        }
    }
    getStaty = async () => {   
        const dataStaty = await Axios.get('Staty.php');
        
        return dataStaty.data;
    }
    getAdresyByStat = async (dataStat) => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }

            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const dataAdresy = await Axios.post('adresybystat.php',{
                stat:dataStat,
            });
            this.refreshLogin();

            if(dataAdresy.data.success && dataAdresy.data.Adresy){                
                return dataAdresy.data.Adresy;
            }
            else
            {         
                this.isLoggedIn();
                return [];
            }
        }
    }
    getAdresy = async () => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }

            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const dataAdresy = await Axios.get('adresy.php');
            this.refreshLogin();

            if(dataAdresy.data.success && dataAdresy.data.Adresy){                
                return dataAdresy.data.Adresy;
            }
            else
            {         
                this.isLoggedIn();
                return [];
            }
        }
    }
    getSpolecnost = async () => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }

            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const data = await Axios.get('spolecnost.php');
            this.refreshLogin();

            if(data.data.success && data.data.Spolecnost){                
                return data.data.Spolecnost;
            }
            else
            {         
                this.isLoggedIn();
                return [];
            }
        }
    }
    getPriplatky = async () => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }

            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const data = await Axios.get('priplatky.php');
            this.refreshLogin();

            
            if(data.data.success && data.data.Priplatky){                
                return data.data.Priplatky;
            }
            else
            {         
                this.isLoggedIn();
                return [];
            }
        }
    }
    getFakturaPolozky = async (inData) => {     
        const loginToken = localStorage.getItem('loginToken');
        
        if(inData!==undefined && inData!== '' ) 
        { 
            if(loginToken){
                if(this.isAuth===false){            
                    this.isLoggedIn();
                }

                Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
                const data = await Axios.get('faktura-polozky.php/'+inData);
                this.refreshLogin();

                if(data.data.success && data.data.FakturaPolozky){                
                    return data.data.FakturaPolozky;
                }
                else
                {         
                    this.isLoggedIn();
                    return [];
                }
            }
        }
    }
    getFakturaZasilky = async (inData) => {     
        const loginToken = localStorage.getItem('loginToken');
        
        if(inData!==undefined && inData!== '' ) 
        { 
            if(loginToken){
                if(this.isAuth===false){            
                    this.isLoggedIn();
                }

                Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
                const data = await Axios.get('faktura-zasilky.php/'+inData);
                this.refreshLogin();

                if(data.data.success && data.data.Zasilky){                
                    return data.data.Zasilky;
                }
                else
                {         
                    this.isLoggedIn();
                    return [];
                }
            }
        }
    }
    getFakturaInfo = async () => {     
        const loginToken = localStorage.getItem('loginToken');

        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }

            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const data = await Axios.get('faktura-info.php');
            this.refreshLogin();

            if(data.data.success && data.data.FakturaInfo){                
                return data.data.FakturaInfo;
            }
            else
            {         
                this.isLoggedIn();
                return [];
            }
        }
    }
    getKlientSlevy = async (inData) => {     
        const loginToken = localStorage.getItem('loginToken');
        
        if(inData!==undefined && inData!== '' ) 
        { 
            if(loginToken){
                if(this.isAuth===false){            
                    this.isLoggedIn();
                }

                Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
                const data = await Axios.get('slevy.php/'+inData);
                this.refreshLogin();

                if(data.data.success && data.data.Slevy){                
                    return data.data.Slevy;
                }
                else
                {         
                    this.isLoggedIn();
                    return [];
                }
            }
        }
    }
    getMarze = async () => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }

            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const data = await Axios.get('marze.php');
            this.refreshLogin();

            if(data.data.success && data.data.Marze){                
                return data.data.Marze;
            }
            else
            {         
                this.isLoggedIn();
                return [];
            }
        }
    }
    getSlevy = async () => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }

            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const data = await Axios.get('slevy.php');
            this.refreshLogin();

            if(data.data.success && data.data.Slevy){                
                return data.data.Slevy;
            }
            else
            {         
                this.isLoggedIn();
                return [];
            }
        }
    }
    getFirmySluzby = async (inData) => {    
        
        if(inData!==undefined && inData!== '' ) 
        { 
            const loginToken = localStorage.getItem('loginToken');
            if(loginToken){
                if(this.isAuth===false){            
                    this.isLoggedIn();
                }

                Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
                const data = await Axios.get('firma-sluzby.php/'+inData);
                this.refreshLogin();

                if(data.data.success && data.data.Sluzby){                
                    return data.data.Sluzby;
                }
                else if (data.data.success === 0)
                {         
                    this.isLoggedIn();
                }
            }
        }
        else
        {
            return [];
        }
    }
    getSluzbyDatagrid = async () => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }

            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const data = await Axios.get('sluzbydata.php');
            this.refreshLogin();

            if(data.data.success && data.data.Sluzby){                
                return data.data.Sluzby;
            }
            else
            {         
                this.isLoggedIn();
                return [];
            }
        }
    }
    getSlevyKlient = async (inData) => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }

            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;            
            const data = await Axios.get('slevyKlient.php',{params: {KlientID: inData}});
            this.refreshLogin();

            if(data.data.success && data.data.Slevy){                
                return data.data.Slevy;
            }
            else
            {         
                this.isLoggedIn();
                return [];
            }
        }
    }
    getSluzby = async () => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }

            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const data = await Axios.get('sluzby.php');
            this.refreshLogin();

            if(data.data.success && data.data.Sluzby){                
                return data.data.Sluzby;
            }
            else
            {         
                this.isLoggedIn();
                return [];
            }
        }
    }
    getPrepravci = async () => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }

            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const data = await Axios.get('prepravci.php');
            this.refreshLogin();

            if(data.data.success && data.data.Prepravci){                
                return data.data.Prepravci;
            }
            else
            {         
                this.isLoggedIn();
                return [];
            }
        }
    }
    getZasilkyAdmin = async () => {     
        const loginToken = localStorage.getItem('loginToken');

        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }

            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const data = await Axios.get('zasilkyfull.php');
            this.refreshLogin();

            if(data.data.success && data.data.Zasilky){                
                return data.data.Zasilky;
            }
            else
            {         
                this.isLoggedIn();
                return [];
            }
        }
    }  
    getZasilkyAdminFiltr = async (inData) => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }

            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const data = await Axios.get('zasilkyfiltr.php',{
                params: {
                    SluzbaID: inData.SluzbaID,
                    StavID: inData.StavID,
                    DruhID: inData.DruhID,
                    FirmaID: inData.FirmaID,
                    PrepravceID: inData.PrepravceID,
                    CisloZasilky: inData.CisloZasilky,
                    DatumZalozeniOd: inData.DatumZalozeniOd,
                    DatumZalozeniDo: inData.DatumZalozeniDo
                }
              });
            this.refreshLogin();

            if(data.data.success && data.data.Zasilky){                
                return data.data.Zasilky;
            }
            else
            {         
                this.isLoggedIn();
                return [];
            }
        }
    } 
    
    
    getZasilky = async () => {     
        const loginToken = localStorage.getItem('loginToken');

        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }

            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const data = await Axios.get('zasilky.php');
            this.refreshLogin();

            if(data.data.success && data.data.Zasilky){                
                return data.data.Zasilky;
            }
            else
            {         
                this.isLoggedIn();
                return [];
            }
        }
    }    
    getFaktury = async (inData) => {     
        const loginToken = localStorage.getItem('loginToken');

        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }

            const dataOud = await Axios.post('faktury.php',{                
                DatumOd:inData.DatumOd?inData.DatumOd:'',        
                DatumDo:inData.DatumDo?inData.DatumDo:'',
                CisloFaktury:inData.CisloFaktury?inData.CisloFaktury:'',
                KlientID:inData.KlientID?inData.KlientID:''
            });
            this.refreshLogin();

            if(dataOud.data.success && dataOud.data.Faktury){                
                return dataOud.data.Faktury;
            }
            else
            {         
                this.isLoggedIn();
                return [];
            }
        }
    }   
    getUserType = async () => {     
        const loginToken = localStorage.getItem('loginToken');

        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }

            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const data = await Axios.get('user-type.php');
            this.refreshLogin();

            if(data.data.success && data.data.UserType){                
                return data.data.UserType;
            }
            else
            {         
                this.isLoggedIn();
                return [];
            }
        }
    }    
    getSdeleni = async () => {     
        const loginToken = localStorage.getItem('loginToken');

        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }

            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const data = await Axios.get('notifikace.php');
            this.refreshLogin();
            
            if(data.data.success && data.data.Notifikace){                
                return data.data.Notifikace;
            }
            else
            {         
                this.isLoggedIn();
                return [];
            }
        }
    } 
    getFirmy = async () => {     
        const loginToken = localStorage.getItem('loginToken');

        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }

            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const data = await Axios.get('firmy.php');
            this.refreshLogin();
            
            if(data.data.success && data.data.Firma){                
                return data.data.Firma;
            }
            else
            {         
                this.isLoggedIn();
                return [];
            }
        }
    } 
    getPristupy = async (inData) => {    
        
        if(inData!==undefined && inData!== '' ) 
        {
            const loginToken = localStorage.getItem('loginToken');
            
            if(loginToken){
                if(this.isAuth===false){            
                    this.isLoggedIn();
                }

                Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
                const data = await Axios.get('pristupy.php/'+inData);
                this.refreshLogin();
                
                
                if(data.data.success && data.data){     
                            
                    return data.data;
                }
                else
                {         
                    this.isLoggedIn();
                    return [];
                }
            }
        }
        else
        {
            return [];

        }
    }    
    getDashboard = async () => {     

        const loginToken = localStorage.getItem('loginToken');

        if(loginToken){
            
            if(this.isAuth===false)
            {            
                this.isLoggedIn();
            }

            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const data = await Axios.get('dashboard.php');
            this.refreshLogin();

            if(data.data.success && data.data){                
                return data.data;
            }
            else
            {         
                this.isLoggedIn();
                return [];
            }
        }
    }   
    getAres = async (data) => {     
        
            const dataOud = await Axios.post('Ares.php',{
                IC:data.IC
            });     

            if(dataOud.data.success && dataOud.data.Ares){   
                    
                return dataOud.data.Ares;
            }
    }
    getUser = async (inData) => {     
        const loginToken = localStorage.getItem('loginToken');
        
        if(loginToken){
            
            if(this.isAuth===false){            
                this.isLoggedIn();
            }
            
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const data = await Axios.get('user.php/'+(inData?inData:''));
            this.refreshLogin();
            
            if(data.data.success && data.data.User){                
                return data.data;
            }
            else
            {         
                this.isLoggedIn();
                return [];
            }
        }
    }   
    getZasilkaStavy = async (inData) => {     
        const loginToken = localStorage.getItem('loginToken');
        
        if(loginToken){
            
            if(this.isAuth===false){            
                this.isLoggedIn();
            }
            
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const data = await Axios.get('zasilka-stavy.php/'+(inData?inData:''));
            this.refreshLogin();
            
            if(data.data.success && data.data.Stavy){                
                return data.data;
            }
            else
            {         
                this.isLoggedIn();
                return [];
            }
        }
    }   
    getPolozky = async (inData) => {     
        const loginToken = localStorage.getItem('loginToken');
        
        if(loginToken){
            
            if(this.isAuth===false){            
                this.isLoggedIn();
            }
            
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const data = await Axios.get('zasilka-Polozky.php/'+(inData?inData:''));
            this.refreshLogin();
            
            if(data.data.success && data.data.Polozky){                
                return data.data;
            }
            else
            {         
                this.isLoggedIn();
                return [];
            }
        }
    }    
    getZasilkaAdresy = async (inData) => {     
        const loginToken = localStorage.getItem('loginToken');
        
        if(loginToken){
            
            if(this.isAuth===false){            
                this.isLoggedIn();
            }
            
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const data = await Axios.get('zasilka-adresy.php/'+(inData?inData:''));
            this.refreshLogin();
            
            if(data.data.success && data.data.Adresy){                
                return data.data;
            }
            else
            {         
                this.isLoggedIn();
                return [];
            }
        }
    } 
    getZasilkaSluzby = async (inData) => {     
        const loginToken = localStorage.getItem('loginToken');
        
        if(loginToken){
            
            if(this.isAuth===false){            
                this.isLoggedIn();
            }
            
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const data = await Axios.get('zasilka-doplnky.php/'+(inData?inData:''));
            this.refreshLogin();
            
            if(data.data.success && data.data.Doplnky){                
                return data.data;
            }
            else
            {         
                this.isLoggedIn();
                return [];
            }
        }
    } 
    getZasilkaZakladniInformace = async (inData) => {     
        const loginToken = localStorage.getItem('loginToken');
        
        if(loginToken){
            
            if(this.isAuth===false){            
                this.isLoggedIn();
            }
            
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const data = await Axios.get('zasilka-zakladni-informace.php/'+(inData?inData:''));
            this.refreshLogin();
            
            if(data.data.success && data.data.ZakladniInformace){                
                return data.data;
            }
            else
            {         
                this.isLoggedIn();
                return [];
            }
        }
    } 
    getStavy = async () => {     
        const loginToken = localStorage.getItem('loginToken');
        
        if(loginToken){
            
            if(this.isAuth===false){            
                this.isLoggedIn();
            }
            
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const data = await Axios.get('stavy.php');
            this.refreshLogin();
            
            if(data.data.success && data.data.Stavy){                
                return data.data.Stavy;
            }
            else
            {         
                this.isLoggedIn();
                return [];
            }
        }
    }   
    getDruhy = async () => {     
        const loginToken = localStorage.getItem('loginToken');
        
        if(loginToken){
            
            if(this.isAuth===false){            
                this.isLoggedIn();
            }
            
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const data = await Axios.get('druhy.php');
            this.refreshLogin();
            
            if(data.data.success && data.data.Druh){                
                return data.data.Druh;
            }
            else
            {         
                this.isLoggedIn();
                return [];
            }
        }
    }   
    getFakturaByID = async (inData) => {    
        
        if(inData!==undefined && inData!== '' ) 
        { 
            const loginToken = localStorage.getItem('loginToken');
            if(loginToken){
                if(this.isAuth===false){            
                    this.isLoggedIn();
                }

                Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
                const data = await Axios.get('faktura.php/'+inData);
                this.refreshLogin();

                if(data.data.success && data.data){                
                    return data.data;
                }
                else
                {         
                    this.isLoggedIn();
                    return [];
                }
            }
        }
        else
        {
            return [];
        }
    }









    vyhledatZasilku = async (data) => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }
            
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const dataOud = await Axios.post('vyhledatzasilku.php',{
                CisloZasilky:data.VyhledavanaZasilka
            });
            this.refreshLogin();

            if (dataOud.data.success === 0)
            {         
                this.isLoggedIn();
            }
            else
            {
                return dataOud.data.VyhledanaZasilka;
            }
        }
    }
    postFirmySluzby = async (data1,data) => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }
            
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const dataOud = await Axios.post('firma-sluzby.php',{
                FirmaID:data1,
                Data:data
            });
            this.refreshLogin();

            if (dataOud.data)
            {         
                this.isLoggedIn();
            }
            else{
                return dataOud.data;
            }
        }
    }
    postSdeleni = async (data) => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }
            
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const dataOud = await Axios.post('notifikace.php',{
                NotifikaceID:data.NotifikaceID,
                Titulek:data.Titulek,
                Notifikace:data.Notifikace,
            });
            this.refreshLogin();

            if (dataOud.data.success === 0)
            {         
                this.isLoggedIn();
            }
            else{
                return dataOud.data;
            }
        }
    }
    postPotvrditNotifikaci = async (data) => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }
            
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const dataOud = await Axios.post('potrvzeninotifikace.php',{
                NotifikaceID:data.NotifikaceID,
            });
            this.refreshLogin();

            if (dataOud.data.success === 0)
            {         
                this.isLoggedIn();
            }
            else{
                return dataOud.data;
            }
        }
    }
    postAdresy = async (AdresyInfo) => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }
            
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const dataOud = await Axios.post('adresy.php',{
                AdresaID:AdresyInfo.AdresaID,
                Nazev:AdresyInfo.Nazev,
                Oznaceni:AdresyInfo.Oznaceni,
                Adresa:AdresyInfo.Adresa,
                Adresa2:AdresyInfo.Adresa2,
                Adresa3:AdresyInfo.Adresa3,
                Mesto:AdresyInfo.Mesto,
                psc:AdresyInfo.psc,
                stat:AdresyInfo.stat,
                Email:AdresyInfo.Email,
                Telefon:AdresyInfo.Telefon,
                KonkaktniOsoba:AdresyInfo.KonkaktniOsoba,
            });
            this.refreshLogin();

            if (dataOud.data)
            {         
                this.isLoggedIn();
            }
            else{
                return dataOud.data;
            }
        }
    }
    postFaktura = async (inData) => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const dataOud = await Axios.post('faktura.php',{
                FakturaID:inData.FakturaID,
                DatumVystaveni:inData.DatumVystaveni,
                DatumSplatnosti:inData.DatumSplatnosti,
                CenaCelkem:inData.CenaCelkem,
                DatumZdanObdobi:inData.DatumZdanObdobi,
            });
            this.refreshLogin();
            
            if (dataOud.data.success === 0)
            {         
                this.isLoggedIn();
            }
            else{
                return dataOud.data;
            }
        }
    }   
    postMarze = async (inData) => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const dataOud = await Axios.post('marze.php',{
                MarzeID:inData.MarzeID,
                SluzbaID:inData.SluzbaID,
                Marze:inData.Marze,
                HmotnostOd: inData.HmotnostOd !== '' ? inData.HmotnostOd : null,
                HmotnostDo:inData.HmotnostDo !== '' ? inData.HmotnostDo : null,
            });
            this.refreshLogin();
            
            if (dataOud.data.success === 0)
            {         
                this.isLoggedIn();
            }
            else{
                return dataOud.data;
            }
        }
    }    
    postSlevy = async (SlevyInfo) => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const dataOud = await Axios.post('slevy.php',{
                SlevaID:SlevyInfo.SlevaID,
                SluzbaID:SlevyInfo.SluzbaID,
                Sleva:SlevyInfo.Sleva,
                UserIDBaseOn:SlevyInfo.UserID,
                HmotnostOd: SlevyInfo.HmotnostOd !== '' ? SlevyInfo.HmotnostOd : null,
                HmotnostDo:SlevyInfo.HmotnostDo !== '' ? SlevyInfo.HmotnostDo : null,
                IsDefault:SlevyInfo.IsDefault,
            });
            this.refreshLogin();
            
            if (dataOud.data.success === 0)
            {         
                this.isLoggedIn();
            }
            else{
                return dataOud.data;
            }
        }
    }    
    StornoZasilky = async (data) => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const dataOud = await Axios.post('zasilky.php',{
                ZasilkaID:data.ZasilkaID,
            });
            this.refreshLogin();
            
            if (dataOud.data.success === 0)
            {         
                this.isLoggedIn();
            }
            else{
                return dataOud.data;
            }
        }
    }
    postSluzby = async (SluzbyInfo) => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const dataOud = await Axios.post('sluzby.php',{
                SluzbaID:SluzbyInfo.SluzbaID,
                Export:SluzbyInfo.SluzbaExport,
                Import:SluzbyInfo.SluzbaImport,
                Paleta:SluzbyInfo.Paleta,
                Dokument:SluzbyInfo.Dokument,
                Balik:SluzbyInfo.Balik,
                PrepravceID:SluzbyInfo.PrepravceID,
                Kod:SluzbyInfo.SluzbaKod,
                Nazev:SluzbyInfo.SluzbaNazev,
            });
            this.refreshLogin();
            
            if (dataOud.data.success === 0)
            {         
                this.isLoggedIn();
            }
            else
            {
                return dataOud.data;
            }
        }
    }
    postPriplatky = async (data) => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const dataOud = await Axios.post('priplatky.php',{
                
                PriplatekID:data.PriplatekID,        
                PlatnostOd:data.PlatnostOd,
                PlatnostDo:data.PlatnostDo,
                SazbaCastka:data.SazbaCastka,
                SazbaProcenta:data.SazbaProcenta,        
                PrepravceID:data.PrepravceID,
                SluzbaID:data.SluzbaID,
                PriplatekNazev:data.PriplatekNazev,
                Popis:data.Popis
            });
            this.refreshLogin();


            if (dataOud.data.success === 0)
            {         
                this.isLoggedIn();
            }
            else
            {
                return dataOud.data;
            }
        }
    }
    postkalulovat = async (inData,Slevy,PouzitSlevy) => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const data = await Axios.post('kalkulace.php',{

                StatFrom:inData.adresFrom,
                StatTo:inData.adresTo,
                Druh:inData.druh,
                Delka:inData.delka,
                Sirka:inData.sirka,
                Vyska:inData.vyska,
                Firma:inData.platce?inData.platce:'',
                Hmotnost:inData.hmotnost,
                Pocet:inData.pocet,
                PouzitSlevy:PouzitSlevy,
                Slevy:Slevy
            });
            this.refreshLogin();
            
            if(data.data){
                if(data.data.success && data.data.Kalkulace){                
                    return data.data.Kalkulace;
                }
                else 
                if (data.data.success === 0)
                {         
                    this.isLoggedIn();
                }
            }
        }
    }
    postkalulovat2 = async (inData,Slevy,PouzitSlevy) => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){

            if(this.isAuth===false){            
                this.isLoggedIn();
            }

            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;

            const data = await Axios.post('kalkulace2.php',{

                StatFrom:inData.adresFrom,
                StatTo:inData.adresTo,
                Druh:inData.druh,
                parametry:inData.parametry,
                Firma:inData.platce?inData.platce:'',
                PouzitSlevy:PouzitSlevy,
                Slevy:Slevy,
                doplnky:inData.doplnky,

            });
            this.refreshLogin();
            
            if(data.data){
                if(data.data.success && data.data.Kalkulace){                
                    return data.data.Kalkulace;
                }
                else
                {         
                    this.isLoggedIn();
                    return [];
                }
            }
        }
    }
    postAkceptace = async (inData,prilohy) => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){

            if(this.isAuth===false){            
                this.isLoggedIn();
            }

            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;

            const data = await Axios.post('zasilka-akceptace.php',{

                ZasilkaID:inData.ZasilkaID,
                CisloZasilky:inData.CisloZasilky,
                Track:inData.Track,
                Prilohy:prilohy,

            });
            this.refreshLogin();
            
            if(data.data){
                if(data.data.success && data.data){                
                    return data.data;
                }
                else
                {         
                    this.isLoggedIn();
                    return {"success":"0","status":422,"message":"Chyba při akceptaci."}
                }
            }
        }
    }
    postEmailPodpora = async (inData,prilohy) => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){

            if(this.isAuth===false){            
                this.isLoggedIn();
            }

            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;

            const data = await Axios.post('podpora-mail.php',{

                Mail:inData.Mail,
                TextMail:inData.TextMail,
                Prilohy:prilohy,

            });
            this.refreshLogin();
            
            if(data.data){
                if(data.data.success && data.data){                
                    return data.data;
                }
                else
                {         
                    this.isLoggedIn();
                    return [];
                }
            }
        }
    }

    
    postFakturaEmail = async (inData,prilohy) => {     
        const loginToken = localStorage.getItem('loginToken');

        if(loginToken){

            if(this.isAuth===false){            
                this.isLoggedIn();
            }

            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;

            const data = await Axios.post('fakturace-mail.php',{

                FakturaID:inData.FakturaID,
                FakturaCislo:inData.VS,
                Prilohy:prilohy,

            });
            this.refreshLogin();
            
            if(data.data){
                if(data.data.success && data.data){                
                    return data.data;
                }
                else
                {         
                    this.isLoggedIn();
                    return {"success":0,"status":422,"message":"Chyba při odesílání faktury."};
                }
            }
        }
    }


    postZalozitFakturu = async (inData) => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }
            
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const data = await Axios.post('zalozit-fakturu.php',{

                KlientID:inData.FakturyInfo.KlientID,
                ZpusobPlabtbyID:inData.FakturyInfo.ZpusobPlabtbyID,
                CenaCelkem:inData.FakturyInfo.CenaCelkem,
                DatumVystaveni:inData.FakturyInfo.DatumVystaveni,
                DatumSplatnosti:inData.FakturyInfo.DatumSplatnosti,
                DatumZdanObdobi:inData.FakturyInfo.DatumZdanObdobi,
                StavID:inData.FakturyInfo.StavID,
                polozky:inData.polozky,
                zasilky:inData.zasilky
                
            });

            this.refreshLogin();
            
            if(data.data){
    
                if(data.data.success && data.data.SEBAplusCislo){                
                    return data.data;
                }
                else if (data.data.success === 0)
                {         
                    this.isLoggedIn();  
                    return data.data;
                }
                else{
                    this.isLoggedIn();
                    return {"success":0,"status":422,"message":"Chyba při zakládání faktury."}; 
                }
            }
        }
    }
    postFakturaZaplaceno = async (inData) => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){

            if(this.isAuth===false){            
                this.isLoggedIn();
            }

            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;

            const data = await Axios.post('faktura-zaplaceno.php',{

                FakturaID:inData

            });
            this.refreshLogin();
            
            if(data.data){
                if(data.data.success && data.data){                
                    return data.data;
                }
                else
                {         
                    this.isLoggedIn();
                    return [];
                }
            }
        }
    }
    postFakturaStorno = async (inData) => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){

            if(this.isAuth===false){            
                this.isLoggedIn();
            }

            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;

            const data = await Axios.post('faktura-storno.php',{

                FakturaID:inData

            });
            this.refreshLogin();
            
            if(data.data){
                if(data.data.success && data.data){                
                    return data.data;
                }
                else
                {         
                    this.isLoggedIn();
                    return [];
                }
            }
        }
    }


    postZalozitZasilku = async (inData) => {     
        const loginToken = localStorage.getItem('loginToken');
        if(inData.SEBAplusCislo!==null || inData.SEBAplusCislo!==undefined || inData.SEBAplusCislo!=='')
        {
            if(loginToken){
                if(this.isAuth===false){ this.isLoggedIn();}
                
                Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
                const data = await Axios.post('ZalozitZasilku.php',{

                    adresFrom:inData.adresFrom,
                    adresTo:inData.adresTo,
                    hodnotaZbozi:inData.hodnotaZbozi,
                    ReferencniCislo:inData.ReferencniCislo,
                    datumVyzvednuti:inData.datumVyzvednuti,
                    casOd:inData.casOd,
                    casDo:inData.casDo,
                    odesilatelJmeno:inData.odesilatelJmeno,
                    odesilatelAdresa:inData.odesilatelAdresa,
                    odesilatelAdresa2:inData.odesilatelAdresa2,
                    odesilatelAdresa3:inData.odesilatelAdresa3,
                    odesilatelMesto:inData.odesilatelMesto,
                    odesilatelPSC:inData.odesilatelPSC,
                    odesilatelKontOsoba:inData.odesilatelKontOsoba,
                    odesilatelEmail:inData.odesilatelEmail,
                    odesilatelTel:inData.odesilatelTel,
                    prijemceJmeno:inData.prijemceJmeno,
                    prijemceAdresa:inData.prijemceAdresa,
                    prijemceAdresa2:inData.prijemceAdresa2,
                    prijemceAdresa3:inData.prijemceAdresa3,
                    prijemceMesto:inData.prijemceMesto,
                    prijemcePSC:inData.prijemcePSC,
                    prijemceKontOsoba:inData.prijemceKontOsoba,
                    prijemceEmail:inData.prijemceEmail,
                    prijemceTel:inData.prijemceTel,
                    popisZbozi:inData.popisZbozi,
                    SluzbaID:inData.SluzbaID,
                    SluzbaNazev:inData.sluzbaNazev,
                    cena:inData.cena,
                    Druh:inData.druh, 
                    Import:inData.Import,
                    poznamka:inData.poznamka,
                    platceJmeno:inData.platceJmeno,
                    platceAdresa:inData.platceAdresa,
                    platceMesto:inData.platceMesto,
                    platcePSC:inData.platcePSC,
                    platceStat:inData.platceStat,
                    platceKontOsoba:inData.platceKontOsoba,
                    platceEmail:inData.platceEmail,
                    platceTel:inData.platceTel,
                    platce:inData.platce,
                    prijemceStatNazev:inData.prijemceStatNazev,
                    odesilatelStatNazev:inData.odesilatelStatNazev,
                    platceStatNazev:inData.platceStatNazev,
                    cenaZaklad:inData.cenaZaklad,
                    parametry:inData.parametry,
                    doplnky:inData.doplnky,
                    ulozitAdrOdesilatel:inData.ulozitAdrOdesilatel,
                    ulozitAdrprijemce:inData.ulozitAdrprijemce,
                    Prilohy:inData.prilohy,
                    UseProForma:inData.UseProForma,
                    PROFORMAdata:inData.PROFORMAdata
                    
                });
                this.refreshLogin();
                
                if(data.data){
                    
                    if(data.data.success && data.data.SEBAplusCislo){                
                        return data.data;
                    }
                    else if (data.data.success === 0)
                    {         
                        this.isLoggedIn();  
                        return data.data;
                    }
                    else{
                        this.isLoggedIn();
                        return {"success":0,"status":422,"message":"Chyba při zakládání zásilky."}; 
                    }
                }
            }
        }
        else
        {
            return {"success":1,"status":201,"SEBAplusCislo":[{SEBAplusCislo:inData.SEBAplusCislo}]}
        }
    }

    postPrepravci = async (data) => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const dataOud = await Axios.post('prepravci.php',{
                PrepravceID:data.PrepravceID,
                Nazev:data.Nazev,
                Kod:data.Kod,
                Logo:data.Logo,
                IsActive:data.IsActive,
            });
            this.refreshLogin();


            if (dataOud.data.success === 0)
            {         
                this.isLoggedIn();
            }
            else
            {
                return dataOud.data;
            }
        }
    }
    postPristupy = async (data) => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const dataOud = await Axios.post('pristupy.php',{
                FirmaPristupID:data.FirmaPristupID,
                FirmaID:data.FirmaID,
                Telefon:data.Telefon,
                Email:data.Email,
                Prijmeni:data.Prijmeni,
                Jmeno:data.Jmeno,
                Aktivni:data.IsActive,
                Login:data.LoginMail,
                Heslo:data.Heslo,
                SendMailAccept:data.SendMailAccept,
                SendMailObjednavka:data.SendMailObjednavka,
                SendMailFaktura:data.SendMailFaktura
            });
            this.refreshLogin();


            if (dataOud.data)
            {         
                return dataOud.data;
            }
            else
            {         
                this.isLoggedIn();
                return [];
            }
        }
    }
    postUser = async (data) => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const dataOud = await Axios.post('user.php',{
                UserUserID:data.UserID,
                OsobaID:data.OsobaID,
                Firma:data.Firma,
                IsActive:data.IsActive,
                IC:data.IC,
                DIC:data.DIC,
                Telefon:data.Telefon,
                Email:data.Email,
                Prijmeni:data.Prijmeni,
                Jmeno:data.Jmeno,
                SendMailAccept:data.SendMailAccept,
                SendMailObjednavka:data.SendMailObjednavka,
                SendMailFaktura:data.SendMailFaktura
                
            });
            this.refreshLogin();


            if (dataOud.data)
            {         
                return dataOud.data;
            }
            else
            {         
                this.isLoggedIn();
                return [];
            }
        }
    }

    postEmailKalkulace = async (data) => {     
        const loginToken = localStorage.getItem('loginToken');
        
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const dataOud = await Axios.post('kalkulace-mail.php',{
                EmailAdresat:data.Mail.EmailAdresat,
                TextMail:data.Mail.TextMail,
                Kalkulace:data.Kalkulace,
                parametry:data.formData.parametry,
                doplnky:data.doplnky,
                druh:data.formData.druh,
                stadOd:data.formData.odesilatelStatNazev,
                statDo:data.formData.prijemceStatNazev
                
                
            });
            this.refreshLogin();


            if (dataOud.data)
            {         
                return dataOud.data;
            }
            else
            {
                this.isLoggedIn();
            }
        }
    }
    postPolozka = async (inData) => {     
        const loginToken = localStorage.getItem('loginToken');
        
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const dataOud = await Axios.post('zasilka-Polozky.php',{
                Delka:inData.Delka,
                DruhID:inData.DruhID,
                Hmotnost:inData.Hmotnost,
                ObjemovaHmotnost:inData.ObjemovaHmotnost,
                Pocet:inData.Pocet,
                Sirka:inData.Sirka,
                Vyska:inData.Vyska,
                ZasilkaID:inData.ZasilkaID,
                ZasilkaPolozkyID:inData.ZasilkaPolozkyID
            });
            this.refreshLogin();

            if (dataOud.data)
            {         
                return dataOud.data;
            }
            else
            {
                this.isLoggedIn();
            }
        }
    }    

    postEmailPoptavka = async (data) => {     
        const loginToken = localStorage.getItem('loginToken');
        
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const dataOud = await Axios.post('kalkulace-poptavka.php',{
                TextMail:data.MailPoptavka.TextMail,
                parametry:data.formData.parametry,
                doplnky:data.formData.doplnky,
                druh:data.formData.druh,
                stadOd:data.formData.odesilatelStatNazev,
                statDo:data.formData.prijemceStatNazev,
                BEZPECNAOSTATNI:data.formData.BEZPECNAOSTATNI
                
            });
            this.refreshLogin();


            if (dataOud.data)
            {         
                return dataOud.data;
            }
            else
            {
                this.isLoggedIn();
            }
        }
    }
    postEditZasilky = async (inData) => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }
            
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const data = await Axios.post('editace-zasilky.php',{


                ReferencniCislo:inData.ReferencniCislo,
                ZasilkaID:inData.ZasilkaID,
                DatumVyzvednuti:inData.DatumVyzvednuti,
                Doruceno:inData.Doruceno,
                Vyzvednuto:inData.Vyzvednuto,
                CasVyzvednutiOd:inData.CasVyzvednutiOd,
                CasVyzvednutiDo:inData.CasVyzvednutiDo,
                tackCislo:inData.Track,
                Popis:inData.Popis,                
                DruhID:inData.DruhID,
                PoznamkaDopravce:inData.PoznamkaDopravce,
                StavID:inData.StavID,
                Cena:inData.CenaZasilky,
                SluzbaID:inData.SluzbaID,
                CenaZaklad:inData.CenaZaklad,
                DPH:inData.DPH,
                SazbaDPH:inData.SazbaDPH,
                FirmaID:inData.FirmaID,
                
            });
            this.refreshLogin();
            
            if(data.data){
                if(data.data.success && data.data){                
                    return data.data;
                }
                else
                {         
                    this.isLoggedIn();
                    return [];
                }
            }
        }
    }


    postDeleteZasilky = async (inData) => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }
            
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const data = await Axios.post('delete-zasilky.php',{
                ZasilkaID:inData.ZasilkaID                
            });
            this.refreshLogin();
            
            if(data.data){
                if(data.data.success && data.data){                
                    return data.data;
                }
                else
                {         
                    this.isLoggedIn();
                    return [];
                }
            }
        }
    }



    



    HeslaZmena = async (data) => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const dataOud = await Axios.post('userZmenaHesla.php',{
                Heslo:data.heslo,
                UzivatelID:data.UzivatelID
                
            });
            this.refreshLogin();


            if (dataOud.data)
            {         
                return dataOud.data;
            }
            else
            {         
                this.isLoggedIn();
                return [];
            }
        }
    }








    delPristupy = async (data) => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const dataOud = await Axios.delete('pristupy.php',{
                data: {
                    FirmaPristupID:data.FirmaPristupID,
                }
            });
            this.refreshLogin();


            if (dataOud.data.success === 0)
            {         
                this.isLoggedIn();
            }
            else
            {
                return dataOud.data;
            }
        }
    }

    delSdeleni = async (data) => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const dataOud = await Axios.delete('notifikace.php',{
                data: {
                    NotifikaceID:data.NotifikaceID,
                }
            });
            this.refreshLogin();


            if (dataOud.data)
            {         
                this.isLoggedIn();
            }
            else
            {
                return dataOud.data;
            }
        }
    }

    delSluzby = async (SluzbyInfo) => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const dataOud = await Axios.delete('sluzby.php',{
                data: {
                    SluzbaID:SluzbyInfo.SluzbaID,
                }
            });
            this.refreshLogin();


            if (dataOud.data.success === 0)
            {         
                this.isLoggedIn();
            }
            else
            {
                return dataOud.data;
            }
        }
    }

    delPrepravci = async (data) => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const dataOud = await Axios.delete('prepravci.php',{
                data: {
                    PrepravceID:data.PrepravceID,
                }
            });
            this.refreshLogin();


            if (dataOud.data.success === 0)
            {         
                this.isLoggedIn();
            }
            else
            {
                return dataOud.data;
            }
        }
    }
    delMarze = async (indata) => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const dataOud = await Axios.delete('marze.php',{
                data: {
                    MarzeID:indata.MarzeID,
                }
            });
            this.refreshLogin();


            if (dataOud.data.success === 0)
            {         
                this.isLoggedIn();
            }
            else
            {
                return dataOud.data;
            }
        }
    }
    delSlevy = async (SlevyInfo) => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const dataOud = await Axios.delete('slevy.php',{
                data: {
                    SlevaID:SlevyInfo.SlevaID,
                }
            });
            this.refreshLogin();


            if (dataOud.data.success === 0)
            {         
                this.isLoggedIn();
            }
            else
            {
                return dataOud.data;
            }
        }
    }
    delAdresy = async (AdresyInfo) => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const dataOud = await Axios.delete('adresy.php',{
                data: {
                    AdresaID:AdresyInfo.AdresaID,
                }
            });
            this.refreshLogin();



            if (dataOud.data.success === 0)
            {         
                this.isLoggedIn();
            }
            else
            {
                return dataOud.data;
            }
        }
    }
    delPriplatky = async (data) => {     
        const loginToken = localStorage.getItem('loginToken');
        if(loginToken){
            if(this.isAuth===false){            
                this.isLoggedIn();
            }
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const dataOud = await Axios.delete('priplatky.php',{
                data: {
                    PriplatekID:data.PriplatekID,
                }
            });
            this.refreshLogin();


            if (dataOud.data.success === 0)
            {         
                this.isLoggedIn();
            }
            else
            {
                return dataOud.data;
            }
        }
    }






    // Checking user logged in or not
    isLoggedIn = async () => {
        
        const loginToken = localStorage.getItem('loginToken');

        if(loginToken){

            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;


            const response1 = await Axios.get('user-info.php',{},{axiosConfig});
            const response2 = await Axios.get('user-type.php',{},{axiosConfig});

            const data = await response1.data;
            const data2 = await response2.data;

            if(data !==undefined && data2 !==undefined)
            {


                // If user information is successfully received            
                if(data.success === 1 && data.user && data2.UserType[0].UserType === '1'){
                    this.setState({
                        ...this.state,
                        isAuth:true,
                        isAdmin:true,
                        theUser:data.user
                    });

                    localStorage.setItem('currentUser', JSON.stringify(data.user));
                    return(true);
                }            
                else if(data.success === 1 && data.user){
                    this.setState({
                        ...this.state,
                        isAuth:true,
                        isAdmin:false,
                        theUser:data.user
                    });

                    localStorage.setItem('currentUser', JSON.stringify(data.user));
                    return(true);
                }
                else
                {
                    this.logoutUser()
                    window.location.reload();
                    return(false);
                }
            }     
            this.logoutUser()
            window.location.reload();
            return(false);
        }
        this.logoutUser()
        return(false);
    }

    loginUser = async (user) => {

        // Sending the user Login request
        let response = await Axios.post('login.php',{
            email:user.email,
            password:user.password
        },axiosConfig);


        let users = (await response).data;
        
        return users;
        
    }

    refreshLogin = async () => {


        const loginToken = localStorage.getItem('loginToken');
        const tokenTime = localStorage.getItem('tokenTime');

        let datumeToken = new Date(tokenTime)
        let nowDate = new Date(new Date().setHours(new Date().getHours() + 1));
        let datumToken = new Date(new Date().setHours(new Date().getHours() + 2));

        if(tokenTime && loginToken)
        {            
            if(nowDate>datumeToken)
            {
                Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
                const refreshtoken = await Axios.post('refreshtoken.php');
                
                if(refreshtoken.data)            
                {                    
                    if(refreshtoken.data.success)
                    {                    
                        localStorage.setItem('loginToken', refreshtoken.data.token);
                        localStorage.setItem('tokenTime', datumToken);
                    }
                    else 
                    {
                        this.logoutUser()
                        window.location.reload();

                    }
                }
                else 
                {
                    this.logoutUser()
                    window.location.reload();

                }
            }
        }

    }


    render(){
        const contextValue = {
            rootState:this.state,
            isLoggedIn:this.isLoggedIn,
            IsUserAuth:this.IsUserAuth,
            registerUser:this.registerUser,
            ZalozitFirmu:this.ZalozitFirmu,
            loginUser:this.loginUser,
            logoutUser:this.logoutUser,
            kontrolaZmenyPW:this.kontrolaZmenyPW,
            restartHeslaMail:this.restartHeslaMail,
            restartHeslaZmena:this.restartHeslaZmena,
            HeslaZmena:this.HeslaZmena,

            getAres:this.getAres,
            getDashboard:this.getDashboard,
            getZasilkyAdmin:this.getZasilkyAdmin,
            getZasilkyAdminFiltr:this.getZasilkyAdminFiltr,
            getZasilky:this.getZasilky,
            getMenuItems:this.getMenuItems,
            getStaty:this.getStaty,
            getAdresyByStat:this.getAdresyByStat,
            getAdresy:this.getAdresy,
            getSluzby:this.getSluzby,
            getSluzbyDatagrid:this.getSluzbyDatagrid,
            getSlevy:this.getSlevy,
            getMarze:this.getMarze,
            getPriplatky:this.getPriplatky,
            getPrepravci:this.getPrepravci,
            getSpolecnost:this.getSpolecnost,
            getUserType:this.getUserType,
            getSdeleni:this.getSdeleni,
            getFirmy:this.getFirmy,
            getPristupy:this.getPristupy,
            getUser:this.getUser,
            getFirmySluzby:this.getFirmySluzby,
            getZasilkaStavy:this.getZasilkaStavy,
            getPolozky:this.getPolozky,
            getZasilkaAdresy:this.getZasilkaAdresy,
            getZasilkaZakladniInformace:this.getZasilkaZakladniInformace,
            getStavy:this.getStavy,
            getDruhy:this.getDruhy,
            getZasilkaSluzby:this.getZasilkaSluzby,
            getKlientSlevy:this.getKlientSlevy,
            getFakturaZasilky:this.getFakturaZasilky,
            getFakturaInfo:this.getFakturaInfo,
            getFaktury:this.getFaktury,
            getFakturaPolozky:this.getFakturaPolozky,
            getFakturaByID:this.getFakturaByID,
            getSlevyKlient:this.getSlevyKlient,
            
            postUser:this.postUser,
            postPristupy:this.postPristupy,
            postSdeleni:this.postSdeleni,
            postPotvrditNotifikaci:this.postPotvrditNotifikaci,
            postAdresy:this.postAdresy,
            postSlevy:this.postSlevy,
            postMarze:this.postMarze,
            postSluzby:this.postSluzby,
            postPrepravci:this.postPrepravci,
            postPriplatky:this.postPriplatky,
            StornoZasilky:this.StornoZasilky,
            postFirmySluzby:this.postFirmySluzby,
            postEmailKalkulace:this.postEmailKalkulace,
            postEditZasilky:this.postEditZasilky,
            postFakturaEmail:this.postFakturaEmail,
            postEmailPoptavka:this.postEmailPoptavka,
            postZalozitFakturu:this.postZalozitFakturu,
            postFakturaZaplaceno:this.postFakturaZaplaceno,
            postFakturaStorno:this.postFakturaStorno,
            postPolozka:this.postPolozka,
            postDeleteZasilky:this.postDeleteZasilky,
            postFaktura:this.postFaktura,

            delPristupy:this.delPristupy,
            delSdeleni:this.delSdeleni,
            delSluzby:this.delSluzby,
            delSlevy:this.delSlevy,
            delMarze:this.delMarze,
            delAdresy:this.delAdresy,
            delPriplatky:this.delPriplatky,
            delPrepravci:this.delPrepravci,

            
            vyhledatZasilku:this.vyhledatZasilku,
            postkalulovat:this.postkalulovat,
            postkalulovat2:this.postkalulovat2,
            postZalozitZasilku:this.postZalozitZasilku,
            postAkceptace:this.postAkceptace,
            postEmailPodpora:this.postEmailPodpora,
        }
        return(
            <UserContext.Provider value={contextValue}>
                {this.props.children}
            </UserContext.Provider>
        )
    }

}

export default UserContextProvider;