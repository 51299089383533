import React from "react";
import Container from '@material-ui/core/Container';

export const Dokonceni = ({ formData }) => {
  const {
    SEBAplusCislo
  } = formData;


  return (
    <Container maxWidth="sm" style={{ marginTop: '4rem' }}>
    <h3>Vaše zásilka byla zařazena do systému SEBAplus!</h3>
    <h3>V systému SEBAplus jí naleznete pod číslem {formData.SEBAplusCislo}.</h3>
    </Container>
  );
};