import React, {useContext,useState, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import customConfig from '../../customConfig.json'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import * as moment from 'moment'
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import Modal from '@material-ui/core/Modal';
import Container from '@material-ui/core/Container';
import { makeStyles,withStyles, } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {UserContext} from '../../contexts/UserContext'


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  shad:{
    boxShadow:'0px 1px 3px #777777c4',
    border:'1px solid'+customConfig.Colors.PrimaryColor,
  },
  table:{
    border:'1px solid'+customConfig.Colors.PrimaryColor,
  },
  tableRows:{
    borderBottom:'1px solid'+customConfig.Colors.PrimaryColor,
    cursor:'pointer'
  },
  tableRow:{
    border: "none",
    padding:"5px"
  },
  paperH: {
    textAlign: 'center',
    backgroundColor: customConfig.Colors.PrimaryColor,
    boxShadow: 'none',
    border:'1px solid'+customConfig.Colors.PrimaryColor,
    borderRadius: '0px'
  },
  nadpisH:{
    color: customConfig.Colors.ButtonTextColor,
    fontSize:'20px'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: customConfig.Colors.BorderColor,       
    borderRadius:"10px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function Notifikace(data){

    
  const classes = useStyles();
  const [Notifikace, setNotifikace] = useState([])  
  const [DisplyNotifikace, setDisplyNotifikace] = useState({
      Titulek:"",
      Notifikace:"",
      DatumZalozeni:""
  })  
  const [openDel, setOpenDel] = React.useState(false);
  const {postPotvrditNotifikaci} = useContext(UserContext);


  
  useEffect(() => {
    if(data.data)
    {
      if(data.data.success===1){
        if(data.data.Notifikace!==undefined) 
        {
          setNotifikace(data.data.Notifikace);
        }
      }
    }    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]) 



  const GreenButton = withStyles((theme) => ({
    root: {
      color: customConfig.Colors.ButtonTextColor, 
      backgroundColor: customConfig.Colors.PrimaryColor,
      fontWeight:'520',
      '&:hover': {
        backgroundColor: customConfig.Colors.SecColor,
      },
    },
  }))(Button);

  const handleDelClose = () => {
    setOpenDel(false);
};

  const handleCellClick = (row) => {
      
    setDisplyNotifikace({
        ...DisplyNotifikace,
        Titulek:row.Titulek,
        Notifikace:row.Notifikace,
        DatumZalozeni:row.DatumZalozeni
    });

    setOpenDel(true);

    if (row.Precteno === '0'){
      postPotvrditNotifikaci(row);
    }

    const newInputFields = Notifikace.map(i => {
        if(row.NotifikaceID === i.NotifikaceID) {
          i.Precteno = "1"
        }
        return i;
      })

      setNotifikace(newInputFields)

}

return (
  <div>

    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openDel}
      onClose={handleDelClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
    >
      <Fade in={openDel}>
        <div className={classes.paper}>
          <Container maxWidth="md">
            <Grid container spacing={2}>      
              <Grid item xs={12} sm={12}> 
                  <Typography variant="h6" className={classes.nadpis}>{DisplyNotifikace.Titulek}</Typography>
              </Grid>
              <Grid item xs={12} sm={12}> 
                <TextField name="Notifikace" type="text" variant="outlined" size="small" fullWidth multiline rows={15} disabled value={DisplyNotifikace.Notifikace} />
              </Grid>
              <Grid item xs={12} sm={10}/>
              <Grid item xs={12} sm={2}> 
                  <GreenButton fullWidth onClick={handleDelClose}>OK</GreenButton>
              </Grid>
            </Grid>                        
          </Container>
        </div>
      </Fade>
    </Modal>    

      <Paper className={classes.shad}>
        <Grid item xs={12}>
          <Grid item xs={12} sm={12}> 
            <Paper className={classes.paperH}>
              <Typography className={classes.nadpisH}>Obchodní sdělení</Typography>
            </Paper>
          </Grid>

            {(Notifikace!==[] && Notifikace!== undefined && Notifikace!== null) && ( 
              <Grid item xs={12} sm={12}> 
                  <Table className={classes.table}>
                      <TableBody>
                          {Notifikace.map(row => {
                              return (
                              <TableRow key={row.NotifikaceID}  className={classes.tableRows} >     
                                  <TableCell onClick={(e) => {handleCellClick(row)}} width="30px" className={classes.tableRow}>{row.Precteno === '0' ? <NotificationImportantIcon /> : <NotificationsNoneIcon /> }</TableCell>     
                                  <TableCell onClick={(e) => {handleCellClick(row)}} className={classes.tableRow} width="80px">{moment(row.DatumZalozeni).format("DD.MM.YYYY")}</TableCell>
                                  <TableCell onClick={(e) => {handleCellClick(row)}} className={classes.tableRow} width="300px">{row.Titulek} </TableCell>                    
                              </TableRow>
                              );
                          })}
                      </TableBody>
                  </Table> 
              </Grid>
            )}   

        </Grid>
      </Paper>
  </div>
);

}

export default Notifikace
