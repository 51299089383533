import React, {useContext,useState, useEffect} from 'react';
import {UserContext} from '../../contexts/UserContext'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles,withStyles, } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import customConfig from '../../customConfig.json'
import { Alert } from '@material-ui/lab';
import { useHistory  } from 'react-router-dom'
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';




const TextFields = withStyles({
    root: {
      '& label.Mui-focused': {
        color: customConfig.Colors.PrimaryColor,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: customConfig.Colors.PrimaryColor,
      },
      '& .MuiOutlinedInput-root': {
  
        '&:hover fieldset': {
          borderColor: customConfig.Colors.SecColor,
        },
        '&.Mui-focused fieldset': {
          borderColor: customConfig.Colors.PrimaryColor,
        },
      },
    },
  })(TextField);


function Register(){

    
    const initialState = {
        userInfo:{
            jmeno:'',
            prijmeni:'',
            IC:'',
            dic:'',
            loginemail:'',
            password:'',
            passwordCheck:'',
            konEmail:'',
            phone:'',
            adresa:'',
            mesto:'',
            psc:'',
            stat:'CZ',
            firma:'',
        },
        errorMsg:'',
        successMsg:'',
        checkedG: false,
        defaultStat:null,
        Formular:{
          dokumenty:false,
          baliky:false,
          palety:false,
          kontejnery:false,
          ostatni:false,
          export:false,
          import:false,
          tuzemsko:false,
          denne:false,
          tydne:false,
          mesice:false,
          dennePocet:'',
          tydnePocet:'',
          mesicePocet:'',
          hmotnost:'',
          bezpecne:null,
          pojistit:null,
        }
    };    
    
    

    const CheckBoxhandleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked },             
            );            
      };

      
      
    const useStyles = makeStyles((theme) => ({
        paper: {
          marginTop: theme.spacing(0),
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          alignItems: 'center',
          padding:'0px'
        },
        contai: {
          margin: 'auto',
          padding:'25px'
        },
        margin: {
          margin: theme.spacing(0),
          padding:'25px'
        },
        modal: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow:'scroll',
        },
        paperError: {
          textAlign: 'center',
          display: 'flex',
          color: '#ff0000',
          padding:'5px 15px',
        },
        paperSuccess: {
          textAlign: 'center',
          display: 'flex',
          color: '#4e8c2d0',
          backgroundColor:"red",
          padding:'5px 15px',
        },
        hide: {
          display: 'none',
        },      
        callContainer: {
          backgroundColor: 'none',
        },
        paperY: {
          backgroundColor: theme.palette.background.paper,
          border: customConfig.Colors.BorderColor,          
          boxShadow: theme.shadows[5],
          padding: '25px',
          borderRadius:'10px 10px 8px 8px',
          border:'none'
        },
        paperx: {
          backgroundColor: theme.palette.background.paper,
          border: customConfig.Colors.BorderColor,          
          boxShadow: theme.shadows[5],
          padding: '0px',
          borderRadius:'10px 10px 8px 8px',
          border:'none'
        },
        margin: {
          margin: theme.spacing(0),
        },
        hide: {
          display: 'none',
        },      
        callContainer: {
          backgroundColor: 'none',
        },
        innerPadding:{
            overflow:'scroll',
            height:'500px',
            display:'block'
        },      
        nadpisOtayka:{          
          fontWeight: '600'
        },     
        nadpisH:{
          color: customConfig.Colors.ButtonTextColor,
          fontSize:'20px'
        },
        paperH: {
          textAlign: 'left',
          paddingLeft:'20px',
          backgroundColor: customConfig.Colors.PrimaryColor,
          boxShadow: 'none',
          border:'1px',
          borderRadius: '8px 8px 0px 0px'
        },
        form: {
          width: '100%', // Fix IE 11 issue.
          marginTop: theme.spacing(1),
          backgroundColor: 'none',
        },
        withoutLabel: {
          marginTop: theme.spacing(1),
        },
        boxStyle:{
          marginTop:'15px'

        },
        nadpis:{
          marginTop:'15px'
        },
        textf: {
          padding: '0px 8px 0px 0px',
        },
        paperH: {
          textAlign: 'left',
          paddingLeft:'20px',
          backgroundColor: customConfig.Colors.PrimaryColor,
          boxShadow: 'none',
          border:'1px',
          borderRadius: '8px 8px 0px 0px'
        },
      }));

      const GreenButton2 = withStyles((theme) => ({
        root: {
          color: 'white',
          backgroundColor: customConfig.Colors.PrimaryColor,
          '&:hover': {
            backgroundColor: customConfig.Colors.SecColor,
          },
        },
      }))(Button);

      const GreenButton = withStyles((theme) => ({
        root: {
          color: 'white',
          backgroundColor: customConfig.Colors.PrimaryColor,
          '&:hover': {
            backgroundColor: customConfig.Colors.SecColor,
          },
        },
      }))(Button);

      const GreenCheckbox = withStyles({
        root: {
          color: customConfig.Colors.PrimaryColor,
          '&$checked': {
            color: customConfig.Colors.PrimaryColor,
          },
        },
        checked: {},
      })((props) => <Checkbox color="default" {...props} />);
    
      
    const submitForm = async (event) => {
        
        event.preventDefault();
        
        setState({
            ...state,
            successMsg:'',
            errorMsg:''
        });

        const data = await registerUser(state);


        if(data.success){
            setState({
                ...initialState,
                successMsg:data.message,
            });
        }
        else{
            setState({
                ...state,
                successMsg:'',
                errorMsg:data.message
            });
        }
    }


    const DohledatIC = async (event) => {
        

        const data = await getAres(state.userInfo);
        if(data!==undefined){
            setState({
                ...state,
                userInfo:{
                    ...state.userInfo,
                    dic:data.Ares.DIC,
                    firma:data.Ares.OF,
                    psc:data.Ares.AA.PSC,
                    mesto:data.Ares.AA.N,
                    adresa:data.Ares.AA.NU + " " + data.Ares.AA.CD,

                },
                defaultStat: Staty.find(x => x.CisloStatu === data.Ares.AA.KS),
                errorMsg:''
            });
        }
        else{
            setState({
                ...state,
                errorMsg:"Zadané IČ se nepodařilo dohledat."
            });
        }

        
    }
        
    

    const {registerUser,getStaty,getAres} = useContext(UserContext);
    const [Staty, setStaty] = useState([])    
    const pojisteni = [{hodnota:'Ano'},{hodnota:'Ne'},{hodnota:'Jen někdy'}];    
    const bezpecne = [{hodnota:'Ano'},{hodnota:'Ne'}] ;  
    const [open, setOpen] = React.useState(false);
    const [nameError, setNameError] = useState({});
    const [stat, setstat] = React.useState(false);


    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  


    useEffect(() => {
        document.title = "SEBAplus - Registrace";

        async function fetchData() {
            const dataOUD = await getStaty(); 
            setStaty(dataOUD.theStaty.stat) 
        }
        
        fetchData()
          
    }, [])   


    function validovatPole(e) {

        if(e.target.value!=="" && e.target.value !== undefined && e.target.value !== null)
        {
          setNameError({
            ...nameError,
                ...nameError,
                [e.target.name]: false,
          });
    
        }
        else
        {
          setNameError({
            ...nameError,
                ...nameError,
                [e.target.name]: true,
          });
        }
      }

    useEffect(() => {
       setState({
        ...state,
        defaultStat: Staty.find(x => x.Kod === 'CZ')
        })

    // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [Staty])   


    const [state,setState] = useState(initialState);

    const onChangeValue = (e) => {
        setState({
            ...state,
            userInfo:{
                ...state.userInfo,
                [e.target.name]:e.target.value
            },
        });

    }
    
    const onChangeValueFormular = (e) => {
        setState({
            ...state,
            Formular:{
                ...state.Formular,
                [e.target.name]:e.target.value
            },
        });
    }
    

    const CheckBoxhandleChangeFormular = (e) => {
      setState({
          ...state,
          Formular:{
              ...state.Formular,
              [e.target.name]: e.target.checked 
          },
      });         
    };    
    
    let history = useHistory();
    const classes = useStyles();

    return(
        <Container className={classes.contai} component="main" maxWidth="lg" >
            <div className={classes.paperY} >
                <Grid container spacing={2}  direction="row" justify="space-between" alignItems="flex-start" style={{textAlign:"center"}} alignContent="center">
                            
                    <Grid item xs={12} sm={4} >  
                        <img src="../img/loga/LogoSEBAPlus.png" alt="SEBA Logistics, s.r.o." height="100px"/>                                        
                    </Grid>       
                    <Grid item xs={12} sm={2}>
                    </Grid>       
                    <Grid item xs={12} sm={4}>                                  
                        <GreenButton
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => { history.push("/Login") }}>
                                        Přihlásit se
                        </GreenButton>                
                        
                    </Grid>    

                    <Grid item xs={12} sm={12}> 
                        <Typography variant="h6" className={classes.nadpis}>
                            Registrace do SEBAplus 
                        </Typography>
                    </Grid>    
                    <Grid item xs={12} sm={12}> 
                        <Typography variant="body1">
                            Mějte vždy přehled! Spravujte všechny své příchozí a odchozí zásilky z jednoho místa.
                        </Typography> 
                    </Grid>                                
                </Grid>   
                


                <form onSubmit={submitForm} noValidate id="registrace">

                <FormControl className={classes.margin}>

                    <Grid container spacing={2} justify="center" alignItems="center">
                        
                        <Grid item xs={12} sm={12}>  
                            <Typography variant="body1" gutterBottom>Přihlašovací údaje: </Typography>                                        
                        </Grid>

                        <Grid item xs={12} sm={4}>                                              
                            <TextFields name="loginemail" className={clsx(classes.textf,classes.margin)} type="email" variant="outlined" size="small" fullWidth id="loginemail" label="Přihlašovací email" required placeholder="Přihlašovací email" value={state.userInfo.loginemail} onClick={(e) => {validovatPole(e)}} onChange={(e) => {validovatPole(e);onChangeValue(e)}} error ={(nameError.loginemail===undefined ? false : nameError.loginemail) ? true : false } />
                        </Grid>
                        <Grid item xs={12} sm={4}>                                              
                            <TextFields name="password" className={clsx(classes.textf,classes.margin)} type="password" margin="normal" variant="outlined" size="small" fullWidth id="password" label="Heslo" required placeholder="Heslo" value={state.userInfo.password} onClick={(e) => {validovatPole(e)}} onChange={(e) => {validovatPole(e);onChangeValue(e)}} error ={(nameError.password===undefined ? false : nameError.password) ? true : false } />
                        </Grid>
                        <Grid item xs={12} sm={4}>                                              
                            <TextFields name="passwordCheck" className={clsx(classes.textf,classes.margin)} type="password" margin="normal" variant="outlined" size="small" fullWidth id="passwordCheck" label="Kontrola hesla" required placeholder="Kontrola hesla" value={state.userInfo.passwordCheck} onChange={(e) => {onChangeValue(e)}} error ={(nameError.password===undefined ? false : nameError.password) ? true : false || state.userInfo.passwordCheck.length<8?true:false || state.userInfo.passwordCheck!==state.userInfo.password?true:false } />
                        </Grid>



                        <Grid item xs={12} sm={12}>  
                        <Typography variant="body1" gutterBottom>Kontaktní údaje: </Typography>                                        
                        </Grid>


                        <Grid item xs={12} sm={6}>                                              
                            <TextFields name="jmeno" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="jmeno" label="Jméno" required placeholder="Jméno" value={state.userInfo.jmeno} onClick={(e) => {validovatPole(e)}} onChange={(e) => {validovatPole(e);onChangeValue(e)}} error ={(nameError.jmeno===undefined ? false : nameError.jmeno) ? true : false } />
                        </Grid>
                        <Grid item xs={12} sm={6}>                                              
                            <TextFields name="prijmeni" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="prijmeni" label="Přijmení" required placeholder="Přijmení" value={state.userInfo.prijmeni} onClick={(e) => {validovatPole(e)}} onChange={(e) => {validovatPole(e);onChangeValue(e)}} error ={(nameError.prijmeni===undefined ? false : nameError.prijmeni) ? true : false } />
                        </Grid>

                        <Grid item xs={12} sm={6}>                                              
                            <TextFields name="phone" className={clsx(classes.textf,classes.margin)} type="tel" variant="outlined" size="small" fullWidth id="phone" label="Telefon" required placeholder="Telefon" value={state.userInfo.phone} onClick={(e) => {validovatPole(e)}} onChange={(e) => {validovatPole(e);onChangeValue(e)}} error ={(nameError.phone===undefined ? false : nameError.phone) ? true : false } />
                        </Grid>
                        <Grid item xs={12} sm={6}>                                              
                            <TextFields name="konEmail" className={clsx(classes.textf,classes.margin)} type="email" variant="outlined" size="small" fullWidth id="konEmail" label="Kontaktní email" required placeholder="Kontaktní email" value={state.userInfo.konEmail} onClick={(e) => {validovatPole(e)}} onChange={(e) => {validovatPole(e);onChangeValue(e)}} error ={(nameError.konEmail===undefined ? false : nameError.konEmail) ? true : false } />
                        </Grid>


                        <Grid item xs={12} sm={12}>  
                            <Typography variant="body1"  gutterBottom>Základní údaje: </Typography>                                        
                        </Grid>
                        
                        <Grid item xs={12} sm={10}>
                            <TextFields name="IC" 
                            className={clsx(classes.textf,classes.margin)}
                            type="text" 
                            variant="outlined"
                            size="small"
                            fullWidth
                            id="IC"
                            label="IČ"
                            required placeholder="IČ" value={state.userInfo.IC} onClick={(e) => {validovatPole(e)}} onChange={(e) => {validovatPole(e);onChangeValue(e)}} error ={(nameError.IC===undefined ? false : nameError.IC) ? true : false } />
                        </Grid>

                        <Grid item xs={12} sm={2}>
                            <GreenButton2
                                fullWidth
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={DohledatIC}
                                >
                                    Dohledat údaje
                            </GreenButton2>
                        </Grid>


                        <Grid item xs={12} sm={12}>                        
                            <TextFields name="firma" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="firma" label="Firma" required placeholder="Název firmy" value={state.userInfo.firma} onClick={(e) => {validovatPole(e)}} onChange={(e) => {validovatPole(e);onChangeValue(e)}} error ={(nameError.firma===undefined ? false : nameError.firma) ? true : false } />
                        </Grid>
                        

                        <Grid item xs={12} sm={6}>                                              
                            <TextFields name="dic" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="dic" label="DIČ" placeholder="DIČ" value={state.userInfo.dic} onClick={(e) => {validovatPole(e)}} onChange={(e) => {onChangeValue(e)}} />
                        </Grid>


                        <Grid item xs={12} sm={6}>                                              
                            <TextFields name="adresa" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="adresa" label="Adresa" required placeholder="Adresa" value={state.userInfo.adresa} onClick={(e) => {validovatPole(e)}} onChange={(e) => {validovatPole(e);onChangeValue(e)}} error ={(nameError.adresa===undefined ? false : nameError.adresa) ? true : false } />
                        </Grid>
                        <Grid item xs={12} sm={6}>                                              
                            <TextFields name="mesto" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="mesto" label="Město" required placeholder="Město" value={state.userInfo.mesto} onClick={(e) => {validovatPole(e)}} onChange={(e) => {validovatPole(e);onChangeValue(e)}} error ={(nameError.mesto===undefined ? false : nameError.mesto) ? true : false } />
                        </Grid>


                        <Grid item xs={12} sm={6}>                                              
                            <TextFields name="psc" className={clsx(classes.textf,classes.margin)} type="text" variant="outlined" size="small" fullWidth id="psc" label="PSČ" required placeholder="PSČ" value={state.userInfo.psc} onClick={(e) => {validovatPole(e)}} onChange={(e) => {validovatPole(e);onChangeValue(e)}} error ={(nameError.psc===undefined ? false : nameError.psc) ? true : false } />
                        </Grid>

                        <Grid item xs={12} sm={6}> 

                        <Autocomplete
                            id="stat"
                            options={Staty}
                            getOptionLabel={(option) => option.Nazev ? option.Nazev : ''}                
                            fullWidth         
                            clearOnBlur 
                            value={state.defaultStat ? state.defaultStat : null}   
                            onChange={(event, newValue) => {                                
                              if (newValue) {                                     
                                setState({
                                    ...state,
                                    userInfo:{
                                        ...state.userInfo,
                                        stat:newValue.Kod
                                    },
                                    defaultStat:newValue
                                }); 
                                setstat(false)
                              }
                              else{        
                                setState({
                                    ...state,
                                    userInfo:{
                                        ...state.userInfo,
                                        stat:''
                                    },
                                    defaultStat:null
                                }); 
                                setstat(true)
                              }
                            }}   
                            renderOption={(option) => (
                              <React.Fragment>
                                  <span><img src={"../img/flags/24/"+option.Kod+".png"} alt={option.Kod}/></span>
                                  {option.Nazev}
                              </React.Fragment>
                            )}
                            renderInput={(params) => <TextFields {...params} label="Stát" size="small" error={stat} variant="outlined" />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}/>                                              
                        




                        <Grid item xs={12} sm={4}/> 
                        <Grid item xs={12} sm={4}>                  
                          <GreenButton size="small" style={{marginLeft:'10px'}} variant="contained" color="primary" onClick={handleOpen}>
                          Formulář pro nastavení individuálních sazeb
                          </GreenButton> 
                        </Grid> 
                        <Grid item xs={12} sm={4}/> 

                        <Grid item xs={12} sm={2}/>   
                        <Grid item xs={12} sm={8}>   
                            <Typography variant="body2"> 
                                <FormControlLabel control={<GreenCheckbox checked={state.checkedG} onChange={CheckBoxhandleChange} name="checkedG" />}/>
                                Souhlasím se 
                                <Link href="https://www.sebalogistics.cz/vseobecne-obchodni-podminky" target="_blank" rel="noopener" variant="body2" > Všeobecnými podmínkami </Link>
                                a 
                                <Link href="https://www.sebalogistics.cz/vseobecne-obchodni-podminky" target="_blank" rel="noopener" variant="body2" > prohlášením o ochraně osobních údajů </Link>
                                společnosti SEBA Logistics
                            </Typography>
                        </Grid> 
                        <Grid item xs={12} sm={2}/>   

                        
                        <Grid item xs={12} sm={2}/>   
                        <Grid item xs={12} sm={8}>                                     
                            {(state.errorMsg.length>0) && ( 
                                <Alert style={{alignItems:"center"}} severity="error">{state.errorMsg}</Alert>                            
                            )}                                                
                            {(state.successMsg.length>0) && ( 
                            <Alert style={{alignItems:"center"}} severity="success">
                                {state.successMsg}                            
                                <GreenButton size="small" style={{marginLeft:'10px'}} variant="contained" color="primary" onClick={() => { history.push("/Login") }}>
                                        Přihlásit se 
                                </GreenButton> 
                            </Alert>       
                            )}    
                            
                            
                        </Grid> 
                        <Grid item xs={12} sm={2}/>    

                        <Grid item xs={12} sm={4}/>   
                        <Grid item xs={12} sm={4}>                                              
                        <GreenButton
                                disabled={!state.checkedG || state.userInfo.passwordCheck!==state.userInfo.password?true:false}
                                fullWidth
                                size="small"
                                variant="contained"
                                color="primary"
                                form="registrace"
                                type="submit">
                                    Vytvořit účet
                            </GreenButton>
                        </Grid>
                        <Grid item xs={12} sm={4}/>   
                        <Grid item xs={12} sm={4}/>   
                        <Grid item xs={12} sm={4}>                           
                            <Link href="/Login" variant="body2">
                                {"Máte již účet? Přihlašte se"}
                            </Link>
                        </Grid> 
                        <Grid item xs={12} sm={4}/>   


                    </Grid>

                </FormControl>
                </form>
            </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        key="add"
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
      >
        <Fade in={open}>
            <div className={classes.paperx}>
              <Paper className={classes.paperH}>
                <Typography className={classes.nadpisH}>Vytvoříme řešení pro Vaše zásilky</Typography>
              </Paper>
              <Container className={classes.innerPadding} maxWidth="md">
                <Grid container spacing={2}>    
                    <Grid item xs={12} sm={12}> 
                      <Typography variant="body1" className={classes.nadpisOtayka} >1.	Jaký druh zásilek zasíláte?</Typography>                                             
                    </Grid> 
                    <Grid item xs={0} sm={1}/>   
                    <Grid item xs={6} sm={1}>                                              
                      <GreenCheckbox name="dokumenty" className={clsx(classes.textf,classes.margin)} variant="outlined" id="dokumenty" checked={state.Formular.dokumenty} onChange={(e) => {CheckBoxhandleChangeFormular(e)}}  />
                    </Grid> 
                    <Grid item xs={6} sm={4}>   
                      <Typography variant="body1">Dokumenty</Typography>                           
                    </Grid> 
                    <Grid item xs={0} sm={6}/> 
                    <Grid item xs={0} sm={1}/>   
                    <Grid item xs={6} sm={1}>                                           
                      <GreenCheckbox name="baliky" id="baliky" className={clsx(classes.textf,classes.margin)} variant="outlined" checked={state.Formular.baliky} onChange={(e) => {CheckBoxhandleChangeFormular(e)}}  />
                    </Grid> 
                    <Grid item xs={6} sm={4}>   
                      <Typography variant="body1">Balíky</Typography>                           
                    </Grid> 
                    <Grid item xs={0} sm={6}/> 
                    <Grid item xs={0} sm={1}/>     
                    <Grid item xs={6} sm={1}>                                              
                      <GreenCheckbox name="palety" id="palety" className={clsx(classes.textf,classes.margin)} variant="outlined" checked={state.Formular.palety} onChange={(e) => {CheckBoxhandleChangeFormular(e)}}  />
                    </Grid> 
                    <Grid item xs={6} sm={4}>   
                      <Typography variant="body1">Palety</Typography>                           
                    </Grid> 
                    <Grid item xs={0} sm={6}/> 
                    <Grid item xs={0} sm={1}/>    
                    <Grid item xs={6} sm={1}>                                             
                      <GreenCheckbox name="kontejnery" id="kontejnery" className={clsx(classes.textf,classes.margin)} variant="outlined" checked={state.Formular.kontejnery} onChange={(e) => {CheckBoxhandleChangeFormular(e)}}  />
                    </Grid> 
                    <Grid item xs={6} sm={4}>   
                      <Typography variant="body1">Kontejnery</Typography>                           
                    </Grid> 
                    <Grid item xs={0} sm={6}/> 
                    <Grid item xs={0} sm={1}/>    
                    <Grid item xs={6} sm={1}>                                               
                      <GreenCheckbox name="ostatni" id="ostatni" className={clsx(classes.textf,classes.margin)} variant="outlined" checked={state.Formular.ostatni} onChange={(e) => {CheckBoxhandleChangeFormular(e)}} />
                    </Grid> 
                    <Grid item xs={6} sm={4}>   
                      <Typography variant="body1">Ostatní</Typography>                           
                    </Grid> 
                    <Grid item xs={0} sm={6}/> 
                    <Grid item xs={12} sm={12}/> 


                    <Grid item xs={12} sm={12}> 
                      <Typography variant="body1" className={classes.nadpisOtayka}>2.	Odkud, kam je zboží odesíláno?</Typography>                                             
                    </Grid> 
                    <Grid item xs={0} sm={1}/>   
                    <Grid item xs={6} sm={1}>                                             
                      <GreenCheckbox id="export" name="export" className={clsx(classes.textf,classes.margin)} variant="outlined" checked={state.Formular.export} onChange={(e) => {CheckBoxhandleChangeFormular(e)}}  />
                    </Grid> 
                    <Grid item xs={6} sm={6}>   
                      <Typography variant="body1">Export (do zahraničí)</Typography>                           
                    </Grid> 
                    <Grid item xs={0} sm={4}/> 
                    <Grid item xs={0} sm={1}/>     
                    <Grid item xs={6} sm={1}>                                               
                      <GreenCheckbox name="import" id="import" className={clsx(classes.textf,classes.margin)} variant="outlined" checked={state.Formular.import} onChange={(e) => {CheckBoxhandleChangeFormular(e)}} />
                    </Grid> 
                    <Grid item xs={6} sm={4}>   
                      <Typography variant="body1">Import (ze zahraničí)</Typography>                           
                    </Grid> 
                    <Grid item xs={0} sm={6}/> 
                    <Grid item xs={0} sm={1}/>     
                    <Grid item xs={6} sm={1}>                                              
                      <GreenCheckbox name="tuzemsko" id="tuzemsko" className={clsx(classes.textf,classes.margin)} variant="outlined" checked={state.Formular.tuzemsko} onChange={(e) => {CheckBoxhandleChangeFormular(e)}}  />
                    </Grid> 
                    <Grid item xs={6} sm={6}>   
                      <Typography variant="body1">Tuzemsko (v rámci České republiky)</Typography>                           
                    </Grid> 
                    <Grid item xs={0} sm={4}/> 


                    <Grid item xs={12} sm={12}/> 
                    <Grid item xs={12} sm={12}> 
                      <Typography variant="body1" className={classes.nadpisOtayka}>3.	Jak často odesíláte zásilky a v jakém objemu?</Typography>                                             
                    </Grid> 
                    <Grid item xs={0} sm={1}/>   
                    <Grid item xs={2} sm={1}>                                              
                      <GreenCheckbox id="denne" name="denne" className={clsx(classes.textf,classes.margin)} variant="outlined" checked={state.Formular.denne} onChange={(e) => {CheckBoxhandleChangeFormular(e)}} />
                    </Grid> 
                    <Grid item xs={6} sm={3}>   
                      <Typography variant="body1">Denně</Typography>                           
                    </Grid> 
                    <Grid item xs={6} sm={7}>   
                      <TextFields name="dennePocet" className={clsx(classes.textf,classes.margin)} type="number" variant="outlined" size="small" fullWidth id="dennePocet" label="Denně" required placeholder="Denně" value={state.Formular.dennePocet} onChange={onChangeValueFormular} />
                    </Grid> 
                    <Grid item xs={0} sm={1}/>   
                    <Grid item xs={2} sm={1}>                                             
                      <GreenCheckbox id="tydne" name="tydne" className={clsx(classes.textf,classes.margin)} variant="outlined" checked={state.Formular.tydne} onChange={(e) => {CheckBoxhandleChangeFormular(e)}}  />
                    </Grid> 
                    <Grid item xs={6} sm={3}>   
                      <Typography variant="body1">Týdně</Typography>                           
                    </Grid> 
                    <Grid item xs={6} sm={7}>   
                      <TextFields name="tydnePocet" className={clsx(classes.textf,classes.margin)} type="number" variant="outlined" size="small" fullWidth id="tydnePocet" label="Týdně" required placeholder="Týdně" value={state.Formular.tydnePocet} onChange={onChangeValueFormular} />
                    </Grid> 
                    <Grid item xs={0} sm={1}/>   
                    <Grid item xs={2} sm={1}>                                                
                      <GreenCheckbox id="mesice" name="mesice" className={clsx(classes.textf,classes.margin)} variant="outlined" checked={state.Formular.mesice} onChange={(e) => {CheckBoxhandleChangeFormular(e)}} />
                    </Grid> 
                    <Grid item xs={6} sm={3}>   
                      <Typography variant="body1">Měsíčně</Typography>                           
                    </Grid> 
                    <Grid item xs={6} sm={7}>   
                      <TextFields name="mesicePocet" className={clsx(classes.textf,classes.margin)} type="number" variant="outlined" size="small" fullWidth id="mesicePocet" label="Měsíčně" required placeholder="Měsíčně" value={state.Formular.mesicePocet} onChange={onChangeValueFormular} />
                    </Grid> 




                    <Grid item xs={12} sm={12}> 
                      <Typography variant="body1" className={classes.nadpisOtayka}>4.	Jaká je průměrná hmotnost zásilek?</Typography>                                             
                    </Grid> 
                    <Grid item xs={0} sm={1}/>   
                    <Grid item xs={6} sm={5}>                                              
                      <TextFields name="hmotnost" className={clsx(classes.textf,classes.margin)} type="number" variant="outlined" size="small" fullWidth id="hmotnost" label="Hmotnost kg" required placeholder="Hmotnost kg" value={state.Formular.hmotnost} onChange={onChangeValueFormular} />
                    </Grid> 

                    <Grid item xs={12} sm={12}> 
                      <Typography variant="body1" className={classes.nadpisOtayka}>5.	Je Vaše zboží bezpečné?</Typography>                                             
                    </Grid> 
                    <Grid item xs={0} sm={1}/>  
                    <Grid item xs={6} sm={5}>                                               

                      <TextFields
                        id="bezpecne"
                        name="bezpecne"
                        required
                        select
                        label="Bezpečné zboží"
                        size="small"
                        fullWidth
                        value={state.Formular.bezpecne}
                        onChange={(e) => {onChangeValueFormular(e)}}
                        variant="outlined"
                      >
                        {bezpecne.map((option) => (
                        <MenuItem key={option.hodnota} value={option.hodnota}>
                            {option.hodnota}
                        </MenuItem>
                        ))}
                      </TextFields>                    
                    </Grid> 

                    <Grid item xs={0} sm={6}/>

                    <Grid item xs={12} sm={12}> 
                      <Typography variant="body1" className={classes.nadpisOtayka}>6.	Požadujete zásilky pojistit?</Typography>                                             
                    </Grid> 
                    <Grid item xs={0} sm={1}/>    
                    <Grid item xs={6} sm={5}>                                               

                      <TextFields
                        id="pojistit"
                        name="pojistit"
                        required
                        select
                        label="Pojištění"
                        size="small"
                        fullWidth
                        value={state.Formular.pojistit}
                        onChange={(e) => {onChangeValueFormular(e)}}
                        variant="outlined"
                      >
                        {pojisteni.map((option) => (
                        <MenuItem key={option.hodnota} value={option.hodnota}>
                            {option.hodnota}
                        </MenuItem>
                        ))}
                      </TextFields>                    
                    </Grid> 

                    <Grid item xs={0} sm={6}/> 
                    <Grid item xs={12} sm={12}>                                              
                    </Grid> 
                    <Grid item xs={12} sm={12}> 
  
                    </Grid>
                  <Grid item xs={12} sm={8}/> 
                  <Grid item xs={12} sm={4}> 
                      <GreenButton fullWidth onClick={handleClose}> Potvrdit </GreenButton>
                  </Grid>
                </Grid>
            </Container>
          </div>
        </Fade>
    </Modal>              
      </Container>  
    );
}

export default Register