import React, {useContext,useState, useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import customConfig from '../../../customConfig.json'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {UserContext} from '../../../contexts/UserContext'
import TextField from '@material-ui/core/TextField';
import { Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Container from '@material-ui/core/Container';
import Backdrop from '@material-ui/core/Backdrop';
import InputAdornment from "@material-ui/core/InputAdornment";
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import * as moment from 'moment'
import { v4 as uuidv4 } from 'uuid';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import { Checkbox } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Nahled from '../Nahled/Nahled';
import './ZalozitFakturu.css';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';



function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }


const TextFields = withStyles({
    root: {
      '& label.Mui-focused': {
        color: customConfig.Colors.PrimaryColor,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: customConfig.Colors.PrimaryColor,
      },
      '& .MuiOutlinedInput-root': {
  
        '&:hover fieldset': {
          borderColor: customConfig.Colors.SecColor,
        },
        '&.Mui-focused fieldset': {
          borderColor: customConfig.Colors.PrimaryColor,
        },
      },
    },
  })(TextField);
  

  const GreenCheckbox = withStyles({
    root: {
      color: customConfig.Colors.PrimaryColor,
      '&$checked': {
        color: customConfig.Colors.PrimaryColor,
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);


const GreenButton = withStyles((theme) => ({
    root: {
      color: customConfig.Colors.TextColor,
      backgroundColor: customConfig.Colors.PrimaryColor,
      '&:hover': {
        backgroundColor: customConfig.Colors.SecColor,
      },
    },
  }))(Button); 

const useStyles = makeStyles((theme) => ({

paperH: {
  textAlign: 'center',
  backgroundColor: customConfig.Colors.PrimaryColor,
  boxShadow: 'none',
  border:'1px solid'+customConfig.Colors.PrimaryColor,
  borderRadius: '0px'
},
nadpisH:{
  color: customConfig.Colors.ButtonTextColor,
  fontSize:'20px',
  textAlign:'left',
  paddingLeft:'20px'
},
typo: {
  color: 'Black',
  verticalAlign: 'middle',
  alignItems: 'center',

},
modal: {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
},
table: {
  minWidth: 200
},
tableRows: {
  height: '25px'
},
tableRow: {
  padding:'5px',
  backgroundColor: customConfig.Colors.GridBackgroundColor,

},
bgGrid: {
  backgroundColor: customConfig.Colors.GridBackgroundColor,
},
tableHeadRow: {
  padding:"5px",
  backgroundColor: customConfig.Colors.PrimaryColor,
  color: customConfig.Colors.ButtonTextColor
},
shad:{
  boxShadow:'0px 1px 3px #777777c4',
  border:'1px solid'+customConfig.Colors.PrimaryColor,
  paddingBottom:'20px'
},
paper: {
  backgroundColor: theme.palette.background.paper,
  border: customConfig.Colors.BorderColor,          
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
},
}));

export default function ZalozitFakturu() {

  const initialFaktury = {
    FakturyInfo:{
        
        FakturaID:'',
        KlientID:'',
        KlientNazev:'',
        KlientAdresa:'',
        KlientMesto:'',
        KlientIC:'',
        KlientDIC:'',
        ZpusobPlabtbyID:'1',
        ZpusobPlabtbyNazev:'Převodem',
        CenaCelkem:'',
        CisloFakturyNadpis:moment().format("YYYY")+'-XXXXXX',
        KodBanky:'',
        VS:moment().format("YYYY"),
        CisloUctu:'',
        PredcisliUctu:'',
        StavID:'1',
        DatumVystaveni:moment().format("YYYY-MM-DD"),
        DatumSplatnosti:moment().add(1, 'month').format("YYYY-MM-DD"),
        DatumZdanObdobi:'',
        DodavatelNazev:'',
        DodavatelAdresa:'',
        DodavatelMesto:'',
        DodavatelIC:'',
        DodavatelDIC:'',
        PlatceDPH:'0'

    },    
    polozky:[],
    zasilky:[],
    souhrnCena:[],
    tisk:false
  };  
  
  

  
  const initialPolozka = { 
    polozky:{ id: uuidv4(), Nazev: '', Cena: '', DPH: true ,SazbaDPH:''},
  };  
  const initialZasilka = { 
    zasilky:{ id: uuidv4(), ZasilkaID:'', HmotnostDruh: '', Hmotnost: '', Nazev: '', Cena: '', DPH: false ,SazbaDPH:'',NazevZobrazit:'',ReferencniCislo:null,Track:null},
  };  

  const classes = useStyles();
  
  const {getUser,getFirmy,getFakturaZasilky,getFakturaInfo,postZalozitFakturu} = useContext(UserContext);
  const [faktury, setState] = useState(initialFaktury);
  const [polozkyX, setPolozky] = useState(initialPolozka);
  const [zasilkyX, setZasilky] = useState(initialZasilka);
  const [zasilkyXa, setZasilkya] = useState([]);
  
  const [Frirmy, setFrirmy] = useState([])  
  const [KleintZasilky, setKleintZasilky] = useState([])  
  const [User, setUser] = useState([])  
  const [openPolozky, setopenPolozky] = React.useState(false);
  const [openZasilky, setopenZasilky] = React.useState(false);
  const [openNahled, setopenNahled] = React.useState(false);
  const [openPolozkyAdd, setopenPolozkyAdd] = React.useState(false);
  const [openZasilkyAdd, setopenZasilkyAdd] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [textMSG, settextMSG] = useState('')  
  const [severityMSG, setseverityMSG] = useState('')  


  
  const handleRemoveFieldsPolozky = id => {
    const values  = [...faktury.polozky];
    values.splice(values.findIndex(value => value.id === id), 1);
    setState({        
        ...faktury,
        polozky:values
    });
  };
  const handleRemoveFieldsZasilky = id => {
    const values  = [...faktury.zasilky];
    values.splice(values.findIndex(value => value.id === id), 1);
    setState({        
        ...faktury,
        zasilky:values
    });
  };


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };


  const handleAddFieldsPolozky = (e) => {

    setState({
        ...faktury,
        polozky:[...faktury.polozky,polozkyX.polozky]
    });
    handleClosePolozkyAdd(e);
  };
  const handleAddFieldsZasilky = (e) => {
    if(zasilkyX.zasilky.ZasilkaID.length>0)
    {
        setState({
            ...faktury,
            zasilky:[...faktury.zasilky,zasilkyX.zasilky]
        });
        handleCloseZasilkyAdd(e);
    }
    else
    {
        errorMSG('Není vybraná zásilka');
    }
  };
  const handleEditFieldsPolozky = (e) => {
    const editrows = faktury.polozky.map(item =>
        item.id === polozkyX.polozky.id ? polozkyX.polozky : item
      );
    setState({
        ...faktury,
        polozky:editrows
    });
    
    handleClosePolozky(e);
  };

  const handleEditFieldsZasilky = (e) => {
    const editrows = faktury.zasilky.map(item =>
        item.id === zasilkyX.zasilky.id ? zasilkyX.zasilky : item
      );
    setState({
        ...faktury,
        zasilky:editrows
    });
    
    handleCloseZasilky(e);
  };



const handleChangeInputPolozky = (e) => {
    
    setPolozky({
        ...polozkyX,
        polozky:{
            ...polozkyX.polozky,
            [e.target.name]:e.target.checked,
            SazbaDPH: e.target.checked === false? '':polozkyX.polozky.SazbaDPH
        },
    });
}

    

const handleChangeInputZasilky = (e) => {
        setZasilky({
            ...zasilkyX,
            zasilky:{
                ...zasilkyX.zasilky,
                [e.target.name]:e.target.checked,
                SazbaDPH: e.target.checked === false? '':zasilkyX.zasilky.SazbaDPH
            },
        });
}

const onChangeValueZasilky = (e) => {
    setZasilky({
        ...zasilkyX,
        zasilky:{
            ...zasilkyX.zasilky,
            [e.target.name]:e.target.value
        },
    });
}
const onChangeValuePolozky = (e) => {
    setPolozky({
        ...polozkyX,
        polozky:{
            ...polozkyX.polozky,
            [e.target.name]:e.target.value
        },
    });
}
  const zalozit = async (e) => {
      
    setOpen(false);
    settextMSG('')


    if(faktury.FakturyInfo.KlientID === '')
    {
        warningMSG('Není vybraný klient.');
    }
    else if(faktury.polozky.length === 0 && faktury.zasilky.length === 0)
    {
        warningMSG('Není vybraná žádná položka nebo zásilka k fakturaci.');
    }
    else
    {
        const data = await postZalozitFakturu(faktury)

        
        if(data.success === 1 )
        {
            succesMSG('Faktura založena. Číslo faktury: '+data.SEBAplusCislo[0].CisloFaktury);
            setState(initialFaktury);
        }
        else
        {
            errorMSG('Chyba při založení faktury')
        }
    }

}

const errorMSG = (MSGtext) => {
    settextMSG(MSGtext)
    setseverityMSG('error')
    setOpen(true);
}
const succesMSG = (MSGtext) => {
    settextMSG(MSGtext)
    setseverityMSG('success')
    setOpen(true);
}

const warningMSG = (MSGtext) => {
    settextMSG(MSGtext)
    setseverityMSG('warning')
    setOpen(true);
}

const onChangeValue = (e) => {
    setState({
        ...faktury,
        FakturyInfo:{
            ...faktury.FakturyInfo,
            [e.target.name]:e.target.value
        },
    });
}  
useEffect(()  => { 
    
    document.title = "SEBAplus - Nová faktura";

    async function fetchData() {

        const dataFirmy = await getFirmy();          
        const dataDodavatel = await getUser("2");    
        const data = await getFakturaInfo();  

        setFrirmy(dataFirmy);


        
        if (dataDodavatel && data){
            
            setState({
                ...faktury,
                FakturyInfo:{
                    ...faktury.FakturyInfo,
                    DodavatelNazev:dataDodavatel.User[0].Firma,
                    DodavatelIC:dataDodavatel.User[0].IC,
                    DodavatelDIC:dataDodavatel.User[0].DIC,
                    DodavatelAdresa:dataDodavatel.Adresa[0].Adresa,
                    DodavatelMesto:dataDodavatel.Adresa[0].PSC+" "+dataDodavatel.Adresa[0].Mesto,
                    PredcisliUctu:data[0].PredcisliUctu,
                    KodBanky:data[0].KodBanky,
                    CisloUctu:data[0].CisloUctu,
                    PlatceDPH:data[0].PlatceDPH,
                },
            });            
        }

    }

    fetchData();


      // eslint-disable-next-line react-hooks/exhaustive-deps
}, [])     

useEffect(()  => { 

    setZasilkya([]);
    
    async function fetchDataKlient() {

        const dataKlient = await getUser(faktury.FakturyInfo.KlientID);    
        const data = await getFakturaZasilky(faktury.FakturyInfo.KlientID)

        setKleintZasilky(data) 
        setUser(dataKlient)
        
        const rows = data.map(newValue =>({        
                id:uuidv4(),
                Cena:newValue.Cena,
                Nazev:newValue.Nazev,
                Cislo:newValue.Cislo,
                NazevZobrazit:newValue.Nazev+' ('+newValue.Cislo+') '+newValue.StatVyzvednut+'-'+newValue.StatDoruceni,
                HmotnostDruh:newValue.DruhHmotnosti,
                Hmotnost:newValue.Hmotnost,
                ZasilkaID:newValue.ZasilkaID,
                ReferencniCislo:newValue.ReferencniCislo,
                Track:newValue.Track,
                DPH:newValue.DPH === "1" ?true:false,
                SazbaDPH:newValue.SazbaDPH
        }));
       
        setZasilkya(rows)

    }

    if(faktury.FakturyInfo.KlientID.length>0){
        fetchDataKlient()
    }

    setState({
        ...faktury,
        zasilky:[],
        polozky:[]
    }
    );

      // eslint-disable-next-line react-hooks/exhaustive-deps
}, [faktury.FakturyInfo.KlientID])   

useEffect(()  => { 
    
    if(User.success){

        setState({
            ...faktury,
            FakturyInfo:{
                ...faktury.FakturyInfo,
                KlientNazev:User.User[0].Firma,
                KlientIC:User.User[0].IC,
                KlientDIC:User.User[0].DIC,
                KlientAdresa:User.Adresa[0].Adresa,
                KlientMesto:User.Adresa[0].PSC+" "+User.Adresa[0].Mesto
            },
        });



    }

      // eslint-disable-next-line react-hooks/exhaustive-deps
}, [User])   



const prepocitatcenu = () => {      
    let CelkemCena = 0;
    let souhrn = [];
    
    if(faktury.polozky.length>0)
    {
        faktury.polozky.map(row => {
            let cenaPolozka = 0;

                if(row.DPH===true)
                {
                    cenaPolozka = (Number(row.Cena)*(1+(Number(row.SazbaDPH)/100))).toFixed(2);
                }
                else{

                    cenaPolozka = Number(row.Cena);
                }
                
                CelkemCena = Number(CelkemCena)+Number(cenaPolozka);        
            }
        );
    }

    if(faktury.zasilky.length>0)
    {
        faktury.zasilky.map(row => {
            let cenaPolozka = 0;

                if(row.DPH===true)
                {
                    cenaPolozka = (Number(row.Cena)*(1+(Number(row.SazbaDPH)/100))).toFixed(2);
                }
                else{
                    cenaPolozka = Number(row.Cena);
                }
                CelkemCena = (Number(CelkemCena)+Number(cenaPolozka)).toFixed(2);           
            }
        );
    }

    if(faktury.polozky.length>0)
    {
        faktury.polozky.map(row =>
        {
            if(row.DPH===true && Number(row.SazbaDPH)?Number(row.SazbaDPH):0>0)
            {
                if(souhrn.length>0)
                {       
                    

                    var index = souhrn.findIndex(x => x.SazbaDPH===row.SazbaDPH);                     
                    if(index === -1)
                    {
                        souhrn.push({SazbaDPH:row.SazbaDPH, Popis:row.SazbaDPH+' %',DPHCena: (Number(row.Cena)*(Number(row.SazbaDPH)/100)).toFixed(2),Cena:row.Cena })                    

                    } 
                    else
                    {
                        const editrows = souhrn.map(item =>
                            item.SazbaDPH === row.SazbaDPH ? 
                                {SazbaDPH:item.SazbaDPH, Popis:item.SazbaDPH+' %',DPHCena: (Number(item.DPHCena) + (Number(row.Cena)*(Number(row.SazbaDPH)/100))).toFixed(2),Cena:(Number(item.Cena) + Number(row.Cena)) } 
                                : item
                        )
                        souhrn = editrows;    

                    }                            
                }
                else
                {          
                    souhrn.push({SazbaDPH:row.SazbaDPH, Popis:row.SazbaDPH+' %',DPHCena: (Number(row.Cena)*(Number(row.SazbaDPH)/100)).toFixed(2),Cena:row.Cena }) 
                }
            }
            else
            {
                if(souhrn.length>0)
                {  
                    var index2 = souhrn.findIndex(x => x.SazbaDPH==='0');                     
                    if(index2 === -1)
                    {                        
                        souhrn.push({SazbaDPH:'0', Popis:'Osvobozeno od DPH',DPHCena: '',Cena:(Number(row.Cena))})                    

                    } 
                    else
                    {
                        const editrows = souhrn.map(item =>
                            item.SazbaDPH === '0' ? 
                                {SazbaDPH:'0', Popis:'Osvobozeno od DPH',DPHCena: '',Cena:(Number(item.Cena) + Number(row.Cena)) } 
                                : item
                        )
                        souhrn = editrows;    

                    }                         
                }
                else
                {          
                    souhrn.push({SazbaDPH:'0', Popis:'Osvobozeno od DPH',DPHCena: '',Cena:(Number(row.Cena)) }) 
                }
            }

        });
    }

    if(faktury.zasilky.length>0)
    {
        faktury.zasilky.map(row =>
        {
            if(row.DPH===true && Number(row.SazbaDPH)?Number(row.SazbaDPH):0>0)
            {
                if(souhrn.length>0)
                {       
                    

                    var index = souhrn.findIndex(x => x.SazbaDPH===row.SazbaDPH);                     
                    if(index === -1)
                    {
                        souhrn.push({SazbaDPH:row.SazbaDPH, Popis:row.SazbaDPH+' %',DPHCena: (Number(row.Cena)*(Number(row.SazbaDPH)/100)).toFixed(2),Cena:row.Cena })                    

                    } 
                    else
                    {
                        const editrows = souhrn.map(item =>
                            item.SazbaDPH === row.SazbaDPH ? 
                                {SazbaDPH:item.SazbaDPH, Popis:item.SazbaDPH+' %',DPHCena: (Number(item.DPHCena) + (Number(row.Cena)*(Number(row.SazbaDPH)/100))).toFixed(2),Cena:(Number(item.Cena) + Number(row.Cena)) } 
                                : item
                        )
                        souhrn = editrows;    

                    }                            
                }
                else
                {          
                    souhrn.push({SazbaDPH:row.SazbaDPH, Popis:row.SazbaDPH+' %',DPHCena: (Number(row.Cena)*(Number(row.SazbaDPH)/100)).toFixed(2),Cena:row.Cena }) 
                }
            }
            else
            {
                if(souhrn.length>0)
                {  
                    var index2 = souhrn.findIndex(x => x.SazbaDPH==='0');                     
                    if(index2 === -1)
                    {                        
                        souhrn.push({SazbaDPH:'0', Popis:'Osvobozeno od DPH',DPHCena: '',Cena:(Number(row.Cena))})                    

                    } 
                    else
                    {
                        const editrows = souhrn.map(item =>
                            item.SazbaDPH === '0' ? 
                                {SazbaDPH:'0', Popis:'Osvobozeno od DPH',DPHCena: '',Cena:(Number(item.Cena) + Number(row.Cena)) } 
                                : item
                        )
                        souhrn = editrows;    

                    }                         
                }
                else
                {          
                    souhrn.push({SazbaDPH:'0', Popis:'Osvobozeno od DPH',DPHCena: '',Cena:(Number(row.Cena)) }) 
                }
            }

        });
    }

//""


    setState({
        ...faktury,
        FakturyInfo:{
            ...faktury.FakturyInfo,
            CenaCelkem:CelkemCena
        },
        souhrnCena:souhrn,
    });
    
};



const  PridatVsechnyPolozky = () => {      
    

    setState({
        ...faktury,
        zasilky:zasilkyXa
    }
    );

};

const handleOpenZasilkyAdd = () => {      
    setopenZasilkyAdd(!openZasilkyAdd);    
};
const handleCloseZasilkyAdd = (e) => {
    setopenZasilkyAdd(!openZasilkyAdd);
    setZasilky(initialZasilka);
};
const handleCloseNahled = (e) => {
    setopenNahled(!openNahled);
};


const handleOpenNahled = () => {  
    
    settextMSG('')


    if(faktury.FakturyInfo.KlientID === '')
    {
        warningMSG('Není vybraný klient.');
    }
    else if(faktury.polozky.length === 0 && faktury.zasilky.length === 0)
    {
        warningMSG('Není vybraná žádná položka nebo zásilka k fakturaci.');
    }
    else
    {
        prepocitatcenu();
        setopenNahled(!openNahled);    
    }
};
const handleOpenPolozkyAdd = () => {      
    setopenPolozkyAdd(!openPolozkyAdd);    
};
const handleClosePolozkyAdd = (e) => {
    setopenPolozkyAdd(!openPolozkyAdd);
    setPolozky(initialPolozka);
};
  
const handleOpenZasilky = (row) => {      
    setopenZasilky(!openZasilky);   
    setZasilky({
        ...zasilkyX,
        zasilky:row
    }); 
};

const handleCloseZasilky = (e) => {
    setopenZasilky(!openZasilky);
    setZasilky(initialZasilka);
};

const handleOpenPolozky = (row) => {      
    setopenPolozky(!openPolozky);       
    setPolozky({
        ...polozkyX,
        polozky:row
    });
};

const handleClosePolozky = (e) => {
    setopenPolozky(!openPolozky);
    setPolozky(initialPolozka);
};


  return (
    <Container component="main" maxWidth="lg" >
        <Grid direction="row"  justify="center"  alignItems="center" container spacing={2} className={classes.bgGrid}>    
            <Grid item xs={12} sm={12}>                
                <Paper className={classes.shad}>
                    <Grid direction="row"  justify="center"  alignItems="center" container spacing={2}>  

                        <Grid item xs={12} sm={12}> 
                            <Paper className={classes.paperH}>
                                <Typography className={classes.nadpisH}>Základní údaje</Typography>
                            </Paper>
                        </Grid>

                        <Grid spacing={2} item direction="row"  justify="center"  alignItems="center" container xs={12} sm={12}> 
                            <Grid item xs={12} sm={6}>                                              
                                <Autocomplete
                                id="Firmax"
                                name="Firmax"
                                options={Frirmy}
                                fullWidth
                                label="Odběratel"
                                getOptionLabel={(option) => option.Firma ? option.Firma +' ('+option.IC+')' : ''}                
                                getOptionSelected={(option) => option.UserID === faktury.FakturyInfo.KlientID}         
                                onChange={(event, newValue) => {           
                                    
                                    if (newValue) {                       
                                        setState({
                                        ...faktury,
                                        FakturyInfo:{
                                            ...faktury.FakturyInfo,
                                            KlientID:newValue.UserID,
                                        },
                                        });
                                    }
                                    else
                                    {       
                                        setState({
                                        ...faktury,
                                        FakturyInfo:{
                                            ...faktury.FakturyInfo,
                                            KlientID:'',
                                        },
                                        });

                                    }
                                }}                                      
                                    renderOption={(option) => (
                                    <React.Fragment>
                                        <span className={classes.left}>{option.Firma +' ('+option.IC+')'}</span>
                                    </React.Fragment>
                                    )}
                                    renderInput={(params) => <TextField {...params} className={classes.tf} fullWidth label="Odběratel" size="small" variant="outlined" />}
                                />    
                                
                            </Grid>
                            <Grid item xs={12} sm={6}>                                              
                                
                                
                                <TextFields
                                    required
                                    id="DatumZdanObdobi"
                                    key="DatumZdanObdobi"
                                    name="DatumZdanObdobi"
                                    label="Zdanitelné plnění"
                                    type="date"
                                    size="small"
                                    inputProps={{
                                        min: moment().format("YYYY-MM-DD")
                                    }}
                                    fullWidth
                                    onChange={(e) => onChangeValue(e)}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    value={faktury.FakturyInfo.DatumZdanObdobi}
                                />       

                            </Grid>      
                            <Grid item xs={12} sm={6}>                                              
                                
                                
                                <TextFields
                                    required
                                    id="DatumVystaveni"
                                    key="DatumVystaveni"
                                    name="DatumVystaveni"
                                    label="Datum vystavení"
                                    type="date"
                                    size="small"
                                    fullWidth
                                    onChange={(e) => onChangeValue(e)}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    value={faktury.FakturyInfo.DatumVystaveni}
                                />       

                            </Grid>
                            <Grid item xs={12} sm={6}>                                              
                                
                                
                                <TextFields
                                    required
                                    id="DatumSplatnosti"
                                    key="DatumSplatnosti"
                                    name="DatumSplatnosti"
                                    label="Datum splatnosti"
                                    type="date"
                                    size="small"
                                    inputProps={{
                                        min: moment().format("YYYY-MM-DD")
                                    }}
                                    fullWidth
                                    onChange={(e) => onChangeValue(e)}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    value={faktury.FakturyInfo.DatumSplatnosti}
                                />       

                            </Grid>
                        </Grid>

                    </Grid>
                </Paper>
            </Grid>

            <Grid item xs={12} sm={12}> 
            
                <Paper className={classes.shad}>
                    <Grid container justify="center" alignItems="flex-start"  spacing={2}>   
                        <Grid  item xs={12} sm={12}> 
                            <Paper className={classes.paperH}>
                                <Typography className={classes.nadpisH}>Zásilky</Typography>
                            </Paper>
                        </Grid>

                        <Grid container justify="center" alignItems="flex-start"  spacing={2} item xs={12} sm={12}> 
                            <Grid item xs={12} sm={2}>                                              
                                <GreenButton fullWidth onClick={(e) => handleOpenZasilkyAdd()} startIcon={<AddIcon />} >Přidat</GreenButton>
                            </Grid>
                            <Grid item xs={12} sm={8}/>       

                            <Grid item xs={12} sm={2}>                                              
                                <GreenButton fullWidth onClick={(e) => PridatVsechnyPolozky()} disabled={faktury.FakturyInfo.KlientID!=''?false:true} startIcon={<AddIcon />} >Přidat vše</GreenButton>
                            </Grid>   

                            <Grid item xs={12} sm={12}>                                              
                            <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.tableHeadRow} width="250px" align="left">Popis</TableCell>
                                            <TableCell className={classes.tableHeadRow} width="150px" align="left">Cena</TableCell>
                                            <TableCell className={classes.tableHeadRow} width="50px"  align="left">DPH</TableCell>
                                            <TableCell className={classes.tableHeadRow} width="100px" align="left">Sazba DPH</TableCell>
                                            <TableCell className={classes.tableHeadRow} width="100px" align="left">Hmotnost</TableCell>
                                            <TableCell className={classes.tableHeadRow} width="150px" align="left">Druh hmotnosti</TableCell>
                                            <TableCell className={classes.tableHeadRow} width="50px"></TableCell>
                                            <TableCell className={classes.tableHeadRow} width="50px"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(faktury.zasilky.length!==0) && ( 
                                        <>
                                        {faktury.zasilky.map(row => {
                                        return (
                                            <TableRow key={row.id}  className={classes.tableRows}>
                                                <TableCell className={classes.tableRow} >{row.NazevZobrazit}</TableCell>        
                                                <TableCell className={classes.tableRow} >{row.Cena+' Kč'}</TableCell>
                                                <TableCell className={classes.tableRow} ><GreenCheckbox disabled checked={row.DPH}  /></TableCell>    
                                                <TableCell className={classes.tableRow} >{row.SazbaDPH+' %'}</TableCell>     
                                                <TableCell className={classes.tableRow} >{row.Hmotnost+' kg'}</TableCell>
                                                <TableCell className={classes.tableRow} >{row.HmotnostDruh}</TableCell>   
                                                <TableCell className={classes.tableRow} ><GreenButton onClick={(e) => handleOpenZasilky(row)}><EditIcon/></GreenButton></TableCell>    
                                                <TableCell className={classes.tableRow} ><GreenButton onClick={(e) => handleRemoveFieldsZasilky(row.id)}><DeleteIcon/></GreenButton></TableCell>                     
                                            </TableRow>
                                        );
                                        })}
                                        </>
                                        )}
                                    </TableBody>
                                </Table>  
                            </Grid> 
                        </Grid> 
                    </Grid> 
                </Paper>
            </Grid>
            

            <Grid item xs={12} sm={12}>             
                <Paper className={classes.shad}>                    
                    <Grid container justify="center" alignItems="flex-start"  spacing={2}> 
                        <Grid item xs={12} sm={12}> 
                            <Paper className={classes.paperH}>
                                <Typography className={classes.nadpisH}>Další položky</Typography>
                            </Paper>
                        </Grid>

                        <Grid container justify="center" alignItems="flex-start"  spacing={2} item xs={12} sm={12}> 
                            <Grid item xs={12} sm={2}>                                              
                                <GreenButton fullWidth onClick={(e) => handleOpenPolozkyAdd()} startIcon={<AddIcon />} >Přidat</GreenButton>
                            </Grid>
                            <Grid item xs={12} sm={10}/>    
                            

                            <Grid item xs={12} sm={12}>              


                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.tableHeadRow} width="250px" align="left">Popis</TableCell>
                                            <TableCell className={classes.tableHeadRow} width="100px" align="left">Cena</TableCell>
                                            <TableCell className={classes.tableHeadRow} width="50px"  align="left">DPH</TableCell>
                                            <TableCell className={classes.tableHeadRow} width="100px" align="left">Sazba DPH</TableCell>
                                            <TableCell className={classes.tableHeadRow} width="50px"></TableCell>
                                            <TableCell className={classes.tableHeadRow} width="50px"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(faktury.polozky.length!==0) && ( 
                                        <>
                                        {faktury.polozky.map(row => {
                                        return (
                                            <TableRow key={row.id}  className={classes.tableRows}>
                                                <TableCell className={classes.tableRow} >{row.Nazev}</TableCell>        
                                                <TableCell className={classes.tableRow} >{row.Cena+' Kč'}</TableCell>
                                                <TableCell className={classes.tableRow} ><GreenCheckbox disabled checked={row.DPH}  /></TableCell>    
                                                <TableCell className={classes.tableRow} >{row.SazbaDPH+' %'}</TableCell>     
                                                <TableCell className={classes.tableRow} ><GreenButton onClick={(e) => handleOpenPolozky(row)}><EditIcon/></GreenButton></TableCell>    
                                                <TableCell className={classes.tableRow} ><GreenButton onClick={(e) => handleRemoveFieldsPolozky(row.id)}><DeleteIcon/></GreenButton></TableCell>                     
                                            </TableRow>
                                        );
                                        })}
                                        </>
                                        )}
                                    </TableBody>
                                </Table>                                              
                            
                            </Grid>  
                        </Grid>
                    </Grid> 
                </Paper>
            </Grid>  
        </Grid>

       
        <Grid container spacing={2} className={classes.bgGrid}>      
            <Grid item xs={12} sm={2}>                                              
                <GreenButton fullWidth onClick={(e) => handleOpenNahled(e)} startIcon={<VisibilityIcon />} >Náhled</GreenButton>
            </Grid>   

            <Grid item xs={12} sm={8}/>     
            
            <Grid item xs={12} sm={2}>                                              
                <GreenButton fullWidth onClick={(e) => zalozit(e)} startIcon={<SaveIcon />} >Založit</GreenButton>
            </Grid>
        </Grid>
    

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openPolozky}
            key="edit"
            onClose={(e) => handleClosePolozky(e)}
            closeAfterTransition
            BackdropComponent={Backdrop}
        >
            <Fade in={openPolozky}>
                <div className={classes.paper}>
                    <Container maxWidth="sm">
                        <Grid container spacing={2}>    
                            <Grid item xs={12} sm={12}> 
                                <TextFields
                                    required
                                    id="Nazev"
                                    name="Nazev"
                                    label="Popis"
                                    type="text"
                                    value={polozkyX.polozky.Nazev || ''}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) => {onChangeValuePolozky(e)}}
                                />

                            </Grid>

                            <Grid item xs={12} sm={12}> 
                                <TextFields
                                    required
                                    id="Cena"
                                    name="Cena"
                                    label="Cena"
                                    type="number"
                                    InputProps={{ endAdornment: <InputAdornment position="end">Kč</InputAdornment> }}
                                    value={polozkyX.polozky.Cena}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) => {onChangeValuePolozky(e)}}
                                />

                            </Grid>
                            <Grid item xs={12} sm={12}> 
                                <Typography className={classes.typo}>
                                    <GreenCheckbox
                                        checked={polozkyX.polozky.DPH}
                                        onChange={(e) => {handleChangeInputPolozky(e); }}
                                        id="DPH"
                                        key="DPH"
                                        name="DPH"
                                        color="primary"
                                    />
                                    DPH  
                                                
                                </Typography>

                            </Grid>
                            <Grid item xs={12} sm={12}> 
                                <TextFields
                                    required
                                    id="SazbaDPH"
                                    name="SazbaDPH"
                                    style={{ visibility: polozkyX.polozky.DPH === false ? 'hidden': 'visible'}}
                                    label="Sazba DPH"
                                    type="number"
                                    InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                                    value={polozkyX.polozky.SazbaDPH}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) => {onChangeValuePolozky(e)}}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}> 
                                <GreenButton fullWidth  > Zrušit </GreenButton>
                            </Grid>
                            <Grid item xs={12} sm={4}/> 
                            <Grid item xs={12} sm={4}> 
                                <GreenButton fullWidth onClick={(e) => handleEditFieldsPolozky(e)} startIcon={<SaveIcon />} >Uložit</GreenButton>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </Fade>
        </Modal>  
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openPolozkyAdd}
            key="addPolozky"
            onClose={(e) => handleClosePolozkyAdd(e)}
            closeAfterTransition
            BackdropComponent={Backdrop}
        >
            <Fade in={openPolozkyAdd}>
                <div className={classes.paper}>
                    <Container maxWidth="sm">
                        <Grid container spacing={2}>    
                            <Grid item xs={12} sm={12}> 
                                <TextFields
                                    required
                                    id="Nazev"
                                    name="Nazev"
                                    label="Popis"
                                    type="text"
                                    value={polozkyX.polozky.Nazev || ''}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) => {onChangeValuePolozky(e)}}
                                />

                            </Grid>

                            <Grid item xs={12} sm={12}> 
                                <TextFields
                                    required
                                    id="Cena"
                                    name="Cena"
                                    label="Cena"
                                    type="number"
                                    InputProps={{ endAdornment: <InputAdornment position="end">Kč</InputAdornment> }}
                                    value={polozkyX.polozky.Cena}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) => {onChangeValuePolozky(e)}}
                                />

                            </Grid>
                            <Grid item xs={12} sm={12}> 
                                <Typography className={classes.typo}>
                                    <GreenCheckbox
                                        checked={polozkyX.polozky.DPH}
                                        onChange={(e) => {handleChangeInputPolozky(e); }}
                                        id="DPH"
                                        key="DPH"
                                        name="DPH"
                                        color="primary"
                                    />
                                    DPH  
                                                
                                </Typography>

                            </Grid>
                            <Grid item xs={12} sm={12}> 
                                <TextFields
                                    required
                                    id="SazbaDPH"
                                    name="SazbaDPH"
                                    style={{ visibility: polozkyX.polozky.DPH === false ? 'hidden': 'visible'}}
                                    label="Sazba DPH"
                                    type="number"
                                    InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                                    value={polozkyX.polozky.SazbaDPH}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) => {onChangeValuePolozky(e)}}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}> 
                                <GreenButton fullWidth onClick={(e) => handleClosePolozkyAdd(e)} > Zrušit </GreenButton>
                            </Grid>
                            <Grid item xs={12} sm={4}/> 
                            <Grid item xs={12} sm={4}> 
                                <GreenButton fullWidth onClick={(e) => handleAddFieldsPolozky(e)} startIcon={<AddIcon />} >Přidat</GreenButton>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </Fade>
        </Modal>  



        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openZasilkyAdd}
            key="addZasilky"
            onClose={(e) => handleCloseZasilkyAdd(e)}
            closeAfterTransition
            BackdropComponent={Backdrop}
        >
            <Fade in={openZasilkyAdd}>
                <div className={classes.paper}>
                    <Container maxWidth="sm">
                        <Grid container spacing={2}>    
                            <Grid item xs={12} sm={12}> 
                            
                            
                            <Autocomplete
                                id="Zasilka"
                                name="Zasilka"
                                options={KleintZasilky}
                                fullWidth
                                label="Zásilka"
                                getOptionLabel={(option) => option.Nazev ? option.Nazev +' ('+option.Cislo+') '+option.StatVyzvednut+'-'+option.StatDoruceni : ''}              
                                onChange={(event, newValue) => {                               
                                    setZasilky({
                                        ...zasilkyX,
                                        zasilky:{
                                            ...zasilkyX.zasilky,
                                            Cena:newValue.Cena,
                                            Nazev:newValue.Nazev,
                                            Cislo:newValue.Cislo,
                                            NazevZobrazit:newValue.Nazev+' ('+newValue.Cislo+') '+newValue.StatVyzvednut+'-'+newValue.StatDoruceni,
                                            HmotnostDruh:newValue.DruhHmotnosti,
                                            Hmotnost:newValue.Hmotnost,
                                            ZasilkaID:newValue.ZasilkaID,
                                            ReferencniCislo:newValue.ReferencniCislo,
                                            Track:newValue.Track,
                                            DPH:newValue.DPH == "1" ?true:false,
                                            SazbaDPH:newValue.SazbaDPH,
                                        },
                                    });
                                }}                                      
                                    renderOption={(option) => (
                                    <React.Fragment>
                                        <span className={classes.left}>{option.Nazev +' ('+option.Cislo+') '+option.StatVyzvednut+'-'+option.StatDoruceni}</span>
                                    </React.Fragment>
                                    )}
                                    renderInput={(params) => <TextField {...params} className={classes.tf} fullWidth label="Zásilka" size="small" variant="outlined" />}
                                />

                            </Grid>

                            <Grid item xs={12} sm={12}> 
                                <TextFields
                                    required
                                    id="Cena"
                                    name="Cena"
                                    label="Cena"
                                    type="number"
                                    disabled
                                    InputProps={{ endAdornment: <InputAdornment position="end">Kč</InputAdornment> }}
                                    value={zasilkyX.zasilky.Cena}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) => {onChangeValueZasilky(e)}}
                                />

                            </Grid>
                            <Grid item xs={12} sm={12}> 
                                <Typography className={classes.typo}>
                                    <GreenCheckbox
                                        checked={zasilkyX.zasilky.DPH}
                                        onChange={(e) => {handleChangeInputZasilky(e); }}
                                        id="DPH"
                                        key="DPH"
                                        name="DPH"
                                        color="primary"
                                    />
                                    DPH  
                                                
                                </Typography>

                            </Grid>
                            <Grid item xs={12} sm={12}> 
                                <TextFields
                                    required
                                    id="SazbaDPH"
                                    name="SazbaDPH"
                                    style={{ visibility: zasilkyX.zasilky.DPH === false ? 'hidden': 'visible'}}
                                    label="Sazba DPH"
                                    type="number"
                                    InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                                    value={zasilkyX.zasilky.SazbaDPH}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) => {onChangeValueZasilky(e)}}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}> 
                                <GreenButton fullWidth onClick={(e) => handleCloseZasilkyAdd(e)} > Zrušit </GreenButton>
                            </Grid>
                            <Grid item xs={12} sm={4}/> 
                            <Grid item xs={12} sm={4}> 
                                <GreenButton fullWidth onClick={(e) => handleAddFieldsZasilky(e)} startIcon={<AddIcon />} >Přidat</GreenButton>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </Fade>
        </Modal>  


        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openZasilky}
            key="Zasilky"
            onClose={(e) => handleCloseZasilky(e)}
            closeAfterTransition
            BackdropComponent={Backdrop}
        >
            <Fade in={openZasilky}>
                <div className={classes.paper}>
                    <Container maxWidth="sm">
                        <Grid container spacing={2}>    
                            <Grid item xs={12} sm={12}> 
                            
                            
                                <Autocomplete
                                    id="Zasilka"
                                    name="Zasilka"
                                    options={KleintZasilky}
                                    fullWidth
                                    label="Zásilka"
                                    getOptionLabel={(option) => option.Nazev ? option.Nazev +' ('+option.Cislo+') '+option.StatVyzvednut+'-'+option.StatDoruceni : ''}                
                                    getOptionSelected={(option, value) => option.ZasilkaID === value.ZasilkaID}                                           
                                    value={zasilkyX.zasilky? zasilkyX.zasilky:null}  
                                    onChange={(event, newValue) => {                               
                                        setZasilky({
                                            ...zasilkyX,
                                            zasilky:{
                                                ...zasilkyX.zasilky,
                                                Cena:newValue.Cena,
                                                Nazev:newValue.Nazev,
                                                Cislo:newValue.Cislo,
                                                NazevZobrazit:newValue.Nazev+' ('+newValue.Cislo+') '+newValue.StatVyzvednut+'-'+newValue.StatDoruceni,
                                                HmotnostDruh:newValue.DruhHmotnosti,
                                                Hmotnost:newValue.Hmotnost,
                                                ZasilkaID:newValue.ZasilkaID,
                                            },
                                        });
                                    }}                                      
                                        renderOption={(option) => (
                                        <React.Fragment>
                                            <span className={classes.left}>{option.Nazev +' ('+option.Cislo+') '+option.StatVyzvednut+'-'+option.StatDoruceni}</span>
                                        </React.Fragment>
                                        )}
                                        renderInput={(params) => <TextField {...params} className={classes.tf} fullWidth label="Zásilka" size="small" variant="outlined" />}
                                />

                            </Grid>

                            <Grid item xs={12} sm={12}> 
                                <TextFields
                                    required
                                    id="Cena"
                                    name="Cena"
                                    label="Cena"
                                    type="number"
                                    disabled
                                    InputProps={{ endAdornment: <InputAdornment position="end">Kč</InputAdornment> }}
                                    value={zasilkyX.zasilky.Cena}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) => {onChangeValueZasilky(e)}}
                                />

                            </Grid>
                            <Grid item xs={12} sm={12}> 
                                <Typography className={classes.typo}>
                                    <GreenCheckbox
                                        checked={zasilkyX.zasilky.DPH}
                                        onChange={(e) => {handleChangeInputZasilky(e); }}
                                        id="DPH"
                                        key="DPH"
                                        name="DPH"
                                        color="primary"
                                    />
                                    DPH  
                                                
                                </Typography>

                            </Grid>
                            <Grid item xs={12} sm={12}> 
                                <TextFields
                                    required
                                    id="SazbaDPH"
                                    name="SazbaDPH"
                                    style={{ visibility: zasilkyX.zasilky.DPH === false ? 'hidden': 'visible'}}
                                    label="Sazba DPH"
                                    type="number"
                                    InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                                    value={zasilkyX.zasilky.SazbaDPH}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) => {onChangeValueZasilky(e)}}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}> 
                                <GreenButton fullWidth onClick={(e) => handleCloseZasilky(e)} > Zrušit </GreenButton>
                            </Grid>
                            <Grid item xs={12} sm={4}/> 
                            <Grid item xs={12} sm={4}> 
                                <GreenButton fullWidth onClick={(e) => handleEditFieldsZasilky(e)} startIcon={<SaveIcon />} >Uložit</GreenButton>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </Fade>
        </Modal>  




        <Dialog
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            className={classes.modal}
            open={openNahled}
            key="Nahled"
            fullWidth={true}
            maxWidth={'xl'}
            onClose={(e) => handleCloseNahled(e)}
            closeAfterTransition
            BackdropComponent={Backdrop}
        >
        <DialogContent>
            <Container maxWidth="lg">      
                <Nahled data={faktury} />    
            </Container>
        </DialogContent>
        </Dialog> 

        <Snackbar open={open} autoHideDuration={3600} onClose={handleClose}>
            <Alert onClose={handleClose} severity={severityMSG}>
                {textMSG}
            </Alert>
        </Snackbar>

    </Container>  
  );
}
